import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'
import FilterSelector from '../../elements/FilterSelector/FilterSelector'

const NewAssignedCategory = ({
  availableCategories,
  assignedCategory = {},
  addCategory,
  updateSubcategory,
  classes,
  index,
  removeNewlyAssignedCategory
}) => {
  const getSubcategories = () => {
    if (assignedCategory.parent_category_id) {
      const selectedCategory = availableCategories.find(category => category.id === assignedCategory.parent_category_id)
      return selectedCategory.subcategories
    } else {
      return []
    }
  }

  const [selectedCategoryId, setSelectedCategoryId] = useState(assignedCategory.parent_category_id || '')
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(assignedCategory.sub_category_id || '')
  const [subcategories, setSubcategories] = useState(getSubcategories())
  const categories = availableCategories.reduce((result, category) => {
    result.push({
      name: category.name,
      id: category.id
    })
    return result
  }, [])

  const handleCategorySelection = (event) => {
    const selectedCategoryId = event.target.value
    setSelectedCategoryId(selectedCategoryId)
    setSelectedSubcategoryId('')
    addCategory(categories.find(category => category.id === selectedCategoryId), index)
  }

  const handleSubcategorySelection = (event) => {
    const subcategoryId = event.target.value
    setSelectedSubcategoryId(subcategoryId)
    const subcategory = subcategories.find(subcategory => subcategory.id === subcategoryId)
    updateSubcategory(subcategory, index)
  }

  useEffect(() => {
    if (selectedCategoryId) {
      const selectedCategory = availableCategories.find(category => category.id === selectedCategoryId)
      setSubcategories(selectedCategory.subcategories)
    }
  }, [selectedCategoryId, availableCategories])

  return (
    <div className={classes.categoryContainer}>
      <div className={classes.selectContainer}>
        <Grid item xs={5} className={classes.parentCategorySelect}>
          <FilterSelector
            name={'newParentCategorySelector'}
            label={'Category'}
            value={selectedCategoryId}
            changeHandler={handleCategorySelection}
            selectionOptions={categories}
            optionValueKey={'name'}
            styles={{ selectorWidth: '100%' }}
            shrink
            displayLabel={false}
            defaultOption={'Select Category'}
          />
        </Grid>
        <Grid item xs={5} className={classes.subCategorySelect}>
          <FilterSelector
            name={'newSubCategorySelector'}
            label={'Subcategory'}
            value={selectedSubcategoryId}
            changeHandler={handleSubcategorySelection}
            selectionOptions={subcategories}
            optionValueKey={'name'}
            styles={{ selectorWidth: '100%' }}
            shrink
            displayLabel={false}
            defaultOption={'Select Subcategory'}
          />
        </Grid>
        <Grid item xs={2}>
          {
            index
              ? <IconButton
                aria-label="delete"
                className={classes.deleteButtonWrapper}
                onClick={() => removeNewlyAssignedCategory(index)}>
                <Delete className={classes.deleteIcon} />
              </IconButton>
              : null
          }
        </Grid>
      </div>

    </div>
  )
}

NewAssignedCategory.propTypes = {
  availableCategories: PropTypes.array,
  assignedCategory: PropTypes.object,
  addCategory: PropTypes.func,
  classes: PropTypes.func,
  index: PropTypes.number,
  updateSubcategory: PropTypes.func,
  removeNewlyAssignedCategory: PropTypes.func
}

export default NewAssignedCategory

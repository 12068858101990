const styles = {
  orderBasicDetailsContainer: {
    display: 'flex',
    width: '1210px',
    padding: '30px 0px',
    color: 'white'
  },
  labelVerticleAligned: {
    paddingTop: '8px'
  },
  label: {
    height: '34px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    margin: '8px 0px'
  },
  value: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px',
    margin: '8px 0px'
  },
  detailExtraLong: {
    width: '320px'
  },
  detailLong: {
    width: '280px'
  },
  detailMedium: {
    width: '180px'
  },
  detailShort: {
    width: '130px'
  },
  labelShort: {
    width: '90px'
  }
}

export default styles

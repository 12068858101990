import moment from 'moment'
import {
  FETCH_ORDERS,
  FETCH_ORDER_BY_ID,
  RESET_ORDERS_DATA,
  SET_ORDER_CANCELLATION_MESSAGE,
  RESET_ORDER_CANCELLATION_MESSAGE,
  UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'
import { formatFloatNumber } from '../../utils/formatters'
import { NON_KOSHER_MENU_TYPE_ID } from '../../constants/clientEvents'
import { DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const initialState = {}
const AdminRoleIds = [1, 2, 5]
const DEFAULT_TZ = 'America/New_York'

const roundToTwo = (number) => {
  return parseFloat(Math.round(number * 100) / 100).toFixed(2)
}

const getPaymentMethodName = (paymentMethods = [], paymentMethodId) => {
  let paymentMethod = ''
  const orderPaymentMethod = paymentMethods.find(method => method.id === paymentMethodId)
  if (orderPaymentMethod) {
    paymentMethod = orderPaymentMethod.status
  }
  return paymentMethod
}

const getPaymentStatusName = (paymentStatusList, paymentStatusId) => {
  let paymentStatus = ''
  const orderPaymentStatus = paymentStatusList.find(status => status.id === paymentStatusId)
  if (orderPaymentStatus) {
    paymentStatus = orderPaymentStatus.status
  }
  return paymentStatus
}

const getBasicOrderDetails = (order, orderBill, paymentStatusList, paymentMethodList) => {
  const {
    suite,
    company_name: companyName,
    event_name: eventName,
    event_date: eventDate,
    created_date: createdDate,
    total,
    menu_type_name: menuTypeName,
    menu_type_id: menuTypeId,
    submitted_by: submittedBy
  } = order
  const { payment_method: paymentMethodId, payment_status: paymentStatusId } = orderBill
  const paymentMethodName = getPaymentMethodName(paymentMethodList, paymentMethodId)
  const paymentStatusName = getPaymentStatusName(paymentStatusList, paymentStatusId)
  return {
    suite,
    companyName,
    eventName,
    eventDate,
    createdDate: moment(createdDate).tz(DEFAULT_TZ).format('MM/DD/YYYY'),
    total,
    menuTypeName,
    menuTypeId,
    paymentMethodName,
    paymentStatusName,
    submittedBy
  }
}

const getOrderSummary = (orderBill) => {
  const {
    sub_total: subTotal,
    total,
    tax_amount: taxAmount,
    gratuity_amount: gratuityAmount,
    gratuity_percentage: gratuityPercentage,
    gratuity_for_attendant: gratuityForAttendant,
    tip_from_terminal: tipFromTerminal
  } = orderBill
  return {
    subTotal: formatFloatNumber(subTotal),
    total: formatFloatNumber(total),
    taxAmount: formatFloatNumber(taxAmount),
    gratuityAmount: formatFloatNumber(gratuityAmount),
    gratuityPercentage,
    gratuityForAttendant: formatFloatNumber(gratuityForAttendant),
    tipFromTerminal: formatFloatNumber(tipFromTerminal)
  }
}

const getInfoTextData = (order, currentUser) => {
  const { menuTypeName } = order
  const cutoffDateTime = getCutoffTimeByRoleAndMenuType(currentUser, order)
  const formattedCutoffDateTime = cutoffDateTime.format('MMMM D, YYYY @ h:mm A')
  return {
    menuTypeName,
    formattedCutoffDateTime
  }
}

const getCheckoutOptionsSummary = (order, orderBill, inSuiteOptions) => {
  const {
    host_name: hostName,
    signature_required: signatureRequired,
    item_additions_by: itemAdditionsBy,
    liquor_cabinet_access: liquorCabinetAccess
  } = order
  const liquorCabinetAccessOption = liquorCabinetAccess || 1
  const {
    in_suite_addition_charge: chargeAdditionsTo,
    payment_details: paymentDetails,
    payment_status: paymentStatus,
    payment_method: paymentMethod
  } = orderBill
  return {
    signatureRequired: (signatureRequired === 1 ? 'Yes' : 'No'),
    hostName,
    item_additions_by: itemAdditionsBy,
    itemAdditionsBy: itemAdditionsBy ? inSuiteOptions.item_additions_by.find(option => option.id === itemAdditionsBy).value : 'N/A',
    liquorCabinetAccess: inSuiteOptions.liquor_cabinet_access.find(option => option.id === liquorCabinetAccessOption).value,
    chargeAdditionsTo: chargeAdditionsTo ? inSuiteOptions.charge_additions_to.find(option => option.id === chargeAdditionsTo).value : null,
    paymentDetails,
    paymentStatus,
    paymentMethod
  }
}

const getOrderItemsData = (orderItems) => {
  const allOrderItems = JSON.parse(JSON.stringify(orderItems))
  const orderItemsByType = {}
  const orderItemTypes = Object.keys(allOrderItems)
  orderItemTypes.forEach(itemType => {
    const orderItems = allOrderItems[itemType] || []
    if (orderItems && orderItems.length > 0) {
      orderItemsByType[itemType] = orderItems.map(item => {
        item.order_window = itemType
        item.menuItemId = item.menu_item_id
        item.orderWindow = itemType
        item.unitPrice = roundToTwo(item.unit_price)
        item.totalPrice = roundToTwo(item.total_price)
        delete item.unit_price
        delete item.total_price
        delete item.menu_item_id
        return item
      })
    }
  })
  return orderItemsByType
}

const getActionBarData = (order, currentUser) => {
  const { orderId } = order
  const isEditableByUser = isEditableByCurrentUser(currentUser, order)
  return { orderId, isEditableByCurrentUser: isEditableByUser }
}

const prepareRowData = (order, currentUser) => {
  const {
    status,
    event_date: eventDate,
    order_id: id,
    total,
    menu_type_name: menuTypeName,
    event_name: eventName,
    submitted_by: submittedBy,
    suite,
    company_name: companyName,
    created_date: createdDate,
    company_id: companyId,
    suite_id: suiteId,
    menu_type_id: menuTypeId,
    admin_kosher_cutoff: adminKosherCutoff,
    client_kosher_cutoff: clientKosherCutoff,
    admin_non_kosher_cutoff: adminNonKosherCutoff,
    client_non_kosher_cutoff: clientNonKosherCutoff
  } = order

  const cutoffDetails = {
    adminKosherCutoff,
    clientKosherCutoff,
    adminNonKosherCutoff,
    clientNonKosherCutoff
  }

  return {
    status,
    eventDate,
    id,
    total,
    menuTypeName,
    eventName,
    submittedBy,
    suite: `${suite} - ${companyName}`,
    createdDate: moment(createdDate).tz(DEFAULT_TZ).format('MM/DD/YYYY'),
    companyId,
    suiteId,
    menuTypeId,
    isEditableByCurrentUser: isEditableByCurrentUser(currentUser, { ...cutoffDetails, menuTypeId, status })
  }
}

const processOrderDetailsData = (orderData, staticData) => {
  const {
    inSuiteOptions,
    currentUser,
    paymentStatusList,
    paymentMethodList
  } = staticData
  const {
    order,
    order_bill: orderBill,
    order_items: allOrderItems,
    event_details: eventDetails
  } = orderData

  const {
    admin_kosher_cutoff: adminKosherCutoff,
    client_kosher_cutoff: clientKosherCutoff,
    admin_non_kosher_cutoff: adminNonKosherCutoff,
    client_non_kosher_cutoff: clientNonKosherCutoff
  } = eventDetails

  const cutoffDetails = {
    adminKosherCutoff,
    clientKosherCutoff,
    adminNonKosherCutoff,
    clientNonKosherCutoff
  }
  const { menu_type_id: menuTypeId, id: orderId, status } = order
  const actionBarData = getActionBarData({ ...cutoffDetails, menuTypeId, orderId, status }, currentUser)
  const basicOrderDetails = getBasicOrderDetails(order, orderBill, paymentStatusList, paymentMethodList)
  const infoTextData = getInfoTextData({ ...cutoffDetails, order }, currentUser)
  const orderSummary = getOrderSummary(orderBill)
  const orderItems = getOrderItemsData(allOrderItems)
  const checkoutOptionsSummary = getCheckoutOptionsSummary(order, orderBill, inSuiteOptions)
  return {
    actionBarData,
    basicOrderDetails,
    infoTextData,
    orderSummary,
    orderItems,
    checkoutOptionsSummary,
    order: prepareRowData({ ...order, ...cutoffDetails }, currentUser)
  }
}

const isOrderActive = ({ status }) => status === 'Active'

const isOrderEditable = (cutoffDateTime) => cutoffDateTime > moment()

const getCutoffTimeByRoleAndMenuType = (currentUser, order) => {
  const {
    adminKosherCutoff,
    adminNonKosherCutoff,
    clientKosherCutoff,
    clientNonKosherCutoff,
    menuTypeId
  } = order

  let cutoffDateTime

  const isAdminUser = AdminRoleIds.includes(currentUser.role_id)
  if (menuTypeId === NON_KOSHER_MENU_TYPE_ID) {
    cutoffDateTime = isAdminUser ? adminNonKosherCutoff : clientNonKosherCutoff
  } else {
    cutoffDateTime = isAdminUser ? adminKosherCutoff : clientKosherCutoff
  }

  return moment.utc(cutoffDateTime).tz(DEFAULT_TZ)
}

const isEditableByCurrentUser = (currentUser, order) => {
  const cutoffDateTime = getCutoffTimeByRoleAndMenuType(currentUser, order)
  return isOrderActive(order) ? isOrderEditable(cutoffDateTime) : false
}

const updateOrdersList = ({ orderId, allOrdersList, ordersListByEvent, currentPage, successfulCancellation }) => {
  let ordersList
  if (currentPage === DEFAULT_CURRENT_PAGE) {
    ordersList = allOrdersList
  } else {
    ordersList = ordersListByEvent
  }
  const { rows = [] } = ordersList
  const cancelledOrderIndex = rows.findIndex(row => row.id === orderId)
  if (cancelledOrderIndex > -1 && successfulCancellation) {
    const cancelledOrder = rows[cancelledOrderIndex]
    cancelledOrder.status = 'Cancelled'
    cancelledOrder.isEditableByCurrentUser = false
  } else if (cancelledOrderIndex > -1 && !successfulCancellation) {
    const cancelledOrder = rows[cancelledOrderIndex]
    cancelledOrder.isEditableByCurrentUser = true
  }
  return ordersList
}

export default function OrdersReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS:
      const { currentPage } = action.payload
      const ordersList = processItemsList(action.payload, prepareRowData)
      let ordersListData
      if (currentPage === DEFAULT_CURRENT_PAGE) {
        ordersListData = { allOrdersList: ordersList }
      } else {
        ordersListData = { ordersListByEvent: ordersList }
      }
      return {
        ...state,
        ...ordersListData,
        currentPage
      }
    case FETCH_ORDER_BY_ID:
      return {
        ...state,
        OrderByIdData: processOrderDetailsData(action.payload.orderData, action.payload.staticData)
      }
    case RESET_ORDERS_DATA:
      return initialState
    case SET_ORDER_CANCELLATION_MESSAGE:
      return {
        ...state,
        orderCancellationMessage: action.payload.message,
        orderCancellationStatus: action.payload.status
      }
    case UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION:
      return {
        ...state,
        ordersList: updateOrdersList(action.payload)
      }
    case RESET_ORDER_CANCELLATION_MESSAGE:
      return {
        ...state,
        orderCancellationMessage: undefined,
        orderCancellationStatus: undefined
      }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Header from '../elements/Header/Header'
import MainNav from '../elements/MainNav/MainNav'
import { verifyToken } from '../utils/verifyToken'
import { routes } from '../pages/index'
import { getRoutes } from '../utils/getRoutes'

const MainContainer = styled.div`
  max-width: 100%;
  margin: auto;

  h3 {
    font-family: 'Ringside Condensed A', 'Ringside Condensed B';
    font-size: 20px;
  }
`
const ContentContainer = styled.div`
  min-height: 100vh;
  background-color: #2a2d3b;
`
const ContentGrid = styled.div`
  margin: auto;
`

const MainLayout = ({ component: Component, route, currentUser }) => {
  return (
    <MainContainer>
      {verifyToken()}
      <Header currentUser={currentUser} />
      <MainNav routes={getRoutes(currentUser, routes)} />
      <ContentContainer>
        <ContentGrid>
          <Component route={route} />
        </ContentGrid>
      </ContentContainer>
    </MainContainer>
  )
}

MainLayout.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  route: PropTypes.object.isRequired,
  currentUser: PropTypes.object
}

const mapStateToProps = ({ authReducer }) => ({
  currentUser: authReducer.user
})

export default connect(mapStateToProps, {})(MainLayout)

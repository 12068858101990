export const getGlutenFreeSelectedOption = (foodTypes) => {
  return foodTypes.reduce((accumulator, type) => (accumulator || (type.id === 'gluten_free' && type.selected)), false)
}

export const getVeganSelectedOption = (foodTypes) => {
  return foodTypes.reduce((accumulator, type) => (accumulator || (type.id === 'vegan' && type.selected)), false)
}

export const filteredCategoryByFoodType = (filteredItems, isGlutonFreeSelected, isVeganSelected) => {
  let clonedFilteredItems = [...filteredItems]
  if (isGlutonFreeSelected && isVeganSelected) {
    clonedFilteredItems = filteredItems.filter(item => {
      const { isGlutenFree, isVegan } = item
      return isGlutenFree || isVegan
    })
  } else if (isGlutonFreeSelected && !isVeganSelected) {
    clonedFilteredItems = filteredItems.filter(item => {
      const { isGlutenFree } = item
      return isGlutenFree
    })
  } else if (!isGlutonFreeSelected && isVeganSelected) {
    clonedFilteredItems = filteredItems.filter(item => {
      const { isVegan } = item
      return isVegan
    })
  }
  return clonedFilteredItems
}

export const filteredCategories = (menuItemsObj, isGlutonFreeSelected, isVeganSelected) => {
  const clonedMenuItemsObj = { ...menuItemsObj }
  const searchResults = {}
  for (const category in clonedMenuItemsObj) {
    const filteredItems = clonedMenuItemsObj[category] ? filteredCategoryByFoodType(clonedMenuItemsObj[category], isGlutonFreeSelected, isVeganSelected) : []
    searchResults[category] = filteredItems
  }
  return searchResults
}

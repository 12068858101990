import React from 'react'
import PropTypes from 'prop-types'
const ViewEventSuiteTitle = ({
  className,
  actionMode
}) => {
  const title = `${actionMode} Event Suite`
  return (
    <div className={className}>
      {title}
    </div>
  )
}
ViewEventSuiteTitle.propTypes = {
  className: PropTypes.string,
  actionMode: PropTypes.string.isRequired
}

export default ViewEventSuiteTitle

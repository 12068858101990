import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomFabButton from '../CustomFabButton/CustomFabButton'

const SECTION_LABEL = 'Charge Additions To'
const VALIDATION_ERROR_MESSAGE = 'You must complete this field.'

const EVENT_SPLIT_PAYMETN_OPTION_ID = 4

const getItemAddtionsChargeOptions = (classes, options, selectedOption, handleSelection, usedCreditCardsCount) => {
  return options.map(option => {
    if (option.id === EVENT_SPLIT_PAYMETN_OPTION_ID) {
      return usedCreditCardsCount > 1 ? <CustomFabButton
        key={option.id}
        variant="extended"
        color="default"
        styleClasses={`${classes.genericFabPill} ${selectedOption === option.id ? classes.activeFabPill : ''}`}
        disableRipple
        disableFocusRipple
        label={option.value}
        value={option.id}
        clickHandler={handleSelection}
      /> : null
    } else {
      return <CustomFabButton
        key={option.id}
        variant="extended"
        color="default"
        styleClasses={`${classes.genericFabPill} ${selectedOption === option.id ? classes.activeFabPill : ''}`}
        disableRipple
        disableFocusRipple
        label={option.value}
        value={option.id}
        clickHandler={handleSelection}
      />
    }
  })
}
const ItemAdditionCharge = ({
  classes,
  inSuiteAdditionsChargeOptions,
  inSuiteAdditionsCharge,
  setInSuiteAdditionsCharge,
  showValidationErrorMessage,
  usedCreditCardsCount
}) => {
  return (
    <div className={classes.paymentMethodOptionsContainer}>
      <div className={classes.sectionTitle}>
        {SECTION_LABEL.toUpperCase()}
      </div>
      <div className={classes.paymentMethodButtonsContainer}>
        {getItemAddtionsChargeOptions(classes, inSuiteAdditionsChargeOptions, inSuiteAdditionsCharge, setInSuiteAdditionsCharge, usedCreditCardsCount)}
      </div>
      {
        showValidationErrorMessage &&
        (!inSuiteAdditionsCharge || inSuiteAdditionsCharge === 1) &&
        <FormHelperText className={classes.inputTextHelper}>
          {VALIDATION_ERROR_MESSAGE}
        </FormHelperText>
      }
    </div>
  )
}

ItemAdditionCharge.propTypes = {
  classes: PropTypes.object.isRequired,
  inSuiteAdditionsChargeOptions: PropTypes.array,
  inSuiteAdditionsCharge: PropTypes.number,
  setInSuiteAdditionsCharge: PropTypes.func.isRequired,
  showValidationErrorMessage: PropTypes.bool,
  usedCreditCardsCount: PropTypes.number
}

ItemAdditionCharge.defaultProps = {
  showValidationErrorMessage: false,
  inSuiteAdditionsCharge: null,
  inSuiteAdditionsChargeOptions: []
}

export default ItemAdditionCharge

import {
  FETCH_CLIENT_EVENTS,
  FILTER_CLIENT_EVENTS,
  FETCH_CLIENT_EVENTS_IN_PROGRESS
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { clientEventsEndpoint } from '../../constants/apiConfig'
import { prepareEventsListQuery, validFilters } from '../helpers/prepareClientEventsListQuery'
import { applyFilter, paginateClientEvents } from '../helpers/clientEventsFilter'
import { getItemsList } from '../helpers/apiClient'

export const fetchClientEvents = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  dispatch({
    type: FETCH_CLIENT_EVENTS_IN_PROGRESS,
    payload: { fetchClientEventsInProgress: true }
  })
  const {
    user_details: currentUser = {}
  } = store.getState().staticData
  const { method, url } = clientEventsEndpoint(currentUser.id).list()
  const data = prepareEventsListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { events: items } = data
      const totalPages = Math.ceil(items.length / limit)
      dispatch({
        type: FETCH_CLIENT_EVENTS,
        payload: { items, meta: { totalPages }, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const filterClientEvents = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const {
    clientEventsList = {}
  } = store.getState().clientEventsData
  const data = validFilters(filters, page, limit, sortParams)
  const filteredEvents = applyFilter(clientEventsList, data)
  const paginatedEvents = paginateClientEvents(filteredEvents, data.pagination)
  const totalPages = Math.ceil(filteredEvents.length / limit)
  dispatch({
    type: FILTER_CLIENT_EVENTS,
    payload: { ...clientEventsList,
      rows: paginatedEvents,
      limit,
      page,
      totalPages,
      filters,
      totalEventsCount: filteredEvents.length
    }
  })
}

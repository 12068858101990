import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { fetchEventEngineEvents } from '../../store/events/actions'
import Dialog from '../../elements/Dialog/Dialog'
import EditEventDialogContent from './EditEventDialogContent'
import styles from './EditEvent.css'

const EditEvent = ({
  classes,
  event,
  eventCategories,
  eventEngineEvents,
  fetchEventEngineEvents,
  handleDialogClose,
  handleUpdate,
  openDialog
}) => {
  useEffect(() => {
    if (event && event.start_date && openDialog) {
      fetchEventEngineEvents(event.start_date)
    }
  }, [event, fetchEventEngineEvents, openDialog])

  const getDialogContent = <EditEventDialogContent
    event={event}
    eventEngineEvents={eventEngineEvents}
    eventCategories={eventCategories}
    handleUpdate={handleUpdate}
    handleCancel={handleDialogClose}
  />

  return (
    <>
      {
        event &&
        <Dialog
          classes={{
            genericPaperStyles: classes.genericPaperStyles,
            genericDialogActionRoot: classes.genericDialogActionRoot,
            genericDialogContentRoot: classes.genericDialogContentRoot,
            genericDialogTitleRoot: classes.genericDialogTitleRoot
          }}
          dialogActions={null}
          dialogContent={getDialogContent}
          dialogTitle={null}
          handleDialogClose={handleDialogClose}
          openDialog={openDialog}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    eventCategories: state.staticData.event_categories,
    event: state.eventsData.eventToEdit,
    eventEngineEvents: state.eventsData.eventEngineEvents
  }
}

const mapDispatchToProps = {
  fetchEventEngineEvents
}

EditEvent.propTypes = {
  classes: PropTypes.object,
  event: PropTypes.object,
  eventCategories: PropTypes.array,
  eventEngineEvents: PropTypes.array,
  fetchEventEngineEvents: PropTypes.func,
  handleDialogClose: PropTypes.func,
  handleUpdate: PropTypes.func,
  openDialog: PropTypes.bool
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEvent))

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SearchField from '../SearchField/SearchField'
import ResetFilters from '../ResetFilters/ResetFilters'

const CompaniesFilterBar = ({
  filters = {
    query: ''
  },
  styles,
  filterItems
}) => {
  const [query, setSearchQuery] = useState(filters.query)
  const [resetFilters, setResetFiltersFlag] = useState(false)

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const clearFilters = () => {
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  const handleSubmit = () => {
    filterItems({ query })
  }

  return (
    <React.Fragment>
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
    </React.Fragment>
  )
}

CompaniesFilterBar.propTypes = {
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  styles: PropTypes.object
}

export default CompaniesFilterBar

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const CustomButton = ({
  variant,
  disableRipple,
  styleClass,
  label,
  color,
  clickHandler,
  disabled
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      disableRipple={disableRipple}
      onClick={() => { clickHandler() }}
      classes={{ root: styleClass }}
      disabled={!!disabled}>
      {label}
    </Button>
  )
}

CustomButton.defaultProps = {
  disableRipple: false,
  color: 'default',
  disabled: false
}

CustomButton.propTypes = {
  variant: PropTypes.string,
  disableRipple: PropTypes.bool,
  styleClass: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool
}

export default CustomButton

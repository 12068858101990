
const clientEventsFilterBarStyles = {
  searchIcon: {
    fontSize: '34px',
    marginTop: '15px'
  },
  searchIconContainer: {
    height: '50px',
    paddingRight: '14px',
    cursor: 'pointer'
  },
  clearFilters: {
    paddingTop: '12px',
    cursor: 'pointer',
    height: '36px',
    width: '82px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular A',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '36px',
    textAlign: 'right',
    float: 'right',
    marginLeft: '12px'
  },
  dateSeperatorLabel: {
    color: 'white',
    fontSize: '1rem',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontWeight: 400,
    lineHeight: 2,
    letterSpacing: '0.00938em',
    verticalAlign: 'bottom'
  }
}

export default clientEventsFilterBarStyles

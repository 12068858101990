import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@material-ui/core'
import styled from 'styled-components'

const NameField = styled(TextField)`
  input {
    font-size: 30px;

    &[disabled] {
      color: gray;
    }
  }
`

const ShareTypeName = ({ isDisabled, name, onChange }) => (
  <FormControl>
    <NameField
      disabled={isDisabled}
      InputLabelProps={{ shrink: true }}
      label="Name"
      onChange={e => onChange(e.target.value)}
      placeholder="Name your share type"
      value={name}
      variant="outlined"
    />
  </FormControl>
)

ShareTypeName.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

ShareTypeName.defaultProps = {
  isDisabled: false,
  name: ''
}

export default ShareTypeName

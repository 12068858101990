import React from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const CardsTable = ({
  classes,
  columns,
  rows,
  handleDeleteCard
}) => {
  const tableHeaders = (<thead>
    <tr>
      {columns.map((column, index) => {
        return <th key={index}>{column.label}</th>
      })}
    </tr>
  </thead>)

  const tableBody = rows.map((row, rowIndex) => {
    return (
      <tr key={rowIndex}>
        {columns.map((column, index) => {
          const rowValue = (column['field'] === 'card_name') ? `${row[column['field']]} ${row['last_four_digits'] || ''}` : (column['field'] === 'delete') ? <IconButton onClick={handleDeleteCard} id={rowIndex} className={classes.paymentCardDeleteButton} aria-label="delete">
            <DeleteIcon />
          </IconButton> : row[column['field']]
          return <td key={index}>{rowValue}</td>
        })}
      </tr>
    )
  })

  return (
    <table className={classes.paymentTable} width="100%">
      {tableHeaders}
      <tbody>
        {tableBody}
      </tbody>
    </table>)
}

CardsTable.propTypes = {
  classes: PropTypes.object,
  handleDeleteCard: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array
}

export default CardsTable

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const ConfirmationActions = ({ classes, onCancel, onSubmit }) => {
  return (
    <div className={classes.actionContainer}>
      <Button variant="outlined" color="default" className={classes.cancelButton} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="outlined" color="primary" className={classes.submitButton} onClick={onSubmit}>
        Confirm
      </Button>
    </div>
  )
}

ConfirmationActions.propTypes = {
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

ConfirmationActions.defaultProps = {
  classes: {}
}

export default ConfirmationActions

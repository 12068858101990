const styles = {
  orderItemsListContainer: {
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '15px',
    paddingTop: '15px'
  },
  topMargin: {
    marginTop: '4px'
  },
  orderLineItemContainer: {
    display: 'flex',
    padding: '15px 0px',
    width: '604px'
  },
  quantity: {
    width: '50px',
    textAlign: 'right',
    marginRight: '15px'
  },
  name: {
    width: '303px',
    marginLeft: '35px'
  },
  unitPrice: {
    width: '90px',
    textAlign: 'right',
    paddingRight: '14px'
  },
  totalPrice: {
    width: '90px',
    textAlign: 'right',
    paddingRight: '18px'
  },
  deleteIcon: {
    height: '22px',
    width: '23px',
    color: '#000000',
    fontFamily: 'SS Standard',
    fontSize: '16px',
    lineHeight: '22px',
    cursor: 'pointer'
  },
  disabledIcon: {
    marginTop: '-10px',
    marginLeft: '-24px'
  },
  orderGroupName: {
    marginLeft: '30px',
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '15px',
    padding: '13px 0px',
    textTransform: 'capitalize'
  }
}

export default styles

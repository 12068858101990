const styles = {
  container: {
    height: '119px',
    width: '578px'
  },
  label: {
    height: '14px',
    width: '274px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  imagePreviewWrapper: {
    display: 'flex'
  },
  imagePreviewContainer: {
    marginRight: '40px'
  },
  selectedImageName: {
    height: '24px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    marginBottom: '10px'
  },
  image: {
    width: '170px',
    height: '95px'
  },
  uploadButtonContainer: {

  },
  uploadImagebutton: {
    boxSizing: 'border-box',
    height: '50px',
    width: '170px',
    border: '1px solid #000000',
    borderRadius: '0px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center'
  }
}

export default styles

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { withStyles } from '@material-ui/styles'
import styles from './ScrollToTopButton.css'

const ScrollToTopButton = ({ scrollStepInPx, delayInMs, classes }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [intervalId, setIntervalId] = useState(0)

  useEffect(() => {
    if (scrollPosition === 0) {
      clearInterval(intervalId)
    }
  }, [scrollPosition])

  const scrollStep = () => {
    setScrollPosition(window.pageYOffset)
    window.scroll(0, window.pageYOffset - scrollStepInPx)
  }

  const scrollToTop = () => {
    const interval = setInterval(scrollStep, delayInMs)
    setIntervalId(interval)
  }

  return (
    <IconButton className={classes.scrollToTopButton} onClick={scrollToTop} title="Back to top" >
      <ArrowUpwardIcon className={classes.arrowUpwardIcon} />
    </IconButton>
  )
}

ScrollToTopButton.propTypes = {
  classes: PropTypes.object,
  delayInMs: PropTypes.number,
  scrollStepInPx: PropTypes.number
}

export default withStyles(styles)(ScrollToTopButton)

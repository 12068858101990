import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DataContainer from '../../elements/DataContainer/DataContainer'
import SubNav from '../../elements/SubNav/SubNav'
import LoadingState from '../../elements/LoadingState/LoadingState'
import ExportReport from '../../Dialogs/ExportReport/ExportReport'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
import {
  ReconciliationReportProperties,
  styles,
  rowsForReconciliation,
  reconciliationResourceName
} from '../../constants/reports'
import { fetchReconciliationReport, exportReconciliationReport } from '../../store/Reports/actions'
import FilterBar from '../../elements/FilterBar/FilterBar'
import ReportsFilterBar from '../../elements/ReportsFilterBar/ReportsFilterBar'
import { routes } from '..'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))
const ReconciliationReport = ({
  companies,
  exportReconciliationReport,
  fetchItems,
  filters,
  limit: rowsPerPage = defaultLimit,
  page = defaultPage,
  rows,
  showSpinner,
  sortParams,
  suites,
  totalPages = defaultTotalPages
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, rowsPerPage, sort)
  }

  const exportReport = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    fetchItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  return (
    <div>
      <SubNav routes={routes.find(({ path }) => path === '/reports').childRoutes} />
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <ReportsFilterBar
            companies={companies}
            suites={suites}
            exportReport={exportReport}
            disableExportButton={showSpinner}
          />
        </FilterBar>
        <Suspense fallback={<div>Loading...</div>}>
          { rows && <LazyTable
            properties={ReconciliationReportProperties}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSorting={handleSorting}
            sortParams={sortParams}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsForReconciliation}
            resourceName={reconciliationResourceName}
          />}
        </Suspense>
      </DataContainer>
      {
        showSpinner && <LoadingState />
      }
      {openDialog && <ExportReport handleDialogClose={handleDialogClose} handleExportReport={exportReconciliationReport} openDialog={openDialog} title="Export Reconciliation Report" />}
    </div>
  )
}

ReconciliationReport.propTypes = {
  companies: PropTypes.array,
  exportReconciliationReport: PropTypes.func,
  fetchItems: PropTypes.func,
  filters: PropTypes.object,
  limit: PropTypes.number,
  page: PropTypes.number,
  rows: PropTypes.array,
  showSpinner: PropTypes.bool,
  sortParams: PropTypes.object,
  suites: PropTypes.array,
  totalPages: PropTypes.number
}

const mapStateToProps = ({ reportsData, staticData }) => {
  return {
    ...reportsData.reconciliationReportData,
    showSpinner: reportsData.showSpinner,
    companies: staticData.companies,
    suites: staticData.suites
  }
}

const mapDispatchToProps = {
  fetchItems: fetchReconciliationReport,
  exportReconciliationReport
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReconciliationReport)
)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { REQUIRED_RECOMMENDATIONS_COUNT } from '../../constants/menuItems'
import {
  ANALYTICS_ACTION_CAROUSEL_CLICK,
  ANALYTICS_ACTION_CAROUSEL_CLICK_LEFT,
  ANALYTICS_ACTION_CAROUSEL_CLICK_RIGHT,
  ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK,
  ANALYTICS_ACTION_LOCATION_DEAL_MODULE,
  ANALYTICS_PAGE_TYPE_MENU_CATALOG
} from '../../constants/analytics'
import { reportAnalytics } from '../../utils/analytics'
import ItemsSlider from '../ItemsSlider/ItemsSlider'
import Bubble from '../Bubble/Bubble'
import { reportGoogleAnalytics } from '../../utils/googleAnalytics'
import useStyles from './Recommendations.css'

const Recommendations = ({
  showMenuDetails,
  handleAddToCart,
  recommendations = [],
  title,
  orderWindow
}) => {
  const classes = useStyles()
  const [currentIndex, setCurrentIndex] = useState(0)
  const recommendationsCount = Math.min(recommendations.length, REQUIRED_RECOMMENDATIONS_COUNT)
  const slidesCount = recommendationsCount % 2 === 0
    ? parseInt((recommendationsCount / 2), 10)
    : parseInt((recommendationsCount / 2), 10) + 1

  useEffect(() => {
    if (currentIndex > slidesCount - 1) {
      setCurrentIndex(slidesCount - 1)
    }
  }, [slidesCount, currentIndex, setCurrentIndex, recommendations])

  const showPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      reportGoogleAnalytics('click_carousel', {
        click_source: 'orders_cart',
        click_type: 'pagination_prev',
        link_position: currentIndex })
      reportAnalytics({
        eventType: ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK,
        detail: {
          actionName: ANALYTICS_ACTION_CAROUSEL_CLICK,
          actionType: ANALYTICS_ACTION_CAROUSEL_CLICK_LEFT,
          actionLocation: ANALYTICS_ACTION_LOCATION_DEAL_MODULE,
          actionTargetIndex: currentIndex
        },
        pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
      })
    }
  }

  const showNext = () => {
    if (currentIndex < slidesCount - 1) {
      setCurrentIndex(currentIndex + 1)
      reportGoogleAnalytics('click_carousel', {
        click_source: 'orders_cart',
        click_type: 'pagination_next',
        link_position: currentIndex })
      reportAnalytics({
        eventType: ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK,
        detail: {
          actionName: ANALYTICS_ACTION_CAROUSEL_CLICK,
          actionType: ANALYTICS_ACTION_CAROUSEL_CLICK_RIGHT,
          actionLocation: ANALYTICS_ACTION_LOCATION_DEAL_MODULE,
          actionTargetIndex: currentIndex
        },
        pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
      })
    }
  }

  const showSelected = (index) => {
    setCurrentIndex(index)
  }

  return (
    <div className={classes.recommendations_container}>
      <div className={classes.title}>
        {title}
      </div>
      <ItemsSlider
        items={recommendations.slice(0, REQUIRED_RECOMMENDATIONS_COUNT)}
        classes={classes}
        currentIndex={currentIndex}
        showMenuDetails={showMenuDetails}
        handleAddToCart={handleAddToCart}
        orderWindow={orderWindow}
      />
      <div className={classes.sliderNav}>
        <KeyboardArrowLeft onClick={showPrevious} className={classes.arrow} />
        {
          recommendations.slice(0, slidesCount).map((item, index) =>
            <Bubble
              key={index}
              showSelected={showSelected}
              currentIndex={currentIndex}
              index={index}
            />
          )
        }
        <KeyboardArrowRight onClick={showNext} className={classes.arrow} />
      </div>
    </div>
  )
}

Recommendations.propTypes = {
  showMenuDetails: PropTypes.func,
  handleAddToCart: PropTypes.func,
  recommendations: PropTypes.array,
  title: PropTypes.string,
  orderWindow: PropTypes.string
}

export default Recommendations

import React from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

const InputField = ({
  changeHandler,
  classes,
  disabled,
  id,
  label,
  placeholder,
  required,
  showLabel,
  value

}) => {
  return (
    <React.Fragment>
      {
        showLabel &&
        <InputLabel
          htmlFor={placeholder}
          classes={{ root: classes.inputLabelRoot }}
        >
          {required && <sup>*</sup>}
          {label}
        </InputLabel>
      }
      <Input
        classes={{ root: classes.inputRoot }}
        disabled={!!disabled}
        id={id}
        name={placeholder}
        onChange={(event) => changeHandler(event.target.value)}
        placeholder={placeholder}
        required={required}
        value={value}
      />
    </React.Fragment>
  )
}

InputField.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  value: PropTypes.string
}

InputField.defaultProps = {
  disabled: false,
  id: '',
  label: '',
  placeholder: '',
  required: false,
  showLabel: false,
  value: ''
}

export default InputField

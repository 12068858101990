import React from 'react'
import PropTypes from 'prop-types'
import RecommendedItem from '../RecommendedItem/RecommendedItem'
import useStyles from './ItemsSlider.css'
const ItemsSlider = ({
  items = [],
  currentIndex,
  showMenuDetails,
  handleAddToCart,
  orderWindow
}) => {
  const classes = useStyles({ currentIndex })
  return (
    <div className={classes.slider_container}>
      <div className={classes.items_container}>
        { items && items.map((item, index) =>
          <RecommendedItem
            key={item.id}
            item={item}
            classes={classes}
            showMenuDetails={showMenuDetails}
            handleAddToCart={handleAddToCart}
            orderWindow={orderWindow}
          />)
        }
      </div>
    </div>
  )
}

ItemsSlider.propTypes = {
  items: PropTypes.array,
  currentIndex: PropTypes.number,
  showMenuDetails: PropTypes.func,
  handleAddToCart: PropTypes.func,
  orderWindow: PropTypes.string
}

export default ItemsSlider

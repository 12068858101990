const styles = {
  paymentMethodOptionsContainer: {
    padding: '10px 40px'
  },
  sectionTitle: {
    margin: '12px 0px',
    height: '14px',
    color: 'black',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  paymentMethodButtonsContainer: {
    '& button:last-of-type': {
      marginRight: '0px'
    }
  },
  genericFabPill: {
    background: 'transparent',
    boxShadow: 'none',
    height: '28px',
    fontSize: '13px',
    fontWeight: '300',
    lineHeight: '28px',
    textAlign: 'center',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    padding: '0px 20px',
    marginRight: '20px'
  },
  inactiveFabPill: {
    background: 'transparent',
    color: 'black',
    '&:hover': {
      color: 'white',
      background: 'black',
      boxShadow: 'none'
    }
  },
  activeFabPill: {
    background: 'black',
    color: 'white',
    '&:hover': {
      color: 'white',
      background: 'black',
      boxShadow: 'none'
    }
  },
  input: {
    borderBottom: '2px dotted black',
    marginTop: '15px',
    width: '460px',
    '& input': {
      color: 'black',
      height: '14px',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '10px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px'
    },
    '&:hover': {
      borderBottom: '2px dotted black',
      color: 'black'
    }
  },
  inputTextHelper: {
    color: 'red',
    marginTop: '2px',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  tooltip: {
    background: 'black',
    color: 'white',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px'
  },
  infoIconRoot: {
    width: '20px',
    height: '20px',
    margin: '0px 0px 4px 4px'
  }
}

export default styles

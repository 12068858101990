import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { reportAnalytics } from '../../utils/analytics'
import { storeOrderItems } from '../../store/CheckoutFlow/actions'
import { getRecommendations } from '../../store/suiteHolderUsers/actions'
import { removeItemFromCart, updateQuantity } from '../../pages/Checkout/CheckoutHelpers'
import QuantitySelector from '../QuantitySelector/QuantitySelector'
import Recommendations from '../Recommendations/Recommendations'
import CustomButton from '../CustomButton/CustomButton'
import { currentUserIsClient } from '../../store/helpers/userRoleManager'
import {
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_EVENT_CART_QUICK_VIEW,
  ANALYTICS_ACTION_RECOMMENDED_CART_VIEW,
  ANALYTICS_ACTION_TYPE_CART_VIEW
} from '../../constants/analytics'
import {
  ADVANCE_PRICE_WINDOW
} from '../../constants/menuItems'

import styles from './CartQuickView.css'

const PAGE_TYPE = 'cart_quick_view'
const RECOMMENDATION_TITLE = 'You May Also Like'

const lineItem = (item, handleQuantityChange, handleRemoveItemFromCart, classes, orderWindow, index, currentUser) => {
  const { menuItemId, quantity, menu_item_name: name, totalPrice, orderWindow: itemOrderWindow, advanced_only: advancedOnly } = item
  return <div className={classes.lineItemContainer} key={index}>
    <QuantitySelector
      disableQuantityChange={itemOrderWindow.toLowerCase() !== orderWindow.toLowerCase()}
      itemId={menuItemId}
      quantity={quantity}
      handleQuantityChange={handleQuantityChange}
      classes={{
        quntitySelector: classes.quantitySelector,
        selectQuantity: classes.selectQuantity
      }} />
    <div className={classes.lineItemName}>{name}</div>
    <div className={classes.lineItemPrice}>{`$${totalPrice}`}</div>
    <IconButton
      aria-label="delete"
      className={classes.deleteButtonWrapper}
      onClick={() => handleRemoveItemFromCart(menuItemId, itemOrderWindow)}
      disabled={itemOrderWindow.toLowerCase() !== orderWindow.toLowerCase() && currentUserIsClient(currentUser) && advancedOnly}>
      <Delete className={classes.deleteIcon} />
    </IconButton>
  </div>
}

const billSummaryLineItem = (item, classes) => {
  return <div key={item.field1} className={classes.lineItemContainer}>
    <div className={classes.lineItemTitle}>{item.field1}</div>
    <div className={classes.lineItemPrice}>{`$${item.field3}`}</div>
  </div>
}

const priceByOrderWindow = (item, effectiveOrderWindow) => {
  return effectiveOrderWindow.toLowerCase() === ADVANCE_PRICE_WINDOW ? item.advancePrice : item.gamedayPrice
}

const CartQuickView = ({
  billDetails: {
    formattedBillSummary = []
  } = {},
  cartItems,
  classes,
  companyName,
  currentUser,
  eventId,
  getRecommendations,
  handleAddToCart,
  orderId,
  orderWindow,
  orderItems = [],
  openMenuItemDetailsDialog,
  proceedToCheckout,
  recommendations = [],
  storeOrderItems,
  suiteDisplayName
}) => {
  const activeCartItems = orderItems.filter(item => item._destroy !== 1)
  const hasCartItems = (orderItems) => Array.isArray(orderItems) && orderItems.length > 0

  const activeRecommendations = recommendations.filter(recommendation => recommendation.isActive)

  useEffect(() => {
    getRecommendations(PAGE_TYPE)
  }, [])

  useEffect(() => {
    if (activeRecommendations.length > 0) {
      reportAnalytics({
        eventType: ANALYTICS_EVENT_CART_QUICK_VIEW,
        detail: {
          actionClient: companyName,
          actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
          actionEventId: eventId,
          actionOrderId: orderId,
          actionMenuSuite: suiteDisplayName,
          actionName: ANALYTICS_ACTION_RECOMMENDED_CART_VIEW,
          actionType: ANALYTICS_ACTION_TYPE_CART_VIEW,
          actionRecommendeditems: activeRecommendations.map(item => {
            const { id, name } = item
            const price = priceByOrderWindow(item, orderWindow)

            return {
              productInfo: {
                productID: id,
                productName: name,
                productPrice: price
              }
            }
          })
        },
        pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
      })
    }
  }, [activeRecommendations])

  const handleQuantityChange = (itemId, quantity) => {
    updateQuantity(itemId, orderItems, quantity, orderWindow, storeOrderItems, orderId)
  }

  const handleRemoveItemFromCart = (itemId, itemOrderWindow) => {
    removeItemFromCart(itemId, orderItems, itemOrderWindow, storeOrderItems, orderId)
  }

  return (
    <div>
      <div><div className={classes.arrowToCartButton} /></div>
      <div className={classes.cartQuickView}>
        <div className={classes.cartQuickViewTitle}>Your Order</div>
        {
          orderItems.length === 0 && <span className={classes.emptyCart}>{`Empty Cart = Empty Stomachs. Your event is incomplete without treats in your suite. Fill me up with food and beverages for your guests!`}</span>
        }
        <div className={classes.cartSummaryContainer}>
          <div className={classes.lineItemsContainer}>
            { cartItems.map((item, index) => lineItem(item, handleQuantityChange, handleRemoveItemFromCart, classes, orderWindow, index, currentUser)) }
          </div>
          <div className={classes.orderBillContainer}>
            { hasCartItems(activeCartItems) && formattedBillSummary.map(item => billSummaryLineItem(item, classes)) }
          </div>
        </div>
        <div className={classes.cartQuickViewActions}>
          <CustomButton
            variant={'contained'}
            disableRipple
            styleClass={classes.proceedToCheckoutButton}
            label={'Proceed To Checkout'}
            color={'primary'}
            disabled={orderItems.length === 0}
            clickHandler={() => proceedToCheckout(orderItems)}
          />
        </div>
        <div className={classes.recommendationsContainer}>
          {
            activeRecommendations && activeRecommendations.length > 0 && <Recommendations
              showMenuDetails={openMenuItemDetailsDialog}
              handleAddToCart={handleAddToCart}
              orderWindow={orderWindow}
              currentUser={currentUser}
              recommendations={activeRecommendations}
              title={RECOMMENDATION_TITLE}
            />
          }
        </div>
      </div>

    </div>
  )
}

CartQuickView.propTypes = {
  billDetails: PropTypes.object,
  cartItems: PropTypes.array,
  classes: PropTypes.object,
  companyName: PropTypes.string,
  currentUser: PropTypes.object,
  eventId: PropTypes.number,
  getRecommendations: PropTypes.func,
  handleAddToCart: PropTypes.func,
  openMenuItemDetailsDialog: PropTypes.func,
  orderId: PropTypes.number,
  orderItems: PropTypes.array,
  orderWindow: PropTypes.string,
  proceedToCheckout: PropTypes.func,
  recommendations: PropTypes.array,
  storeOrderItems: PropTypes.func,
  suiteDisplayName: PropTypes.string
}

const mapStateToProps = ({
  checkoutData: {
    billDetails,
    company,
    eventId,
    orderId,
    orderItems,
    orderWindow,
    recommendations,
    suite
  } = {}
}) => {
  return {
    billDetails,
    companyName: company.name,
    eventId,
    orderId,
    orderItems,
    orderWindow,
    recommendations,
    suiteDisplayName: suite.display_name
  }
}

const mapDispatchToProps = {
  getRecommendations,
  storeOrderItems
}

const CartQuickViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartQuickView)

export default withStyles(styles)(CartQuickViewContainer)

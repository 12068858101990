import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import styles from './QuickNotice.css'

const QuickNotice = ({ classes, message }) => {
  return (
    <div className={classes.addQuickNotice}>
      {message}
    </div>
  )
}

QuickNotice.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.object
}

export default withStyles(styles)(QuickNotice)

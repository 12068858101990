import {
  AdminRoleIds,
  ClientRoleIds,
  SuiteHolderAdminRoleId,
  FinanceRoleIds,
  SuperAdminRoleID
} from '../../constants/userRoles'

export const currentUserIsAdmin = ({ role_id: roleId }) => AdminRoleIds.includes(roleId)
export const currentUserIsSuperAdmin = ({ role_id: roleId }) => roleId === SuperAdminRoleID
export const currentUserIsClient = ({ role_id: roleId }) => ClientRoleIds.includes(roleId)
export const currentUserIsFinanceAdmin = ({ role_id: roleId }) => FinanceRoleIds.includes(roleId)

export const currentUserIsSuiteHolderAdmin = ({ role_id: roleId }) => roleId === SuiteHolderAdminRoleId

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import ParOptions from '../ParOptions/ParOptions'
import SignatureOptions from '../SignatureOptions/SignatureOptions'
import ItemAdditionOptions from '../ItemAdditionOptions/ItemAdditionOptions'
import InSuiteAdditionsCharge from '../InSuiteAdditionsCharge/InSuiteAdditionsCharge'
import CheckoutInputs from '../CheckoutInputs/CheckoutInputs'
import {
  setItemAdditionsBy,
  setSignatureReguired,
  setLiquorCabinetAccess,
  hostNameChangeHandler,
  orderCommentChangeHandler,
  orderNameChangeHandler,
  inSuiteAdditionsChargeHandler
} from '../../store/CheckoutFlow/actions'
import styles from './InSuiteActionsOptions.css'

const InSuiteAdditionsChargeOptions = [
  {
    id: 3,
    value: 'Primary Card'
  },
  {
    id: 4,
    value: 'Even Split'
  }
]

const NO_ADDITION_TO_ORDER = 3
const CREDIT_CARD_PAYMENT_METHOD = 1
const InSuiteActionsOptions = ({
  classes,
  inSuiteOptions,
  itemAdditionsBy,
  inSuiteAdditionsCharge,
  signatureRequired,
  liquorCabinetAccess,
  hostName,
  orderName,
  orderComment,
  setLiquorCabinetAccess,
  setItemAdditionsBy,
  setSignatureReguired,
  hostNameChangeHandler,
  orderCommentChangeHandler,
  orderNameChangeHandler,
  paymentMethod,
  showValidationErrorMessage,
  inSuiteAdditionsChargeHandler,
  usedCreditCards = []
}) => {
  const {
    item_additions_by: itemAdditionsByOptions = [],
    liquor_cabinet_access: liquorCabinetAccessOptions = []
  } = inSuiteOptions
  const itemAdditionsAllowed = itemAdditionsBy !== NO_ADDITION_TO_ORDER
  const isPaymentUsingCreditCard = paymentMethod === CREDIT_CARD_PAYMENT_METHOD
  const usedCreditCardsCount = usedCreditCards.length
  return (
    <React.Fragment>
      <ItemAdditionOptions
        classes={classes}
        itemAdditionsByOptions={itemAdditionsByOptions}
        itemAdditionsBy={itemAdditionsBy}
        setItemAdditionsBy={setItemAdditionsBy}
        showValidationErrorMessage={showValidationErrorMessage}
      />
      {
        itemAdditionsAllowed &&
        isPaymentUsingCreditCard &&
        <InSuiteAdditionsCharge
          classes={classes}
          showValidationErrorMessage={showValidationErrorMessage}
          inSuiteAdditionsChargeOptions={InSuiteAdditionsChargeOptions}
          inSuiteAdditionsCharge={inSuiteAdditionsCharge}
          setInSuiteAdditionsCharge={inSuiteAdditionsChargeHandler}
          usedCreditCardsCount={usedCreditCardsCount}
        />
      }
      <SignatureOptions
        classes={classes}
        setSignatureReguired={setSignatureReguired}
        signatureRequired={signatureRequired}
        showValidationErrorMessage={showValidationErrorMessage}
      />
      <ParOptions
        classes={classes}
        liquorCabinetAccess={liquorCabinetAccess}
        liquorCabinetAccessOptions={liquorCabinetAccessOptions}
        setLiquorCabinetAccess={setLiquorCabinetAccess}
      />
      <CheckoutInputs
        classes={classes}
        hostName={hostName}
        hostNameChangeHandler={hostNameChangeHandler}
        orderComment={orderComment}
        orderCommentChangeHandler={orderCommentChangeHandler}
        orderName={orderName}
        orderNameChangeHandler={orderNameChangeHandler}
        showValidationErrorMessage={showValidationErrorMessage}
      />
    </React.Fragment>
  )
}

InSuiteActionsOptions.propTypes = {
  hostNameChangeHandler: PropTypes.func,
  orderNameChangeHandler: PropTypes.func,
  orderCommentChangeHandler: PropTypes.func,
  classes: PropTypes.object,
  inSuiteOptions: PropTypes.object,
  setItemAdditionsBy: PropTypes.func,
  itemAdditionsBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inSuiteAdditionsChargeHandler: PropTypes.func,
  inSuiteAdditionsCharge: PropTypes.number,
  setLiquorCabinetAccess: PropTypes.func,
  liquorCabinetAccess: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSignatureReguired: PropTypes.func,
  signatureRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hostName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderComment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentMethod: PropTypes.number,
  showValidationErrorMessage: PropTypes.bool,
  usedCreditCards: PropTypes.array
}

InSuiteActionsOptions.defaultProps = {
  itemAdditionsByOptions: [],
  liquorCabinetAccessOptions: [],
  hostName: '',
  orderName: '',
  orderComment: '',
  paymentMethod: null
}

const mapDispatchToProps = {
  setItemAdditionsBy,
  setSignatureReguired,
  setLiquorCabinetAccess,
  hostNameChangeHandler,
  orderNameChangeHandler,
  orderCommentChangeHandler,
  inSuiteAdditionsChargeHandler
}

const mapStateToProps = (state, ownProps) => {
  return {
    liquorCabinetAccess: state.checkoutData.liquorCabinetAccess,
    inSuiteOptions: state.staticData.in_suite_options,
    itemAdditionsBy: state.checkoutData.itemAdditionsBy,
    signatureRequired: state.checkoutData.signatureRequired,
    orderName: state.checkoutData.orderName,
    hostName: state.checkoutData.hostName,
    orderComment: state.checkoutData.orderComment,
    inSuiteAdditionsCharge: state.checkoutData.inSuiteAdditionsCharge,
    paymentMethod: state.checkoutData.paymentMethod,
    usedCreditCards: state.checkoutData.usedCreditCards
  }
}

const InSuiteActionsOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InSuiteActionsOptions)

export default withStyles(styles)(InSuiteActionsOptionsContainer)

import { FETCH_STAFF_USERS, SET_STAFF_USER_TO_EDIT, UPDATE_STAFF_USER_TO_LIST, UPDATE_STAFF_USER_STATUS, ADD_STAFF_USER_TO_LIST } from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { staffUsersEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/staffUsers'
import { getItemsList } from '../helpers/apiClient'

const prepareStaffUserListQuery = (filters, page, limit, sort) => {
  const { query, filterKey } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      key: filterKey,
      value: query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchStaffUsers = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = staffUsersEndpoints.list
  const data = prepareStaffUserListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { users: items, meta } = data
      dispatch({
        type: FETCH_STAFF_USERS,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const addStaffUser = (staffUserData) => dispatch => {
  const { method, url } = staffUsersEndpoints.addUser
  const { firstName, lastName, email, selectedRoleId } = staffUserData
  const user = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    role_id: selectedRoleId
  }
  getItemsList(method, url, { user })
    .then(res => {
      const {
        staffUsersList
      } = store.getState().staffUsersData
      const {
        data: { message }
      } = res
      dispatch({
        type: ADD_STAFF_USER_TO_LIST,
        payload: { staffUsersList, message }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateStaffUser = (staffUserData) => dispatch => {
  const { method, url } = staffUsersEndpoints.updateUser(staffUserData.id)
  const { firstName, lastName, email, selectedRoleId, webStatus, roleName, webStatusText } = staffUserData
  const user = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    role_id: selectedRoleId,
    web_status: webStatus,
    role_name: roleName.toLowerCase(),
    web_status_text: webStatusText
  }

  getItemsList(method, url, { user })
    .then(res => {
      const {
        staffUsersList
      } = store.getState().staffUsersData
      const {
        data: { message }
      } = res
      dispatch({
        type: UPDATE_STAFF_USER_TO_LIST,
        payload: { staffUsersList, staffUserData, message }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const activateStaffUser = (staffUserData) => dispatch => {
  const { method, url } = staffUsersEndpoints.activateUser(staffUserData.id)
  getItemsList(method, url, staffUserData)
    .then(res => {
      staffUserData.webStatus = true
      staffUserData.webStatusText = 'Active'
      const staffUsersList = store.getState().staffUsersData.staffUsersList
      dispatch({
        type: UPDATE_STAFF_USER_STATUS,
        payload: { staffUserData, staffUsersList }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const deActivateStaffUser = (staffUserData) => dispatch => {
  const { method, url } = staffUsersEndpoints.deactivateUser(staffUserData.id)
  getItemsList(method, url, staffUserData)
    .then(res => {
      staffUserData.webStatus = false
      staffUserData.webStatusText = 'Inactive'
      const staffUsersList = store.getState().staffUsersData.staffUsersList
      dispatch({
        type: UPDATE_STAFF_USER_STATUS,
        payload: { staffUserData, staffUsersList }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchStaffUserToEdit = (userId) => dispatch => {
  const user = store.getState().staffUsersData.staffUsersList.rows.find(user => user.id === userId)
  dispatch({
    type: SET_STAFF_USER_TO_EDIT,
    payload: { user }
  })
}

const styles = {
  addMoreCardButton: {
    borderRadius: '0px',
    border: '1px solid #000000',
    height: '40px',
    width: '463px',
    marginTop: '20px'
  }
}

export default styles

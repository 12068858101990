const styles = {
  accountDetailsWrapper: {
    width: '794px',
    margin: 'auto'
  },
  accountDetailsContainer: {
    marginBottom: '30px'
  },
  accountDetailsContainerNotifications: {
    marginBottom: '0px'
  },
  accountDetailsTitleContainer: {
    paddingTop: '80px',
    paddingBottom: '40px',
    '& a': {
      float: 'right',
      border: '1px solid #fff',
      color: '#fff',
      textDecoration: 'none',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      height: '50px',
      width: '170px',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '19px',
      paddingTop: '14px',
      textAlign: 'center',
      borderRadius: '4px',
      letterSpacing: '0.02857em',
      textTransform: 'capitalize'
    }
  },
  accountTitle: {
    height: '60px',
    width: '267px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '56px',
    fontWeight: '900',
    lineHeight: '60px'
  },
  accountDetailsContainerTitle: {
    height: '44px',
    width: '165px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px'
  },
  labelTitle: {
    height: '14px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  accountDetailLabel: {
    height: '24px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px'
  },
  filledButton: {
    float: 'right',
    border: '1px solid #fff',
    color: '#000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    background: '#fff',
    height: '50px',
    width: '170px',
    textTransform: 'none',
    '&:hover': {
      background: '#fff',
      color: '#000'
    }
  },
  notifications: {
    paddingLeft: 0,
    paddingTop: 0
  },
  notificationLabel: {
    marginLeft: '0!important',
    '& span': {
      color: '#fff'
    }
  },
  section: {
    marginTop: '30px',
    '& label': {
      marginLeft: '12px',
      display: 'block',
      marginTop: '2px'
    }
  },
  accountDetailsSection: {
    height: '48px',
    width: '274px',
    float: 'left'
  },
  accountDetailsSectionLeft: {
    height: '48px',
    width: '274px',
    display: 'inline-block'
  },
  accountDetailsSectionRight: {
    height: '48px',
    width: '274px',
    marginTop: '30px'
  },
  cardHolderContainer: {
    display: 'block',
    borderRadius: '4px',
    marginTop: '10px',
    '& p': {
      float: 'left'
    },
    '& button': {
      marginTop: '12px'
    }
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_IMAGE_URL } from '../../constants/events'

Title.propTypes = {
  classes: PropTypes.object,
  imageUrl: PropTypes.string
}

function Title ({ classes, imageUrl }) {
  return (
    <div className={classes.menuItemImageHolder}>
      <img src={imageUrl || DEFAULT_IMAGE_URL} className={classes.eventBannerImage} alt="" />
    </div>
  )
}

export default Title

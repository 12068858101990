import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../elements/CustomButton/CustomButton'

const CopyOrderDialogActions = ({
  classes,
  handleDialogClose,
  proceedToCheckout,
  eventId
}) => {
  return (
    <div className={classes.copyOrderActionsContainer}>
      <CustomButton
        variant={'outlined'}
        disableRipple
        styleClass={classes.cancelCopyOrderButton}
        label={'Cancel'}
        clickHandler={handleDialogClose}
      />
      <CustomButton
        variant={'contained'}
        disableRipple
        styleClass={classes.placeOrderButton}
        label={'Continue'}
        color={'primary'}
        clickHandler={proceedToCheckout}
        disabled={!eventId}
      />
    </div>
  )
}

CopyOrderDialogActions.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  proceedToCheckout: PropTypes.func,
  eventId: PropTypes.number
}

export default CopyOrderDialogActions

export const suitesProperties = [
  {
    key: 'name',
    label: 'SUITE NAME',
    sortable: false
  },
  {
    key: 'status',
    label: 'STATUS',
    sortable: false
  },
  {
    key: 'capacity',
    label: 'CAPACITY',
    sortable: false
  }
]

export const rowsPerPageOptions = [10, 25, 50]

export const sortParamsMapping = {}

export const styles = {
  searchFieldWidth: '680px',
  selectorWidth: '250px'
}

export const rowsFor = 'suites'

export const resourceName = 'suites'

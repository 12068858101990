import { reportAnalytics } from '../../utils/analytics'
import {
  ANALYTICS_ACTION_QUANTITY_SELECTION,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_ITEM_DELETE,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_PAGE_TYPE_MENU_CATALOG,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT,
  ANALYTICS_ACTION_TYPE_DELETE_LINE_ITEM,
  ANALYTICS_ACTION_TYPE_UPDATE_QUANTITY,
  ANALYTICS_ACTION_CART_ADD
} from '../../constants/analytics'
import { history } from '../../store/store'

const getCurrentPageType = () => {
  const urlPath = history.location.pathname
  const orderEditPathRegex = /^\/orders\/[0-9]+\/edit$/g
  const currentPath = urlPath.match(orderEditPathRegex) ? '/orders/edit' : urlPath
  let pageType = ''
  switch (currentPath) {
    case '/checkout':
      pageType = ANALYTICS_PAGE_TYPE_CHECKOUT
      break
    case '/menu-catalog':
      pageType = ANALYTICS_PAGE_TYPE_MENU_CATALOG
      break
    case urlPath.match(orderEditPathRegex):
      pageType = ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT
      break
    default:
      window.__reportingUrlPath = urlPath || 'unknown'
  }
  return pageType
}

export const getExistingItemIndex = (itemId, orderItems, orderWindow) => {
  return orderItems.findIndex(
    orderItem => orderItem.menuItemId === itemId &&
    orderItem.orderWindow.toLowerCase() === orderWindow.toLowerCase() &&
    orderItem._destroy !== 1
  )
}

export const removeItemFromCart = (itemId, orderItems, orderWindow, updateOrderItems, orderId) => {
  const currentOrderItems = [...orderItems]
  const existingIndex = getExistingItemIndex(itemId, orderItems, orderWindow)
  const existingItem = currentOrderItems[existingIndex]

  reportAnalytics({
    eventType: ANALYTICS_ACTION_CART_ADD,
    detail: {
      actionName: ANALYTICS_ACTION_ITEM_DELETE,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_DELETE_LINE_ITEM,
      actionItem: existingItem.menu_item_name,
      actionOrderId: orderId,
      actionOrderWindow: orderWindow
    },
    pageType: getCurrentPageType()
  })

  if (existingItem.id) {
    existingItem._destroy = 1
  } else {
    currentOrderItems.splice(existingIndex, 1)
  }
  updateOrderItems(currentOrderItems.slice())
}

export const updateQuantity = (itemId, orderItems, quantity, orderWindow, updateOrderItems, orderId) => {
  const existingIndex = getExistingItemIndex(itemId, orderItems, orderWindow)
  const existingItem = orderItems[existingIndex]
  existingItem.quantity = quantity
  existingItem.totalPrice = parseFloat(+existingItem.unitPrice * existingItem.quantity).toFixed(2)
  orderItems[existingIndex] = existingItem

  reportAnalytics({
    eventType: ANALYTICS_ACTION_CART_ADD,
    detail: {
      actionName: ANALYTICS_ACTION_QUANTITY_SELECTION,
      actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
      actionType: ANALYTICS_ACTION_TYPE_UPDATE_QUANTITY,
      actionQty: quantity,
      actionItem: existingItem.menu_item_name,
      actionOrderId: orderId,
      actionOrderWindow: orderWindow
    },
    pageType: getCurrentPageType()
  })

  updateOrderItems(orderItems.slice())
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { getEventSuiteById } from '../../store/eventSuites/actions'
import styles from './EditEventSuite.css'
import EditEventSuiteDialogContent from './EditEventSuiteDialogContent'
import EditEventSuiteTitle from './EditEventSuiteTitle'

const getDialogContent = (eventSuite, handleUpdate, handleCancel, validationError) => {
  return <EditEventSuiteDialogContent
    eventSuite={eventSuite}
    handleUpdate={handleUpdate}
    handleCancel={handleCancel}
    validationError={validationError}
  />
}

const getTitle = (classes, actionMode) =>
  <EditEventSuiteTitle className={classes.eventSuiteTitle} actionMode={actionMode} />

const EditEventSuite = ({
  classes,
  eventSuiteId,
  eventSuite,
  getEventSuiteById,
  handleDialogClose,
  handleUpdate,
  openDialog,
  validationError
}) => {
  useEffect(() => {
    if (eventSuiteId) {
      getEventSuiteById(eventSuiteId)
    }
  }, [eventSuiteId, getEventSuiteById])

  return (
    <React.Fragment>
      { eventSuite && <Dialog
        openDialog={openDialog}
        dialogTitle={getTitle(classes, 'Edit')}
        dialogContent={getDialogContent(eventSuite, handleUpdate, handleDialogClose, validationError)}
        dialogActions={null}
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    eventCategories: state.staticData.event_categories,
    event: state.eventsData.eventToEdit,
    eventSuite: state.eventSuitesData.eventSuiteToEdit
  }
}

const mapDispatchToProps = {
  getEventSuiteById
}

EditEventSuite.propTypes = {
  classes: PropTypes.object,
  eventSuiteId: PropTypes.number,
  eventSuite: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleUpdate: PropTypes.func,
  getEventSuiteById: PropTypes.func,
  openDialog: PropTypes.bool,
  validationError: PropTypes.string
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEventSuite))

import moment from 'moment'
import { MONTH_NAMES, MENU_TYPES } from '../../constants/clientEvents'

const filterEventsByMonth = (events, filters) => {
  if (filters.selectedMonth) {
    return events.filter(event => event.startDateMonth === MONTH_NAMES[filters.selectedMonth - 1].shortName)
  }
  return events
}

const filterEventsByDate = (events, filters) => {
  if (filters.date) {
    const filterStartDate = moment(filters.date.start_date)
    const filterEndDate = moment(filters.date.end_date)
    return events.filter((event) => {
      const { eventDate } = event
      const eventStartDate = moment(eventDate).startOf('day')
      return eventStartDate.isBetween(filterStartDate, filterEndDate, 'days', true)
    })
  }
  return events
}

const filterEventsByCategory = (events, filters) => {
  if (filters.category) {
    return events.filter((event) => event.eventCategoryId === filters.category)
  }
  return events
}

const filterEventsByMenuType = (events, filters) => {
  if (filters.menuTypeId) {
    return events.filter((event) => {
      if (MENU_TYPES[filters.menuTypeId - 1].id === 1) {
        return event.nonKosherMenuCutoff !== 'N/A'
      } else {
        return event.kosherMenuCutoff !== 'N/A'
      }
    })
  }
  return events
}

export const paginateClientEvents = (array, pagination) => {
  let { page: index, limit: size } = pagination
  index = Math.abs(parseInt(index, 10))
  index = index > 0 ? index - 1 : index
  size = parseInt(size, 10)
  size = size < 1 ? 1 : size
  return [...(array.filter((value, n) => {
    return (n >= (index * size)) && (n < ((index + 1) * size))
  }))]
}

export const applyFilter = (eventsList, filterObj) => {
  const { allEvents: events = [] } = eventsList
  const { filters } = filterObj
  const filteredEventsByMonth = filterEventsByMonth(events, filters)
  const filteredEventsByDate = filterEventsByDate(filteredEventsByMonth, filters)
  const filteredEventsByCategory = filterEventsByCategory(filteredEventsByDate, filters)
  const filteredEventsByMenuType = filterEventsByMenuType(filteredEventsByCategory, filters)
  return filteredEventsByMenuType
}

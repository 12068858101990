import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { basicDetailslabels } from '../../constants/OrderDetails'
import styles from './OrderBasicDetails.css'
const {
  suiteLabel,
  eventLabel,
  paymentStatusLabel,
  paymentTypeLabel,
  menuTypeLabel,
  submittedByLabel,
  dateSubmittedLabel,
  totalLabel
} = basicDetailslabels

const OrderBasicDetails = ({
  classes,
  suite,
  companyName,
  eventName,
  eventDate,
  paymentStatusName,
  createdDate,
  submittedBy,
  menuTypeName,
  paymentMethodName,
  total
}) => {
  return (
    <div className={classes.orderBasicDetailsContainer}>
      <div className={classes.detailMedium}>
        <div className={`${classes.label} ${classes.labelVerticleAligned}`}>{suiteLabel}</div>
        <div className={classes.value}>{suite}-{companyName}</div>
      </div>
      <div className={classes.detailLong}>
        <div className={`${classes.label} ${classes.labelVerticleAligned}`}>{eventLabel}</div>
        <div className={classes.value}>{eventName} -  {eventDate}</div>
      </div>
      <div className={classes.detailShort}>
        <div className={`${classes.label} ${classes.labelShort}`}>{paymentStatusLabel}</div>
        <div className={classes.value}>{paymentStatusName}</div>
      </div>
      <div className={classes.detailShort}>
        <div className={`${classes.label} ${classes.labelShort}`}>{dateSubmittedLabel}</div>
        <div className={classes.value}>{createdDate}</div>
      </div>
      <div className={classes.detailMedium}>
        <div className={`${classes.label} ${classes.labelVerticleAligned}`}>{submittedByLabel}</div>
        <div className={classes.value}>{submittedBy}</div>
      </div>
      <div className={classes.detailShort}>
        <div className={`${classes.label} ${classes.labelVerticleAligned}`}>{menuTypeLabel}</div>
        <div className={classes.value}>{menuTypeName}</div>
      </div>
      <div className={classes.detailShort}>
        <div className={`${classes.label} ${classes.labelShort}`}>{paymentTypeLabel}</div>
        <div className={classes.value}>{paymentMethodName}</div>
      </div>
      <div className={classes.detailShort}>
        <div className={`${classes.label} ${classes.labelVerticleAligned}`}>{totalLabel}</div>
        <div className={classes.value}>{`$${parseFloat(total).toFixed(2)}`}</div>
      </div>
    </div>
  )
}

OrderBasicDetails.propTypes = {
  classes: PropTypes.object,
  suite: PropTypes.string,
  companyName: PropTypes.string,
  eventName: PropTypes.string,
  eventDate: PropTypes.string,
  paymentStatusName: PropTypes.string,
  createdDate: PropTypes.string,
  submittedBy: PropTypes.string,
  menuTypeName: PropTypes.string,
  paymentMethodName: PropTypes.string,
  total: PropTypes.string
}

export default withStyles(styles)(OrderBasicDetails)

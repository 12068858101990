import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import theme from '../../theme'
import styles from './FilterBar.css'

const FilterBar = ({
  children,
  classes,
  fetchItems,
  filters,
  limit,
  page,
  sortParams,
  styles
}) => {
  const filterItems = (filters) => {
    fetchItems(filters, page, limit, sortParams)
  }

  const classNames = cx({
    [classes.filterContainer]: !!classes.filterContainer
  })

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classNames}>
        {React.cloneElement(children, { filterItems, filters, styles })}
      </div>
    </MuiThemeProvider>
  )
}

FilterBar.propTypes = {
  fetchItems: PropTypes.func.isRequired,
  children: PropTypes.object,
  filters: PropTypes.object,
  page: PropTypes.number,
  limit: PropTypes.number,
  sortParams: PropTypes.object,
  classes: PropTypes.object,
  styles: PropTypes.object
}

export default withStyles(styles)(FilterBar)

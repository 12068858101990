export const shareTypesProperties = [
  {
    key: 'name',
    label: 'NAME',
    sortable: false
  },
  {
    key: 'event_count',
    label: 'EVENTS',
    sortable: false
  }
]

export const rowsFor = 'shareTypes'

export const resourceName = 'shareTypes'

const styles = {
  fieldTitle: {
    height: '14px',
    width: '175px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    '&$disabled': {
      color: '#000000'
    }
  },
  disabled: {},
  eventEditInputFields: {
    width: '586px',
    margin: '0px 0px'
  },
  eventEditInputContainer: {
    padding: '10px 0px'
  },
  uploadImagebutton: {
    boxSizing: 'border-box',
    height: '50px',
    width: '170px',
    border: '1px solid #000000',
    borderRadius: '0px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center'
  },
  row: {
    display: 'flex'
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(1)'
  },
  timePickerRootSmall: {
    margin: '0px',
    height: '54px',
    width: '96px'
  },
  timePickerRootLarge: {
    margin: '0px',
    height: '54px',
    width: '152px'
  },
  marginRight: {
    marginRight: '25px'
  },
  dateTimePickersContainer: {
    display: 'flex',
    marginTop: '25px'
  },
  dateTimeInputField: {
    border: '0px',
    '&&&&:focus': {
      outline: 'none'
    },
    borderBottom: '2px dotted black',
    marginTop: '6px',
    width: '145px',
    fontFamily: 'Ringside Condensed A , Ringside Condensed B'
  },
  timeInputField: {
    border: '0px',
    '&&&&:focus': {
      outline: 'none'
    },
    borderBottom: '2px dotted black',
    marginTop: '6px',
    width: '95px',
    fontFamily: 'Ringside Condensed A , Ringside Condensed B'
  },
  kosherCutoffContainer: {
    marginRight: '30px'
  },
  timeFieldContainer: {
    width: '122px'
  },
  dateTimeFieldContainer: {
    width: '172px',
    '& span': {
      color: 'black',
      width: '100%',
      border: 0,
      height: '1.1875em',
      margin: 0,
      display: 'block',
      padding: '6px 0 7px',
      minWidth: 0,
      background: 'none',
      boxSizing: 'content-box',
      fontFamily: 'Ringside Condensed A , Ringside Condensed B'
    }
  },
  imageSelectorsContainer: {
    marginTop: '30px'
  },
  imageFieldContainer: {
    marginTop: '30px'
  },
  startDateFieldContainer: {
    width: '120px',
    marginRight: '30px',
    marginTop: '20px'
  },
  startDateInput: {
    width: '120px'
  },
  statusButtonsContainer: {
    marginTop: '3px'
  },
  statusFieldContainer: {
    marginTop: '12px',
    width: '230px'
  },
  eventTypeSelectorContainer: {
    marginTop: '13px',
    '& label': {
      height: '14px',
      width: '151px',
      color: '#000000',
      fontFamily: 'Ringside Wide A, Ringside Wide B',
      fontSize: '10px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px',
      '&$disabled': {
        color: '#000000'
      },
      transform: 'translate(0, 1.5px) scale(1)'
    }
  },
  reactDatePicker: {
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  actionsContainer: {
    marginTop: '30px'
  },
  cancelButton: {
    width: '280px',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px'
  },
  updateEventButton: {
    width: '280px',
    borderRadius: '0px',
    boxShadow: 'none'
  }
}

export default styles

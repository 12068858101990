import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import ShoppingCart from '@material-ui/icons/ShoppingCartOutlined'
import { withStyles } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import styles from './CartIcon.css'

const CartIcon = (props) => {
  const { viewCart, classes, lineItemsCount } = props
  return (
    <div className={classes.cartIconContainer}>
      <Fab color="primary" aria-label="add" className={classes.cartIconWrapper} onClick={viewCart}>
        <Badge badgeContent={lineItemsCount} color="primary" classes={{ badge: classes.itemCountBadge }}>
          <ShoppingCart className={classes.cartIconSvg} />
        </Badge>
      </Fab>
    </div>
  )
}

CartIcon.propTypes = {
  viewCart: PropTypes.func,
  classes: PropTypes.object,
  lineItemsCount: PropTypes.number
}

export default withStyles(styles)(CartIcon)

const styles = {
  creditCardAmountInput: {
    height: '25px',
    marginTop: '12px',
    borderBottom: '2px dotted black',
    width: '100px',
    '& input': {
      color: 'black',
      height: '14px',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '10px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px'
    },
    '&:hover': {
      borderBottom: '2px dotted black',
      color: 'black'
    }
  },
  radioButtonContainer: {
    marginTop: '5px'
  },
  creditCardLineItem: {
    display: 'flex'
  },
  deleteButtonWrapper: {
    padding: '0px',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  removeCreditCard: {
    margin: '8px 5px',
    fontSize: '20px'
  },
  amountPrefix: {
    marginTop: '14px',
    marginRight: '3px',
    fontSize: '14px',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  sectionTitle: {
    margin: '12px 0px',
    height: '14px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  primaryCardTitle: {
    marginLeft: '48px',
    height: '14px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  }
}

export default styles

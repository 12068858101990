const styles = {
  checkoutDetailsWrapper: {
    width: '1102px',
    margin: 'auto'
  },
  orderItemsContainer: {
    minHeight: '646px',
    width: '550px',
    backgroundColor: 'white',
    marginRight: '1px'
  },
  checkoutDetailsTitleContainer: {
    padding: '30px 0px'
  },
  checkoutTitle: {
    height: '44px',
    width: '1002px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px'
  },
  checkoutDetailsContainer: {
    color: 'black',
    display: 'flex'
  },
  paymentOptionsContainer: {
    minHeight: '300px',
    width: '550px',
    backgroundColor: 'white',
    marginLeft: '1px'
  },
  backToOrderButtonStyle: {
    boxShadow: 'none',
    borderRadius: '0px',
    height: '50px',
    width: '470px',
    margin: '30px 40px',
    border: '1px solid #000000',
    '&:hover': {
      background: 'transparent'
    }
  },
  placeOrderButton: {
    boxShadow: 'none',
    borderRadius: '0px',
    height: '50px',
    width: '470px',
    margin: '30px 40px',
    border: '1px solid #000000'
  },
  advancedWarning: {
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '.75rem',
    margin: '0px 40px 20px'
  },
  businessDayNote: {
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '.75rem',
    fontWeight: '400',
    margin: '0px 40px 20px'
  },
  sectionTitle: {
    height: '24px',
    width: '337px',
    color: '#000000',
    fontFamily: 'Ringside Condensed A, RingSide Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    padding: '40px 0px 25px 40px'
  },
  spinner: {
    position: 'fixed',
    left: '47%',
    top: '55%',
    zIndex: 9999
  },
  spinnerContainer: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 999999
  }
}

export default styles

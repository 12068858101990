import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { array, object, number, func, string } from 'prop-types'
import {
  fetchStaffUsers,
  activateStaffUser,
  addStaffUser,
  deActivateStaffUser,
  fetchStaffUserToEdit,
  updateStaffUser
} from '../../store/staffUsers/actions'
import StaffUsersFilterBar from '../../elements/StaffUsersFilterBar/StaffUsersFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import SubNav from '../../elements/SubNav/SubNav'
import { routes } from '../../pages'
import {
  addQuickNoticeStyles,
  ctaLabel,
  defaultUserObject,
  rowsFor,
  resourceName,
  staffUserProperties,
  styles
} from '../../constants/staffUsers'
import {
  currentUserIsAdmin,
  currentUserIsSuperAdmin
} from '../../store/helpers/userRoleManager'
import {
  defaultPage,
  defaultLimit,
  defaultTotalPages,
  rowsPerPageOptions
} from '../../constants/pagination'
import {
  SuperAdminRoleID
} from '../../constants/userRoles'
import ManageStaffUser from '../../Dialogs/ManageStaffUser/ManageStaffUser'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))

const StaffUsersList = ({
  activateUser,
  addUser,
  deActivateUser,
  editStaffUserById,
  fetchItems,
  filters,
  limit: rowsPerPage = defaultLimit,
  page = defaultPage,
  rolesList,
  rows,
  sortParams = {},
  staffUser,
  totalPages = defaultTotalPages,
  updateMessage,
  updateUser
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [mode, setMode] = useState()
  const [userUpdateMessage, setUserUpdateMessage] = useState(false)

  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleDialogClose = () => {
    setOpenDialog(false)
    setCurrentUser({})
    setMode(null)
  }

  useEffect(() => {
    if (updateMessage) {
      setUserUpdateMessage(updateMessage)
      handleDialogClose()
      const timer1 = setTimeout(() => {
        setUserUpdateMessage('')
      }, 1000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [updateMessage])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, rowsPerPage, sort)
  }

  const editStaffUser = user => {
    editStaffUserById(user.id)
    setOpenDialog(true)
    setCurrentUser(user)
    setMode('Edit')
  }

  const addStaffUserHandler = user => {
    setOpenDialog(true)
    setCurrentUser(defaultUserObject)
    setMode('Add')
  }

  const toggleStatus = user => {
    if (user.webStatus) {
      deActivateUser(user)
    } else {
      activateUser(user)
    }
  }

  const settingsMenuBuilder = item => [
    {
      id: item.id,
      label: 'Edit',
      action: editStaffUser
    },
    {
      id: item.id,
      label: item.webStatus ? 'Deactivate' : 'Activate',
      action: toggleStatus
    }
  ]

  const applyFilter = (filters, page, limit, sortParams) => {
    fetchItems(filters, defaultPage, limit, sortParams)
  }

  return (
    <div>
      <SubNav routes={routes.find(({ path }) => path === '/admin').childRoutes} />
      <DataContainer>
        { userUpdateMessage &&
        <div style={addQuickNoticeStyles}>
          {userUpdateMessage}
        </div> }
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <StaffUsersFilterBar
            ctaLabel={ctaLabel}
            addStaffUserAction={addStaffUserHandler}
          />
        </FilterBar>
        <Suspense fallback={<div>Loading...</div>}>
          { rows && <LazyTable
            properties={staffUserProperties}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSorting={handleSorting}
            sortParams={sortParams}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsFor}
            resourceName={resourceName}
            settingsMenuBuilder={settingsMenuBuilder}
          />}
        </Suspense>
        {openDialog && <ManageStaffUser
          openDialog={openDialog}
          staffUser={currentUser}
          handleDialogClose={handleDialogClose}
          mode={mode}
          rolesList={rolesList}
          addStaffUser={addUser}
          updateStaffUser={updateUser}
          activateStaffUserStatus={activateUser}
          deActivateStaffUserStatus={deActivateUser}
        />}
      </DataContainer>
    </div>

  )
}

StaffUsersList.propTypes = {
  activateUser: func,
  addUser: func,
  deActivateUser: func,
  editStaffUserById: func,
  fetchItems: func,
  filters: object,
  limit: number,
  page: number,
  rolesList: array,
  rows: array,
  sortParams: object,
  staffUser: object,
  totalPages: number,
  updateUser: func,
  updateMessage: string
}

const mapStateToProps = (state) => {
  const currentUser = state.authReducer.user
  let rolesList = []
  if (currentUserIsSuperAdmin(currentUser)) {
    rolesList = state.staticData.roles.admin
  } else if (currentUserIsAdmin(currentUser)) {
    rolesList = state.staticData.roles.admin
      .filter(({ id: roleId }) => roleId !== SuperAdminRoleID)
  }
  return {
    ...state.staffUsersData.staffUsersList,
    rolesList,
    staffUser: state.staffUsersData.staffUserToEdit,
    updateMessage: state.staffUsersData.message
  }
}
const mapDispatchToProps = {
  activateUser: activateStaffUser,
  addUser: addStaffUser,
  deActivateUser: deActivateStaffUser,
  editStaffUserById: fetchStaffUserToEdit,
  fetchItems: fetchStaffUsers,
  updateUser: updateStaffUser
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StaffUsersList)
)

const styles = {
  eventDateContainer: {
    width: '65px',
    marginRight: '40px'
  },
  eventMonth: {
    height: '20px',
    color: '#65FFF5',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '20px',
    textAlign: 'center'
  },
  eventDayName: {
    height: '20px',
    color: '#65FFF5',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '20px',
    textAlign: 'center'
  },
  eventDate: {
    height: '56px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '56px',
    fontWeight: '900',
    lineHeight: '56px',
    textAlign: 'center'
  }
}

export default styles

import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import useStyles from './FilterSelector.css'

const FilterSelector = (props) => {
  const {
    name,
    label,
    value,
    changeHandler,
    selectionOptions = [],
    optionValueKey = 'value',
    shrink = false,
    displayLabel,
    defaultOption = 'All',
    disabled
  } = props
  const classes = useStyles(props)
  const inputLabel = shrink
    ? <InputLabel shrink htmlFor={name}> {label.toUpperCase()} </InputLabel>
    : <InputLabel htmlFor={name} className={classes.inputLabel}> {label} </InputLabel>

  return (
    <FormControl className={classes.formControl}>
      { displayLabel ? inputLabel : '' }
      <Select
        value={value}
        onChange={changeHandler}
        inputProps={{ name: label, id: name }}
        displayEmpty
        name={name}
        disabled={!!disabled}
      >
        { shrink && <MenuItem value="">{defaultOption}</MenuItem>}
        { selectionOptions.map(option => <MenuItem key={option.id} value={option.id}>{option[optionValueKey]}</MenuItem>) }
      </Select>
    </FormControl>
  )
}
FilterSelector.defaultProps = {
  selectionOptions: [],
  optionValueKey: 'value',
  shrink: false,
  displayLabel: true
}

FilterSelector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  changeHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectionOptions: PropTypes.array.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  shrink: PropTypes.bool,
  displayLabel: PropTypes.bool,
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool
}

export default FilterSelector

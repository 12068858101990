import React from 'react'
import PropTypes from 'prop-types'

function ShareTypeTitle ({ className, mode }) {
  return (
    <div className={className}>
      {mode} Share Type
    </div>
  )
}

ShareTypeTitle.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(['add', 'edit'])
}

ShareTypeTitle.defaultProps = {
  mode: 'add'
}

export default ShareTypeTitle

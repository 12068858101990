import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import SearchField from '../SearchField/SearchField'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import CustomButton from '../CustomButton/CustomButton'
import EditCategories from '../../Dialogs/EditCategories/EditCategories'
import ResetFilters from '../ResetFilters/ResetFilters'
import menuItemsFilterBarStyles from './MenuItemsFilterBar.css'

const MenuItemsFilterBar = ({
  currentUser,
  classes,
  eventCategories = [],
  filters = {},
  filterItems,
  menuItemStatusList = [],
  menuTypes = [],
  menuCategories = [],
  styles
}) => {
  const [status, setStatus] = useState(filters.status || '')
  const [eventCategory, setEventCategory] = useState(filters.eventCategory || '')
  const [menuCategory, setMenuCategory] = useState(filters.menuCategory || '')
  const [menuType, setMenuType] = useState(filters.menuType || '')
  const [query, setSearchQuery] = useState(filters.query || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleMenuStatusSelection = (event) => {
    const status = event.target.value
    setStatus(status)
  }

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleEventCategorySelection = (event) => {
    const eventCategory = event.target.value
    setEventCategory(eventCategory)
  }

  const handleMenuCategorySelection = (event) => {
    const menuCategory = event.target.value
    setMenuCategory(menuCategory)
  }

  const handleMenuTypeSelection = (event) => {
    const menuType = event.target.value
    setMenuType(menuType)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const handleSubmit = () => {
    filterItems({ status, menuType, eventCategory, menuCategory, query })
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const clearFilters = () => {
    setStatus('')
    setMenuType('')
    setEventCategory('')
    setMenuCategory('')
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  return (
    <React.Fragment>
      {getFilterSelector('Status', 'status', status, handleMenuStatusSelection, menuItemStatusList, 'status', styles, true)}
      {getFilterSelector('Menu Categories', 'menuCategories', menuCategory, handleMenuCategorySelection, menuCategories, 'name', styles, true)}
      {getFilterSelector('Event Type', 'eventType', eventCategory, handleEventCategorySelection, eventCategories, 'name', styles, true)}
      {getFilterSelector('Menu Type', 'MenuType', menuType, handleMenuTypeSelection, menuTypes, 'name', styles, true)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
      {
        currentUser && currentUserIsAdmin(currentUser) && <FormControl className={classes.editCategoriesButtonContainer}>
          <CustomButton
            variant="contained"
            disableRipple
            styleClass={classes.editCategoriesButton}
            label="Edit Categories"
            color="primary"
            clickHandler={handleClickOpen}
          />
          <EditCategories openDialog={openDialog} handleDialogClose={handleDialogClose} />
        </FormControl>
      }
    </React.Fragment>
  )
}

MenuItemsFilterBar.propTypes = {
  menuItemStatusList: PropTypes.array,
  eventCategories: PropTypes.array,
  menuCategories: PropTypes.array,
  menuTypes: PropTypes.array,
  filters: PropTypes.object,
  styles: PropTypes.object,
  currentUser: PropTypes.object,
  classes: PropTypes.object,
  filterItems: PropTypes.func
}

export default withStyles(menuItemsFilterBarStyles)(MenuItemsFilterBar)

import moment from 'moment'
import axios from 'axios'
import {
  FETCH_EVENTS,
  FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW,
  SET_EVENTS_TO_MOVE_ORDERS,
  SET_EVENT_TO_EDIT,
  STORE_EE_EVENTS_LIST,
  UPDATE_EVENTS_LIST,
  TOGGLE_EVENT_STATUS
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { eventEndpoints, EE_API_AUTH_TOKEN } from '../../constants/apiConfig'
import { prepareEventsListQuery } from '../helpers/prepareEventsListQuery'
import { getItemsList } from '../helpers/apiClient'
import { getSecureImageUrl } from '../../utils/imageUtils'
import { fetchOrders } from '../orders/actions'

const MSG_VENUE_ID = 'KovZpZA7AAEA'

const EVENT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const fetchEvents = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = eventEndpoints.list
  const data = prepareEventsListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { items, meta } = data
      dispatch({
        type: FETCH_EVENTS,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const refreshEventsList = () => dispatch => {
  const {
    eventsList
  } = store.getState().eventsData

  const {
    page,
    limit,
    filters,
    sortParams
  } = eventsList

  dispatch(fetchEvents(filters, page, limit, sortParams))
}

export const fetchOrderEventDetails = (eventId, menuTypeId) => dispatch => {
  const { method, url } = eventEndpoints.orderWindow
  const urlWithId = `${url.replace(':eventId', eventId)}?menu_type_id=${menuTypeId}`
  getItemsList(method, urlWithId, {
    menu_type_id: menuTypeId
  })
    .then(res => {
      const { data = {} } = res
      const { event_details: eventDetails, order_window: orderWindow } = data
      dispatch({
        type: FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW,
        payload: { eventDetails, orderWindow }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const processOrderItemOnEdit = (item) => {
  const {
    thumbnail,
    banner
  } = item
  return {
    ...item,
    thumbnail: getSecureImageUrl(thumbnail),
    banner: getSecureImageUrl(banner)
  }
}

export const fetchEventToEdit = (eventId) => dispatch => {
  const { method, url } = eventEndpoints.edit(eventId)
  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      const { event } = data
      dispatch({
        type: SET_EVENT_TO_EDIT,
        payload: { event: processOrderItemOnEdit(event) }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const getEventById = (eventId) => {
  const { method, url } = eventEndpoints.byId(eventId)
  return getItemsList(method, url)
}

export const updateEvent = (eventData) => dispatch => {
  const {
    id,
    bannerImage,
    name,
    status,
    description,
    thumbnailImage,
    doorOpenTime,
    startTime,
    nonKosherCutoffTime,
    kosherCutoffTime,
    eventCategory,
    ticketMasterEventId
  } = eventData
  const data = new FormData()
  if (thumbnailImage) { data.append('event[thumb_image]', thumbnailImage) }
  if (bannerImage) { data.append('event[banner_image]', bannerImage) }
  data.append('event[web_name]', name)
  data.append('event[web_status]', status)
  data.append('event[web_description]', description)
  data.append('event[web_start_time]', startTime)
  data.append('event[doors_time]', doorOpenTime)
  data.append('event[ticket_master_event_id]', ticketMasterEventId)
  if (nonKosherCutoffTime) {
    data.append('event[non_kosher_cutoff_time]', nonKosherCutoffTime)
    data.append('event[non_kosher_cutoff_date]', nonKosherCutoffTime.format('YYYY/MM/DD'))
    data.append('event[non_kosher_cutoff_datetime]', nonKosherCutoffTime)
  }
  if (kosherCutoffTime) {
    data.append('event[kosher_cutoff_time]', kosherCutoffTime)
    data.append('event[kosher_cutoff_date]', kosherCutoffTime.format('YYYY/MM/DD'))
    data.append('event[kosher_cutoff_datetime]', kosherCutoffTime)
  }
  data.append('event[event_category_id]', eventCategory)
  const { method, url } = eventEndpoints.update(id)
  getItemsList(method, url, data)
    .then(res => {
      getEventById(id).then(res => {
        const { data = {} } = res
        const {
          eventsList
        } = store.getState().eventsData
        dispatch({
          type: UPDATE_EVENTS_LIST,
          payload: { eventsList, event: data }
        })
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getEventHotsheet = (eventId) => dispatch => {
  const { method, url } = eventEndpoints.hotSheet(eventId)
  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      const { file_name: fileUrl, is_hotsheet_available: hotsheetAvailable = false } = data
      if (hotsheetAvailable) window.open(fileUrl, '_blank')
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchEventEngineEvents = (startDate) => dispatch => {
  const formattedStartDate = moment(startDate).format('MMDDYYYY')
  const formattedEndDate = moment(startDate).add(1, 'days').format('MMDDYYYY')
  const { url } = eventEndpoints.eventEgineEvents
  const query = `start_date=${formattedStartDate}&end_date=${formattedEndDate}&venue_id=${MSG_VENUE_ID}`
  const axiosInstance = axios.create({
    headers: { Authorization: `Bearer ${EE_API_AUTH_TOKEN}` }
  })
  axiosInstance.defaults.headers.common = { Accept: 'application/json, text/plain, */*' }
  axiosInstance.get(`${url}?${query}`)
    .then(res => {
      const { data = {} } = res
      const { results } = data
      dispatch({
        type: STORE_EE_EVENTS_LIST,
        payload: { events: results }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchCompatibleEventsToMoveOrders = (eventId, filterOptions) => dispatch => {
  const { method, url } = eventEndpoints.listCompatibleEvents(eventId)
  filterOptions.eventCategory = filterOptions.eventType
  const data = prepareEventsListQuery(filterOptions)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { events } = data
      dispatch({
        type: SET_EVENTS_TO_MOVE_ORDERS,
        payload: { events }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetEventsToMoveOrders = () => dispatch => {
  dispatch({
    type: SET_EVENTS_TO_MOVE_ORDERS,
    payload: { events: [] }
  })
}

export const toggleEventStatus = (eventId, eventStatus) => dispatch => {
  const apiConfig = eventStatus === EVENT_STATUS.ACTIVE
    ? eventEndpoints.deactivate(eventId)
    : eventEndpoints.activate(eventId)

  const { method, url } = apiConfig
  getItemsList(method, url)
    .then(res => {
      dispatch({
        type: TOGGLE_EVENT_STATUS,
        payload: { eventId }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const moveOrders = (sourceEventId, targetEventId) => dispatch => {
  const apiConfig = eventEndpoints.moveOrders(sourceEventId)
  const { method, url } = apiConfig
  const data = { new_event_id: targetEventId }
  getItemsList(method, url, data)
    .then(res => {
      dispatch(fetchOrders({ eventId: sourceEventId }))
      dispatch(refreshEventsList())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

import {
  FETCH_RECONCILIATION_REPORT,
  FETCH_INVOICE_REPORT,
  SHOW_SPINNER,
  HIDE_SPINNER
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { reportEndpoints } from '../../constants/apiConfig'
import { getItemsList } from '../helpers/apiClient'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/menuItems'

const prepareReportQuery = (filters = {}, page, limit, sort = {}) => {
  const { companyId, suiteId, startDate, endDate, query } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      date: {
        start_date: startDate,
        end_date: endDate
      },
      company_id: companyId,
      suite_id: suiteId,
      query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchReconciliationReport = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = reportEndpoints.reconciliation
  const queryData = prepareReportQuery(filters, page, limit, sortParams)
  getItemsList(method, url, queryData)
    .then(res => {
      const { data = {} } = res
      const { items, meta } = data
      dispatch({
        type: FETCH_RECONCILIATION_REPORT,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchInvoiceReport = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = reportEndpoints.invoice
  const queryData = prepareReportQuery(filters, page, limit, sortParams)
  const {
    companies
  } = store.getState().staticData
  getItemsList(method, url, queryData)
    .then(res => {
      const { data = {} } = res
      const { items, meta } = data
      dispatch({
        type: FETCH_INVOICE_REPORT,
        payload: { companies, items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const exportInvoiceReport = (filters, page, limit, sortParams) => dispatch => {
  const { method, url } = reportEndpoints.exportInvoice
  const queryData = prepareReportQuery(filters, page, limit, sortParams)
  dispatch({
    type: SHOW_SPINNER,
    payload: {}
  })
  getItemsList(method, url, queryData)
    .then(res => {
      const { data = {} } = res
      const { file_name: fileUrl } = data
      dispatch({
        type: HIDE_SPINNER,
        payload: {}
      })
      setTimeout(() => {
        window.open(fileUrl, '_blank')
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const exportReconciliationReport = (filters = {}, page, limit, sortParams = {}) => dispatch => {
  const { method, url } = reportEndpoints.exportReconciliation
  const queryData = prepareReportQuery(filters, page, limit, sortParams)
  dispatch({
    type: SHOW_SPINNER,
    payload: {}
  })
  getItemsList(method, url, queryData)
    .then(res => {
      const { data = {} } = res
      const { file_name: fileUrl } = data
      dispatch({
        type: HIDE_SPINNER,
        payload: {}
      })
      setTimeout(() => {
        window.open(fileUrl, '_blank')
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

import React, { Suspense, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchSuites } from '../../store/suites/actions'
import SuitesFilterBar from '../../elements/SuitesFilterBar/SuitesFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import SubNav from '../../elements/SubNav/SubNav'
import { routes } from '../../pages'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))
const {
  columnNames,
  suitesProperties,
  rowsFor,
  resourceName,
  ctaLabel,
  styles
} = require('../../constants/suites')

const SuitesList = ({
  fetchItems,
  rows,
  totalPages = defaultTotalPages,
  page = defaultPage,
  limit,
  limit: rowsPerPage = defaultLimit,
  filters,
  sortParams
}) => {
  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, limit)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, limit, sort)
  }

  const applyFilter = (filters, page, limit, sortParams) => {
    fetchItems(filters, defaultPage, limit, sortParams)
  }

  return (
    <div>
      <SubNav routes={routes.find(({ path }) => path === '/admin').childRoutes} />
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <SuitesFilterBar
            ctaLabel={ctaLabel}
          />
        </FilterBar>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyTable
            columnNames={columnNames}
            properties={suitesProperties}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortParams={sortParams}
            handleSorting={handleSorting}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsFor}
            resourceName={resourceName}
          />
        </Suspense>
      </DataContainer>
    </div>

  )
}

SuitesList.propTypes = {
  rows: PropTypes.array,
  filters: PropTypes.object,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  fetchItems: PropTypes.func,
  sortParams: PropTypes.object
}

const mapStateToProps = (state) => ({
  ...state.suitesData.suitesList
})

const mapDispatchToProps = { fetchItems: fetchSuites }

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SuitesList)
)

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import styles from './ImageField.css'
const ImageField = ({
  classes,
  imageUrl,
  setImage,
  setImageUrl,
  label,
  selectedImage,
  fieldId
}) => {
  function handleImageChange (e) {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      setImageUrl(reader.result)
    }
    reader.readAsDataURL(file)
    setImage(file)
  }

  return (
    <div className={classes.container}>
      <span className={classes.label}> {label} </span>
      <div className={classes.imagePreviewWrapper}>
        <div className={classes.imagePreviewContainer}>
          <img className={classes.image} src={imageUrl || DEFAULT_IMAGE_URL} alt="Not Available" />
        </div>
        <div className={classes.uploadButtonContainer}>
          <div className={classes.selectedImageName}> {selectedImage ? selectedImage.name : 'No File Selected'} </div>
          <input
            onChange={(e) => handleImageChange(e)}
            accept="image/*"
            style={{ display: 'none' }}
            id={fieldId}
            type="file"
          />
          <label htmlFor={fieldId}>
            <Button variant="primary" component="span" className={classes.uploadImagebutton}>
              Browse
            </Button>
          </label>
        </div>
      </div>
    </div>
  )
}

ImageField.propTypes = {
  classes: PropTypes.object,
  imageUrl: PropTypes.string,
  setImage: PropTypes.func,
  setImageUrl: PropTypes.func,
  label: PropTypes.string,
  selectedImage: PropTypes.object,
  fieldId: PropTypes.string
}

ImageField.defaultProps = {
  label: 'IMAGE',
  selectedImage: null,
  fieldId: 'image-upload-button'
}

export default withStyles(styles)(ImageField)

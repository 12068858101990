const styles = {
  eventName: {
    height: '24px',
    width: '452px',
    color: 'black',
    fontFamily: 'Ringside Regular',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    margin: '7px 0px'
  },
  eventsContainer: {
    height: '350px',
    backgroundColor: '#EEEEEE',
    margin: '10px 3px',
    width: '98%',
    overflow: 'scroll',
    padding: '10px 5px',
    borderRadius: '0px',
    boxShadow: 'none'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'

function ChangePasswordTitle ({ className }) {
  return (
    <div className={className}>
      Change Password
    </div>
  )
}

ChangePasswordTitle.propTypes = {
  className: PropTypes.string
}

export default ChangePasswordTitle

const styles = {
  eventDetailsContainer: {
    display: 'flex',
    width: '1210px',
    paddingTop: '30px',
    color: 'white'
  },
  actionBar: {
    width: '100%'
  },
  col: {
    color: '#FFFFFF',
    height: '24px',
    fontSize: '16px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontWeight: 300,
    lineHeight: '24px',
    width: '380px'
  },
  eventWebName: {
    color: '#FFFFFF',
    height: 'auto',
    fontSize: '20px',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontWeight: 900,
    lineHeight: '24px',
    float: 'left',
    marginTop: '24px'
  },
  labelVerticleAligned: {
    paddingTop: '8px'
  },
  label: {
    height: '34px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    margin: '8px 0px',
    textTransform: 'uppercase'
  },
  value: {
    height: '24px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px',
    margin: '8px 0px'
  },
  detailExtraLong: {
    width: '320px'
  },
  detailLong: {
    width: '280px'
  },
  detailMedium: {
    width: '180px'
  },
  detailShort: {
    width: '130px'
  },
  labelShort: {
    width: '90px'
  },
  downloadHotSheetButton: {
    borderRadius: '0px',
    height: '50px',
    marginTop: '10px',
    width: '190px',
    marginRight: '20px',
    color: '#000',
    background: 'white',
    '&:disabled': {
      background: 'white'
    }
  },
  editEventCardButton: {
    borderRadius: '0px',
    height: '50px',
    marginTop: '10px',
    width: '175px',
    marginRight: '20px',
    color: '#000',
    background: 'white'
  },
  actionButtonRightPosition: {
    float: 'right'
  },
  subNav: {
    backgroundColor: '#1C202E',
    height: '65px',
    margin: 'auto',
    width: '1210px'
  },
  tab: {
    height: '100%',
    position: 'relative',
    width: '16%',
    color: 'white',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: 'bold',
    justifyContent: 'left',
    lineHeight: '30px',
    textDecoration: 'none',
    '& span': {
      alignItems: 'center',
      display: 'flex',
      height: '65px',
      marginLeft: '36px'
    }
  },
  isSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  tabsContainer: {
    marginTop: '10px'
  }
}

export default styles

const styles = {
  label: {
    backgroundClip: 'padding-box',
    backgroundColor: 'white',
    display: 'block',
    fontSize: '1rem',
    fontFamily: 'Arial,sans-serif',
    height: 'calc(2.25rem + 2px)',
    lineHeight: '1.5',
    marginLeft: '-3px',
    padding: '0.375rem 0.75rem',
    width: '100%'
  },
  input: {
    backgroundClip: 'padding-box',
    backgroundColor: 'white',
    border: '1px solid #939393',
    borderRadius: '0.25rem',
    display: 'block',
    fontSize: '1rem',
    fontFamily: 'Arial,sans-serif',
    height: 'calc(2.25rem + 1px)',
    lineHeight: '1.5',
    padding: '0.375rem 0.75rem',
    marginLeft: '10px',
    width: '431px',
    transition: 'borderColor .15s ease-in-out,boxShadow .15s ease-in-out',
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: '#2990ff',
      boxShadow: '0 0 0 .2rem #c4dbf9',
      outline: 'none'
    }
  },
  p: {
    color: '#dc3545',
    margin: '0px',
    fontSize: '80%',
    marginTop: '0.25rem',
    width: '100%',
    fontFamily: 'Arial,sans-serif',
    marginLeft: '10px'
  }
}

export default styles

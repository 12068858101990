import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import styles from './TableDataRow.css'

const rowCells = (row, properties, classes, settingsMenu, resourceName) => {
  const cells = properties.map(property => {
    const { key } = property
    if (key === 'id') {
      return <TableCell className={classes.tableCell} key={key} component="td" scope="row"> <Link to={`/${resourceName}/${row['id']}`} className={classes.linkToDetailsPage}> {row[key]} </Link> </TableCell>
    } else if (key === 'advanceOrderPrice' || key === 'gamedayOrderPrice') {
      return <TableCell className={classes.tableCell} key={key} component="td" scope="row">
        {row[key].map(location => <div key={location.locationId}>{location.locationName} - ${location.price}</div>)}
      </TableCell>
    } else if (key === 'imageUrl') {
      return <TableCell className={classes.headerPadding} key={key} component="td" scope="row">
        <img src={row[key] || DEFAULT_IMAGE_URL} width={'100px'} alt="" />
      </TableCell>
    } else if (key === 'suites') {
      return <TableCell className={classes.headerPadding} key={key} component="td" scope="row">
        {row[key].map((suite, index) => <div key={index}>{suite.display_name}</div>)}
      </TableCell>
    } else if (key === 'suiteDetails') {
      return <TableCell className={classes.headerPadding} key={key} component="td" scope="row">
        {row[key].map((suite, index) => <div key={index}>Suite: {suite.displayName}, Pay by Invoice: {suite.payByInvoice}</div>)}
      </TableCell>
    } else {
      return <TableCell className={classes.headerPadding} key={key} component="td" scope="row"> {row[key]} </TableCell>
    }
  })
  cells.unshift(<TableCell className={classes.headerPadding} key={-1} component="td" scope="row">{settingsMenu}</TableCell>)
  return cells
}
const TableDataRow = (props) => {
  const { row, properties, settingsMenu, resourceName, classes } = props
  return (
    <TableRow className={classes.tableRow}>{rowCells(row, properties, classes, settingsMenu, resourceName)}</TableRow>
  )
}

TableDataRow.propTypes = {
  row: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired,
  settingsMenu: PropTypes.object,
  resourceName: PropTypes.string.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(TableDataRow)

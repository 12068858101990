import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import Input from '@material-ui/core/Input'
import { MuiThemeProvider } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'
import theme from '../../dialogTheme'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import styles from './CreditCard.css'

const filterStyles = {
  selectorWidth: '300px'
}

const CreditCard = ({
  classes,
  userCreditCards,
  handleDelete,
  handleInput,
  currentAmount,
  currentSelectedCardId,
  currentIsPrimary,
  enableDelete
}) => {
  const [amount, setAmount] = useState(currentAmount)
  const [selectedCardId, setSelectedCardId] = useState(currentSelectedCardId)
  const [isPrimary, setIsPrimary] = useState(currentIsPrimary)

  const handleChange = (selectedCardId, amount, isPrimary) => {
    if (!selectedCardId) return
    const creditCard = userCreditCards.find(card => card.id === selectedCardId)
    handleInput({
      amount: parseFloat(amount) || null,
      id: selectedCardId,
      isPrimary,
      cardType: creditCard.card_type
    })
  }

  const handleIsPrimaryChange = e => {
    setIsPrimary(e.target.checked)
    handleChange(selectedCardId, amount, e.target.checked)
  }

  const handleSelectedCardIdChange = e => {
    setSelectedCardId(e.target.value)
    handleChange(e.target.value, amount, isPrimary)
  }

  const handleAmountChange = e => {
    setAmount(e.target.value)
    handleChange(selectedCardId, e.target.value, isPrimary)
  }
  const handleOnKeyPress = (event) => {
    const currentValue = event.target.value
    const isValidCurrentValue = /^[0-9]{4}.[0-9]{2}$/.test(currentValue)
    const isNumericValue = event.charCode === 46 || (event.charCode >= 48 && event.charCode <= 57)
    if (!isNumericValue || isValidCurrentValue) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    setAmount(currentAmount)
  }, [currentAmount, setAmount])

  useEffect(() => {
    setSelectedCardId(currentSelectedCardId)
  }, [currentSelectedCardId])

  useEffect(() => {
    setIsPrimary(currentIsPrimary)
  }, [currentIsPrimary])
  return (
    <Fragment>
      <div className={classes.creditCardLineItem}>
        <div className={classes.radioButtonContainer}>
          <Radio checked={!!currentIsPrimary} onClick={handleIsPrimaryChange} color="primary" />
        </div>
        <MuiThemeProvider theme={theme}>
          { getFilterSelector('Credit Cards', 'CreditCard', selectedCardId, handleSelectedCardIdChange, userCreditCards, 'cardName', filterStyles, false, false) }
        </MuiThemeProvider>
        <div className={classes.amountPrefix}>$</div>
        <Input
          placeholder="Enter Amount"
          className={classes.creditCardAmountInput}
          value={amount}
          onChange={handleAmountChange}
          onKeyPress={handleOnKeyPress}
        />
        { enableDelete && <IconButton
          aria-label="delete"
          className={classes.deleteButtonWrapper}
          onClick={() => handleDelete(selectedCardId)}>
          <Delete className={classes.removeCreditCard} />
        </IconButton> }
      </div>
      { currentIsPrimary && <Typography display="block" className={classes.primaryCardTitle} variant="h6" color="primary">Primary Card</Typography> }
    </Fragment>
  )
}

CreditCard.propTypes = {
  classes: PropTypes.object,
  userCreditCards: PropTypes.array.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  currentAmount: PropTypes.number,
  currentSelectedCardId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currentIsPrimary: PropTypes.bool,
  enableDelete: PropTypes.bool
}

CreditCard.defaultProps = {
  currentAmount: null,
  currentSelectedCardId: null,
  currentIsPrimary: false,
  enableDelete: true
}

export default withStyles(styles)(CreditCard)

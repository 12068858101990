import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles, FormControlLabel, Button, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { fetchAccount, updateAccountInfomation, updateAccountCreditCard, deleteAccountCard, clearAccountUpdateErr } from '../../store/settings/actions'
import { savePassword as updatePassword, resetUpdatePasswordMessage } from '../../store/authentication/actions'
import AddCreditCard from '../../Dialogs/AddCreditCard/AddCreditCard'
import EditableText from '../../elements/EditableText/EditableText'
import Confirmation from '../../Dialogs/Confirmation/Confirmation'
import ChangePassword from '../../Dialogs/ChangePassword/ChangePassword'
import AccountUpdate from '../../Dialogs/AccountUpdate/AccountUpdate'
import { storeUserCreditCards, initiateAddCreditCard, resetCreditCardAddedMessage, removeFreedomPayIframe, tokenizeCreditCard, hideUpdateCardNameForm, clearAddCreditCardError } from '../../store/creditCard/actions'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'
import { reportAnalytics } from '../../utils/analytics'
import {
  ANALYTICS_ACTION_TYPE_CTA,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_LOCATION_HEADER,
  ANALYTICS_ACTION_CHANGE_PASSWORD,
  ANALYTICS_ACTION_ADD_CARD,
  ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT
} from '../../constants/analytics'
import styles from './AccountInfo.css'

const ALLOW_ADD_CREDIT_CARD = true

const CreditCardDeleteConfirmationMessage = 'Your changes will be permanent. Please confirm that you want to save them.'
const AccountInfo = ({
  accountInfo,
  accountUpdateErr,
  addCardErr,
  classes,
  clearAddCreditCardError,
  clearAccountUpdateErr,
  creditCardAddedMessage,
  currentUser,
  deleteCardById,
  fetchAccountInfo,
  initiateAddCreditCard,
  resetCreditCardAddedMessage,
  resetUpdatePasswordMessage,
  saveAccountPassword,
  showCreditCardAddedMessage,
  storeUserCreditCards,
  updateAccountCard,
  updateAccountInfo,
  removeFreedomPayIframe,
  hideUpdateCardNameForm,
  updatePwdMessage,
  freedompayIframe,
  showFormToNameCreditCard,
  tokenizeCreditCard
}) => {
  const [addedCreditCard, setAddedCreditCard] = useState()
  const [cardIdToDelete, setCardIdToDelete] = useState(null)
  const [openAddCardDialog, setOpenAddCardDialog] = useState()
  const [openAccountUpdateInfoDialog, setAccountUpdateInfoDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [updatePasswordMessage, setUpdatePasswordMessage] = useState('')
  const [iframeRequestInProgress, setIframeRequestInProgress] = useState(false)

  useEffect(() => {
    if (freedompayIframe) {
      setIframeRequestInProgress(false)
    }
  }, [freedompayIframe])

  useEffect(() => {
    fetchAccountInfo()
  }, [fetchAccountInfo])

  useEffect(() => {
    if (accountUpdateErr) {
      setAccountUpdateInfoDialog(true)
    }
  }, [accountUpdateErr])

  useEffect(() => {
    if (updatePwdMessage) {
      setUpdatePasswordMessage(updatePwdMessage)
      const timer1 = setTimeout(() => resetUpdatePasswordMessage(), 2000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [updatePwdMessage, resetUpdatePasswordMessage])

  useEffect(() => {
    if (showCreditCardAddedMessage) {
      setOpenAddCardDialog(false)
      setTimeout(() => {
        resetCreditCardAddedMessage()
      }, 2000)
    }
  }, [showCreditCardAddedMessage, resetCreditCardAddedMessage])

  const cardObject = JSON.parse(localStorage.getItem('addedCreditCard'))

  if (cardObject) {
    localStorage.removeItem('addedCreditCard')
    setAddedCreditCard(cardObject)
    setOpenAddCardDialog(true)
  }

  const handleNotificationUpdate = (event) => {
    const { notification_details: notificationDetails = [] } = accountInfo
    const options = [...notificationDetails]
    const currentCheckboxIndex = options.findIndex(({ notification_setting_id: notificationSettingId, id }) => (String(id) === event.currentTarget.id || String(notificationSettingId) === event.currentTarget.id))
    const selectedNotification = options[currentCheckboxIndex]
    options[currentCheckboxIndex].send_notification = !selectedNotification.send_notification
    const updatedNotication = options[currentCheckboxIndex]
    updateAccountInfo({
      ...accountInfo,
      ...{ notification_details: options }
    }, updatedNotication)
  }

  const handleCreditCardNameUpdate = (updatedCreditCard) => {
    const { credit_card_details: creditCardDetails = [] } = accountInfo
    const options = [...creditCardDetails]
    const currentCreditCardIndex = options.findIndex(({ id }) => String(id) === String(updatedCreditCard.id))
    const selectedCreditCard = options[currentCreditCardIndex]
    options[currentCreditCardIndex].card_name = updatedCreditCard.updatedValue
    const updatedCardInformation = {
      ...accountInfo,
      ...{ credit_card_details: options }
    }
    updateAccountCard(updatedCardInformation, selectedCreditCard)
  }

  const getNotifications = () => {
    const { notification_details: notificationDetails = [] } = accountInfo
    const [notifications] = notificationDetails
    const { notification_setting_id: notificatioSettingId, name, send_notification: sendNotification, id } = notifications
    return <FormControlLabel
      key={notificatioSettingId}
      control={
        <Checkbox
          id={notificatioSettingId || id}
          name={notificatioSettingId}
          className={classes.notifications}
          checked={sendNotification}
          value={notificatioSettingId}
          onChange={handleNotificationUpdate}
        />
      }
      label={name}
      className={classes.notificationLabel}
    />
  }

  const deleteConfirmation = (id) => {
    setCardIdToDelete(id)
    setOpenDialog(true)
  }

  const getCards = () => {
    const { credit_card_details: creditCardDetails } = accountInfo
    return creditCardDetails.map(({ id, card_type: cardType, card_name: cardName }) => <div key={id} className={classes.cardHolderContainer}><FormControlLabel
      control={
        <EditableText
          id={id}
          className={classes.accountDetailLabel}
          text={cardName}
          remove={() => deleteConfirmation(id)}
          update={handleCreditCardNameUpdate}
        />
      }
    /></div>)
  }

  const deleteCreditCard = () => {
    const { credit_card_details: creditCardDetails = [] } = accountInfo
    const options = [...creditCardDetails]
    const currentCreditCardIndex = options.findIndex(({ id }) => String(id) === String(cardIdToDelete))
    if (currentCreditCardIndex > -1) {
      options.splice(currentCreditCardIndex, 1)
    }
    const updatedCardInformation = {
      ...accountInfo,
      ...{ credit_card_details: options }
    }
    deleteCardById(updatedCardInformation, cardIdToDelete)
    setOpenDialog(false)
    setCardIdToDelete(null)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setCardIdToDelete(null)
  }

  const handlePasswordDialogClose = () => {
    setOpenPasswordDialog(false)
  }

  const handleAccountUpdateInfoDialogClose = () => {
    setAccountUpdateInfoDialog(false)
    clearAccountUpdateErr(accountUpdateErr)
  }

  const savePassword = (password) => {
    if (password && password.new && password.confirmation) {
      saveAccountPassword({
        user: {
          password: password.new,
          password_confirmation: password.confirmation
        }
      })
    }
    setOpenPasswordDialog(false)
  }

  const changeAccountPassowrd = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CHANGE_PASSWORD,
        actionLocation: ANALYTICS_ACTION_LOCATION_HEADER,
        actionType: ANALYTICS_ACTION_TYPE_CTA
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT
    })
    setOpenPasswordDialog(true)
    setUpdatePasswordMessage('')
  }

  const closeFeedomPayModal = () => {
    clearAddCreditCardError()
    hideUpdateCardNameForm()
    setOpenAddCardDialog(false)
  }

  const handleAddCardDialogClose = () => {
    setOpenAddCardDialog(false)
  }

  const handleAddCard = (cardName) => {
    storeUserCreditCards(addedCreditCard, cardName, currentUser.id)
  }

  const initiateAddCardRedirection = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_ADD_CARD,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_CTA
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT
    })
    initiateAddCreditCard(window.location.pathname)
    setUpdatePasswordMessage('')
    setOpenAddCardDialog(true)
  }

  return (
    <div className={classes.accountDetailsWrapper}>
      {updatePasswordMessage && <QuickNotice message={updatePasswordMessage} />}
      {showCreditCardAddedMessage && <QuickNotice message={creditCardAddedMessage} />}
      <div className={classes.accountDetailsTitleContainer}>
        <span className={classes.accountTitle}>My Account</span>
        <a href="mailto:suiteservices@msg.com">Contact Us</a>
      </div>
      <div className={classes.accountDetailsContainer}>
        <span className={classes.accountDetailsContainerTitle}>Account Info</span>
        <Button variant="outlined" className={classes.filledButton} onClick={changeAccountPassowrd}>
          Change Password
        </Button>
        <div className={classes.section}>
          <div className={classes.accountDetailsSection}>
            <Typography className={classes.labelTitle} color="textSecondary" gutterBottom>
              Account Name
            </Typography>
            <Typography className={classes.accountDetailLabel} color="textSecondary" gutterBottom>
              {
                accountInfo && accountInfo.company_name
              }
            </Typography>
          </div>
          <div className={classes.accountDetailsSectionLeft}>
            <Typography className={classes.labelTitle} color="textSecondary" gutterBottom>
              Suite
            </Typography>
            <Typography className={classes.accountDetailLabel} color="textSecondary" gutterBottom>
              {
                accountInfo && accountInfo.suites.map(suite => suite.display_name)
              }
            </Typography>
          </div>
          <div className={classes.accountDetailsSectionRight}>
            <Typography className={classes.labelTitle} color="textSecondary" gutterBottom>
              Share Type
            </Typography>
            <Typography className={classes.accountDetailLabel} color="textSecondary" gutterBottom>
              {
                accountInfo && accountInfo.suites.map(suite => suite.share_types)
              }
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.accountDetailsContainer}>
        <span className={classes.accountDetailsContainerTitle}>Payment Methods</span>
        {
          ALLOW_ADD_CREDIT_CARD &&
          <Button variant="outlined" className={classes.filledButton} onClick={() => initiateAddCardRedirection()}>
            Add a Card
          </Button>
        }
        <div className={classes.section}>
          <Typography className={classes.labelTitle} color="textSecondary" gutterBottom>
            Cards on file
          </Typography>
          {
            accountInfo && getCards()
          }
        </div>
      </div>
      <div className={classes.accountDetailsContainerNotifications}>
        <span className={classes.accountDetailsContainerTitle}>Notifications</span>
        <div className={classes.section}>
          {
            accountInfo && getNotifications()
          }
        </div>
      </div>
      <Confirmation
        isOpen={openDialog}
        onClose={handleDialogClose}
        onSubmit={deleteCreditCard}
        message={CreditCardDeleteConfirmationMessage}
      />
      <ChangePassword
        isOpen={openPasswordDialog}
        onClose={handlePasswordDialogClose}
        savePassword={savePassword}
      />
      {openAccountUpdateInfoDialog && <AccountUpdate
        isOpen={openAccountUpdateInfoDialog}
        onClose={handleAccountUpdateInfoDialogClose}
        onSubmit={handleAccountUpdateInfoDialogClose}
        accountUpdateErr={accountUpdateErr}
      />}
      <AddCreditCard
        cardObject={addedCreditCard}
        clearAddCreditCardError={clearAddCreditCardError}
        openAddCardDialog={openAddCardDialog}
        handleAddCard={handleAddCard}
        handleAddCardDialogClose={handleAddCardDialogClose}
        iframeRequestInProgress={iframeRequestInProgress}
        freedompayIframe={freedompayIframe}
        removeFreedomPayIframe={removeFreedomPayIframe}
        tokenizeCreditCard={tokenizeCreditCard}
        closeFeedomPayModal={closeFeedomPayModal}
        showFormToNameCreditCard={showFormToNameCreditCard}
        suiteHolderUserId={currentUser.id}
        addCardErr={addCardErr}
        closeCCModal={() => {
          closeFeedomPayModal()
          window?.location?.reload()
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    accountInfo,
    accountUpdateErr,
    showCreditCardAddedMessage,
    creditCardAddedMessage
  } = state.accountInfo
  const { updatePwdMessage } = state.authReducer
  const { resetUpdatePasswordMessage } = state.authReducer
  const { freedompayIframe, showFormToNameCreditCard, addCardErr } = state.creditCardsReducers

  return {
    accountInfo,
    accountUpdateErr,
    updatePwdMessage,
    resetUpdatePasswordMessage,
    currentUser: state.authReducer.user,
    showCreditCardAddedMessage,
    creditCardAddedMessage,
    freedompayIframe,
    showFormToNameCreditCard,
    addCardErr
  }
}

AccountInfo.propTypes = {
  classes: PropTypes.object,
  clearAddCreditCardError: PropTypes.func,
  fetchAccountInfo: PropTypes.func.isRequired,
  updateAccountInfo: PropTypes.func,
  accountInfo: PropTypes.object,
  saveAccountPassword: PropTypes.func.isRequired,
  updateAccountCard: PropTypes.func.isRequired,
  clearAccountUpdateErr: PropTypes.func.isRequired,
  deleteCardById: PropTypes.func.isRequired,
  updatePwdMessage: PropTypes.string,
  accountUpdateErr: PropTypes.object,
  initiateAddCreditCard: PropTypes.func.isRequired,
  storeUserCreditCards: PropTypes.func,
  currentUser: PropTypes.object,
  resetCreditCardAddedMessage: PropTypes.func,
  resetUpdatePasswordMessage: PropTypes.func,
  showCreditCardAddedMessage: PropTypes.bool,
  creditCardAddedMessage: PropTypes.string,
  freedompayIframe: PropTypes.string,
  showFormToNameCreditCard: PropTypes.bool,
  removeFreedomPayIframe: PropTypes.func,
  hideUpdateCardNameForm: PropTypes.func,
  tokenizeCreditCard: PropTypes.func,
  addCardErr: PropTypes.object
}

const mapDispatchToProps = {
  clearAccountUpdateErr: clearAccountUpdateErr,
  deleteCardById: deleteAccountCard,
  fetchAccountInfo: fetchAccount,
  hideUpdateCardNameForm,
  initiateAddCreditCard,
  removeFreedomPayIframe,
  clearAddCreditCardError,
  resetCreditCardAddedMessage,
  resetUpdatePasswordMessage,
  saveAccountPassword: updatePassword,
  storeUserCreditCards,
  tokenizeCreditCard,
  updateAccountCard: updateAccountCreditCard,
  updateAccountInfo: updateAccountInfomation
}

const AccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfo)

export default withStyles(styles)(AccountContainer)

import moment from 'moment'
import {
  FETCH_EVENTS,
  FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW,
  SET_EVENTS_TO_MOVE_ORDERS,
  CLEAR_EVENT_DETAILS,
  SET_EVENT_TO_EDIT,
  STORE_EE_EVENTS_LIST,
  UPDATE_EVENTS_LIST,
  TOGGLE_EVENT_STATUS
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'
import { getSecureImageUrl } from '../../utils/imageUtils'

const EVENT_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}
const initialState = {}

const getStatus = (status) => {
  return status ? 'Active' : 'Inactive'
}

const getFormattedDateTime = dateTime => moment(new Date(dateTime)).tz('America/New_York').format('MM/DD/YYYY hh:mm A')

const getFormattedTime = dateTime => moment(new Date(dateTime)).tz('America/New_York').format('hh:mm A')

const processEventData = (event) => {
  const {
    available_suites: activeSuitesCount,
    booked_suites: bookedSuitesCount,
    doors_time: doors,
    id,
    kosher_cutoff_datetime: kosherMenuCutoff,
    non_kosher_cutoff_datetime: nonKosherMenuCutoff,
    is_kosher_order_allowed: isKosherOrderAllowed,
    orders,
    start_date: startDate,
    start_time: startTime,
    unavailable_suites: inactiveSuitesCount,
    web_name: webName,
    web_status: webStatus
  } = event

  return {
    activeSuitesCount,
    bookedSuitesCount,
    doors,
    id,
    inactiveSuitesCount,
    kosherMenuCutoff: isKosherOrderAllowed ? getFormattedDateTime(kosherMenuCutoff) : 'N/A',
    nonKosherMenuCutoff: getFormattedDateTime(nonKosherMenuCutoff),
    orders,
    status: getStatus(webStatus),
    startDate,
    startTime,
    webName
  }
}

const getFormatedEventData = (eventDetails, orderWindow) => {
  const {
    web_name: name,
    number,
    start_date: startDate,
    thumb_image: thumbnailUrl,
    client_cutoff_date: clientCutoffDate,
    client_cutoff_time: clientCutoffTime,
    admin_cutoff_date: adminCutoffDate,
    admin_cutoff_time: adminCutoffTime
  } = eventDetails
  const eventDate = moment(startDate, 'MM/DD/YYYY')
  return {
    name,
    startDateMonth: eventDate.format('MMM'),
    startDate: eventDate.format('DD'),
    startDateDay: eventDate.format('ddd'),
    thumbnailUrl: getSecureImageUrl(thumbnailUrl),
    eatecId: number,
    orderWindow,
    clientCutoffDate,
    clientCutoffTime: getFormattedTime(clientCutoffTime),
    adminCutoffDate,
    adminCutoffTime
  }
}

const updateEventsList = (payload) => {
  const { eventsList, event } = payload
  const { rows } = eventsList
  const indexOfUpdateItem = rows.findIndex(item => item.id === event.id)
  rows[indexOfUpdateItem] = processEventData(event)
  eventsList.rows = rows.slice()
  return eventsList
}

const processEventEngineEvents = (events) => events.map(({ id, name }) => ({ id, title: `${name} - (${id})` }))

const toggleEventStatus = (events, eventId) => {
  const { rows } = events
  const eventIndex = rows.findIndex(row => row.id === eventId)
  const event = rows[eventIndex]
  const status = event.status === EVENT_STATUS.ACTIVE
    ? EVENT_STATUS.INACTIVE
    : EVENT_STATUS.ACTIVE
  event.status = status
  rows[eventIndex] = event
  events.roes = [...rows]
  return events
}

export default function EventsReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        eventsList: processItemsList(action.payload, processEventData)
      }
    case FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW:
      return {
        ...state,
        orderEventDetails: getFormatedEventData(action.payload.eventDetails, action.payload.orderWindow)
      }
    case CLEAR_EVENT_DETAILS:
      return {
        ...state,
        orderEventDetails: {}
      }
    case SET_EVENT_TO_EDIT: {
      const { event = {} } = action.payload
      return {
        ...state,
        eventToEdit: event
      }
    }
    case UPDATE_EVENTS_LIST:
      return {
        ...state,
        eventsList: updateEventsList(action.payload)
      }
    case STORE_EE_EVENTS_LIST:
      return {
        ...state,
        eventEngineEvents: processEventEngineEvents(action.payload.events) || []
      }
    case SET_EVENTS_TO_MOVE_ORDERS:
      return {
        ...state,
        compatibleEventsToMoveOrders: action.payload.events
      }
    case TOGGLE_EVENT_STATUS:
      return {
        ...state,
        eventsList: toggleEventStatus(state.eventsList, action.payload.eventId)
      }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import CustomButton from '../../elements/CustomButton/CustomButton'
const EditSuiteHolderUserActions = ({
  classes,
  handleCancel,
  saveSuiteHolderUser,
  handleUserStatus,
  suiteHolderUser,
  validationError,
  showCreditCardAddedMessage,
  creditCardAddedMessage,
  cardPresentToAdd
}) => {
  return (
    <div className={classes.saveSuiteHolderUserActionsContainer}>
      {
        validationError &&
          <Typography variant="h6" className={classes.validationError}>{validationError}</Typography>
      }
      {
        showCreditCardAddedMessage &&
          <Typography variant="h6" className={classes.creditCardMessage}>{creditCardAddedMessage}</Typography>
      }
      {
        suiteHolderUser && suiteHolderUser.id && <CustomButton
          variant="contained"
          disableRipple
          color="primary"
          disabled={cardPresentToAdd}
          styleClass={classes.saveSuiteHolderUserButton}
          clickHandler={handleUserStatus}
          label={suiteHolderUser.webStatus ? 'Deactivate User' : 'Activate User'}
        />
      }
      {suiteHolderUser && <CustomButton
        variant={'contained'}
        disableRipple
        disabled={cardPresentToAdd}
        styleClass={suiteHolderUser.id ? classes.saveSuiteHolderUserButton : classes.newSuiteHolderUserButton}
        label={'Save'}
        color={'primary'}
        clickHandler={saveSuiteHolderUser}
      />}
      {suiteHolderUser && <CustomButton
        variant={'contained'}
        disableRipple
        disabled={cardPresentToAdd}
        styleClass={suiteHolderUser.id ? classes.cancelButton : classes.cancelButtonForNewUser}
        label={'Cancel'}
        clickHandler={handleCancel}
      />}
    </div>
  )
}
EditSuiteHolderUserActions.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
  saveSuiteHolderUser: PropTypes.func,
  handleUserStatus: PropTypes.func,
  suiteHolderUser: PropTypes.object,
  validationError: PropTypes.string,
  showCreditCardAddedMessage: PropTypes.bool,
  creditCardAddedMessage: PropTypes.string,
  cardPresentToAdd: PropTypes.bool
}

export default EditSuiteHolderUserActions

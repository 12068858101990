import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import OrderItem from '../../elements/OrderItem/OrdeItem'
import styles from './OrderItemsContainer.css'
const OrderItemsContainer = ({
  classes,
  orderItems,
  handleQuantityChange,
  handleRemoveItemFromCart,
  currentOrderWindow,
  currentUser
}) => {
  const activeOrderItems = orderItems.filter(item => item._destroy !== 1)
  return (
    <div>
      <div className={classes.title}>Order Summary</div>
      <div className={classes.tableHeader}>
        <div className={classes.quantity}>QTY</div>
        <div className={classes.name}>ITEM</div>
        <div className={classes.price}>UNIT</div>
        <div className={classes.price}>TOTAL</div>
      </div>
      <div className={classes.orderItemsRowsContainer}>
        {
          activeOrderItems.map((item, index) => {
            return <OrderItem
              key={index}
              index={index}
              currentUser={currentUser}
              currentOrderWindow={currentOrderWindow}
              orderItem={item}
              handleQuantityChange={handleQuantityChange}
              handleRemoveItemFromCart={handleRemoveItemFromCart}
            />
          })
        }
      </div>
    </div>
  )
}

OrderItemsContainer.propTypes = {
  classes: PropTypes.object,
  currentOrderWindow: PropTypes.string,
  currentUser: PropTypes.string,
  handleQuantityChange: PropTypes.func,
  handleRemoveItemFromCart: PropTypes.func,
  orderItems: PropTypes.array
}

export default withStyles(styles)(OrderItemsContainer)

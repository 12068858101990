import { SUITE_HOLDER_USER_UPDATED,
  UPDATE_SUITE_HOLDER_USER_FORM_DATA,
  FETCH_SUITE_HOLDER_USERS,
  UPDATE_CLIENT_CREDIT_CARD,
  SET_SUITE_HOLDER_USER_TO_EDIT,
  SET_SUITE_HOLDER_USER_TO_ADD,
  CLOSE_SUITE_HOLDER_USER_DIALOG,
  SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE,
  SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER,
  REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER,
  UPDATE_CREDIT_CARD_DATA_TO_STORE,
  DELETE_USER_CREDIT_CARD_ERR
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'

const initialState = {}

function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getFormattedRoleName = (roleName) => {
  return capitalizeFirstLetter(roleName.replace(/_/g, ' '))
}

const getSuiteDetails = (suiteDetails) => {
  return suiteDetails.reduce((result, suiteDetail) => {
    const { display_name: displayName, can_use_invoice: canUseInvoice } = suiteDetail
    result.push({
      displayName,
      payByInvoice: canUseInvoice ? 'Yes' : 'No'
    })
    return result
  }, [])
}
const processSuiteHolderUserData = (staffUser) => {
  const {
    id,
    user_name: name,
    web_status: webStatus,
    contact_number: phoneNumber,
    role_name: roleName,
    is_oracle_admin: isOracleAdmin,
    email,
    suite_details: suiteDetails
  } = staffUser

  return {
    id,
    name,
    webStatus,
    webStatusText: webStatus ? 'Active' : 'Inactive',
    phoneNumber,
    roleName: getFormattedRoleName(roleName),
    isOracleAdmin: isOracleAdmin ? 'Yes' : 'No',
    email,
    suiteDetails: getSuiteDetails(suiteDetails)
  }
}

const serializeSHData = (payload) => {
  const {
    user: suiteHolderUser,
    suitesList,
    mode,
    dialogOpenStatus,
    paymentDetails = [],
    companies,
    roleList,
    message = ''
  } = payload
  return {
    ...suiteHolderUser,
    paymentDetails,
    suitesList,
    mode,
    dialogOpenStatus,
    companies,
    roleList,
    message
  }
}

export default function SuiteHolderUsersReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_SUITE_HOLDER_USERS:
      return {
        ...state,
        suiteHolderUsersList: processItemsList(action.payload, processSuiteHolderUserData)
      }
    case SET_SUITE_HOLDER_USER_TO_EDIT:
      return {
        ...state,
        suiteHolderUserToProcess: serializeSHData(action.payload)
      }
    case SET_SUITE_HOLDER_USER_TO_ADD:
      return {
        ...state,
        suiteHolderUserToProcess: serializeSHData(action.payload)
      }
    case CLOSE_SUITE_HOLDER_USER_DIALOG:
      return {
        ...state,
        suiteHolderUserToProcess: serializeSHData(action.payload),
        cardDeletionErr: null
      }
    case UPDATE_SUITE_HOLDER_USER_FORM_DATA:
      const { suiteHolderUserToProcess } = action.payload
      return {
        ...state,
        suiteHolderUserToProcess
      }
    case SUITE_HOLDER_USER_UPDATED:
      return {
        ...state,
        suiteHolderUserToProcess: serializeSHData(action.payload)
      }
    case UPDATE_CLIENT_CREDIT_CARD:
      return {

      }
    case SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE:
      return {
        ...state,
        suiteHolderUserToProcess: action.payload
      }
    case SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER:
      return {
        ...state,
        suiteHolderUserCreditCard: action.payload
      }
    case REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER:
      const { creditCardData } = action.payload
      return {
        ...state,
        suiteHolderUserCreditCard: creditCardData
      }
    case UPDATE_CREDIT_CARD_DATA_TO_STORE:
      const { suiteHolderUserToProcess: suitesHolderUserData } = action.payload
      return {
        ...state,
        suiteHolderUserToProcess: suitesHolderUserData
      }
    case DELETE_USER_CREDIT_CARD_ERR:
      const { cardDeletionErr } = action.payload
      return {
        ...state,
        cardDeletionErr
      }
    default:
      return state
  }
}

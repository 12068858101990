import { checkProperties, validateEmail } from './validations'

export const validateUser = (suiteHolderUser) => {
  let validationError = ''
  const { firstName, lastName, roleId, email } = suiteHolderUser
  const fieldsToValidate = {
    firstName,
    lastName,
    email,
    roleId
  }
  const isSuiteHolderFormValid = checkProperties(fieldsToValidate) && validateEmail(email)
  if (!isSuiteHolderFormValid) {
    validationError = 'Provide valid inputs to required fields'
  }
  return validationError
}

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Dialog from '../../elements/Dialog/Dialog'
import styles from './ClientAddOrder.css'
import Title from './Title'
import Content from './Content'
const getTitle = (classes, item = {}) => {
  return <Title classes={classes} imageUrl={item.banner} />
}

const getDialogContent = (item, classes, handleAddOrder, suites, orderWindow) => {
  return <Content classes={classes} item={item} handleAddOrder={handleAddOrder} suites={suites} orderWindow={orderWindow} />
}

const getDialogActions = (classes) => {
  return <div className={classes.dialogActions} />
}

const ClientAddOrder = ({
  handleDialogClose,
  openDialog,
  classes,
  event,
  handleAddOrder,
  orderWindow,
  suites
}) => {
  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(classes, event)}
      dialogContent={getDialogContent(event, classes, handleAddOrder, suites, orderWindow)}
      dialogActions={getDialogActions(classes)}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

ClientAddOrder.propTypes = {
  handleDialogClose: PropTypes.func,
  openDialog: PropTypes.bool,
  classes: PropTypes.object,
  event: PropTypes.object,
  handleAddOrder: PropTypes.func,
  orderWindow: PropTypes.string,
  suites: PropTypes.array
}

export default withStyles(styles)(ClientAddOrder)

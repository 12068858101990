const styles = {
  detailsHeader: {
    display: 'flex',
    padding: '10px 0px 10px'
  },
  companyName: {
    width: '300px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px'
  },
  fieldTitle: {
    height: '14px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    marginBottom: '6px'
  },
  fieldValue: {
    height: '50px',
    minWidth: '170px',
    maxWidth: '300px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px'
  },
  assignEventButton: {
    color: 'white',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    height: '50px',
    width: '170px',
    backgroundColor: '#0078D4',
    borderRadius: '0px',
    float: 'right',
    '&:hover': {
      backgroundColor: '#0078D4'
    }
  },
  actionsContainer: {
    marginLeft: '12px',
    '& button': {
      marginBottom: '8px',
      marginTop: '8px'
    }
  },
  separator: {
    boxSizing: 'border-box',
    height: '2px',
    width: '1210px',
    border: '1px solid #4D4E58'
  },
  usersListHeader: {
    height: '24px',
    width: '485px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '24px',
    fontWeight: '900',
    lineHeight: '24px',
    paddingTop: '10px'
  }
}

export default styles

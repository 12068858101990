import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../elements/CustomButton/CustomButton'

const MoveOrdersDialogActions = ({
  classes,
  handleDialogClose,
  handleSubmit,
  eventId
}) => {
  return (
    <div className={classes.moveOrderActionsContainer}>
      <CustomButton
        variant={'outlined'}
        disableRipple
        styleClass={classes.cancelMoveOrdersButton}
        label={'Cancel'}
        clickHandler={handleDialogClose}
      />
      <CustomButton
        variant={'contained'}
        disableRipple
        styleClass={classes.continueButton}
        label={'Continue'}
        color={'primary'}
        clickHandler={handleSubmit}
        disabled={!eventId}
      />
    </div>
  )
}

MoveOrdersDialogActions.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  eventId: PropTypes.number
}

export default MoveOrdersDialogActions

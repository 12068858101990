import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import SearchField from '../SearchField/SearchField'
import CustomCheckBox from '../CustomCheckBox/CustomCheckBox'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { getDateSelector } from '../DateSelector/getDateSelector'
import ResetFilters from '../ResetFilters/ResetFilters'
import useStyles from './EventsFilterBar.css'

const EventsFilterBar = ({
  classes,
  eventStatusList = [],
  eventCategories = [],
  filters = {},
  filterItems,
  handleViewEventsSuitesData,
  showEventsSuitesData,
  styles
}) => {
  const [status, setStatus] = useState(filters.status || '')
  const [eventCategory, setEventCategory] = useState(filters.eventCategory || '')
  const [startDate, setStartDate] = useState(filters.startDate || '')
  const [endDate, setEndDate] = useState(filters.endDate || '')
  const [query, setSearchQuery] = useState(filters.query || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)

  const handleEventStatusSelection = (event) => {
    const status = event.target.value
    setStatus(status)
  }

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleEventCategorySelection = (event) => {
    const eventCategory = event.target.value
    setEventCategory(eventCategory)
  }

  const handleStartDateSelection = (event) => {
    const startDate = event.target.value
    setStartDate(startDate)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const clearFilters = () => {
    setStatus('')
    setStartDate('')
    setEndDate('')
    setEventCategory('')
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  const handleSubmit = () => {
    filterItems({ status, startDate, endDate, eventCategory, query })
  }

  const handleEndDateSelection = (event) => {
    const endDate = event.target.value
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() > startDateObj.getTime()) {
      setEndDate(endDate)
    } else {
      setEndDate(startDate)
    }
  }

  return (
    <>
      {getDateSelector('DATE RANGE', startDate, handleStartDateSelection)}
      {getDateSelector('  ', endDate, handleEndDateSelection)}
      {getFilterSelector('Status', 'status', status, handleEventStatusSelection, eventStatusList, 'status', styles)}
      {getFilterSelector('Event Type', 'eventType', eventCategory, handleEventCategorySelection, eventCategories, 'name', styles)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <div className={classes.checkbox}>
        <CustomCheckBox
          value={showEventsSuitesData}
          isChecked={showEventsSuitesData}
          label="Single Event Suite Rentals"
          handleChange={handleViewEventsSuitesData}
        />
      </div>
      <ResetFilters handleClick={clearFilters} />
    </>
  )
}

EventsFilterBar.propTypes = {
  classes: PropTypes.object,
  eventStatusList: PropTypes.array,
  eventCategories: PropTypes.array,
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  handleViewEventsSuitesData: PropTypes.func,
  styles: PropTypes.object,
  showEventsSuitesData: PropTypes.bool
}

export default withStyles(useStyles)(EventsFilterBar)

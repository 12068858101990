const styles = {
  fieldTitle: {
    height: '14px',
    width: '51px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  menuFilterContainer: {
    height: '100px',
    display: 'flex',
    width: '1255px'
  },
  menuItemsWrapper: {
    paddingTop: '50px'
  },
  menuItemsByCateoryContainer: {

  },
  cartQuickViewContainer: {
    position: 'absolute',
    right: 0
  },
  categoryTitle: {
    height: '44px',
    width: '346px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px',
    marginBottom: '20px'
  },
  menuItemsList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '1250px'
  },
  menuItemWrapper: {
    height: '233px',
    backgroundColor: '#000',
    minWidth: '274px',
    maxWidth: '274px',
    margin: '0px 37px 50px 0px',
    cursor: 'pointer'
  },
  searchFieldContainer: {
    // width: '450px',
    // display: 'flex'
  },
  clearFilters: {
    paddingTop: '20px',
    cursor: 'pointer',
    height: '36px',
    width: '82px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular A',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '36px',
    textAlign: 'right',
    textDecoration: 'underline solid #0078D4',
    float: 'right'
  },
  showMoreButton: {
    width: '80px',
    paddingTop: '40px'
  },
  eventAndCartContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  filterBarContainer: {
    marginTop: '40px'
  },
  sticky: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    backgroundColor: 'black',
    zIndex: '9999',
    minHeight: '120px',
    '& div': {
      justifyContent: 'center'
    }
  },
  quickAddNotice: {
    height: '38px',
    width: '100%',
    backgroundColor: '#65FFF5',
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    position: 'fixed',
    left: '0px',
    top: '120px',
    zIndex: 9999999,
    paddingTop: '8px'
  },
  stickyCartContainer: {
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 999
  },
  deadlineText: {
    marginTop: '5px'
  },
  taxAndGratuitiesText: {
    marginBottom: '30px'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import styles from './LoadingState.css'

const LoadingState = ({
  classes
}) => {
  return (
    <div className={classes.loadingStateContainer}>
      <CircularProgress disableShrink size={80} className={classes.spinner} />
    </div>
  )
}

LoadingState.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(LoadingState)

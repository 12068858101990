import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const EditMenuItemDialogActions = ({
  classes,
  handleCancel,
  updateItem,
  handleAssignCategory
}) => {
  return (
    <div className={classes.editMenuItemActionsContainer}>
      <Button variant="contained" color="primary" className={classes.assignCategoryButton} onClick={handleAssignCategory}>
        Assign Category
      </Button>
      <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" className={classes.updateMenuItemButton} onClick={updateItem}>
        Update Menu Item
      </Button>
    </div>
  )
}

EditMenuItemDialogActions.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
  updateItem: PropTypes.func,
  handleAssignCategory: PropTypes.func
}

export default EditMenuItemDialogActions

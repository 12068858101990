import store, { history } from '../store/store'
import setAuthToken from '../store/authentication/setAuthToken'
import { logoutUser } from '../store/authentication/actions'

export const verifyToken = () => {
  if (localStorage.ValidToken === 'true') {
    setAuthToken(localStorage.Token, localStorage.Uid, localStorage.Client)
  } else {
    store.dispatch(logoutUser(history))
  }
}

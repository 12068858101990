import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Switch } from '@material-ui/core'
import styles from './SwitchButton.css'

const SwitchButton = ({ classes, ...props }) => {
  return (
    <>
      { props.label && <span className={classes.switchButtonLabel}>{props.label}</span> }
      <div className={classes.switchButtonsContainer}>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          {...props}
        />
      </div>
    </>
  )
}

SwitchButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string
}

export default withStyles(styles)(SwitchButton)

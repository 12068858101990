import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import useStyles from './TableHeader.css'

const getSortIcon = (columnKey, sortBy, order) => {
  let sortIcon
  if (columnKey === sortBy && order === 'desc') {
    sortIcon = <KeyboardArrowDown />
  } else {
    sortIcon = <KeyboardArrowUp />
  }
  return sortIcon
}

const TableHeader = props => {
  const { properties = [], handleSorting, sortParams = {} } = props
  const { sortBy = '', order = 'desc' } = sortParams
  const classes = useStyles(props)

  const sortRows = columnName => {
    const sortDirection = order === 'desc' ? 'asc' : 'desc'
    handleSorting(columnName, sortDirection)
  }

  const tableCells = properties.map(property => {
    if (property.sortable) {
      return <TableCell key={property.key} onClick={() => sortRows(property.key)} className={classes.sortableColumn}>
        {property.label}
        {getSortIcon(property.key, sortBy, order)}
      </TableCell>
    } else {
      return <TableCell key={property.key}> {property.label} </TableCell>
    }
  })
  tableCells.unshift(<TableCell key={-1} />)
  return (
    <TableHead>
      <TableRow className={classes.tableHeader}>
        { tableCells }
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  properties: PropTypes.array.isRequired,
  handleSorting: PropTypes.func.isRequired,
  sortParams: PropTypes.object
}

export default TableHeader

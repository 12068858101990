import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const styles = {
  root: {},
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: 'black'
  }
}
const DialogTitle = props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  onClose: PropTypes.func
}

export default withStyles(styles)(DialogTitle)

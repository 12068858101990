const styles = {
  orderItemsContainer: {
    padding: '0px 10px 5px'
  },
  orderDetailsContainer: {
    width: '1210px',
    margin: 'auto'
  },
  orderBasicDetailsContainer: {
    display: 'flex',
    width: '1210px',
    padding: '30px 0px',
    color: 'white'
  },
  label: {
    height: '34px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    margin: '8px 0px'
  },
  value: {
    height: '24px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    margin: '8px 0px'
  },
  detailExtraLong: {
    width: '320px'
  },
  detailLong: {
    width: '280px'
  },
  detailMedium: {
    width: '180px'
  },
  detailShort: {
    width: '130px'
  },
  labelShort: {
    width: '80px'
  },
  detailsContainerPadding: {
    padding: '40px'
  },
  itemsAndPaymentWrapper: {
    display: 'flex'
  },
  orderItemsContainerList: {
    width: '604px',
    margin: '2px 2px 0px 0px',
    background: 'white',
    minHeight: '300px'
  },
  paymentSummaryContainer: {
    width: '604px',
    marginTop: '2px',
    background: 'white',
    minHeight: '300px',
    paddingTop: '60px'
  },
  subTotalContainer: {
    color: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 24px 30px 30px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px'
  },
  orderDetailsHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '30px'
  },
  orderActionButton: {
    background: 'white',
    borderRadius: '0px',
    width: '170px',
    height: '50px',
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    marginRight: '20px',
    '&:hover': {
      background: 'white'
    }
  },
  orderNumber: {
    height: '44px',
    width: '378px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px'
  }
}

export default styles

import React from 'react'
import FilterSelector from './FilterSelector'

export const getFilterSelector = (
  label,
  name,
  value,
  changeHandler,
  selectionOptions,
  optionValueKey,
  styles,
  shrink = true,
  displayLabel = true,
  defaultOption = 'All',
  disabled = false
) => {
  return <FilterSelector
    name={name}
    label={label}
    value={value}
    changeHandler={changeHandler}
    selectionOptions={selectionOptions}
    optionValueKey={optionValueKey}
    styles={styles}
    shrink={shrink}
    displayLabel={displayLabel}
    defaultOption={defaultOption}
    disabled={disabled}
  />
}

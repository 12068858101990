import {
  SET_DEFAULT_DATA,
  RESET_DEFAULT_DATA
} from '../actionTypes'

const initialState = {}

export default function StaticDataReducers (state = initialState, action) {
  switch (action.type) {
    case SET_DEFAULT_DATA:
      return {
        ...state,
        ...action.payload
      }
    case RESET_DEFAULT_DATA:
      return initialState
    default:
      return state
  }
}

const styles = theme => ({
  logoContainer: {
    height: '50px',
    margin: '35px 20px 20px',
    width: '156px'
  },
  suitesImage: {
    width: '100%'
  },
  gloablNavContainer: {
    display: 'flex',
    margin: '35px 20px 20px'
  },
  headerLinksContainer: {
    height: '24px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '24px',
    textAlign: 'right',
    paddingRight: '20px',
    cursor: 'pointer',
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  headerGrid: {
    justifyContent: 'space-between',
    height: '70px',
    display: 'flex',
    width: '1440px',
    margin: 'auto',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B'
  },
  cartQuickViewContainer: {
    position: 'relative',
    bottom: '10px'
  }
})

export default styles

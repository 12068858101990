const styles = {
  dataContainer: {
    width: '1210px',
    margin: '0 auto',
    paddingTop: '40px',
    position: 'relative'
  }
}

export default styles

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import idx from 'idx'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import DataContainer from '../../elements/DataContainer/DataContainer'
import { fetchEventToEdit, updateEvent, getEventHotsheet } from '../../store/events/actions'
import TabGroup from '../../elements/TabGroup/TabGroup'
import OrdersByEvent from '../Orders/OrdersByEvent'
import EventSuitesList from '../EventSuitesList/EventSuitesList'
import CustomButton from '../../elements/CustomButton/CustomButton'
import EditEvent from '../../Dialogs/EditEvent/EditEvent'
import { SuperAdminRoleID } from '../../constants/userRoles.js'
import styles from './EventDetails.css'

const EventDetails = ({
  classes,
  currentUser,
  eventToEdit,
  eventCategories,
  fetchEventToEdit,
  getEventHotsheet,
  route,
  updateEvent
}) => {
  const eventId = idx(route, _ => parseInt(_.match.params.id, 10))
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false)
  const [showOrdersTab, setShowOrdersTab] = useState(true)
  const [showSuitesTab, setShowSuitesTab] = useState()
  const [showTabs, setShowTabs] = useState(false)

  useEffect(() => {
    if (currentUser) {
      const { role_id: roleId } = currentUser
      setShowTabs(roleId === SuperAdminRoleID)
    }
  }, [currentUser])

  useEffect(() => {
    if (!eventToEdit) {
      fetchEventToEdit(eventId)
    }
  }, [eventId, eventToEdit, fetchEventToEdit, route])

  useEffect(() => {
    fetchEventToEdit(eventId)
  }, [eventId, fetchEventToEdit])

  const getKosherMenuCutoff = (eventToEdit) => {
    const isKosherMenuAvailable = idx(eventToEdit, _ => eventToEdit.cut_off_by_menu_types.kosher.is_available)
    return isKosherMenuAvailable
      ? moment(+`${idx(eventToEdit, _ => eventToEdit.cut_off_by_menu_types.kosher.client_cut_off_in_int)}000`).tz('America/New_York').format('MMMM Do YYYY, h:mm:ss a')
      : 'N/A'
  }

  const getNonKosherMenuCutoff = (eventToEdit) => {
    return moment(+`${idx(eventToEdit, _ => eventToEdit.cut_off_by_menu_types.non_kosher.client_cut_off_in_int)}000`).tz('America/New_York').format('MMMM Do YYYY, h:mm:ss a')
  }

  const handleEventEdit = () => {
    setOpenEditEventDialog(true)
  }

  const handleDownloadHotSheetButton = () => {
    getEventHotsheet(eventId)
  }

  const handleDialogClose = () => {
    setOpenEditEventDialog(false)
  }

  const handleUpdate = (eventData) => {
    updateEvent(eventData)
    setTimeout(() => {
      setOpenEditEventDialog(false)
      fetchEventToEdit(eventId)
    }, 1000)
  }

  const disableHotsheetDownload = (eventToEdit) => {
    const { orders: ordersCount = 0 } = eventToEdit
    return ordersCount === 0
  }

  const showOrders = () => {
    setShowOrdersTab(true)
    setShowSuitesTab(false)
  }

  const showSuites = () => {
    setShowOrdersTab(false)
    setShowSuitesTab(true)
  }

  return (
    <div>
      {
        eventToEdit && <DataContainer>
          <div className={classes.actionBar}>
            <div className={classes.eventWebName}>{eventToEdit.web_name}</div>
            <div className={classes.actionButtonRightPosition}>
              <CustomButton
                variant="contained"
                disableRipple
                styleClass={classes.downloadHotSheetButton}
                label={'Download Hotsheet'}
                disabled={disableHotsheetDownload(eventToEdit)}
                clickHandler={handleDownloadHotSheetButton}
              />
              <CustomButton
                variant="contained"
                disableRipple
                styleClass={classes.editEventCardButton}
                label={'Edit'}
                clickHandler={handleEventEdit}
              />
            </div>
          </div>
          <div className={classes.eventDetailsContainer}>
            <div className={classes.detailMedium}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Date</div>
              <div className={classes.value}>{eventToEdit.start_date}</div>
            </div>
            <div className={classes.detailMedium}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Start Time</div>
              <div className={classes.value}>{eventToEdit.start_time}</div>
            </div>
            <div className={classes.detailExtraLong}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Assigned Share Types</div>
              <div className={classes.value}>{eventToEdit.suite_shares}</div>
            </div>
            <div className={classes.detailMedium}>
              <div className={`${classes.label} ${classes.labelShort} ${classes.labelVerticleAligned}`}>Status</div>
              <div className={classes.value}>{eventToEdit.status ? 'Active' : 'Inactive'}</div>
            </div>
            <div className={classes.detailMedium}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Event Type</div>
              <div className={classes.value}>{eventToEdit.category_name}</div>
            </div>
            <div className={classes.detailMedium}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Door Open Time</div>
              <div className={classes.value}>{eventToEdit.doors}</div>
            </div>
          </div>
          <div className={classes.eventDetailsContainer}>
            <div className={classes.detailExtraLong}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Kosher Cutoff</div>
              <div className={classes.value}>{getKosherMenuCutoff(eventToEdit)}</div>
            </div>
            <div className={classes.detailExtraLong}>
              <div className={`${classes.label} ${classes.labelVerticleAligned}`}>Nonkosher Cutoff</div>
              <div className={classes.value}>{getNonKosherMenuCutoff(eventToEdit)}</div>
            </div>
          </div>
          {
            showTabs &&
            <div className={classes.tabsContainer}>
              <TabGroup className={classes.subNav}>
                <div className={`${classes.tab} ${showOrdersTab ? classes.isSelected : ''}`} onClick={showOrders}> <span>Orders</span> </div>
                <div className={`${classes.tab} ${showSuitesTab ? classes.isSelected : ''}`} onClick={showSuites}> <span>Suites</span> </div>
              </TabGroup>
            </div>
          }
          <div className={classes.usersContainer}>
            {
              showOrdersTab
                ? <OrdersByEvent
                  eventId={eventId}
                  eventName={eventToEdit.web_name}
                  eventDate={eventToEdit.start_date}
                />
                : <EventSuitesList eventId={eventId} />
            }
          </div>
          {
            openEditEventDialog &&
            <EditEvent
              openDialog={openEditEventDialog}
              eventId={eventId}
              handleDialogClose={handleDialogClose}
              eventTypes={eventCategories}
              handleUpdate={handleUpdate}
            />
          }
        </DataContainer>
      }
    </div>
  )
}

EventDetails.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  eventCategories: PropTypes.array.isRequired,
  eventToEdit: PropTypes.object,
  fetchEventToEdit: PropTypes.func,
  getEventHotsheet: PropTypes.func,
  route: PropTypes.object,
  updateEvent: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    eventToEdit: state.eventsData.eventToEdit,
    eventCategories: state.staticData.event_categories,
    currentUser: state.authReducer.user
  }
}
const mapDispatchToProps = {
  fetchEventToEdit,
  updateEvent,
  getEventHotsheet
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EventDetails))
)

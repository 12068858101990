const styles = {
  fieldTitle: {
    height: '14px',
    width: '250px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    '&$disabled': {
      color: '#000000'
    }
  },
  disabled: {},
  inputFields: {
    width: '100%',
    margin: '0px 0px'
  },
  inputContainer: {
    padding: '10px 0px'
  },
  statusButtons: {
    width: '128px'
  },
  statusFieldContainer: {
    padding: '10px 0px'
  },
  active: {
    color: 'white',
    height: '28px',
    borderRadius: '16px',
    backgroundColor: 'black',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    marginTop: '10px',
    '& span': {
      color: 'white',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  inactive: {
    color: 'black',
    height: '24px',
    borderRadius: '16px',
    backgroundColor: 'white',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    marginTop: '10px',
    '& span': {
      color: 'black',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '11px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    }
  },
  actionsContainer: {
    marginTop: '30px'
  },
  cancelButton: {
    width: '180px',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px'
  },
  updateEventButton: {
    width: '180px',
    borderRadius: '0px',
    boxShadow: 'none'
  },
  eventSuiteSelectorContainer: {
    marginTop: '13px',
    marginLeft: '-5px',
    '& label': {
      height: '14px',
      width: '151px',
      color: '#000000',
      fontFamily: 'Ringside Wide A, Ringside Wide B',
      fontSize: '10px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px',
      '&$disabled': {
        color: '#000000'
      },
      transform: 'translate(0, 1.5px) scale(1)'
    }
  },
  capacityDetails: {
    color: '#000000',
    width: '250px',
    height: '14px',
    fontSize: '10px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontWeight: '600',
    lineHeight: '14px',
    letterSpacing: '1px'
  }
}

export default styles

const styles = {
  eventDetailsBarContainer: {
    display: 'flex',
    paddingTop: '0px'
  },
  eventImage: {
    '& img': {
      height: 'auto',
      width: 'auto'
    }
  },
  eventDetails: {
    margin: '10px 40px'
  },
  eventName: {
    height: 'auto',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  eventEatecId: {
    height: '24px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px'
  },
  eventSuite: {
    height: '24px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px'
  },
  orderDueTimeMessage: {
    width: '420px',
    height: '24px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    margin: '10px 12px'
  },
  gameDayMessage: {
    height: '24px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    paddingTop: '15px',
    margin: '10px 12px'
  }
}

export default styles

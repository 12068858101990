const styles = {
  genericPaperStyles: {
    minHeight: '240px',
    minWidth: '680px',
    maxWidth: '680px',
    borderRadius: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 20px 30px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '0px 20px 30px',
    order: 2
  },
  genericDialogContentRootError: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '0px 20px 50px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '30px',
    color: 'black'
  },
  button: {
    height: '50px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    borderRadius: '0px',
    margin: '0px 13px'
  },
  orderSuccessMessageTitle: {
    height: '24px',
    width: '400px',
    color: '#061860',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '24px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  orderSuccessMessageBody: {
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    lineHeight: '18px',
    marginLeft: '12px'
  },
  orderErrorMessageBody: {
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    lineHeight: '18px',
    marginLeft: '12px'
  }
}

export default styles

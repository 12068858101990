import axios from 'axios'

const setAuthToken = (token, uid, clientId) => {
  if (token) {
    axios.defaults.headers.common['Token'] = token
    axios.defaults.headers.common['Client'] = clientId
    axios.defaults.headers.common['Uid'] = uid
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export default setAuthToken

import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  fetchEvents,
  updateEvent,
  fetchEventToEdit,
  toggleEventStatus
} from '../../store/events/actions'
import EventsFilterBar from '../../elements/EventsFilterBar/EventsFilterBar'
import Loader from '../../elements/LoadingState/LoadingState'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import EditEvent from '../../Dialogs/EditEvent/EditEvent'
import { history } from '../../store/store'
import {
  EventProperties,
  EventSuitesProperties,
  rowsFor,
  resourceName,
  styles
} from '../../constants/events'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))

const EventsList = ({
  rows,
  totalPages = defaultTotalPages,
  page = defaultPage,
  limit: rowsPerPage = defaultLimit,
  eventStatusList,
  eventCategories,
  filters,
  sortParams,
  fetchItems,
  updateEvent,
  fetchEventToEdit,
  toggleEventStatus
}) => {
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false)
  const [eventIdToEdit, setEventIdToEdit] = useState('')
  const [viewEventsSuitesData, setViewEventsSuitesData] = useState(false)
  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, rowsPerPage, sort)
  }

  const editEvent = (event) => {
    fetchEventToEdit(event.id)
    setEventIdToEdit(event.id)
    setOpenEditEventDialog(true)
  }

  const toggleStatus = (event) => {
    const { id, status } = event
    toggleEventStatus(id, status)
  }

  const handleDialogClose = () => {
    setOpenEditEventDialog(false)
  }

  function handleUpdate (eventData) {
    updateEvent(eventData)
    setTimeout(() => {
      setOpenEditEventDialog(false)
    }, 1000)
  }

  const viewEvent = (item) => {
    history.push(`/events/${item.id}`)
  }

  const settingsMenuBuilder = item => [
    {
      id: item.id,
      label: 'View',
      action: viewEvent
    },
    {
      id: item.id,
      label: 'Edit',
      action: editEvent
    },
    {
      id: item.id,
      label: item.status === 'Active' ? 'Deactivate' : 'Activate',
      action: toggleStatus
    }
  ]

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    fetchItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  const handleViewEventsSuitesData = (event) => {
    setViewEventsSuitesData(event.target.checked)
  }

  return (
    <div>
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <EventsFilterBar
            eventStatusList={eventStatusList}
            eventCategories={eventCategories}
            handleViewEventsSuitesData={handleViewEventsSuitesData}
            viewEventsSuitesData={viewEventsSuitesData}
          />
        </FilterBar>
        <Suspense fallback={<div><Loader /></div>}>
          {rows &&
            <LazyTable
              properties={viewEventsSuitesData
                ? EventSuitesProperties
                : EventProperties}
              sortParams={sortParams}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSorting={handleSorting}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              totalPages={totalPages}
              rowsFor={rowsFor}
              resourceName={resourceName}
              settingsMenuBuilder={settingsMenuBuilder}
            />}
        </Suspense>
        {
          openEditEventDialog &&
            <EditEvent
              openDialog={openEditEventDialog}
              eventId={eventIdToEdit}
              handleDialogClose={handleDialogClose}
              eventTypes={eventCategories}
              handleUpdate={handleUpdate}
            />
        }
      </DataContainer>
    </div>
  )
}

EventsList.propTypes = {
  eventStatusList: PropTypes.array.isRequired,
  eventCategories: PropTypes.array.isRequired,
  fetchEventToEdit: PropTypes.func,
  fetchItems: PropTypes.func.isRequired,
  filters: PropTypes.object,
  totalPages: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  rows: PropTypes.array,
  sortParams: PropTypes.object,
  updateEvent: PropTypes.func,
  toggleEventStatus: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    ...state.eventsData.eventsList,
    eventStatusList: state.staticData.menuItemStatusList,
    eventCategories: state.staticData.event_categories
  }
}
const mapDispatchToProps = {
  fetchItems: fetchEvents,
  updateEvent,
  fetchEventToEdit,
  toggleEventStatus
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventsList)
)

import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'

const CustomFabButton = ({
  color,
  label,
  variant,
  styleClasses,
  disableRipple = false,
  disableFocusRipple = false,
  clickHandler,
  value
}) => {
  return (
    <Fab
      variant={variant}
      color={color}
      className={styleClasses}
      disableRipple={disableRipple}
      disableFocusRipple={disableFocusRipple}
      onClick={() => { clickHandler(value) }}
    >
      {label}
    </Fab>
  )
}

CustomFabButton.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  styleClasses: PropTypes.string,
  disableRipple: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  clickHandler: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default CustomFabButton

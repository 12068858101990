import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import {
  CART_ADD_TYPES
} from '../../constants/menuItems'
import useStyles from './RecommendedItem.css'
const NON_KOSHER_MENU_TYPE_ID = 1
const { QUICK_ADD } = CART_ADD_TYPES

const RecommendedItem = ({
  item,
  showMenuDetails,
  handleAddToCart,
  orderWindow
}) => {
  const classes = useStyles()
  const {
    advancePrice,
    name
  } = item
  const thumbnailUrl = idx(item, _ => _.image.thumbnail.image.url) || idx(item, _ => _.image.thumbnail.url)
  return (
    <div className={classes.item}>
      <div className={classes.image} onClick={() => showMenuDetails(item)}>
        <img src={thumbnailUrl} alt="test" />
      </div>
      <div className={classes.item_details}>
        <div className={classes.item_name}> {name} </div>
        <div className={classes.item_price}> ${advancePrice} </div>
        <div>
          <Fab variant="extended" aria-label="delete" className={classes.addToCartButton} onClick={() => handleAddToCart(item, NON_KOSHER_MENU_TYPE_ID, orderWindow, QUICK_ADD)}>
            <AddIcon className={classes.addIcon} />
            Add to Order
          </Fab>
        </div>
      </div>
    </div>
  )
}

RecommendedItem.propTypes = {
  item: PropTypes.object,
  handleAddToCart: PropTypes.func,
  showMenuDetails: PropTypes.func,
  orderWindow: PropTypes.string
}

export default RecommendedItem

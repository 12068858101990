import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import idx from 'idx'
import { FormControl, IconButton, MenuItem } from '@material-ui/core'
import { Search as SearchIcon, Backspace as UndoIcon } from '@material-ui/icons'
import DateSelector from '../DateSelector/DateSelector'
import { Filters, Select, TextField } from './EventsListFilter.css'

const EventsListFilter = ({ categories, onChange }) => {
  const [category, setCategory] = useState('')
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [search, setSearch] = useState('')

  const filterEvents = () => onChange({ category, dateFrom, dateTo, search })
  const reset = () => {
    setCategory('')
    setDateFrom('')
    setDateTo('')
    setSearch('')
    onChange({ category: '', dateFrom: '', dateTo: '', search: '' })
  }

  return (
    <Filters onSubmit={filterEvents}>
      <FormControl>
        <Select
          value={category}
          onChange={e => setCategory(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">All Categories</MenuItem>
          { categories.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>) }
        </Select>
      </FormControl>
      <DateSelector value={dateFrom} changeHandler={e => setDateFrom(e.target.value)} placeholder="Date From" />
      <DateSelector value={dateTo} changeHandler={e => setDateTo(e.target.value)} placeholder="Date To" />
      <TextField value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} onKeyPress={e => e.key === 'Enter' && filterEvents()} />
      <IconButton onClick={filterEvents}><SearchIcon /></IconButton>
      <IconButton onClick={reset}><UndoIcon /></IconButton>
    </Filters>
  )
}

EventsListFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  categories: idx(state, _ => _.staticData.event_categories)
})

export default connect(mapStateToProps)(EventsListFilter)

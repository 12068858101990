module.exports.EventProperties = [
  {
    key: 'status',
    label: 'STATUS',
    sortable: false
  },
  {
    key: 'startDate',
    label: 'DATE',
    sortable: true
  },
  {
    key: 'webName',
    label: 'EVENT',
    sortable: true
  },
  {
    key: 'startTime',
    label: 'START TIME',
    sortable: false
  },
  {
    key: 'doors',
    label: 'DOOR OPEN TIME',
    sortable: false
  },
  {
    key: 'nonKosherMenuCutoff',
    label: 'REG. CUT OFF',
    sortable: false
  },
  {
    key: 'kosherMenuCutoff',
    label: 'KOSHER CUTOFF',
    sortable: false
  },
  {
    key: 'orders',
    label: 'ORDERS',
    sortable: false
  }
]

module.exports.EventSuitesProperties = [
  {
    key: 'status',
    label: 'STATUS',
    sortable: false
  },
  {
    key: 'startDate',
    label: 'DATE',
    sortable: true
  },
  {
    key: 'webName',
    label: 'EVENT',
    sortable: true
  },
  {
    key: 'startTime',
    label: 'START TIME',
    sortable: false
  },
  {
    key: 'bookedSuitesCount',
    label: '#BOOKED SUITES',
    sortable: false
  },
  {
    key: 'activeSuitesCount',
    label: '#ACTIVE SUITES',
    sortable: false
  },
  {
    key: 'inactiveSuitesCount',
    label: '#INACTIVE SUITES',
    sortable: false
  }
]
module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.sortParamsMapping = {
  startDate: 'start_date',
  webName: 'web_name'
}

module.exports.styles = {
  selectorWidth: '210px',
  searchFieldWidth: '320px'
}

module.exports.rowsFor = 'events'
module.exports.resourceName = 'events'
module.exports.DEFAULT_IMAGE_URL = './imageNotAvailable.png'

export const trueRounder = (value, digits) => {
  return +(Math.round((value * Math.pow(10, digits)).toFixed(digits - 1)) / Math.pow(10, digits)).toFixed(digits)
}

export const formatFloatNumber = (number) => {
  const formattedNumber = parseFloat(number).toFixed(2)
  return isNaN(formattedNumber) ? '$0.00' : `$${formattedNumber}`
}

// adds '.00' to whole number
export const addZeroToOrderPrice = (number) => {
  const num = parseFloat(number)
  return num.toFixed(2)
}

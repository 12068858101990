import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { withStyles } from '@material-ui/core/styles'
import styles from './CustomCheckBox.css'

const CustomCheckBox = ({
  value,
  isChecked,
  label,
  handleChange,
  classes,
  id
}) =>
  <FormControlLabel
    control={
      <Checkbox
        classes={{
          root: classes.root
        }}
        icon={<CheckBoxOutlineBlankIcon className={classes.customCheckBoxOutline} />}
        onChange={handleChange}
        color="primary"
        checked={isChecked}
        name={id}
        checkedIcon={<svg className={classes.customCheckBox} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5z" /></svg>}
        value={value}
      />
    }
    labelPlacement="end"
    label={label}
  />

CustomCheckBox.propTypes = {
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  id: PropTypes.string
}

export default withStyles(styles)(CustomCheckBox)

const styles = {
  genericPaperStyles: {
    backgroundColor: '#fff',
    borderRadius: '0px',
    display: 'flex',
    maxWidth: '530px',
    minHeight: '200px',
    padding: '30px 40px 20px 40px',
    width: '530px'
  },
  genericDialogTitleRoot: {
    backgroundColor: '#fff',
    height: '40px',
    margin: 0,
    padding: 0
  },
  genericDialogContentRoot: {
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    overflow: 'hidden'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: '#fff',
    display: 'initial',
    marginTop: '0px',
    padding: '0'
  },
  title: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  addCardActionsContainer: {
    padding: '20px 12px'
  },
  addCreditCardHeader: {
    borderBottom: '1px solid #DDD',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    margin: '0px 0px 10px 10px',
    paddingBottom: '8px'
  },
  addCreditCardHeaderLabel: {
    width: '300px'
  },
  addCreditCardHeaderCloseButton: {
    width: '50px !important',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardNameContainer: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    padding: '5px 10px',
    '& label': {
      paddingTop: '15px'
    },
    '& input': {
      width: '428px'
    },
    '& button': {
      marginLeft: '12px',
      marginTop: '12px',
      float: 'right'
    }
  },
  errorContainer: {
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    marginLeft: '10px',
    marginTop: '5px'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './OrderItemsListHeader.css'

const OrderItemsListHeader = ({ classes }) => {
  return (
    <div className={classes.orderItemsListHeaderContainer}>
      <div className={classes.quantityHeader}>QTY</div>
      <div className={classes.nameHeader}>ITEM</div>
      <div className={classes.itemPriceHeader}>ITEM PRICE</div>
      <div className={classes.totalPriceHeader}>TOTAL</div>
    </div>
  )
}

OrderItemsListHeader.propTypes = {
  classes: PropTypes.object
}
export default withStyles(styles)(OrderItemsListHeader)

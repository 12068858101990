const styles = {
  genericPaperStyles: {
    minHeight: '551px',
    minWidth: '350px',
    maxWidth: '350px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    paddingLeft: '38px',
    paddingRight: '38px',
    paddingTop: '30px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '40px 40px 10px',
    paddingTop: '12px',
    paddingBottom: '12px',
    maxHeight: '412px',
    overflow: 'auto'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px',
    paddingTop: '8px'
  },
  editCategoriesTitle: {
    height: '14px',
    width: '274px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  categoriesButton: {
    boxSizing: 'border-box',
    height: '50px',
    width: '274px',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    marginBottom: '12px'
  },
  primaryCategoriesButton: {
    boxSizing: 'border-box',
    height: '50px',
    width: '274px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    marginBottom: '12px'
  },
  editCategoriesContentContainer: {
    height: '100%'
  },
  expandedSummary: {
    margin: 0,
    padding: 0,
    '&:before, &:after': {
      backgroundColor: '#fff'
    },
    '&:first-child': {
      margin: 0
    }
  },
  expansionPanel: {
    marginBottom: '0px !important',
    '&$expanded': {
      marginBottom: '0px !important',
      '&:before, &:after': {
        backgroundColor: '#fff'
      }
    },
    '&:before, &:after': {
      backgroundColor: '#fff'
    },
    backgroundColor: '#fff',
    boxShadow: 'none',
    margin: '0px!important'
  },
  menuCatalogName: {
    height: '24px',
    width: '100%',
    color: '#000000',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '24px',
    margin: 0
  },
  menuSubCategoryName: {
    height: '15px',
    width: '100%',
    color: ' #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px'
  },
  settingMenuContainer: {
    padding: 0
  },
  expansionPanelDetails: {
    padding: 0,
    display: 'block'
  },
  settingMenuExpandableIcon: {
    margin: 0,
    float: 'right'
  },
  categoryInputFields: {
    width: '274px',
    margin: '10px 0px'
  },
  inputContainer: {
    '& div': {
      width: '100%'
    }
  },
  updateMessage: {
    height: '38px',
    width: '100%',
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    paddingTop: '8px'
  }
}

export default styles

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const actionsStyles = () => ({
  paginatioActionsContainer: {
    flexShrink: 0,
    color: 'white'
  },
  actions: {
    color: 'white'
  }
})

const PagicationActionIconButton = (props) => {
  const {
    disabled,
    clickHandler,
    ariaLabel,
    classes,
    Icon
  } = props
  return (
    <IconButton
      onClick={clickHandler}
      disabled={disabled}
      aria-label={ariaLabel}
      className={classes.actions}
    >
      {Icon}
    </IconButton>
  )
}

PagicationActionIconButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  Icon: PropTypes.element.isRequired
}

const TablePaginationActions = (props) => {
  const { onChangePage, classes, count, page, rowsPerPage } = props

  function handleFirstPageButtonClick (event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick (event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick (event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick (event) {
    onChangePage(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1)
    )
  }
  const icons = [
    {
      disabled: page === 0,
      clickHandler: handleFirstPageButtonClick,
      ariaLabel: 'First Page',
      classes,
      Icon: <FirstPageIcon />
    },
    {
      disabled: page === 0,
      clickHandler: handleBackButtonClick,
      ariaLabel: 'Previous Page',
      classes,
      Icon: <KeyboardArrowLeft />
    },
    {
      disabled: page >= Math.ceil(count / rowsPerPage) - 1,
      clickHandler: handleNextButtonClick,
      ariaLabel: 'Next Page',
      classes,
      Icon: <KeyboardArrowRight />
    },
    {
      disabled: page >= Math.ceil(count / rowsPerPage) - 1,
      clickHandler: handleLastPageButtonClick,
      ariaLabel: 'Last Page',
      classes,
      Icon: <LastPageIcon />
    }
  ]
  return (
    <Fragment>

      { icons.map((icon, index) => <PagicationActionIconButton key={index} {...icon} />) }

    </Fragment>
  )
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

export default withStyles(actionsStyles, { withTheme: true })(TablePaginationActions)

import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormControl, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SettingsMenu from '../../elements/SettingsMenu/SettingsMenu'
import InputField from '../../elements/InputField/InputField'
import TextArea from '../../elements/TextArea/TextArea'
import { resetUpdateMessage } from '../../store/menuItems/actions'
import styles from './EditCategoriesDialogContent.css'

const EditCategoriesDialogContent = ({
  classes,
  menuCategories,
  actionMode = 'List',
  handleEditCategory,
  selectedMenuCategories,
  setCategoryName,
  setSubCategory,
  menuCategoriesMessage,
  handleDialogClose,
  resetUpdateMessage
}) => {
  const settingsMenuBuilder = item => [{
    label: 'Edit',
    action: handleEditCategory,
    id: item.id
  }]
  const [updateMessage, setUpdateMessage] = useState('')
  useEffect(() => {
    if (menuCategoriesMessage) {
      const { message } = menuCategoriesMessage
      setUpdateMessage({
        message
      })
      const timer1 = setTimeout(() => {
        setUpdateMessage({})
        resetUpdateMessage()
        handleDialogClose()
      }, 2000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [handleDialogClose, menuCategoriesMessage, resetUpdateMessage])

  return (
    <div className={classes.editCategoriesContentContainer}>
      {
        updateMessage && updateMessage.message && <Typography variant="h6" className={classes.updateMessage}>{updateMessage.message}</Typography>
      }
      {
        (actionMode === 'Edit' || actionMode === 'Add') && selectedMenuCategories && <Fragment>
          <FormControl><InputField
            classes={{
              inputLabelRoot: classes.fieldTitle,
              inputRoot: classes.categoryInputFields
            }}
            placeholder="Category"
            value={selectedMenuCategories.name || ''}
            changeHandler={setCategoryName}
            showLabel={false}
          /></FormControl>
          <FormControl><TextArea
            classes={{
              inputLabelRoot: classes.fieldTitle,
              inputRoot: classes.categoryInputFields
            }}
            placeholder={'Separate subcategories by comma: Hot, Cold, Spicy'}
            changeHandler={setSubCategory}
            value={selectedMenuCategories.updatedSubCategory || ''}
            multiline
            showLabel={false}
          /></FormControl>
        </Fragment>
      }
      {
        actionMode === 'List' && menuCategories && menuCategories.map(menuCategory =>
          <ExpansionPanel key={menuCategory.id} TransitionProps={{ unmountOnExit: true }} className={classes.expansionPanel} square>
            <ExpansionPanelSummary
              expandIcon={
                <IconButton className={classes.settingMenuContainer}>
                  <ExpandMoreIcon className={classes.settingMenuExpandableIcon} />
                </IconButton>
              } aria-controls="panel1a-content" className={classes.expandedSummary} >
              <Typography variant="h6" gutterBottom className={classes.menuCatalogName}>{menuCategory.name}</Typography>
              <div className="col-md-5" onClick={e => e.stopPropagation()}>
                <FormControlLabel control={
                  <SettingsMenu settingsMenuBuilder={settingsMenuBuilder}
                    item={menuCategory} />
                } />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails} >
              {
                menuCategory.subcategories && menuCategory.subcategories.map(subCategory =>
                  <Typography key={subCategory.id} variant="h6" gutterBottom className={classes.menuSubCategoryName}>{subCategory.name}</Typography>
                )
              }
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }
    </div>
  )
}

EditCategoriesDialogContent.propTypes = {
  classes: PropTypes.object,
  menuCategories: PropTypes.array,
  actionMode: PropTypes.string,
  handleEditCategory: PropTypes.func,
  selectedMenuCategories: PropTypes.object,
  setCategoryName: PropTypes.func,
  setSubCategory: PropTypes.func,
  menuCategoriesMessage: PropTypes.object,
  handleDialogClose: PropTypes.func,
  resetUpdateMessage: PropTypes.func
}

const mapStateToProps = (state) => {
  const { menuCategoriesMessage } = state.menuItemsData
  return {
    menuCategoriesMessage
  }
}

const mapDispatchToProps = {
  resetUpdateMessage
}

const EditCategoriesContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategoriesDialogContent)

export default withStyles(styles)(EditCategoriesContentContainer)

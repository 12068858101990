const styles = {
  textField: {
    marginLeft: '0px',
    marginRight: '10px',
    boxSizing: 'border-box',
    marginTop: '0px',
    background: '#FFF',
    width: '100%',
    marginBottom: '10px',
    '& input': {
      height: '14px',
      width: '356.35px',
      color: '#2A2D3B',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px',
      '&::placeholder': {
        color: '2A2D3B',
        opacity: 1
      }
    },
    '& label': {
      height: '14px',
      width: '260px',
      color: '#FF0000',
      fontFamily: 'Ringside Wide A, Ringside Wide B',
      fontSize: '10px',
      fontWeight: '600',
      letterSpacing: '1px',
      lineHeight: '14px',
      transform: 'translate(14px, -16px)!important',
      textTransform: 'uppercase',
      top: '-5px',
      left: '-12px'
    },
    '& fieldset': {
      borderColor: 'white !important'
    }
  },
  suitesBackground: {
    backgroundImage: "url('/suites_bg_desktop_v2.jpg')",
    backgroundSize: '100%',
    height: 'calc(100% - 50px)',
    backgroundRepeat: 'round'
  },
  logoContainer: {
    height: '50px',
    width: '156px'
  },
  headerGrid: {
    margin: '18px 36px 4px',
    height: '60vh',
    width: 'auto'
  },
  suitesImage: {
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  signInHeaderText: {
    height: '44px',
    width: '500px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px',
    margin: 'auto',
    paddingTop: '12px',
    paddingBottom: '31px'
  },
  subText: {
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    margin: 'auto',
    width: '377px',
    marginBottom: '12px',
    marginTop: '12px',
    display: 'block'
  },
  signInButtonContainer: {
    width: '100%'
  },
  formContainer: {
    height: '100%',
    width: '500px',
    margin: 'auto',
    paddingTop: '25px',
    marginTop: '0px',
    paddingLeft: '0px',
    '& button': {
      backgroundColor: '#0078D4',
      color: '#fff',
      marginTop: '12px',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      marginBottom: '12px',
      width: '100%',
      '&:hover': {
        backgroundColor: '#0078D4'
      },
      '&:disabled': {
        backgroundColor: '#0078D4',
        color: '#fff'
      }
    },
    '& form': {
      width: '300px',
      float: 'left'
    }
  },
  root: {
    backgroundColor: 'inherit',
    width: '1440px',
    margin: 'auto',
    marginTop: '18px'
  },
  forgotPasswordAckContainer: {
    '& h6': {
      height: '48px',
      width: '506px',
      color: '#FFFFFF',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px',
      marginBottom: '12px'
    }
  },
  contentContainer: {
    height: '40vh'
  },
  loginFailed: {
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  resetContainer: {
    display: 'flex'
  },
  passwordRequirementsContainer: {
    display: 'inline-block',
    marginTop: '24px'
  },
  forgotPwdLink: {
    height: '20px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: '#0078D4',
    textUnderlinePosition: 'under',
    marginBottom: '10px',
    float: 'left',
    width: '100%'
  },
  resetPassRequirements: {
    color: '#fff',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '14px',
    marginLeft: '325px',
    width: '200px',
    '& ul': {
      margin: '10px 0 0 10px',
      listStyleType: 'circle',
      textAlign: 'left'
    }
  },
  suitesLabel: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '15px'
  },
  updatePwdMessage: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '15px',
    color: '#fff',
    height: '100px'
  }
}

export default styles

const styles = {
  orderCutoffDetailsContainer: {
    height: '57px',
    width: '274px',
    marginTop: '14px'
  },
  cutoffHeader: {
    height: '14px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  cutoffByWindow: {
    height: '36px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    marginTop: '7px'
  }
}

export default styles

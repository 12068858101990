import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './ClientEventCutoff.css'

const ClientEventCutoff = ({
  classes,
  nonKosherMenuCutoff,
  kosherMenuCutoff
}) => {
  return (
    <div className={classes.orderCutoffDetailsContainer}>
      <div className={classes.cutoffHeader}>Deadline for new orders</div>
      <div className={classes.cutoffByWindow}>
        <div>Kosher: {kosherMenuCutoff} </div>
        <div>Non-Kosher: {nonKosherMenuCutoff} </div>
      </div>
    </div>
  )
}

ClientEventCutoff.propTypes = {
  classes: PropTypes.object,
  nonKosherMenuCutoff: PropTypes.string,
  kosherMenuCutoff: PropTypes.string
}

export default withStyles(styles)(ClientEventCutoff)

const styles = {
  customCheckBox: {
    fill: '#0C92E1',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: '0',
    userSelect: 'none',
    border: '2px solid #fff',
    margin: '5px'
  },
  customCheckBoxOutline: {
    border: '2px solid #fff',
    margin: '5px'
  }
}

export default styles

import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  addEventSuiteMapping,
  updateEventSuiteMapping,
  fetchEventSuites,
  resetEventSuiteToEdit,
  voidSuiteReservtionById
} from '../../store/eventSuites/actions'
import SuitesFilterBar from '../../elements/SuitesFilterBar/SuitesFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
import EditEventSuite from '../../Dialogs/EditEventSuite/EditEventSuite'
import ViewEventSuite from '../../Dialogs/ViewEventSuite/ViewEventSuite'
import VoidEventSuiteReservation from '../../Dialogs/VoidEventSuiteReservation/VoidEventSuiteReservation'

const LazyTable = React.lazy(() => import('../../elements/Table/Table'))
const {
  columnNames,
  ctaLabel,
  eventSuitesProperties,
  rowsFor,
  resourceName,
  suitesFilterStyles
} = require('../../constants/eventSuites')

const EventSuitesList = ({
  addEventSuiteMapping,
  eventId,
  fetchItems,
  filters,
  limit: rowsPerPage = defaultLimit,
  page = defaultPage,
  resetEventSuiteToEdit,
  rows,
  sortParams = { sortBy: 'suiteName', order: 'desc' },
  totalPages = defaultTotalPages,
  updateEventSuiteMapping,
  voidSuiteReservtionById
}) => {
  useEffect(() => {
    fetchItems({ eventId }, defaultPage, rowsPerPage, sortParams)
  }, [])

  useEffect(() => {
    if (!rows) {
      fetchItems({ eventId })
    }
  }, [rows, fetchItems, eventId])

  const [showEditEventSuiteDialog, setShowEditEventSuiteDialog] = useState(false)
  const [showViewEventSuiteDialog, setShowViewEventSuiteDialog] = useState(false)
  const [eventSuiteId, setEventSuiteId] = useState()
  const [showVoidEventSuiteReservationDialog, setShowVoidEventSuiteReservationDialog] = useState()
  const [validationError, setValidationError] = useState('')

  const handleChangePage = (event, page) => {
    fetchItems({ eventId }, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems({ eventId }, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems({ eventId }, page, rowsPerPage, sort)
  }

  const applyFilter = (filters, page, limit, sortParams) => {
    fetchItems({ ...filters, eventId }, defaultPage, limit, sortParams)
  }

  const settingsMenuBuilder = item => {
    const settingsMenu = [
      {
        id: item.id,
        label: item.isReserved ? 'View' : 'Edit',
        action: () => {
          setEventSuiteId(item.id)
          item.isReserved
            ? setShowViewEventSuiteDialog(true)
            : setShowEditEventSuiteDialog(true)
        }
      }
    ]
    if (item.isReserved) {
      settingsMenu.push({
        id: item.id,
        label: 'Void Reservation',
        action: () => {
          setEventSuiteId(item.id)
          setShowVoidEventSuiteReservationDialog(true)
        }
      })
    }
    return settingsMenu
  }

  const handleDialogClose = () => {
    setShowEditEventSuiteDialog(false)
    setShowViewEventSuiteDialog(false)
    setShowVoidEventSuiteReservationDialog(false)
    resetEventSuiteToEdit()
    setEventSuiteId(null)
  }

  const handleUpdate = eventSuite => {
    setValidationError('')
    updateEventSuiteMapping({ ...eventSuite, eventId })
    setShowEditEventSuiteDialog(false)
    resetEventSuiteToEdit()
    setEventSuiteId(null)
    fetchItems({ eventId }, page, rowsPerPage, sortParams)
  }

  const handleVoidReservation = eventSuiteId => {
    voidSuiteReservtionById(eventId, eventSuiteId)
    setEventSuiteId(null)
    setShowVoidEventSuiteReservationDialog(false)
  }

  const handleAddNewSuite = eventSuite => {
    addEventSuiteMapping(eventSuite)
    fetchItems({ eventId }, page, rowsPerPage, sortParams)
  }

  return (
    <DataContainer>
      <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={suitesFilterStyles}>
        <SuitesFilterBar
          ctaLabel={ctaLabel}
          eventId={eventId}
          showAddButton
          styles={suitesFilterStyles}
          addEventSuiteMapping={handleAddNewSuite}
        />
      </FilterBar>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyTable
          columnNames={columnNames}
          properties={eventSuitesProperties}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortParams={sortParams}
          handleSorting={handleSorting}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          totalPages={totalPages}
          rowsFor={rowsFor}
          resourceName={resourceName}
          settingsMenuBuilder={settingsMenuBuilder}
        />
      </Suspense>
      {
        eventSuiteId &&
        <>
          {showEditEventSuiteDialog && <EditEventSuite
            openDialog={showEditEventSuiteDialog}
            handleUpdate={handleUpdate}
            eventSuiteId={eventSuiteId}
            handleDialogClose={handleDialogClose}
            validationError={validationError}
          />}
          <ViewEventSuite
            openDialog={showViewEventSuiteDialog}
            eventSuiteId={eventSuiteId}
            handleDialogClose={handleDialogClose}
          />
          <VoidEventSuiteReservation
            openDialog={showVoidEventSuiteReservationDialog}
            eventSuiteId={eventSuiteId}
            handleDialogClose={handleDialogClose}
            handleVoidReservation={handleVoidReservation}
          />
        </>
      }
    </DataContainer>
  )
}

EventSuitesList.propTypes = {
  addEventSuiteMapping: PropTypes.func,
  eventId: PropTypes.string,
  fetchItems: PropTypes.func,
  filters: PropTypes.object,
  limit: PropTypes.number,
  page: PropTypes.number,
  resetEventSuiteToEdit: PropTypes.func,
  rows: PropTypes.array,
  sortParams: PropTypes.object,
  totalPages: PropTypes.number,
  updateEventSuiteMapping: PropTypes.func,
  voidSuiteReservtionById: PropTypes.func
}

const mapStateToProps = (state) => ({
  ...state.eventSuitesData.eventSuitesList
})

const mapDispatchToProps = {
  fetchItems: fetchEventSuites,
  updateEventSuiteMapping,
  addEventSuiteMapping,
  resetEventSuiteToEdit,
  voidSuiteReservtionById
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventSuitesList)
)

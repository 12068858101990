const styles = {
  genericPaperStyles: {
    minHeight: '350px',
    minWidth: '590px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    paddingTop: '30px',
    paddingLeft: '38px',
    paddingRight: '38px'
  },
  genericDialogContentRoot: {
    color: 'black',
    padding: '40px 40px 10px',
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: 'white',
    height: 'auto'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px'
  },
  editEventSuiteTitle: {
    height: '14px',
    width: '274px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  }
}

export default styles

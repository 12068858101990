import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './OrderBillDetailsContainer.css'

const OrderBillDetailsContainer = ({
  classes,
  billSummary = []
}) => {
  const isBillSummaryAvailable = Array.isArray(billSummary) && billSummary.length > 0
  return (
    <div className={classes.orderBillDetailsContainer}>
      {isBillSummaryAvailable && billSummary.map(item => {
        return <div key={item.field1} className={classes.orderBillLineItem}>
          <div>{item.field1}</div>
          <div>{item.field2}</div>
          <div>{`$${item.field3}`}</div>
        </div>
      })}
    </div>
  )
}

OrderBillDetailsContainer.propTypes = {
  classes: PropTypes.object,
  billSummary: PropTypes.array
}

export default withStyles(styles)(OrderBillDetailsContainer)

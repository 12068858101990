const styles = {
  container: {

  },
  title: {

  },
  active: {
    color: 'white',
    height: '28px',
    borderRadius: '16px',
    backgroundColor: 'black',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    '& span': {
      color: 'white',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  inactive: {
    color: 'black',
    height: '24px',
    borderRadius: '16px',
    backgroundColor: 'white',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    '& span': {
      color: 'black',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '11px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    }
  }
}

export default styles

const styles = {
  genericPaperStyles: {
    minHeight: '150px',
    minWidth: '362px',
    maxWidth: '662px',
    borderRadius: '0px',
    padding: '40px',
    backgroundColor: 'white'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '0px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    padding: '0px',
    overflowY: 'unset'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 10px'
  },
  title: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  content: {
    paddingTop: '10px'
  },
  cancelButton: {
    width: '150px',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px'
  },
  submitButton: {
    width: '150px',
    borderRadius: '0px',
    boxShadow: 'none'
  },
  buttonContainer: {
    marginTop: '20px'
  },
  errorMessage: {
    padding: '5px 0px',
    color: 'red'
  }
}

export default styles

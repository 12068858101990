import {
  STORE_CREDIT_CARD_SECURE_HASH,
  STORE_IFRAME_CODE,
  RESET_IFRAME_CODE,
  SHOW_FORM_TO_NAME_ADDED_CARD,
  HIDE_FORM_TO_NAME_ADDED_CARD,
  ADD_CREDIT_CARD_ERROR,
  CLEAR_ADD_CREDIT_CARD_ERROR
} from '../actionTypes'

const initialState = {}

export default function CreditCardsReducers (state = initialState, action) {
  switch (action.type) {
    case STORE_CREDIT_CARD_SECURE_HASH:
      return {
        ...state,
        secureCreditCardHash: action.payload
      }
    case STORE_IFRAME_CODE:
      return {
        ...state,
        freedompayIframe: action.payload.iframe
      }
    case RESET_IFRAME_CODE:
      return {
        ...state,
        freedompayIframe: null
      }
    case SHOW_FORM_TO_NAME_ADDED_CARD:
      return {
        ...state,
        newCreditCard: action.payload.creditCard,
        showFormToNameCreditCard: true
      }
    case HIDE_FORM_TO_NAME_ADDED_CARD:
      return {
        ...state,
        newCreditCard: null,
        showFormToNameCreditCard: false
      }
    case ADD_CREDIT_CARD_ERROR:
      return {
        ...state,
        addCardErr: action.payload.addCardErr,
        showFormToNameCreditCard: false
      }
    case CLEAR_ADD_CREDIT_CARD_ERROR:
      return {
        ...state,
        addCardErr: null
      }
    default:
      return state
  }
}

import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  overrides: {
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      color: 'black'
    },
    MuiInput: {
      underline: {
        '&&&&:before, &&&&:after': {
          borderBottom: '2px dotted black',
          transition: 'none'
        },
        '&&&&:hover:before': {
          borderBottom: '2px dotted black'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: 'black',
        fontFamily: 'Ringside Condensed A , Ringside Condensed B'
      },
      root: {
        fontFamily: 'Ringside Condensed A , Ringside Condensed B',
        '&&&&:hover': {
          color: 'black'
        }
      }
    },
    MuiSelect: {
      icon: {
        color: 'black'
      },
      select: {
        fontWeight: 'bolder'
      }
    },
    MuiFormControl: {
      root: {
        margin: '5px'
      },
      marginNormal: {
        marginTop: '5px'
      }
    },
    MuiFormLabel: {
      root: {
        color: 'black',
        fontFamily: 'Ringside Regular A, Ringside Regular B',
        '&$focused': {
          color: 'black',
          textTransform: 'uppercase',
          fontWeight: 'normal'

        }
      }
    },
    MuiList: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#2A2D3B'
      },
      elevation2: {
        boxShadow: 'none'
      }
    },
    MuiIconButton: {
      root: {
        color: 'black',
        '&$disabled': {
          color: 'black'
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        color: 'black'
      }
    },
    MuiTypography: {
      caption: {
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        color: 'black'
      },
      colorInherit: {
        color: 'black'
      }
    }
  },
  palette: {
    primary: {
      main: '#0078D4'
    }
  },
  typography: {
    button: {
      textTransform: 'capitalize',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  }
})

export default theme

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const EditEventSuiteDialogActions = ({
  classes,
  handleCancel,
  handleUpdate,
  disableUpdate
}) => {
  return (
    <div className={classes.editEventActionsContainer}>
      <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.updateEventButton}
        onClick={handleUpdate}
        disabled={!!disableUpdate}
      >
        Update Suite
      </Button>
    </div>
  )
}

EditEventSuiteDialogActions.propTypes = {
  classes: PropTypes.object.isRequired,
  disableUpdate: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired
}

EditEventSuiteDialogActions.defaultProps = {
  disableUpdate: false
}

export default EditEventSuiteDialogActions

const styles = {
  genericPaperStyles: {
    backgroundColor: '#fff',
    borderRadius: '0px',
    display: 'flex',
    height: '320px',
    maxWidth: '450px',
    minHeight: '320px',
    padding: '30px 40px 40px 40px',
    width: '450px'
  },
  genericDialogTitleRoot: {
    backgroundColor: '#fff',
    height: '40px',
    margin: 0,
    padding: 0
  },
  genericDialogContentRoot: {
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    padding: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: '#fff',
    display: 'initial',
    marginTop: '20px',
    padding: '0'
  },
  actionContainer: {
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'none',
      width: 'calc(50% - 20px)'
    }
  },
  title: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  cancelButton: {
    borderRadius: '0px',
    height: '50px',
    marginTop: '10px'
  },
  submitButton: {
    backgroundColor: '#0078D4',
    borderColor: '#0078D4',
    borderRadius: '0px',
    color: '#fff',
    height: '50px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#0078D4',
      color: '#fff'
    }
  },
  listPicker: {
    overflow: 'hidden'
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#fff'
  },
  passwordText: {
    width: '100%',
    '& input[type=text]': {
      letterSpacing: '.5px'
    }
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'

const ChangePasswordName = ({ classes, handlePassword, handleConfirmPassword, newPassword = '', confirmationPassword = '' }) => {
  return (
    <div>
      <TextField
        label="New Password"
        type="password"
        margin="normal"
        value={newPassword}
        onChange={handlePassword}
        autoComplete={'off'}
        className={classes.passwordText}
      />
      <TextField
        label="Confirm Password"
        type="password"
        margin="normal"
        autoComplete="off"
        value={confirmationPassword}
        onChange={handleConfirmPassword}
        className={classes.passwordText}
      />
    </div>
  )
}

ChangePasswordName.propTypes = {
  classes: PropTypes.object,
  newPassword: PropTypes.string,
  confirmationPassword: PropTypes.string,
  handlePassword: PropTypes.func.isRequired,
  handleConfirmPassword: PropTypes.func.isRequired
}

ChangePasswordName.defaultProps = {
  isDisabled: false,
  name: ''
}

export default ChangePasswordName

import React from 'react'
import PropTypes from 'prop-types'

function ConfirmationTitle ({ className }) {
  return (
    <div className={className}>
      Confirmation
    </div>
  )
}

ConfirmationTitle.propTypes = {
  className: PropTypes.string
}

export default ConfirmationTitle

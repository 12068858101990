import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'

const MenuTypeField = ({
  classes,
  menuTypeId,
  menuTypes,
  setMenuType
}) => {
  return (
    <div className={classes.fieldContainer}>
      <span className={classes.fieldTitle}> MENU TYPE </span>
      <div>
        {
          menuTypes.map(menuType => {
            return <Fab
              key={menuType.id}
              variant="extended"
              aria-label="Active"
              className={`${menuType.id === menuTypeId ? classes.active : classes.inactive}`}
              onClick={() => setMenuType(menuType.id)} >
              {menuType.name}
            </Fab>
          })
        }
      </div>
    </div>
  )
}

MenuTypeField.propTypes = {
  classes: PropTypes.object,
  menuTypeId: PropTypes.number,
  menuTypes: PropTypes.array,
  setMenuType: PropTypes.func
}

export default MenuTypeField

import React from 'react'
import PropTypes from 'prop-types'
import InputField from '../../elements/InputField/InputField'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'

const EditStaffUserContent = ({ classes, item, setFirstName, setLastName, setEmail, setRole, rolesList, validationError }) => {
  const { email = '', name = '', roleName = '' } = item
  const [firstName, lastName] = name.split(' ')
  const selectedRole = rolesList.filter(role => role.name.toLowerCase() === roleName.toLowerCase())
  let selectedRoleIndex = null
  if (selectedRole && selectedRole.length > 0 && selectedRole[0].id) {
    selectedRoleIndex = selectedRole[0].id
  }
  return (
    <div className={classes.staffUserDetailsContainer}>
      <InputField
        classes={{
          inputLabelRoot: classes.fieldTitle,
          inputRoot: classes.staffUserDetailsInputFields
        }}
        placeholder={'First Name'}
        id={'editStaffUserFirstName'}
        label={''}
        value={firstName}
        changeHandler={setFirstName}
        showLabel
      />
      <InputField
        classes={{
          inputLabelRoot: classes.fieldTitle,
          inputRoot: classes.staffUserDetailsInputFields
        }}
        placeholder={'Last Name'}
        id={'editStaffUserLastName'}
        label={''}
        value={lastName}
        changeHandler={setLastName}
        showLabel
      />
      {getFilterSelector('', '', selectedRoleIndex, setRole, rolesList, 'name', { selectorWidth: '100%' }, false, false)}
      <InputField
        classes={{
          inputLabelRoot: classes.fieldTitle,
          inputRoot: classes.staffUserDetailsInputFields
        }}
        placeholder={'Email'}
        id={'editStaffUserEmail'}
        label={''}
        value={email}
        changeHandler={setEmail}
        showLabel
      />
      {
        validationError && <span className={classes.validationErrText}>{validationError}</span>
      }
    </div>
  )
}

EditStaffUserContent.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  setRole: PropTypes.func,
  rolesList: PropTypes.array,
  validationError: PropTypes.string
}

export default EditStaffUserContent

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { reportAnalytics } from '../../utils/analytics'
import { loginUser } from '../../store/authentication/actions'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_SIGN_IN,
  ANALYTICS_PAGE_TYPE_LOGIN
} from '../../constants/analytics'
import { validateEmail } from '../../utils/validations'
import { reportGoogleAnalytics } from '../../utils/googleAnalytics'
import styles from './Login.css'
const Login = ({
  classes,
  loginUser,
  errors,
  currentUser = {}
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleInputChange = (e) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else {
      setPassword(e.target.value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    reportGoogleAnalytics('sign_in')
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: { actionName: ANALYTICS_ACTION_SIGN_IN },
      pageType: ANALYTICS_PAGE_TYPE_LOGIN
    })
    const user = {
      email,
      password
    }
    loginUser(user)
  }

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.headerGrid}>
          <div className={classes.logoContainer}>
            <img src="/msgLogo.png" alt="" className={classes.suitesImage} />
          </div>
          <div className={classes.suitesBackground} />
        </div>
        <div className={classes.contentContainer}>
          <h2 className={classes.signInHeaderText}>Suites App Log In</h2>
          <div className={classes.formContainer}>
            <form onSubmit={handleSubmit}>
              <TextField
                id="outlined-email-input"
                className={classes.textField}
                type="email"
                name="email"
                margin="normal"
                variant="outlined"
                placeholder="EMAIL ADDRESS"
                error={errors}
                label={errors ? 'Invalid email or password.' : ' '}
                onChange={handleInputChange}
                value={email}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="outlined-password-input"
                label=""
                name="password"
                className={classes.textField}
                type="password"
                margin="normal"
                variant="outlined"
                placeholder="PASSWORD"
                onChange={handleInputChange}
                value={password}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <div className={classes.signInButtonContainer}>
                <Button variant="contained" type="submit" color="primary" disabled={!validateEmail(email)}>
                  Log In
                </Button>
              </div>
              <Link className={classes.forgotPwdLink} to={'/login/forgot-password'}>
                <span>Forgot Password?</span>
              </Link>
            </form>
          </div>
        </div>
      </Paper>
    </div>
  )
}

Login.propTypes = {
  errors: PropTypes.object,
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}

const mapStateToProps = (state) => ({
  errors: state.errorReducer.errors,
  staticData: state.staticData,
  currentUser: state.authReducer.user
})

export default withStyles(styles)(connect(mapStateToProps, { loginUser })(Login))

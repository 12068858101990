const styles = () => ({
  table: {},
  tableWrapper: {},
  pagination: {
    width: '100%',
    minWidth: '500px'
  }
})

export default styles

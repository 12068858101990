module.exports.MENU_TYPES = [
  { id: 1, name: 'Non Kosher' },
  { id: 2, name: 'Kosher' }
]

module.exports.NON_KOSHER_MENU_TYPE_ID = 1
module.exports.KOSHER_MENU_TYPE_ID = 2
module.exports.MONTH_NAMES = [
  { id: 1, name: 'January', shortName: 'Jan' },
  { id: 2, name: 'February', shortName: 'Feb' },
  { id: 3, name: 'March', shortName: 'Mar' },
  { id: 4, name: 'April', shortName: 'Apr' },
  { id: 5, name: 'May', shortName: 'May' },
  { id: 6, name: 'June', shortName: 'Jun' },
  { id: 7, name: 'July', shortName: 'Jul' },
  { id: 8, name: 'August', shortName: 'Aug' },
  { id: 9, name: 'September', shortName: 'Sep' },
  { id: 10, name: 'October', shortName: 'Oct' },
  { id: 11, name: 'November', shortName: 'Nov' },
  { id: 12, name: 'December', shortName: 'Dec' }
]

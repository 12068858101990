import {
  FETCH_SUITES_HOLDERS_ACCOUNT,
  ADD_CLIENT_CREDIT_CARD,
  UPDATE_ACCOUNT_INFORMATION,
  UPDATE_ACCOUNT_INFORMATION_ERR,
  CLEAR_UPDATE_ACCOUNT_ERR
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { clientEventsEndpoint, suiteHolderUsersEndpoints } from '../../constants/apiConfig'
import { getItemsList } from '../helpers/apiClient'

export const fetchAccount = () => dispatch => {
  const {
    user_details: currentUser = {}
  } = store.getState().staticData
  const { method, url } = clientEventsEndpoint(currentUser.id).settings()
  getItemsList(method, url, {})
    .then(res => {
      const { data = {} } = res
      const { settings } = data
      dispatch({
        type: FETCH_SUITES_HOLDERS_ACCOUNT,
        payload: settings
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const addClientCreditCard = () => dispatch => {
  dispatch({
    type: ADD_CLIENT_CREDIT_CARD,
    payload: {}
  })
}

export const updateAccountInfomation = (accountInfo, updatedNotication) => dispatch => {
  const {
    user_details: currentUser = {}
  } = store.getState().staticData
  const { method, url } = clientEventsEndpoint(currentUser.id).updateNotificationPreferences()
  const suiteHolderUser = {
    suite_holder_user: {
      id: currentUser.id,
      notification_preferences_attributes: [updatedNotication]
    }
  }
  getItemsList(method, url, suiteHolderUser)
    .then(res => {
      dispatch({
        type: UPDATE_ACCOUNT_INFORMATION,
        payload: {
          accountInfo
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateAccountCreditCard = (accountInfo, updatedCard) => dispatch => {
  const {
    user_details: currentUser = {}
  } = store.getState().staticData
  const { method, url } = suiteHolderUsersEndpoints.updateCard
  const urlWithId = `${url.replace(':suiteHolderUserId', currentUser.id)}`
  const suiteHolderUser = {
    suite_user: {
      credit_cards_attributes: [updatedCard]
    }
  }
  getItemsList(method, urlWithId, suiteHolderUser)
    .then(res => {
      dispatch({
        type: UPDATE_ACCOUNT_INFORMATION,
        payload: {
          accountInfo
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const deleteAccountCard = (accountInfo, cardIdToDelete) => dispatch => {
  const { method, url } = clientEventsEndpoint().deleteClientCreditCard(cardIdToDelete)
  getItemsList(method, url)
    .then(res => {
      const { data } = res
      if (data.success) {
        dispatch({
          type: UPDATE_ACCOUNT_INFORMATION,
          payload: {
            accountInfo,
            accountUpdateErr: data
          }
        })
      } else {
        const { accountInfo } = store.getState().accountInfo
        dispatch({
          type: UPDATE_ACCOUNT_INFORMATION_ERR,
          payload: {
            accountInfo,
            accountUpdateErr: data
          }
        })
      }
    }).catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const clearAccountUpdateErr = (accountUpdateErr) => dispatch => {
  dispatch({
    type: CLEAR_UPDATE_ACCOUNT_ERR,
    payload: {
      accountUpdateErr: ''
    }
  })
}

import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchOrders, fetchCopyOrderData, cancelOrderById, resetOrderCancellationNotice } from '../../store/orders/actions'
import { resetCheckoutData, getEventDetailsWithOrderWindow } from '../../store/CheckoutFlow/actions'
import { moveOrders } from '../../store/events/actions'
import { resetNewOrderData } from '../../store/companies/actions'
import OrderFilterBar from '../../elements/OrderFilterBar/OrderFilterBar'
import Loader from '../../elements/LoadingState/LoadingState'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import { history } from '../../store/store'
import CopyOrder from '../../Dialogs/CopyOrder/CopyOrder'
import MoveOrders from '../../Dialogs/MoveOrders/MoveOrders'
import Confirmation from '../../Dialogs/Confirmation/Confirmation'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'
import {
  OrderItemProperties,
  styles,
  rowsFor,
  resourceName
} from '../../constants/orders'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_TYPE_CTA,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS,
  ANALYTICS_ACTION_VIEW_ORDER,
  ANALYTICS_ACTION_EDIT_ORDER,
  ANALYTICS_ACTION_COPY_ORDER,
  ANALYTICS_ACTION_CANCEL_ORDER
} from '../../constants/analytics'
const LazyTable = React.lazy(() => import('../../elements/Table/Table'))
const CancelOrderConfirmationMessage = 'Your changes will be permanent. Please confirm you want to cancel the order.'

const OrdersList = ({
  currentPage,
  fetchItems,
  rows,
  totalPages = defaultTotalPages,
  page = defaultPage,
  limit: rowsPerPage = defaultLimit,
  orderStatusList,
  menuTypes,
  eventCategories,
  filters,
  sortParams,
  resetCreateOrderDialogData,
  currentUser,
  fetchCopyOrderData,
  resetCheckoutData,
  getEventDetailsWithOrderWindow,
  orderCancellationStatus,
  orderCancellationMessage,
  cancelOrderById,
  resetOrderCancellationNotice,
  eventId,
  eventName,
  eventDate,
  moveOrders
}) => {
  const [openCopyOrderDialog, setOpenCopyOrderDialog] = useState(false)
  const [currentOrder, setCurrentOrder] = useState()
  const [openCancelOrderConfirmationDialog, setOpenCancelOrderConfirmationDialog] = useState(false)
  const [openMoveOrdersDialog, setOpenMoveOrdersDialog] = useState(false)
  const [openMoveOrdersConfirmationDialog, setOpenMoveOrdersConfirmationDialog] = useState(false)
  const [targetEvent, setTargetEvent] = useState({})

  useEffect(() => {
    if (!rows) {
      fetchItems({ eventId }, defaultPage, defaultLimit, { sortBy: 'createdDate', order: 'desc' }, currentPage)
    }
  }, [rows, fetchItems, eventId, currentPage])

  useEffect(() => {
    if (eventId) {
      fetchItems({ eventId }, defaultPage, defaultLimit, { sortBy: 'createdDate', order: 'desc' }, currentPage)
    }
  }, [eventId, fetchItems, currentPage])

  useEffect(() => {
    if (orderCancellationStatus) {
      setTimeout(() => {
        resetOrderCancellationNotice()
      }, 5000)
    }
  }, [orderCancellationStatus, resetOrderCancellationNotice])

  const handleChangePage = (event, page) => {
    fetchItems({ ...filters, eventId }, page, rowsPerPage, sortParams, currentPage)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems({ ...filters, eventId }, defaultPage, +event.target.value, sortParams, currentPage)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems({ ...filters, eventId }, page, rowsPerPage, sort, currentPage)
  }

  const trackMenuCta = (actionName, item) => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_CTA,
        actionOrderId: item.id
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
  }

  const viewOrder = (item) => {
    trackMenuCta(ANALYTICS_ACTION_VIEW_ORDER, item)
    history.push(`/orders/${item.id}`)
  }

  const editOrder = (item) => {
    trackMenuCta(ANALYTICS_ACTION_EDIT_ORDER, item)
    history.push(`/orders/${item.id}/edit`)
  }

  const copyOrder = (item) => {
    trackMenuCta(ANALYTICS_ACTION_COPY_ORDER, item)
    resetCheckoutData()
    setOpenCopyOrderDialog(true)
    setCurrentOrder(item)
  }

  const cancelOrder = (item) => {
    trackMenuCta(ANALYTICS_ACTION_CANCEL_ORDER, item)
    setCurrentOrder(item)
    setOpenCancelOrderConfirmationDialog(true)
  }

  const handleCancelConfirmationDialogClose = () => {
    setCurrentOrder(null)
    setOpenCancelOrderConfirmationDialog(false)
  }

  const handleCancelOrder = () => {
    cancelOrderById(currentOrder.id, currentPage)
    setCurrentOrder(null)
    setOpenCancelOrderConfirmationDialog(false)
  }

  const handleCopyOrder = (order, eventId) => {
    getEventDetailsWithOrderWindow(eventId, order.menuTypeId)
    fetchCopyOrderData(order.id, eventId)
    history.push('/checkout')
  }

  const closeCopyOrderDialog = () => setOpenCopyOrderDialog(false)

  const showMoveOrdersDialog = () => setOpenMoveOrdersDialog(true)

  const closeMoveOrdersDialog = () => setOpenMoveOrdersDialog(false)

  const triggerConfirmation = (targetEvent) => {
    setOpenMoveOrdersDialog(false)
    setOpenMoveOrdersConfirmationDialog(true)
    setTargetEvent(targetEvent)
  }

  const moveOrdersToTargetEvent = () => {
    setOpenMoveOrdersConfirmationDialog(false)
    setTargetEvent({})
    moveOrders(eventId, targetEvent.id)
  }

  const handleCancelMoveOrderConfirmation = () => {
    setOpenMoveOrdersConfirmationDialog(false)
    setTargetEvent({})
  }

  const settingsMenuBuilder = item => [
    {
      id: item.id,
      label: 'View',
      action: viewOrder
    },
    {
      id: item.id,
      label: 'Edit',
      action: editOrder,
      enabled: item.isEditableByCurrentUser
    },
    {
      id: item.id,
      label: 'Copy',
      action: copyOrder
    },
    {
      id: item.id,
      label: 'Cancel',
      action: cancelOrder,
      enabled: item.isEditableByCurrentUser
    }
  ]

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    fetchItems({ ...filters, eventId }, defaultPage, rowsPerPage, sortParams, currentPage)
  }

  return (
    <div>
      { orderCancellationStatus && <QuickNotice message={orderCancellationMessage} /> }
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <OrderFilterBar
            orderStatusList={orderStatusList}
            menuTypes={menuTypes}
            eventCategories={eventCategories}
            resetCreateOrderDialogData={resetCreateOrderDialogData}
            resetCheckoutData={resetCheckoutData}
            currentUser={currentUser}
            eventId={eventId}
            handleMoveOrders={showMoveOrdersDialog}
          />
        </FilterBar>
        <Suspense fallback={<div><Loader /></div>}>
          { rows && <LazyTable
            properties={OrderItemProperties}
            sortParams={sortParams}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSorting={handleSorting}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsFor}
            resourceName={resourceName}
            settingsMenuBuilder={settingsMenuBuilder}
          />}
        </Suspense>
      </DataContainer>
      {
        openCopyOrderDialog &&
        <CopyOrder
          openDialog={openCopyOrderDialog}
          handleDialogClose={closeCopyOrderDialog}
          handleCopyOrder={handleCopyOrder}
          order={currentOrder}
        />
      }
      {
        openMoveOrdersDialog &&
        <MoveOrders
          openDialog={openMoveOrdersDialog}
          eventId={eventId}
          eventName={eventName}
          eventDate={eventDate}
          handleDialogClose={closeMoveOrdersDialog}
          eventTypes={eventCategories}
          handleMoveOrders={triggerConfirmation}
        />
      }
      {
        openCancelOrderConfirmationDialog &&
        <Confirmation
          isOpen={openCancelOrderConfirmationDialog}
          onClose={handleCancelConfirmationDialogClose}
          onSubmit={handleCancelOrder}
          message={CancelOrderConfirmationMessage}
        />
      }
      {
        openMoveOrdersConfirmationDialog &&
        <Confirmation
          isOpen={openMoveOrdersConfirmationDialog}
          onClose={handleCancelMoveOrderConfirmation}
          onSubmit={moveOrdersToTargetEvent}
          message={`Are you sure you want to move all orders from ${eventName} (${eventDate}) to ${targetEvent.web_name} (${targetEvent.start_date})?`}
        />
      }
    </div>
  )
}

OrdersList.propTypes = {
  currentPage: PropTypes.string,
  rows: PropTypes.array,
  orderStatusList: PropTypes.array.isRequired,
  menuTypes: PropTypes.array.isRequired,
  eventCategories: PropTypes.array.isRequired,
  filters: PropTypes.object,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  fetchItems: PropTypes.func.isRequired,
  sortParams: PropTypes.object,
  resetCreateOrderDialogData: PropTypes.func,
  currentUser: PropTypes.object,
  fetchCopyOrderData: PropTypes.func,
  resetCheckoutData: PropTypes.func,
  getEventDetailsWithOrderWindow: PropTypes.func,
  orderCancellationStatus: PropTypes.bool,
  orderCancellationMessage: PropTypes.string,
  cancelOrderById: PropTypes.func,
  resetOrderCancellationNotice: PropTypes.func,
  eventId: PropTypes.number,
  eventName: PropTypes.string,
  eventDate: PropTypes.string,
  moveOrders: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    orderStatusList: state.staticData.order_status,
    eventCategories: state.staticData.event_categories,
    menuTypes: state.staticData.menu_types,
    currentUser: state.staticData.user_details,
    orderCancellationStatus: state.orderReducer.orderCancellationStatus,
    orderCancellationMessage: state.orderReducer.orderCancellationMessage
  }
}
const mapDispatchToProps = {
  fetchItems: fetchOrders,
  resetCreateOrderDialogData: resetNewOrderData,
  fetchCopyOrderData,
  resetCheckoutData,
  cancelOrderById,
  getEventDetailsWithOrderWindow,
  resetOrderCancellationNotice,
  moveOrders
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersList)

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import idx from 'idx'
import Dialog from '../../elements/Dialog/Dialog'
import EventsListFilter from '../../elements/EventsListFilter/EventsListFilter'
import ListPicker from '../../elements/ListPicker/ListPicker'
import {
  createShareType,
  fetchEventsForShareType,
  fetchAvailableEventsForShareTypes,
  updateShareType,
  resetEventsForShareType
} from '../../store/shareTypes/actions'
import ShareTypeTitle from './ShareTypeTitle'
import ShareTypeActions from './ShareTypeActions'
import ShareTypeName from './ShareTypeName'
import styles from './ShareType.css'

const ShareType = ({
  allowNameEdit,
  classes,
  createShareType,
  fetchAvailableEventsForShareTypes,
  fetchEventsForShareType,
  id,
  initialAvailableEvents,
  initialAssignedEvents,
  initialName,
  isOpen,
  onClose,
  resetEventsForShareType,
  updateShareType
}) => {
  const mode = id ? 'edit' : 'add'

  const [assignments, changeAssignments] = useState({
    assignedEvents: initialAssignedEvents,
    availableEvents: initialAvailableEvents
  })

  const [name, changeName] = useState(initialName)
  const [search, changeSearch] = useState('')

  const updateAssignments = ({ availableItems, selectedItems }) => {
    changeAssignments({
      assignedEvents: selectedItems,
      availableEvents: availableItems
    })
  }

  const onAvailableItemsFilterChange = ({ category, dateFrom, dateTo, search }) => {
    const formData = new FormData()
    id && formData.append('id', id)
    changeSearch(search)
    !!category && formData.append('filters[category]', category)
    !!dateFrom && formData.append('filters[date][start_date]', dateFrom)
    !!dateTo && formData.append('filters[date][end_date]', dateTo)
    fetchAvailableEventsForShareTypes(formData)
  }

  const doUpdate = () => {
    const { assignedEvents, availableEvents } = assignments
    const prefix = 'suite_share[events]'
    const formData = new FormData()
    assignedEvents.forEach(({ id }) => formData.append(`${prefix}[assign][]`, id))
    availableEvents.forEach(({ id }) => formData.append(`${prefix}[unassign][]`, id))
    formData.append('suite_share[name]', name)
    formData.append('id', id)
    updateShareType(formData)
    onClose()
  }

  const doCreate = () => {
    const { assignedEvents } = assignments
    const prefix = 'suite_share[events]'
    const formData = new FormData()
    assignedEvents.forEach(({ id }) => formData.append(`${prefix}[assign][]`, id))
    formData.append('suite_share[name]', name)
    createShareType(formData)
    onClose()
  }

  const onSaveButtonClick = id ? doUpdate : doCreate

  useEffect(() => {
    if (id && !initialAvailableEvents) {
      fetchEventsForShareType(id)
    } else if (!initialAvailableEvents) {
      fetchAvailableEventsForShareTypes()
    }

    return () => {
      resetEventsForShareType()
    }
  }, [])

  useEffect(() => {
    changeName(initialName)
  }, [initialName])

  useEffect(() => {
    const assignedEvents = !assignments.assignedEvents
      ? initialAssignedEvents
      : assignments.assignedEvents
    changeAssignments({
      assignedEvents,
      availableEvents: initialAvailableEvents
    })
  }, [initialAvailableEvents, initialAssignedEvents]) // eslint-disable-line react-hooks/exhaustive-deps

  const assignedEvents = assignments.assignedEvents || []
  const availableEvents = assignments.availableEvents || []

  return (
    <Dialog
      openDialog={isOpen}
      dialogTitle={<ShareTypeTitle className={classes.title} mode={mode} />}
      dialogContent={
        <Fragment>
          <ShareTypeName name={name} onChange={changeName} isDisabled={!allowNameEdit} />
          <ListPicker
            availableItemsButtonLabel="Assign"
            availableItemsFilter={
              <EventsListFilter onChange={onAvailableItemsFilterChange} />
            }
            availableItemsFilterText={search}
            availableItemsHeadline="Available Events"
            availableItemsNameKey="web_name"
            availableItemsDateKey="start_date"
            className={classes.listPicker}
            initialSelectedItems={assignedEvents}
            initialAvailableItems={availableEvents}
            onChange={updateAssignments}
            selectedItemsButtonLabel="Unassign"
            selectedItemsHeadline="Assigned Events"
            selectedItemsNameKey="web_name"
            selectedItemsDateKey="start_date"
          />
        </Fragment>
      }
      dialogActions={
        <ShareTypeActions
          classes={classes}
          onCancel={onClose}
          onSave={onSaveButtonClick}
        />
      }
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={onClose}
    />
  )
}

ShareType.propTypes = {
  allowNameEdit: PropTypes.bool,
  classes: PropTypes.object,
  createShareType: PropTypes.func.isRequired,
  fetchEventsForShareType: PropTypes.func.isRequired,
  fetchAvailableEventsForShareTypes: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  initialAssignedEvents: PropTypes.array,
  initialAvailableEvents: PropTypes.array,
  initialName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  resetEventsForShareType: PropTypes.func.isRequired,
  updateShareType: PropTypes.func.isRequired
}

ShareType.defaultProps = {
  allowNameEdit: true,
  classes: {},
  id: null,
  initialAssignedEvents: null,
  initialAvailableEvents: null,
  initialName: '',
  isOpen: false
}

const mapStateToProps = (state, { id }) => {
  if (id) {
    const {
      assignedEvents,
      availableEvents,
      name
    } = idx(state, _ => _.shareTypesData.events[id]) || {}

    return {
      initialAssignedEvents: assignedEvents,
      initialAvailableEvents: availableEvents,
      initialName: name
    }
  }

  return {
    initialAvailableEvents: idx(state, _ => _.shareTypesData.availableEvents)
  }
}

const mapDispatchToProps = {
  createShareType,
  fetchEventsForShareType,
  fetchAvailableEventsForShareTypes,
  resetEventsForShareType,
  updateShareType
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareType))

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import idx from 'idx'
import Dialog from '../../elements/Dialog/Dialog'
import { styles } from './MenuItemDetails.css'
import MenuItemDetailsTitle from './MenuItemDetailsTitle'
import MenuItemDetailsActions from './MenuItemDetailsActions'
import MenuItemDetailsContent from './MenuItemDetailsContent'
const getTitle = (item, classes) => {
  const imageUrl = item.image ? idx(item, _ => _.image.popup.url) : ''
  return <MenuItemDetailsTitle classes={classes} imageUrl={imageUrl} />
}

const getDialogContent = (item, classes) => {
  return <MenuItemDetailsContent classes={classes} item={item} />
}

const getDialogActions = (item, classes, handleAddToCart, orderWindow, currentUser) => {
  return <MenuItemDetailsActions
    classes={classes}
    item={item}
    handleAddToCart={handleAddToCart}
    orderWindow={orderWindow}
    currentUser={currentUser}
  />
}

const MenuItemDetails = ({
  classes,
  handleAddToCart,
  handleDialogClose,
  menuItem,
  currentUser,
  openDialog,
  orderWindow
}) => {
  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(menuItem, classes)}
      dialogContent={getDialogContent(menuItem, classes)}
      dialogActions={getDialogActions(menuItem, classes, handleAddToCart, orderWindow, currentUser)}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

MenuItemDetails.propTypes = {
  classes: PropTypes.object,
  handleAddToCart: PropTypes.func,
  handleDialogClose: PropTypes.func,
  menuItem: PropTypes.object,
  openDialog: PropTypes.bool,
  orderWindow: PropTypes.string,
  currentUser: PropTypes.object
}

export default withStyles(styles)(MenuItemDetails)

import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'

const FoodTypeField = ({
  classes,
  foodTypes,
  setFoodType
}) => {
  return (
    <div classes={classes.fieldContainer}>
      <span className={classes.fieldTitle}> FOOD TYPE </span>
      <div>
        {
          foodTypes.map(foodType => {
            return <Fab
              key={foodType.id}
              variant="extended"
              aria-label={foodType.name}
              className={`${foodType.selected ? classes.active : classes.inactive}`}
              onClick={() => setFoodType(foodType.id)} >
              {foodType.name}
            </Fab>
          })
        }
      </div>
    </div>
  )
}

FoodTypeField.propTypes = {
  classes: PropTypes.object,
  foodTypes: PropTypes.array,
  setFoodType: PropTypes.func
}

export default FoodTypeField

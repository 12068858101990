import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { getEventSuiteById } from '../../store/eventSuites/actions'
import styles from './VoidEventSuiteReservation.css'
import VoidEventSuiteReservationContent from './VoidEventSuiteReservationContent'
import VoidEventSuiteReservationTitle from './VoidEventSuiteReservationTitle'
import VoidEventSuiteReservationActions from './VoidEventSuiteReservationActions'

const VoidEventSuiteReservation = ({
  classes,
  eventSuiteId,
  eventSuite = {},
  getEventSuiteById,
  handleDialogClose,
  openDialog,
  handleVoidReservation
}) => {
  useEffect(() => {
    if (!eventSuiteId) return
    getEventSuiteById(eventSuiteId)
  }, [eventSuiteId, getEventSuiteById])

  let suiteName = ''
  if (eventSuite) {
    suiteName = eventSuite.suiteName
  }

  const handleSubmit = () => {
    handleVoidReservation(eventSuiteId)
  }

  return (
    <React.Fragment>
      {
        eventSuiteId && eventSuite &&
        <Dialog
          openDialog={openDialog}
          dialogTitle={
            <VoidEventSuiteReservationTitle
              classes={classes}
              suiteName={suiteName}
            />
          }
          dialogContent={
            <VoidEventSuiteReservationContent />
          }
          dialogActions={
            <VoidEventSuiteReservationActions
              classes={classes}
              handleVoidReservation={handleSubmit}
              handleCancel={handleDialogClose}
            />
          }
          classes={{
            genericPaperStyles: classes.genericPaperStyles,
            genericDialogActionRoot: classes.genericDialogActionRoot,
            genericDialogContentRoot: classes.genericDialogContentRoot,
            genericDialogTitleRoot: classes.genericDialogTitleRoot
          }}
          handleDialogClose={handleDialogClose}
        />
      }
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    eventCategories: state.staticData.event_categories,
    event: state.eventsData.eventToEdit,
    eventSuite: state.eventSuitesData.eventSuiteToEdit
  }
}

const mapDispatchToProps = {
  getEventSuiteById
}

VoidEventSuiteReservation.propTypes = {
  classes: PropTypes.object,
  eventSuiteId: PropTypes.number.isRequired,
  eventSuite: PropTypes.object,
  getEventSuiteById: PropTypes.func,
  handleDialogClose: PropTypes.func,
  handleVoidReservation: PropTypes.func,
  openDialog: PropTypes.bool
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(VoidEventSuiteReservation))

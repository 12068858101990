import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import SearchField from '../SearchField/SearchField'
import { filterKeys } from '../../constants/staffUsers'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import ResetFilters from '../ResetFilters/ResetFilters'
import styles from './StaffUsersFilterBar.css'

const StaffUsersFilterBar = ({
  addStaffUserAction,
  classes,
  ctaLabel,
  filters = {},
  filterItems,
  styles
}) => {
  const [query, setSearchQuery] = useState(filters.query || '')
  const [filterKey, setFilterKey] = useState(filters.filterKey || 'name')
  const [resetFilters, setResetFiltersFlag] = useState(false)

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleFilterKeySelection = (event) => {
    const filterKey = event.target.value
    setFilterKey(filterKey)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const clearFilters = () => {
    setFilterKey('name')
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  const handleSubmit = () => {
    filterItems({ query, filterKey })
  }

  return (
    <React.Fragment>
      {getFilterSelector('FILTER BY', 'Filter By', filterKey, handleFilterKeySelection, filterKeys, 'name', styles, false)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
      <FormControl className={classes.addStaffUserButtonContainer}>
        <Button variant="contained" color="primary" className={classes.addStaffUserButton} onClick={addStaffUserAction}>
          {ctaLabel}
        </Button>
      </FormControl>
    </React.Fragment>
  )
}

StaffUsersFilterBar.propTypes = {
  addStaffUserAction: PropTypes.func,
  ctaLabel: PropTypes.string,
  classes: PropTypes.object,
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  styles: PropTypes.object
}

export default withStyles(styles)(StaffUsersFilterBar)

import {
  CLEAR_UPDATE_PASSWORD_MESSAGE,
  SET_CURRENT_USER_FROM_LOCALSTORAGE,
  SET_CURRENT_USER,
  RESET_AUTH_DATA,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  NEW_PASSWORD_UPDATED,
  SET_DEFAULT_DATA
} from '../actionTypes'
import isEmpty from '../validation/is-empty'
import { currentUserIsAdmin } from '../helpers/userRoleManager'

const initialState = {
  isAuthenticated: false,
  user: {}
}

export default function AuthenticationReducers (state = initialState, action) {
  const { payload = {} } = action
  const { user_details: currentUser = {} } = payload
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: currentUser,
        isAdmin: currentUserIsAdmin(currentUser)
      }
    case SET_DEFAULT_DATA:
      return {
        ...state,
        suiteShares: action.payload.suite_shares || [],
        suites: action.payload.suites || []
      }
    case SET_CURRENT_USER_FROM_LOCALSTORAGE:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: currentUser,
        isAdmin: currentUserIsAdmin(currentUser),
        dataLoadedFromLocalStorage: true
      }
    case RESET_AUTH_DATA:
      return initialState
    case RESET_PASSWORD:
      return {
        ...state,
        resetPwd: action.payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        updatePwdMessage: action.payload
      }
    case NEW_PASSWORD_UPDATED:
      return {
        ...state,
        newPasswordUpdateMessage: action.payload
      }
    case CLEAR_UPDATE_PASSWORD_MESSAGE:
      return {
        ...state,
        updatePwdMessage: action.payload
      }
    default:
      return state
  }
}

import { FETCH_SUITES } from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'

const initialState = {}

const processData = ({
  capacity,
  display_name: name,
  is_available: isAvailable
}) => ({
  capacity,
  name,
  status: isAvailable ? 'Available' : 'Not Available'
})

export default function SuitesReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_SUITES:
      return {
        ...state,
        suitesList: processItemsList(action.payload, processData)
      }
    default:
      return state
  }
}

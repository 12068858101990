const styles = {
  editMenuItemDialogContentContainer: {
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    padding: '10px 40px'
  },
  statusButtons: {
    width: '128px'
  },
  statusFieldContainer: {
    padding: '10px 0px'
  },
  active: {
    color: 'white',
    height: '28px',
    borderRadius: '16px',
    backgroundColor: 'black',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    marginTop: '10px',
    '& span': {
      color: 'white',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  inactive: {
    color: 'black',
    height: '24px',
    borderRadius: '16px',
    backgroundColor: 'white',
    marginRight: '20px',
    boxShadow: 'none',
    border: '1px solid',
    marginTop: '10px',
    '& span': {
      color: 'black',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '11px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    }
  },
  fieldTitle: {
    height: '14px',
    width: '91px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  priceList: {
    color: 'black',
    listStyle: 'none',
    padding: '0px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    margin: '5px 0px',
    fontSize: '14px'
  },
  priceListContainer: {
    display: 'flex',
    '& div': {
      width: '150px'
    }
  },
  fieldContainer: {
    margin: '5px 0px'
  },
  menuItemEditInputFields: {
    width: '586px',
    margin: '0px 0px'
  },
  menuItemEditInputContainer: {
    padding: '10px 0px'
  },
  focused: {
    borderBottom: '0px'
  },
  editMenuItemActionsContainer: {
    marginTop: '30px'
  },
  cancelButton: {
    width: '180px',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '10px',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  updateMenuItemButton: {
    width: '180px',
    borderRadius: '0px',
    boxShadow: 'none',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  assignCategoryButton: {
    width: '180px',
    borderRadius: '0px',
    marginRight: '10px',
    boxShadow: 'none',
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  selectContainer: {
    width: '100%',
    display: 'flex'
  },
  categoryContainer: {
    display: 'flex'
  },
  parentCategorySelect: {
    marginRight: '20px'
  },
  deleteCategoryCheckbox: {
    width: '50px'
  },
  categoryHeaders: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    display: 'flex',
    '& div:nth-child(1)': {
      width: '257px'
    },
    '& div:nth-child(2)': {
      width: '247px'
    },
    '& div:nth-child(3)': {
      width: '80px'
    }
  }
}

export default styles

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { setItemToEdit } from '../../store/menuItems/actions'
import EditMenuItemDialogContent from './EditMenuItemDialogContent'
import styles from './EditMenuItem.css'

const getDialogActions = (classes) => {
  return <div />
}

const getDialogContent = (menuItem, eventTypes, menuTypes, foodTypes, handleUpdate, handleDialogClose, allCategories) => {
  return <EditMenuItemDialogContent
    menuItem={menuItem}
    eventTypes={eventTypes}
    menuTypes={menuTypes}
    handleUpdate={handleUpdate}
    handleCancel={handleDialogClose}
    foodTypes={foodTypes}
    allCategories={allCategories}
  />
}

function EditMenuItem ({
  openDialog,
  handleUpdate,
  classes,
  menuItem,
  handleDialogClose,
  eventTypes,
  menuTypes,
  foodTypes,
  setItemToEdit,
  menuItemIdToEdit,
  allCategories
}) {
  useEffect(() => {
    if (!menuItem && menuItemIdToEdit) {
      setItemToEdit(menuItemIdToEdit)
    }
  })

  return (
    <React.Fragment>
      { menuItem && <Dialog
        openDialog={openDialog}
        dialogTitle={null}
        dialogContent={getDialogContent(menuItem, eventTypes, menuTypes, foodTypes, handleUpdate, handleDialogClose, allCategories)}
        dialogActions={getDialogActions(classes)}
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    menuTypes: state.staticData.menu_types,
    allCategories: state.staticData.menu_categories,
    suites: state.companiesData.suites || [],
    eventTypes: state.staticData.event_categories,
    menuItem: state.menuItemsData.menuItemToEdit
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setItemToEdit: (menuItemId) => {
      dispatch(setItemToEdit(menuItemId))
    }
  }
}

EditMenuItem.propTypes = {
  classes: PropTypes.object,
  eventTypes: PropTypes.array,
  handleDialogClose: PropTypes.func,
  handleUpdate: PropTypes.func,
  menuItem: PropTypes.object,
  menuItemIdToEdit: PropTypes.string.isRequired,
  menuTypes: PropTypes.array,
  openDialog: PropTypes.bool,
  setItemToEdit: PropTypes.func,
  foodTypes: PropTypes.array,
  allCategories: PropTypes.array
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMenuItem))

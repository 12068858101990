import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MultiSelectList from '../MultiSelectList/MultiSelectList'
import { SelectedItemsSection, AvailableItemsSection, Container } from './ListPicker.css'

const removeItemsFromArray = (arr1, arr2) =>
  arr1.filter(({ id: id1 }) =>
    !arr2.find(({ id: id2 }) => id2 === id1))

const ListPicker = ({
  availableItemsButtonLabel,
  availableItemsFilter,
  availableItemsFilterText,
  availableItemsHeadline,
  availableItemsNameKey,
  availableItemsDateKey,
  className,
  initialAvailableItems,
  initialSelectedItems,
  onChange,
  selectedItemsButtonLabel,
  selectedItemsFilter,
  selectedItemsFilterText,
  selectedItemsHeadline,
  selectedItemsNameKey,
  selectedItemsDateKey
}) => {
  const [items, setItems] = useState({
    availableItems: initialAvailableItems,
    selectedItems: initialSelectedItems
  })

  const moveItemsToSelected = itemsToMove => {
    const { availableItems, selectedItems } = items
    const newItems = {
      availableItems: removeItemsFromArray(availableItems, itemsToMove),
      selectedItems: [...selectedItems, ...itemsToMove]
    }

    setItems(newItems)
    onChange(newItems)
  }

  const moveItemsToAvailable = itemsToMove => {
    const { availableItems, selectedItems } = items
    const newItems = {
      availableItems: [...availableItems, ...itemsToMove],
      selectedItems: removeItemsFromArray(selectedItems, itemsToMove)
    }

    setItems(newItems)
    onChange(newItems)
  }

  useEffect(() => {
    setItems({
      availableItems: removeItemsFromArray(initialAvailableItems, initialSelectedItems),
      selectedItems: removeItemsFromArray(initialSelectedItems, initialAvailableItems)
    })
  }, [initialAvailableItems, initialSelectedItems]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className}>
      <SelectedItemsSection>
        <h3>{selectedItemsHeadline}</h3>
        <MultiSelectList
          filterComponent={selectedItemsFilter}
          filterText={selectedItemsFilterText}
          items={items.selectedItems}
          nameKey={selectedItemsNameKey}
          dateKey={selectedItemsDateKey}
          onSelect={moveItemsToAvailable}
          selectButtonLabel={selectedItemsButtonLabel}
        />
      </SelectedItemsSection>
      <AvailableItemsSection>
        <h3>{availableItemsHeadline}</h3>
        <MultiSelectList
          filterComponent={availableItemsFilter}
          filterText={availableItemsFilterText}
          items={items.availableItems}
          nameKey={availableItemsNameKey}
          dateKey={availableItemsDateKey}
          onSelect={moveItemsToSelected}
          selectButtonLabel={availableItemsButtonLabel}
        />
      </AvailableItemsSection>
    </Container>
  )
}

ListPicker.propTypes = {
  availableItemsButtonLabel: PropTypes.string,
  availableItemsFilter: PropTypes.node,
  availableItemsFilterText: PropTypes.string,
  availableItemsHeadline: PropTypes.string,
  availableItemsNameKey: PropTypes.string,
  availableItemsDateKey: PropTypes.string,
  className: PropTypes.string,
  initialAvailableItems: PropTypes.array.isRequired,
  initialSelectedItems: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectedItemsButtonLabel: PropTypes.string,
  selectedItemsFilter: PropTypes.node,
  selectedItemsFilterText: PropTypes.string,
  selectedItemsHeadline: PropTypes.string,
  selectedItemsNameKey: PropTypes.string,
  selectedItemsDateKey: PropTypes.string
}

ListPicker.defaultProps = {
  availableItemsButtonLabel: 'Assign',
  availableItemsFilter: null,
  availableItemsFilterText: '',
  availableItemsHeadline: 'Available',
  availableItemsNameKey: 'name',
  availableItemsDateKey: 'start_date',
  className: null,
  initialSelectedItems: [],
  selectedItemsButtonLabel: 'Unassign',
  selectedItemsFilter: null,
  selectedItemsFilterText: '',
  selectedItemsHeadline: 'Selected',
  selectedItemsNameKey: 'name',
  selectedItemsDateKey: 'start_date'
}

export default ListPicker

import React from 'react'
import DateSelector from './DateSelector'

export const getDateSelector = (label, value, changeHandler, placeholder) => {
  return <DateSelector
    label={label}
    value={value}
    placeholder={placeholder}
    changeHandler={changeHandler}
  />
}

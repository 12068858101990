import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  font-family: 'Ringside Regular A', 'Ringside Regular B';
  justify-content: space-between;

  section {
    width: calc(50% - 20px);
  }
`

export const AvailableItemsSection = styled.section`
  display: flex;
  flex-direction: column;
`

export const SelectedItemsSection = styled.section`
  display: flex;
  flex-direction: column;
`

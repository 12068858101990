import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import ChangePasswordTitle from './ChangePasswordTitle'
import ChangePasswordActions from './ChangePasswordActions'
import ChangePasswordName from './ChangePasswordName'
import styles from './ChangePassword.css'

const ChangePassword = ({
  classes,
  isOpen,
  onClose,
  savePassword
}) => {
  const [password, setPassword] = useState({
    new: '',
    confirmation: ''
  })

  const handlePassword = (event) => {
    const passwordClone = { ...password }
    setPassword({
      ...passwordClone,
      new: event.target.value
    })
  }

  const handleConfirmPassword = (event) => {
    const passwordClone = { ...password }
    setPassword({
      ...passwordClone,
      confirmation: event.target.value
    })
  }

  const handleSubmit = () => {
    savePassword(password)
    setPassword({
      new: '',
      confirmation: ''
    })
  }

  return (
    <Dialog
      openDialog={isOpen}
      dialogTitle={<ChangePasswordTitle className={classes.title} />}
      dialogContent={
        <Fragment>
          <ChangePasswordName classes={classes} handlePassword={handlePassword} handleConfirmPassword={handleConfirmPassword} newPassword={password.new} confirmationPassword={password.confirmation} />
        </Fragment>
      }
      dialogActions={
        <ChangePasswordActions
          classes={classes}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      }
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={onClose}
    />
  )
}

ChangePassword.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  savePassword: PropTypes.func.isRequired
}

ChangePassword.defaultProps = {
  classes: {},
  isOpen: false
}

export default withStyles(styles)(ChangePassword)

const styles = {
  title: {
    height: '24px',
    width: '337px',
    color: '#000000',
    fontFamily: 'Ringside Condensed A, RingSide Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    padding: '40px 0px 25px 40px'
  },
  quantity: {
    height: '14px',
    width: '60px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  name: {
    width: '220px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  price: {
    width: '95px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    textAlign: 'right'
  },
  orderItemsRowsContainer: {
    padding: '10px 15px 10px 15px',
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeader: {
    display: 'flex',
    padding: '30px 40px'
  }
}

export default styles

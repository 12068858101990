import { creditCardEndpoints, suiteHolderUsersEndpoints } from '../../constants/apiConfig'
import { history } from '../store'
import { getItemsList } from '../helpers/apiClient'
import { logoutUser } from '../authentication/actions'
import { fetchAccount } from '../settings/actions'
import {
  SHOW_ADD_CREDIT_CARD_MESSAGE,
  CLEAR_ADD_CREDIT_CARD_MESSAGE,
  STORE_IFRAME_CODE,
  RESET_IFRAME_CODE,
  SHOW_FORM_TO_NAME_ADDED_CARD,
  HIDE_FORM_TO_NAME_ADDED_CARD,
  ADD_CREDIT_CARD_ERROR,
  CLEAR_ADD_CREDIT_CARD_ERROR
} from '../actionTypes'
import { addNewCreditCardToPaymentDetails } from '../suiteHolderUsers/actions'

export const tokenizeCreditCard = (sessionKey, paymentKey, suiteHolderUserId, nameOnCard) => async dispatch => {
  const { method, url } = creditCardEndpoints.create
  const response = await getItemsList(method, url, { credit_card: {
    sessionKey, paymentKey, suiteHolderUserId, nameOnCard
  } })
  if (response.data && response.data.status === 201) {
    const creditCard = response.data.new_card_data

    if (creditCard) {
      dispatch({
        type: SHOW_FORM_TO_NAME_ADDED_CARD,
        payload: {
          creditCard
        }
      })
    }
  } else if (response.data && response.data.status === 200) {
    const errorMessage = response.data
    if (errorMessage) {
      dispatch({
        type: ADD_CREDIT_CARD_ERROR,
        payload: {
          addCardErr: errorMessage
        }
      })
    }
  }
  dispatch({
    type: RESET_IFRAME_CODE,
    payload: {
      showFormToNameCreditCard: false
    }
  })
}

export const updateCardName = (cardId, cardData, stopEventDispatchForClientUser) => async dispatch => {
  const { method, url } = creditCardEndpoints.update(cardId)
  const { cardName } = cardData
  const response = await getItemsList(
    method,
    url,
    {
      credit_card: {
        card_name: cardName
      }
    }
  )
  if (response && response.data.status === 200) {
    const updatedCreditCard = response.data.credit_card_data
    dispatch({
      type: HIDE_FORM_TO_NAME_ADDED_CARD,
      payload: {}
    })
    if (!stopEventDispatchForClientUser) {
      dispatch(addNewCreditCardToPaymentDetails(updatedCreditCard, cardName))
    }
  }
}

export const hideUpdateCardNameForm = () => async dispatch => {
  dispatch({
    type: HIDE_FORM_TO_NAME_ADDED_CARD,
    payload: {}
  })
}

export const initiateAddCreditCard = () => async dispatch => {
  const { method, url } = creditCardEndpoints.new
  const iframeData = await getItemsList(method, url)
  const { data: { iframe } } = iframeData
  dispatch({
    type: STORE_IFRAME_CODE,
    payload: {
      iframe
    }
  })
}

export const removeFreedomPayIframe = () => async dispatch => {
  dispatch({
    type: RESET_IFRAME_CODE,
    payload: {
      showFormToNameCreditCard: false
    }
  })
}

export const clearAddCreditCardError = () => async dispatch => {
  dispatch({
    type: CLEAR_ADD_CREDIT_CARD_ERROR,
    payload: {
      addCardErr: null,
      showFormToNameCreditCard: false
    }
  })
}

export const storeUserCreditCards = (cardData, cardName, currentUserId) => dispatch => {
  const {
    cardNumber,
    expiryDate,
    cardType
  } = cardData

  const creditCard = {
    transarmor_token: cardNumber,
    card_name: cardName,
    suite_holder_user_id: currentUserId,
    expiry_date: expiryDate,
    card_type: cardType
  }

  const { method, url } = suiteHolderUsersEndpoints.updateCard
  const urlWithUserId = url.replace(':suiteHolderUserId', currentUserId)
  const userData = {
    suite_user: {
      credit_cards_attributes: [creditCard]
    }
  }

  getItemsList(method, urlWithUserId, userData)
    .then(res => {
      dispatch(fetchAccount())
      dispatch({
        type: SHOW_ADD_CREDIT_CARD_MESSAGE,
        payload: {
          showCreditCardAddedMessage: true,
          creditCardAddedMessage: 'Credit card added successfully'
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetCreditCardAddedMessage = () => dispatch => {
  dispatch({
    type: CLEAR_ADD_CREDIT_CARD_MESSAGE,
    payload: {
      showCreditCardAddedMessage: false,
      creditCardAddedMessage: ''
    }
  })
}

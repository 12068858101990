export const getRoutes = (currentUser = {}, allRoutes) => {
  const { role_id: roleId } = currentUser
  const allowedRoutes = allRoutes.reduce((allowedRoutes, route) => {
    const { authRequired, allowedRoles } = route
    if (authRequired) {
      if (allowedRoles.includes(roleId)) allowedRoutes.push(route)
    } else {
      allowedRoutes.push(route)
    }
    return allowedRoutes
  }, [])
  return allowedRoutes
}

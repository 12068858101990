import { AdminRoleIds, ClientRoleIds, SuiteHolderAdminRoleId, FinanceRoleIds } from '../constants/userRoles'
import LoginLayout from './LoginLayout'
import Login from './Login'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ResetPassword/ResetPassword'
import Orders from './Orders/Orders'
import CompaniesList from './CompaniesList/CompaniesList'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import SuitesList from './SuitesList/SuitesList'
import StaffUsersList from './StaffUsers/StaffUsersList'
import SuiteHolderUsersList from './SuiteHolderUsers/SuiteHolderUsersList'
import ShareTypes from './ShareTypes/ShareTypes'
import EventsList from './EventsList/EventsList'
import MenuItemsList from './MenuItemsList/MenuItemsList'
import MenuCatalog from './MenuCatalog/MenuCatalog'
import Checkout from './Checkout/Checkout'
import OrderDetails from './OrderDetails/OrderDetails'
import ClientEvents from './ClientEvents/ClientEvents'
import AccountInfo from './AccountInfo/AccountInfo'
import EditOrder from './EditOrder/EditOrder'
import CreditCard from './CreditCard/CreditCard'
import ReconciliationReport from './ReconciliationReport/ReconciliationReport'
import InvoiceReport from './InvoiceReport/InvoiceReport'
import EventDetails from './EventDetails/EventDetails'

export const routes = [
  {
    name: 'Default',
    path: '/',
    redirectTo: '/login',
    showTab: false,
    allowedRoles: [...AdminRoleIds, ...ClientRoleIds, ...FinanceRoleIds],
    authRequired: false
  },
  {
    name: 'Default',
    path: '/',
    redirectTo: '/orders/list',
    showTab: false,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Default',
    path: '/',
    redirectTo: '/my-events',
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Orders',
    path: '/orders',
    redirectTo: '/orders/list',
    showTab: true,
    allowedRoles: AdminRoleIds,
    authRequired: true,
    childRoutes: [
      {
        name: 'Orders',
        path: '/orders/list',
        component: Orders,
        showTab: false,
        allowedRoles: AdminRoleIds,
        authRequired: true
      },
      {
        name: 'New Order',
        path: '/orders/new',
        component: MenuCatalog,
        showTab: false,
        allowedRoles: AdminRoleIds,
        authRequired: true
      },
      {
        name: 'New Order',
        path: '/menu-catalog',
        component: MenuCatalog,
        showTab: false
      },
      {
        name: 'Order',
        path: '/orders/:id',
        component: OrderDetails,
        showTab: false,
        allowedRoles: AdminRoleIds,
        authRequired: true
      },
      {
        name: 'Order',
        path: '/orders/:id/edit',
        component: EditOrder,
        showTab: false,
        allowedRoles: AdminRoleIds,
        authRequired: true
      }
    ]
  },
  {
    name: 'ForgotPassword',
    path: '/login/forgot-password',
    component: ForgotPassword,
    exact: true,
    layout: LoginLayout,
    showTab: false,
    authRequired: false
  },
  {
    name: 'ResetPassword',
    path: '/reset',
    component: ResetPassword,
    exact: true,
    layout: LoginLayout,
    showTab: false,
    authRequired: false
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    exact: true,
    layout: LoginLayout,
    showTab: false,
    authRequired: false
  },
  {
    name: 'Menu Items',
    path: '/menu-items',
    component: MenuItemsList,
    showTab: true,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Suite Holders',
    path: '/suite-holder-users',
    component: CompaniesList,
    showTab: true,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Suite Holder Details',
    path: '/suite-holder-users/:suite_holder_id',
    component: CompanyDetails,
    showTab: false,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Events',
    path: '/events',
    component: EventsList,
    showTab: true,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Events',
    path: '/events/:id',
    component: EventDetails,
    showTab: false,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Reports',
    path: '/reports',
    redirectTo: '/reports/reconciliation',
    showTab: true,
    allowedRoles: [...AdminRoleIds, ...FinanceRoleIds],
    authRequired: true,
    childRoutes: [
      {
        name: 'Reconciliation',
        path: '/reports/reconciliation',
        component: ReconciliationReport,
        showTab: true,
        allowedRoles: [...AdminRoleIds, ...FinanceRoleIds],
        authRequired: true
      },
      {
        name: 'Invoice',
        path: '/reports/invoice',
        component: InvoiceReport,
        showTab: true,
        allowedRoles: [...AdminRoleIds, ...FinanceRoleIds],
        authRequired: true
      }
    ]
  },
  {
    name: 'Admin',
    path: '/admin',
    redirectTo: '/admin/staff-users',
    showTab: true,
    allowedRoles: AdminRoleIds,
    authRequired: true,
    childRoutes: [
      {
        name: 'Staff Users',
        path: '/admin/staff-users',
        component: StaffUsersList,
        showTab: true,
        allowedRoles: AdminRoleIds,
        authRequired: true
      },
      {
        name: 'Suites',
        path: '/admin/suites',
        component: SuitesList,
        showTab: true,
        allowedRoles: AdminRoleIds,
        authRequired: true
      },
      {
        name: 'Share Types',
        path: '/admin/share-types',
        component: ShareTypes,
        showTab: true,
        allowedRoles: AdminRoleIds,
        authRequired: true
      }
    ]
  },
  {
    name: 'Companies',
    path: '/companies',
    component: CompaniesList,
    showTab: false,
    allowedRoles: AdminRoleIds,
    authRequired: true
  },
  {
    name: 'Checkout',
    path: '/checkout',
    component: Checkout,
    showTab: false,
    allowedRoles: [...AdminRoleIds, ...ClientRoleIds],
    authRequired: true
  },
  {
    name: 'Events',
    path: '/my-events',
    component: ClientEvents,
    showTab: true,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Orders',
    path: '/my-orders',
    component: Orders,
    showTab: true,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Users',
    path: '/suite-holder-users',
    component: SuiteHolderUsersList,
    showTab: true,
    allowedRoles: [SuiteHolderAdminRoleId],
    authRequired: true
  },
  {
    name: 'Account',
    path: '/my-account',
    component: AccountInfo,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'New Order',
    path: '/menu-catalog',
    component: MenuCatalog,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'New Order',
    path: '/orders/new',
    component: MenuCatalog,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Order',
    path: '/orders/:id',
    component: OrderDetails,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Order',
    path: '/orders/:id/edit',
    component: EditOrder,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Order',
    path: '/orders/:id/edit',
    component: EditOrder,
    showTab: false,
    allowedRoles: ClientRoleIds,
    authRequired: true
  },
  {
    name: 'Credit Card',
    path: '/addCard',
    component: CreditCard,
    showTab: false,
    allowedRoles: [...AdminRoleIds, ...ClientRoleIds],
    authRequired: false
  },
  {
    name: 'NoMatch',
    path: '/*',
    redirectTo: '/login',
    showTab: false,
    allowedRoles: [...AdminRoleIds, ...ClientRoleIds, ...FinanceRoleIds],
    authRequired: false
  }
]

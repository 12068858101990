import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TabGroup from '../TabGroup/TabGroup'
import Tab from '../Tab/Tab'
import styles from './SubNav.css'

const SubNav = ({ classes, routes, pathPattern }) => {
  const RouteTabs = routes
    .filter(({ path, showTab }) => showTab && (!pathPattern || pathPattern.test(path)))
    .map(({ name, path, showTab }, index) =>
      <Tab
        key={index}
        className={classes.Tab}
        activeClassName={classes.isSelected}
        name={name}
        path={path}
      />
    )

  return <TabGroup className={classes.SubNav}>{RouteTabs}</TabGroup>
}

SubNav.propTypes = {
  classes: PropTypes.object.isRequired,
  pathPattern: PropTypes.object,
  routes: PropTypes.array.isRequired
}

SubNav.defaultProps = {
  pathPattern: null
}

export default withStyles(styles)(SubNav)

import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core'
import styles from './EventsBySuiteList.css'

EventsBySuiteList.propTypes = {
  classes: PropTypes.object,
  events: PropTypes.array,
  eventId: PropTypes.string,
  onEventSelect: PropTypes.func
}

const eventNameWithRadioButton = (event, classes, targetEventId) => {
  return <FormControlLabel
    key={event.id}
    value={event.id.toString()}
    control={<Radio color="primary" checked={event.id === targetEventId} />}
    label={`${event.web_name} - ${event.start_date}`}
    labelPlacement="end"
    className={classes.eventName}
  />
}

function EventsBySuiteList (props) {
  const { classes, events, eventId, onEventSelect } = props
  return (
    <div className={classes.eventsContainer}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="event"
          name="event"
          defaultValue={eventId}
          onChange={onEventSelect}
        >
          { events.map(event => eventNameWithRadioButton(event, classes, eventId)) }
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default withStyles(styles)(EventsBySuiteList)

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { tokenizeCreditCard, initiateAddCreditCard, removeFreedomPayIframe, clearAddCreditCardError } from '../../store/creditCard/actions'
import AccountUpdateActions from '../AccountUpdate/AccountUpdateActions'
import AccountUpdateContent from '../AccountUpdate/AccountUpdateContent'
import CardsTable from './CardsTable'
import FreedomPayIframe from './FreedomPayIframe'
import CardNameUpdateForm from './CardNameUpdateForm'

const PAYMENT_TABLE_COLUMNS = [
  { label: 'NAME', field: 'card_name' },
  { label: 'TYPE', field: 'card_type' },
  { label: '', field: 'delete' }
]
const ALLOW_ADD_CREDIT_CARD = true

const renderCreditCardList = (addNewCard, classes, creditCardDetails, paymentDetails, handleCardDelete) => {
  return (
    <>
      {
        paymentDetails && creditCardDetails &&
        <Fragment>
          <CardsTable classes={classes} columns={PAYMENT_TABLE_COLUMNS} rows={creditCardDetails} handleDeleteCard={handleCardDelete} />
        </Fragment>
      }
      {
        paymentDetails &&
        ALLOW_ADD_CREDIT_CARD &&
        <Button variant="contained" color="primary" className={classes.addNewCardButton} onClick={addNewCard}>
          Add a Card
        </Button>
      }
    </>
  )
}

const PaymentCardList = ({
  addCardErr,
  classes,
  paymentDetails,
  initiateAddCreditCard,
  handleCardDelete,
  freedompayIframe,
  removeFreedomPayIframe,
  suiteHolderUserId,
  tokenizeCreditCard,
  showFormToNameCreditCard,
  clearAddCreditCardError
}) => {
  const [iframeRequestInProgress, setIframeRequestInProgress] = useState(false)

  const addNewCard = () => {
    setIframeRequestInProgress(true)
    initiateAddCreditCard()
  }

  useEffect(() => {
    if (freedompayIframe) {
      setIframeRequestInProgress(false)
    }
  }, [freedompayIframe])

  const { credit_card_details: creditCardDetails } = paymentDetails

  return (
    <Grid item xs={12} className={classes.inputContainer}>
      <FreedomPayIframe
        classes={classes}
        iframeRequestInProgress={iframeRequestInProgress}
        freedompayIframe={freedompayIframe}
        removeFreedomPayIframe={removeFreedomPayIframe}
        tokenizeCreditCard={tokenizeCreditCard}
        suiteHolderUserId={suiteHolderUserId}
        addCardErr={addCardErr}
        clearAddCreditCardError={clearAddCreditCardError}
      />
      {
        addCardErr &&
        <AccountUpdateContent
          classes={classes}
          accountUpdateErr={addCardErr}
        />
      }
      {
        addCardErr && <AccountUpdateActions
          classes={classes}
          onSubmit={clearAddCreditCardError}
        />
      }
      { showFormToNameCreditCard && !addCardErr &&
        <CardNameUpdateForm
          classes={classes}
          suiteHolderUserId={suiteHolderUserId}
        />
      }
      {(!iframeRequestInProgress && !freedompayIframe && !showFormToNameCreditCard && !addCardErr) && renderCreditCardList(addNewCard, classes, creditCardDetails, paymentDetails, handleCardDelete)}
    </Grid>
  )
}
PaymentCardList.propTypes = {
  addCardErr: PropTypes.object,
  clearAddCreditCardError: PropTypes.func,
  classes: PropTypes.object,
  paymentDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  initiateAddCreditCard: PropTypes.func,
  handleCardDelete: PropTypes.func,
  removeFreedomPayIframe: PropTypes.func,
  freedompayIframe: PropTypes.string,
  suiteHolderUserId: PropTypes.string,
  tokenizeCreditCard: PropTypes.func,
  showFormToNameCreditCard: PropTypes.bool
}

const mapStateToProps = (state) => {
  const { creditCardsReducers } = state
  const { freedompayIframe, showFormToNameCreditCard, addCardErr, clearAddCreditCardError } = creditCardsReducers
  return {
    addCardErr,
    freedompayIframe,
    showFormToNameCreditCard,
    clearAddCreditCardError
  }
}

const mapDispatchToProps = {
  tokenizeCreditCard,
  initiateAddCreditCard,
  removeFreedomPayIframe,
  clearAddCreditCardError
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCardList)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
const styles = {
  dialogTitle: {
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    textTransform: 'uppercase',
    fontSize: '10px',
    lineHeight: '14px',
    fontWeight: '600',
    paddingLeft: '6px',
    height: '14px',
    width: '274px',
    color: '#000000',
    letterSpacing: '1px'
  }
}
const CreateOrderDialogTitle = ({ classes, title }) => {
  return (
    <span className={classes.dialogTitle}> {title} </span>
  )
}

CreateOrderDialogTitle.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string
}

export default withStyles(styles)(CreateOrderDialogTitle)

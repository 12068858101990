import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import InputField from '../../elements/InputField/InputField'
import { states as stateList } from '../../constants/companies'
import styles from './EditCompanyDialogContent.css'
import EditCompanySuites from './EditCompanySuites'

const EditCompanyDialogContent = ({
  classes,
  company,
  setName,
  setAddress,
  setCity,
  setState,
  setZip,
  suites,
  suiteShares,
  setSuites,
  setSuiteShare,
  deleteSuite,
  companyUpdatedMessage
}) => {
  const [selectedStateIndex, setSelectedStateIndex] = useState(null)
  const [updateMessage, setUpdateMessage] = useState('')

  useEffect(() => {
    if (company && company.address && company.address.state) {
      const selectedState = stateList.filter(state => state.name.toLowerCase() === company.address.state.toLowerCase())
      if (selectedState && selectedState.length > 0 && selectedState[0].id) {
        setSelectedStateIndex(selectedState[0].id)
      }
    }
  }, [company])

  useEffect(() => {
    if (company) {
      const { message } = company
      setUpdateMessage({
        message
      })
      const timer1 = setTimeout(() => setUpdateMessage({}), 5000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [company])

  return (
    <div className={classes.editCompanyContent}>
      {
        companyUpdatedMessage && !companyUpdatedMessage.success && <Typography variant="h6" className={classes.errorText}>{companyUpdatedMessage.message}</Typography>
      }
      {
        updateMessage && updateMessage.message && <Typography variant="h6" className={classes.errorText}>{updateMessage.message}</Typography>
      }
      {
        company && <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item xs={6} className={classes.companyNameInputFields}>
              <InputField
                classes={{
                  inputLabelRoot: classes.fieldTitle,
                  inputRoot: classes.companyDetailsInputFields
                }}
                placeholder={'Name'}
                label={''}
                value={company.name}
                changeHandler={setName}
                showLabel
                disabled
              />
            </Grid>
            <Grid item xs={6} className={classes.companyIdInputFields}>
              <InputField
                classes={{
                  inputLabelRoot: classes.fieldTitle,
                  inputRoot: classes.companyDetailsInputFields
                }}
                placeholder={'Oracle ID'}
                label={''}
                value={company.oracleId}
                disabled
                showLabel
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.companyDetailsInputFields
              }}
              placeholder={'Address'}
              label={''}
              value={company.address.streetAddress || ''}
              changeHandler={setAddress}
              showLabel
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.companyDetailsInputFields
              }}
              placeholder={'City'}
              label={''}
              value={company.address.city || ''}
              changeHandler={setCity}
              showLabel
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6} className={classes.companyNameInputFields}>
              {
                selectedStateIndex && getFilterSelector('', '', selectedStateIndex, setState, stateList, 'name', { selectorWidth: '100%' }, false, false)
              }
            </Grid>
            <Grid item xs={6} className={classes.companyZipInputFields}>
              <input
                className={classes.companyZipInput}
                placeholder="Zip"
                value={company.address.zip || ''}
                onChange={setZip}
                maxLength="10"
              />
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.assignedSuitesTitle}>Assigned Suites</Typography>
          <div className={classes.suitesContainer}>
            {
              company.suites && company.suites.map((suite, index) =>
                <EditCompanySuites classes={classes} suiteShares={suiteShares} suites={suites} companySuite={suite || []}
                  setSuites={setSuites}
                  setSuiteShare={setSuiteShare}
                  remove={deleteSuite}
                  key={index}
                  index={index} />
              )
            }
          </div>
        </Grid>
      }
    </div>
  )
}

EditCompanyDialogContent.propTypes = {
  classes: PropTypes.object,
  company: PropTypes.object,
  setName: PropTypes.func,
  setAddress: PropTypes.func,
  setCity: PropTypes.func,
  setState: PropTypes.func,
  setZip: PropTypes.func,
  suites: PropTypes.array,
  suiteShares: PropTypes.array,
  setSuites: PropTypes.func,
  setSuiteShare: PropTypes.func,
  deleteSuite: PropTypes.func,
  companyUpdatedMessage: PropTypes.object
}

export default withStyles(styles)(EditCompanyDialogContent)

const styles = {
  genericPaperStyles: {
    minHeight: '618px',
    minWidth: '870px',
    maxWidth: '870px',
    borderRadius: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '0px 30px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '30px',
    color: 'black'
  },
  copyOrderDialogBody: {

  },
  copyOrderTitle: {
    height: '14px',
    width: '778px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px'
  },
  placeOrderButton: {
    width: '378px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '0px',
    height: '50px'
  },
  copyOrderActionsContainer: {
    padding: '20px 0px'
  },
  cancelCopyOrderButton: {
    width: '378px',
    background: 'white',
    color: 'black',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    boxSizing: 'border-box',
    height: '50px',
    textTransform: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    border: '1px solid #000000',
    '&:hover': {
      background: 'transparent'
    },
    marginRight: '40px'
  }
}

export default styles

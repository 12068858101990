import moment from 'moment'
import {
  CLEAR_UPDATE_MESSAGE,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUITES,
  FETCH_COMPANIES_SUITES_USERS,
  FETCH_COMPANIES_SUITES_EVENTS,
  RESET_NEW_ORDER_DATA,
  COMPANY_DETAILS_BY_ID,
  FETCH_EVENTS_FOR_SUITES_IN_COMPANY,
  UPDATE_COMPANY_SUITE_EVENT,
  FETCH_COMPANIES_BY_ID_TO_EDIT,
  COMPANY_UPDATED
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'
const initialState = {}

const processCompanyData = (company) => {
  const {
    eatec_id: oracleId,
    id,
    name,
    suites,
    users
  } = company

  return {
    oracleId,
    id,
    name,
    suites,
    users
  }
}

const processCompany = (company) => {
  const {
    id,
    name,
    address,
    message = '',
    orders = []
  } = company

  return {
    oracleId: company.eatec_id || company.oracleId,
    id,
    name,
    suites: company.companies_suites || company.suites,
    address: {
      streetAddress: address ? address.street_address : '',
      city: address ? address.city : '',
      state: address ? address.state : 'AL',
      zip: address ? address.zip : '',
      id: address ? address.id : ''
    },
    message,
    orders
  }
}

const getCompanyDetails = (companyData) => {
  const { company = {}, suites = [] } = companyData
  const assignedSuites = suites.map(({ share_type_name: shareTypeName = 'N/A',
    display_name: suiteName = 'N/A',
    active_since: activeSince,
    suite_id: suiteId,
    companies_suite_id: companiesSuiteId }) => {
    return {
      shareTypeName,
      suiteName,
      activeSince: moment(activeSince).format('MMM DD, YYYY'),
      suiteId,
      companiesSuiteId
    }
  })
  let companyDetails = {
    oracleId: company.eatec_id,
    name: company.name,
    suites: []
  }
  if (assignedSuites) {
    companyDetails = {
      oracleId: company.eatec_id,
      name: company.name,
      assignedSuites
    }
  }
  return companyDetails
}
export default function CompaniesReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        companiesList: processItemsList(action.payload, processCompanyData),
        companiesSuitesEvents: []
      }
    case FETCH_COMPANIES_SUITES:
      return {
        ...state,
        suites: action.payload.suites.map(suite => {
          return {
            id: suite.suite_id,
            name: suite.display_name,
            companiesSuiteId: suite.companies_suite_id
          }
        }),
        companiesSuitesEvents: []
      }
    case FETCH_COMPANIES_SUITES_USERS:
      return {
        ...state,
        companiesSuitesUsers: action.payload.users
      }
    case FETCH_COMPANIES_SUITES_EVENTS:
      return {
        ...state,
        companiesSuitesEvents: action.payload.events
      }
    case FETCH_EVENTS_FOR_SUITES_IN_COMPANY:
      return {
        ...state,
        companiesSuitesAvailableEvents: action.payload.availableEvents,
        companiesSuitesAssignedEvents: action.payload.assignedEvents,
        eventAssignmentMessage: '',
        companyUpdatedMessage: {
          message: '',
          success: false
        }
      }
    case RESET_NEW_ORDER_DATA:
      return {
        ...state,
        suites: [],
        companiesSuitesUsers: [],
        companiesSuitesEvents: []
      }
    case UPDATE_COMPANY_SUITE_EVENT:
      return {
        ...state,
        eventAssignmentMessage: action.payload
      }
    case FETCH_COMPANIES_BY_ID_TO_EDIT:
      return {
        ...state,
        companyDetailsToEdit: processCompany(action.payload),
        companyUpdatedMessage: {
          message: '',
          success: false
        }
      }
    case COMPANY_UPDATED:
      return {
        ...state,
        companyUpdatedMessage: action.payload
      }
    case CLEAR_UPDATE_MESSAGE:
      return {
        ...state,
        companyUpdatedMessage: null
      }
    case COMPANY_DETAILS_BY_ID:
      return {
        ...state,
        companyDetails: getCompanyDetails(action.payload),
        companyUpdatedMessage: {
          message: '',
          success: false
        }
      }
    default:
      return state
  }
}

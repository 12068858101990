module.exports.staffUserProperties = [
  {
    key: 'name',
    label: 'NAME',
    sortable: true
  },
  {
    key: 'roleName',
    label: 'ROLE',
    sortable: true
  },
  {
    key: 'email',
    label: 'EMAIL',
    sortable: true
  },
  {
    key: 'webStatusText',
    label: 'STATUS',
    sortable: false
  }
]
module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.sortParamsMapping = {
  name: 'users.name',
  roleName: 'roles.name',
  email: 'users.email'
}

module.exports.styles = {
  searchFieldWidth: '640px',
  selectorWidth: '250px'
}

module.exports.addQuickNoticeStyles = {
  height: '38px',
  width: '100%',
  backgroundColor: '#65FFF5',
  color: '#061860',
  fontFamily: 'Ringside Regular A, Ringside Regular B',
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: '18px',
  textAlign: 'center',
  position: 'fixed',
  left: '0px',
  top: '120px',
  zIndex: 9999999,
  paddingTop: '8px'
}

module.exports.rowsFor = 'staffUsers'
module.exports.resourceName = 'users'

module.exports.filterKeys = [
  {
    name: 'Name',
    id: 'name'
  },
  {
    name: 'Email',
    id: 'email'
  },
  {
    name: 'Role',
    id: 'role'
  }
]

module.exports.ctaLabel = 'Add Staff User'

module.exports.defaultUserObject = {
  email: '',
  name: ''
}

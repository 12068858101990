const styles = {
  autoCompleteContainer: {
    width: '100%',
    margin: '30px 10px 10px 30px'
  },
  autoCompleteTextField: {
    borderBottom: '1px dotted black',
    color: 'black',
    backgroundColor: 'white'
  },
  paper: {
    backgroundColor: 'white',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px'
  },
  input: {
    color: 'black'
  },
  endAdornment: {
    color: 'black'
  },
  popupIndicator: {
    display: 'none'
  },
  noOptions: {
    display: 'none'
  }
}

export default styles

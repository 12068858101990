import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import CustomButton from '../../elements/CustomButton/CustomButton'
import styles from './OrderSuccessMessage.css'
const getTitle = (classes, apiStatus) => {
  return <div className={classes.orderSuccessMessageTitle}>
    {apiStatus ? 'Thank You! Order Placed Successfully.' : 'Error While Placing Order'}
  </div>
}

const getDialogContent = (apiStatus, message, classes) => {
  const messageClass = apiStatus ? classes.orderSuccessMessageBody : classes.orderErrorMessageBody
  return <div className={messageClass}>
    {message}
  </div>
}

const getDialogActions = (orderId, classes, handleGoToHome, handleViewOrder, apiStatus) => {
  return <div>
    <CustomButton
      variant={'contained'}
      disableRipple
      styleClass={classes.button}
      label={'View Order'}
      color={'primary'}
      disabled={!apiStatus}
      clickHandler={() => { handleViewOrder(orderId) }}
    />
    <CustomButton
      variant={'contained'}
      disableRipple
      styleClass={classes.button}
      label={'Go To Home'}
      color={'primary'}
      clickHandler={handleGoToHome}
    />
  </div>
}
const OrderSuccessMessage = ({
  classes,
  handleGoToHome,
  handleViewOrder,
  openDialog,
  orderId,
  successMessage,
  apiStatus
}) => {
  const dialogueContentClass = apiStatus ? classes.genericDialogContentRoot : classes.genericDialogContentRootError
  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(classes, apiStatus)}
      dialogActions={getDialogActions(orderId, classes, handleGoToHome, handleViewOrder, apiStatus)}
      dialogContent={getDialogContent(apiStatus, successMessage, classes)}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: dialogueContentClass,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
    />
  )
}

OrderSuccessMessage.propTypes = {
  classes: PropTypes.object,
  handleGoToHome: PropTypes.func,
  handleViewOrder: PropTypes.func,
  openDialog: PropTypes.bool,
  orderId: PropTypes.number,
  successMessage: PropTypes.string,
  apiStatus: PropTypes.bool
}

export default withStyles(styles)(OrderSuccessMessage)

import _ from 'lodash'
import { FETCH_MENU_CATALOG_BY_SUITE, CLEAR_MENU_CATALOG } from '../actionTypes'
const initialState = {}
const OTHERS_CATEGORY = 'Others'
const PREVIOUSLY_ORDERED = 'Previously Ordered'

const processMenuItems = (items, defaultMenuItemName = OTHERS_CATEGORY) => {
  const menuItems = items ? items.reduce((allItems, item) => {
    const categories = item.categories || []
    const tempItem = {
      advancePrice: item.advance_price,
      gamedayPrice: item.game_day_price,
      id: item.id,
      image: item.image,
      description: item.web_description,
      name: item.web_name,
      isGlutenFree: item.is_gluten_free,
      isVegan: item.is_vegan
    }
    if (categories.length > 0) {
      categories.forEach(category => {
        tempItem.category = category.name
        allItems.push({ ...tempItem })
      })
    } else {
      tempItem.category = OTHERS_CATEGORY
      allItems.push({ ...tempItem })
    }
    return allItems
  }, []) : []
  return _.groupBy(menuItems, 'category')
}

export default function MenuCatalogReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_MENU_CATALOG_BY_SUITE:
      const menuItems = processMenuItems(action.payload.menuItems)
      const recentOrderMenuItems = processMenuItems(action.payload.recentOrdersMenuItems, PREVIOUSLY_ORDERED)
      const uniqueCategories = [
        ...Object.keys(menuItems),
        ...Object.keys(recentOrderMenuItems)
      ]
      return {
        ...state,
        menuItemsCatalogBySuite: {
          ...menuItems,
          ...recentOrderMenuItems
        },
        uniqueCategories: [
          ...uniqueCategories
        ]
      }
    case CLEAR_MENU_CATALOG:
      return {
        ...state,
        menuItemsCatalogBySuite: null
      }
    default:
      return state
  }
}

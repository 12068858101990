import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import OrdersList from '../OrdersList/OrdersList'

const OrdersByEvent = ({
  rows,
  totalPages,
  page,
  limit,
  filters,
  sortParams,
  eventId,
  eventName,
  eventDate
}) => {
  return (
    <OrdersList
      rows={rows}
      totalPages={totalPages}
      page={page}
      limit={limit}
      filters={filters}
      sortParams={sortParams}
      eventId={eventId}
      eventName={eventName}
      eventDate={eventDate}
      currentPage={'eventDetails'}
    />
  )
}

OrdersByEvent.propTypes = {
  rows: PropTypes.array,
  filters: PropTypes.object,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  sortParams: PropTypes.object,
  eventId: PropTypes.number,
  eventName: PropTypes.string,
  eventDate: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    ...state.orderReducer.ordersListByEvent
  }
}
const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersByEvent)

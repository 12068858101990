import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Dialog, withStyles } from '@material-ui/core'
import theme from '../../dialogTheme'
import DialogActions from './DialogActions'
import DialogContent from './DialogContent'
import DialogTitle from './DialogTitle'
import { styles } from './Dialog.css'

const CustomizedDialog = ({
  classes,
  dialogActions,
  dialogContent,
  dialogTitle,
  handleDialogClose,
  openDialog = false
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog onClose={handleDialogClose} disableBackdropClick disableEscapeKeyDown aria-labelledby="customized-dialog-title" open={openDialog} classes={{ paper: classes.genericPaperStyles }}>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose} classes={{ root: classes.genericDialogTitleRoot }}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent classes={{ root: classes.genericDialogContentRoot }}>
          {dialogContent}
        </DialogContent>
        <DialogActions classes={{ root: classes.genericDialogActionRoot }}>
          {dialogActions}
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  )
}

CustomizedDialog.propTypes = {
  classes: PropTypes.object,
  dialogActions: PropTypes.object,
  dialogContent: PropTypes.object,
  dialogTitle: PropTypes.object,
  handleDialogClose: PropTypes.func,
  openDialog: PropTypes.bool
}

export default withStyles(styles)(CustomizedDialog)

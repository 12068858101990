export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID'
export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'
export const SET_DEFAULT_DATA_FROM_LOCALSTORAGE = 'SET_DEFAULT_DATA_FROM_LOCALSTORAGE'

export const FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS'
export const CLEAR_MENU_ITEMS = 'CLEAR_MENU_ITEMS'
export const GET_MENU_ITEM_BY_ID_TO_EDIT = 'GET_MENU_ITEM_BY_ID_TO_EDIT'
export const UPDATE_MENU_ITEMS_LIST = 'UPDATE_MENU_ITEMS_LIST'

export const FETCH_MENU_CATALOG_BY_SUITE = 'FETCH_MENU_CATALOG_BY_SUITE'
export const CLEAR_MENU_CATALOG = 'CLEAR_MENU_CATALOG'

export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW = 'FETCH_EVENT_DETAILS_WITH_ORDER_WINDOW'
export const CLEAR_EVENT_DETAILS = 'CLEAR_EVENT_DETAILS'

export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANIES_SUITES = 'FETCH_COMPANIES_SUITES'
export const FETCH_COMPANIES_SUITES_USERS = 'FETCH_COMPANIES_SUITES_USERS'
export const FETCH_COMPANIES_SUITES_EVENTS = 'FETCH_COMPANIES_SUITES_EVENTS'
export const RESET_NEW_ORDER_DATA = 'RESET_NEW_ORDER_DATA'

export const FETCH_SUITE_HOLDER_USERS = 'FETCH_SUITE_HOLDER_USERS'
export const FETCH_SUITE_HOLDER_USERS_BY_COMPANY = 'FETCH_SUITE_HOLDER_USERS_BY_COMPANY'

export const STORE_MENU_CATALOG_PREREQUISITES = 'STORE_MENU_CATALOG_PREREQUISITES'
export const STORE_ORDER_ITEMS = 'STORE_ORDER_ITEMS'
export const STORE_CHECKOUT_FLOW_EVENT_DATA = 'STORE_CHECKOUT_FLOW_EVENT_DATA,'
export const STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA = 'STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA,'
export const STORE_CHECKOUT_FLOW_SUITE_DATA = 'STORE_CHECKOUT_FLOW_SUITE_DATA'
export const STORE_CHECKOUT_PAYMENT_DATA = 'STORE_CHECKOUT_PAYMENT_DATA'
export const RESET_CHECKOUT_DATA = 'RESET_CHECKOUT_DATA'
export const STORE_CHECKOUT_VALIDATION_DATA = 'STORE_CHECKOUT_VALIDATION_DATA'
export const STORE_CHECKOUT_PAYMENT_METHOD_DATA = 'STORE_CHECKOUT_PAYMENT_METHOD_DATA'
export const STORE_CHECKOUT_PAYMENT_OPTIONS_DATA = 'STORE_CHECKOUT_PAYMENT_OPTIONS_DATA'
export const SHOW_CHECKOUT_SUCCESS_MESSAGE = 'SHOW_CHECKOUT_SUCCESS_MESSAGE'
export const SHOW_CHECKOUT_ERROR_MESSAGE = 'SHOW_CHECKOUT_ERROR_MESSAGE'
export const FETCH_STAFF_USERS = 'FETCH_STAFF_USERS'
export const FETCH_SUITES = 'FETCH_SUITES'
export const FETCH_SHARE_TYPES = 'FETCH_SHARE_TYPES'
export const FETCH_EVENTS_FOR_SHARE_TYPE = 'FETCH_EVENTS_FOR_SHARE_TYPE'
export const FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE = 'FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE'
export const CREATE_SHARE_TYPE = 'CREATE_SHARETYPE'
export const UPDATE_SHARE_TYPE = 'UPDATE_SHARETYPE'
export const SET_EVENT_TO_EDIT = 'SET_EVENT_TO_EDIT'
export const UPDATE_EVENTS_LIST = 'UPDATE_EVENTS_LIST'
export const FETCH_CLIENT_EVENTS = 'FETCH_CLIENT_EVENTS'
export const FILTER_CLIENT_EVENTS = 'FILTER_CLIENT_EVENTS'
export const SET_CURRENT_USER_FROM_LOCALSTORAGE = 'SET_CURRENT_USER_FROM_LOCALSTORAGE'
export const SET_CHECKOUT_DATA_FROM_ORDER = 'SET_CHECKOUT_DATA_FROM_ORDER'
export const RESET_CLIENT_EVENTS = 'RESET_CLIENT_EVENTS'
export const RESET_ORDERS_DATA = 'RESET_ORDERS_DATA'
export const RESET_DEFAULT_DATA = 'RESET_DEFAULT_DATA'
export const RESET_AUTH_DATA = 'RESET_AUTH_DATA'
export const SET_CHECKOUT_DATA_TO_COPY_ORDER = 'SET_CHECKOUT_DATA_TO_COPY_ORDER'
export const SET_STAFF_USER_TO_EDIT = 'SET_STAFF_USER_TO_EDIT'
export const UPDATE_STAFF_USER_TO_LIST = 'UPDATE_STAFF_USER_TO_LIST'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FETCH_SUITES_HOLDERS_ACCOUNT = 'FETCH_SUITES_HOLDERS_ACCOUNT'
export const ADD_CLIENT_CREDIT_CARD = 'ADD_CLIENT_CREDIT_CARD'
export const UPDATE_ACCOUNT_INFORMATION = 'UPDATE_ACCOUNT_INFORMATION'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_CLIENT_CREDIT_CARD = 'UPDATE_CLIENT_CREDIT_CARD'
export const STORE_COMPANIES_SUITE_ID_OF_CLIENT = 'STORE_COMPANIES_SUITE_ID_OF_CLIENT'
export const RESET_PAYMENT_OPTION_DATA = 'RESET_PAYMENT_OPTION_DATA'
export const UPDATE_ACCOUNT_INFORMATION_ERR = 'UPDATE_ACCOUNT_INFORMATION_ERR'
export const UPDATE_STAFF_USER_STATUS = 'UPDATE_STAFF_USER_STATUS'
export const ADD_STAFF_USER_TO_LIST = 'ADD_STAFF_USER_TO_LIST'
export const STORE_CREDIT_CARD_SECURE_HASH = 'STORE_CREDIT_CARD_SECURE_HASH'
export const SHOW_ADD_CREDIT_CARD_MESSAGE = 'SHOW_ADD_CREDIT_CARD_MESSAGE'
export const CLEAR_ADD_CREDIT_CARD_MESSAGE = 'CLEAR_ADD_CREDIT_CARD_MESSAGE'
export const SET_ORDER_CANCELLATION_MESSAGE = 'SET_ORDER_CANCELLATION_MESSAGE'
export const RESET_ORDER_CANCELLATION_MESSAGE = 'RESET_ORDER_CANCELLATION_MESSAGE'
export const SET_SUITE_HOLDER_USER_TO_EDIT = 'SET_SUITE_HOLDER_USER_TO_EDIT'
export const SET_SUITE_HOLDER_USER_TO_ADD = 'SET_SUITE_HOLDER_USER_TO_ADD'
export const CLOSE_SUITE_HOLDER_USER_DIALOG = 'CLOSE_SUITE_HOLDER_USER_DIALOG'
export const UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION = 'UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION'
export const UPDATE_SUITE_HOLDER_USER_FORM_DATA = 'UPDATE_SUITE_HOLDER_USER_FORM_DATA'
export const SUITE_HOLDER_USER_UPDATED = 'SUITE_HOLDER_USER_UPDATED'
export const SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE = 'SET_SUITE_HOLDER_DATA_FROM_LOCALSTORAGE'
export const SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER = 'SET_NEW_CREDIT_CARD_FOR_SUITE_HOLDER_USER'
export const REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER = 'REMOVE_NEW_CREDIT_CARD_FROM_SUITE_HOLDER_USER'
export const UPDATE_CREDIT_CARD_DATA_TO_STORE = 'UPDATE_CREDIT_CARD_DATA_TO_STORE'
export const DELETE_USER_CREDIT_CARD_ERR = 'DELETE_USER_CREDIT_CARD_ERR'
export const NEW_PASSWORD_UPDATED = 'NEW_PASSWORD_UPDATED'
export const FETCH_CLIENT_EVENTS_IN_PROGRESS = 'FETCH_CLIENT_EVENTS_IN_PROGRESS'
export const USER_LOGOUT = 'USER_LOGOUT'
export const COMPANY_DETAILS_BY_ID = 'COMPANY_DETAILS_BY_ID'
export const FETCH_EVENTS_FOR_SUITES_IN_COMPANY = 'FETCH_EVENTS_FOR_SUITES_IN_COMPANY'
export const UPDATE_COMPANY_SUITE_EVENT = 'UPDATE_COMPANY_SUITE_EVENT'
export const FETCH_COMPANIES_BY_ID_TO_EDIT = 'FETCH_COMPANIES_BY_ID_TO_EDIT'
export const COMPANY_UPDATED = 'COMPANY_UPDATED'
export const FETCH_RECONCILIATION_REPORT = 'FETCH_RECONCILIATION_REPORT'
export const FETCH_INVOICE_REPORT = 'FETCH_INVOICE_REPORT'
export const FETCH_MENU_CATEGORIES = 'FETCH_MENU_CATEGORIES'
export const CREATE_MENU_CATEGORIES = 'CREATE_MENU_CATEGORIES'
export const DELETE_MENU_CATEGORIES = 'DELETE_MENU_CATEGORIES'
export const UPDATE_MENU_CATEGORIES = 'UPDATE_MENU_CATEGORIES'
export const REST_MENU_CATEGORIES_UPDATE_MESSAGE = 'REST_MENU_CATEGORIES_UPDATE_MESSAGE'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'
export const STORE_RECOMMENDATIONS = 'STORE_RECOMMENDATIONS'
export const STORE_FAVORITE_RECOMMENDATIONS = 'STORE_FAVORITE_RECOMMENDATIONS'
export const FETCH_EVENT_SUITES = 'FETCH_EVENT_SUITES'
export const UPDATE_EVENT_SUITES = 'UPDATE_EVENT_SUITES'
export const ADD_EVENT_SUITES = 'ADD_EVENT_SUITES'
export const STORE_EVENT_SUITE_TO_EDIT = 'STORE_EVENT_SUITE_TO_EDIT'
export const RESET_EVENT_SUITE_TO_EDIT = 'RESET_EVENT_SUITE_TO_EDIT'
export const STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT = 'STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT'
export const CLEAR_UPDATE_MESSAGE = 'CLEAR_UPDATE_MESSAGE'
export const STORE_EE_EVENTS_LIST = 'STORE_EE_EVENTS_LIST'
export const RESET_EVENTS_FOR_SHARE_TYPE = 'RESET_EVENTS_FOR_SHARE_TYPE'
export const SET_EVENTS_TO_MOVE_ORDERS = 'SET_EVENTS_TO_MOVE_ORDERS'
export const TOGGLE_EVENT_STATUS = 'TOGGLE_EVENT_STATUS'
export const STORE_IFRAME_CODE = 'STORE_IFRAME_CODE'
export const RESET_IFRAME_CODE = 'RESET_IFRAME_CODE'
export const SHOW_FORM_TO_NAME_ADDED_CARD = 'SHOW_FORM_TO_NAME_ADDED_CARD'
export const HIDE_FORM_TO_NAME_ADDED_CARD = 'HIDE_FORM_TO_NAME_ADDED_CARD'
export const ADD_CREDIT_CARD_ERROR = 'ADD_CREDIT_CARD_ERROR'
export const CLEAR_ADD_CREDIT_CARD_ERROR = 'CLEAR_ADD_CREDIT_CARD_ERROR'
export const CLEAR_UPDATE_PASSWORD_MESSAGE = 'CLEAR_UPDATE_PASSWORD_MESSAGE'
export const CLEAR_UPDATE_ACCOUNT_ERR = 'CLEAR_UPDATE_ACCOUNT_ERR'

import axios from 'axios'
import {
  FETCH_ORDERS,
  FETCH_ORDER_BY_ID,
  SET_CHECKOUT_DATA_FROM_ORDER,
  SET_CHECKOUT_DATA_TO_COPY_ORDER,
  SET_ORDER_CANCELLATION_MESSAGE,
  RESET_ORDER_CANCELLATION_MESSAGE,
  UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION,
  RESET_PAYMENT_OPTION_DATA
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { orderEndpoints, clientOrdersEndpoint } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping, DEFAULT_CURRENT_PAGE } from '../../constants/orders'
import { getItemsList } from '../helpers/apiClient'
import { currentUserIsAdmin } from '../helpers/userRoleManager'
import { getUserPaymentDetails } from '../CheckoutFlow/actions'

const prepareOrderListQuery = (filters, page, limit, sort) => {
  const { status, eventCategory: category, menuType, startDate, endDate, query, eventId } = cleanUpFilterObject(filters)
  const { sortBy = sortParamsMapping.orderId, order = 'desc' } = sort
  return {
    filters: {
      date: {
        start_date: startDate,
        end_date: endDate
      },
      status,
      menu_type_id: menuType,
      category,
      query,
      event_id: eventId
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchOrders = (filters = {}, page = 0, limit = 10, sortParams = { sortBy: sortParamsMapping.orderId, order: 'desc' }, currentPage) => dispatch => {
  const {
    user_details: currentUser = {}
  } = store.getState().staticData
  let orderListEndpointByUser
  if (currentUserIsAdmin(currentUser)) {
    orderListEndpointByUser = orderEndpoints.list
  } else {
    orderListEndpointByUser = clientOrdersEndpoint.list(currentUser.id)
  }
  const { method, url } = orderListEndpointByUser
  const data = prepareOrderListQuery(filters, page, limit, sortParams)

  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { orders } = data
      const { items, meta } = orders
      dispatch({
        type: FETCH_ORDERS,
        payload: { items, meta, page, limit, filters, sortParams, currentUser, currentPage }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const getRequiredStaticDataFromStore = () => {
  const {
    in_suite_options: inSuiteOptions,
    user_details: currentUser,
    payment_status: paymentStatusList,
    payment_methods: paymentMethodList
  } = store.getState().staticData
  return {
    inSuiteOptions,
    currentUser,
    paymentStatusList,
    paymentMethodList
  }
}
export const fetchOrderById = (orderId) => dispatch => {
  const { method, url } = orderEndpoints.byId(orderId)
  axios({
    method,
    url
  }).then(res => {
    const { data = {} } = res
    const { order: orderData } = data
    const {
      order: {
        submitted_for: submittedFor
      } = {}
    } = orderData
    const staticData = getRequiredStaticDataFromStore()
    dispatch({
      type: RESET_PAYMENT_OPTION_DATA,
      payload: {}
    })
    dispatch({
      type: FETCH_ORDER_BY_ID,
      payload: { orderData, staticData }
    })
    dispatch({
      type: SET_CHECKOUT_DATA_FROM_ORDER,
      payload: { orderData, staticData }
    })
    dispatch(getUserPaymentDetails(submittedFor))
  })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const getUnavailableItems = (orderId, eventId) => {
  const { method, url } = orderEndpoints.unavailableItems(orderId, eventId)
  return axios({ method, url })
}

const getOrderData = orderId => {
  const { method, url } = orderEndpoints.byId(orderId)
  return axios({ method, url })
}

const getOrderItemsToCopy = async (orderId, eventId) => {
  const { method, url } = orderEndpoints.copyOrder(orderId)
  const copyOrderResponse = await axios({ method, url, data: { event_id: eventId } })
  const {
    data: {
      order = {}
    }
  } = copyOrderResponse
  return order.order_items_attributes
}

const getUnavailableItemsToCopy = async (orderId, eventId) => {
  const {
    data: {
      unavailable_items: unavailableItems = []
    } = {}
  } = await getUnavailableItems(orderId, eventId)
  return unavailableItems
}
export const fetchCopyOrderData = (orderId, eventId) => async dispatch => {
  try {
    const existingOrderData = await getOrderData(orderId)
    const orderItemsToCopy = await getOrderItemsToCopy(orderId, eventId)
    const unavailableItems = await getUnavailableItemsToCopy(orderId, eventId)
    const {
      data: {
        order: orderData = {}
      } = {}
    } = existingOrderData
    const {
      order: {
        submitted_for: submittedFor
      } = {}
    } = orderData
    orderData.order_items = orderItemsToCopy
    dispatch({
      type: SET_CHECKOUT_DATA_TO_COPY_ORDER,
      payload: { orderData, eventId, unavailableItems }
    })
    dispatch(getUserPaymentDetails(submittedFor))
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

export const cancelOrderById = (orderId, currentPage) => dispatch => {
  const { method, url } = orderEndpoints.cancelOrder(orderId)
  const {
    allOrdersList = {},
    ordersListByEvent = {}
  } = store.getState().orderReducer

  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      const successfulCancellation = data.message === 'Order cancelled successfully.'
      dispatch({
        type: UPDATE_ORDERS_LIST_AFTER_ORDER_CANCELLATION,
        payload: {
          allOrdersList,
          currentPage,
          ordersListByEvent,
          orderId,
          successfulCancellation
        }
      })
      if (currentPage === DEFAULT_CURRENT_PAGE) {
        dispatch({
          type: SET_ORDER_CANCELLATION_MESSAGE,
          payload: {
            message: data.message,
            status: true
          }
        })
      } else {
        dispatch(fetchOrderById(orderId))
        dispatch({
          type: SET_ORDER_CANCELLATION_MESSAGE,
          payload: {
            message: data.message,
            status: true
          }
        })
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetOrderCancellationNotice = () => dispatch => {
  dispatch({
    type: RESET_ORDER_CANCELLATION_MESSAGE,
    payload: {
      orderCancellationMessage: '',
      orderCancellationStatus: null
    }
  })
}

const styles = {
  orderItemsListHeaderContainer: {
    display: 'flex',
    color: 'black',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    paddingTop: '30px'
  },
  quantityHeader: {
    width: '100px',
    paddingLeft: '30px'
  },
  nameHeader: {
    width: '305px'
  },
  itemPriceHeader: {
    width: '90px'
  },
  totalPriceHeader: {
    width: '90px',
    textAlign: 'center'
  }
}

export default styles

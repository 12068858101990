const styles = {
  genericPaperStyles: {
    minHeight: '500px',
    minWidth: '580px',
    maxWidth: '580px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    paddingLeft: '38px',
    paddingRight: '38px',
    paddingTop: '30px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '40px 40px 10px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& ul': {
      listStyle: 'none',
      padding: '0px'
    }
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px'
  },
  saveSuiteHolderUserActionsContainer: {
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    marginTop: '30px',
    width: '100%'
  },
  focused: {
    borderBottom: '0px'
  },
  cancelButton: {
    width: '30%',
    borderRadius: '0px',
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000'
    }
  },
  cancelButtonForNewUser: {
    width: '48%',
    borderRadius: '0px',
    boxShadow: 'none',
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000'
    }
  },
  newSuiteHolderUserButton: {
    width: '48%',
    color: 'white',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    borderRadius: '0px',
    textTransform: 'none',
    marginRight: '12px'
  },
  saveSuiteHolderUserButton: {
    width: '32%',
    color: 'white',
    border: '1px solid #000000',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    borderRadius: '0px',
    textTransform: 'none',
    marginRight: '12px'
  },
  suiteHolderUserDetailsInputFields: {
    width: '274px',
    margin: '10px 0px'
  },
  inputContainer: {
    '& div': {
      width: '100%'
    },
    '& ul': {
      margin: 0
    }
  },
  saveSuiteHolderUserActionsTitle: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  validationErrText: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    color: '#FF0000'
  },
  SHUserCompany: {
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  addNewCardSuiteHolderUserButton: {
    width: '100%',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    borderRadius: '0px'
  },
  suitesLabel: {
    fontFamily: 'Ringside Regular A, Ringside Regular B'
  },
  paymentCards: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '1.0rem'
  },
  cardsContainer: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    borderBottom: '1px solid',
    textAlign: 'center'
  },
  paymentTable: {
    border: '1px solid #ddd',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    backgroundColor: 'transparent',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& >thead:first-child>tr:first-child>th': {
      borderTop: 0
    },
    '& >thead>tr>th': {
      border: '1px solid #ddd',
      verticalAlign: 'middle',
      paddingLeft: '12px',
      paddingTop: '8px',
      lineHeight: 1.42857143
    },
    '& >tbody>tr>td': {
      border: '1px solid #ddd',
      paddingLeft: '8px',
      paddingTop: '8px',
      lineHeight: 1.42857143,
      verticalAlign: 'middle',
      textAlign: 'left'
    },
    '& tbody': {
      display: 'block',
      overflow: 'auto',
      height: '200px'
    },
    '& thead, tbody tr': {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed'
    },
    '& table': {
      width: '400px'
    },
    '& >thead:first-child>tr>th:last-child': {
      width: '45px'
    },
    '& >tbody>tr>td:last-child': {
      width: '45px'
    }
  },
  paymentCardDeleteButton: {
    padding: 0
  },
  addNewCardButton: {
    color: 'white',
    border: '1px solid #000000',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    borderRadius: '0px',
    textTransform: 'none',
    marginRight: '12px',
    float: 'right',
    marginBottom: '12px'
  },
  validationError: {
    color: ' #FF0000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    marginBottom: '12px'
  },
  cardNameContainer: {
    padding: '20px 0px',
    '& label': {
      paddingTop: '15px'
    },
    '& button': {
      marginLeft: '12px',
      marginTop: '12px',
      float: 'right'
    }
  },
  creditCardMessage: {
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    paddingTop: '8px',
    paddingBottom: '12px'
  },
  addCreditCardHeader: {
    borderBottom: '1px solid #DDD',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    margin: '0px 0px 10px 10px',
    paddingBottom: '8px',
    width: '430px !important'
  },
  addCreditCardHeaderLabel: {
    width: '300px'
  },
  addCreditCardHeaderCloseButton: {
    width: '50px !important',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submitButton: {
    backgroundColor: '#0078D4',
    borderColor: '#0078D4',
    borderRadius: '0px',
    color: '#fff',
    height: '50px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#0078D4',
      color: '#fff'
    }
  },
  actionContainer: {
    '& button': {
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'none',
      marginLeft: '132px',
      width: '200px'
    }
  },
  errorContainer: {
    backgroundColor: '#fff',
    color: '#000',
    justifyContent: 'space-between',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    minHeight: '230px'
  }
}

export default styles

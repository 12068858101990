const SUITES_API_BASE_URL = process.env.REACT_APP_SUITES_API_BASE_URL
const EE_API_BASE_URL = process.env.REACT_APP_EE_API_BASE_URL

module.exports.EE_API_AUTH_TOKEN = process.env.REACT_APP_EE_API_AUTH_TOKEN

module.exports.authEndpoints = {
  signIn: {
    url: `${SUITES_API_BASE_URL}/users/sign_in`,
    method: 'POST'
  },
  resetPassword: {
    url: `${SUITES_API_BASE_URL}/users/passwords`,
    method: 'POST'
  },
  savePassword: {
    url: `${SUITES_API_BASE_URL}/users/passwords`,
    method: 'PUT'
  },
  updateNewPasswordWithToken: {
    url: `${SUITES_API_BASE_URL}/users/passwords/reset`,
    method: 'POST'
  }
}

module.exports.creditCardEndpoints = {
  new: {
    url: `${SUITES_API_BASE_URL}/credit_cards/new`,
    method: 'GET'
  },
  create: {
    url: `${SUITES_API_BASE_URL}/credit_cards`,
    method: 'POST'
  },
  update: (cardId) => ({
    url: `${SUITES_API_BASE_URL}/credit_cards/${cardId}`,
    method: 'PUT'
  })
}

module.exports.orderEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/orders/list`,
    method: 'POST'
  },
  placeOrder: {
    url: `${SUITES_API_BASE_URL}/orders`,
    method: 'POST'
  },
  updateOrder: orderId => ({
    url: `${SUITES_API_BASE_URL}/orders/${orderId}`,
    method: 'PUT'
  }),
  byId: orderId => ({
    url: `${SUITES_API_BASE_URL}/orders/${orderId}`,
    method: 'GET'
  }),
  copyOrder: orderId => ({
    url: `${SUITES_API_BASE_URL}/orders/${orderId}/copy`,
    method: 'POST'
  }),
  unavailableItems: (orderId, eventId) => ({
    url: `${SUITES_API_BASE_URL}/orders/${orderId}/events/${eventId}/unavailable_items`,
    method: 'GET'
  }),
  cancelOrder: orderId => ({
    url: `${SUITES_API_BASE_URL}/orders/${orderId}`,
    method: 'DELETE'
  })
}

module.exports.menuItemEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/menu_items/list`,
    method: 'POST'
  },
  bySuite: {
    url: `${SUITES_API_BASE_URL}/menu_items/by_suite`,
    method: 'POST'
  },
  byId: {
    url: `${SUITES_API_BASE_URL}/menu_items/:menuItemId/edit`,
    method: 'GET'
  },
  update: {
    url: `${SUITES_API_BASE_URL}/menu_items/:menuItemId`,
    method: 'PUT'
  },
  activate: {
    url: `${SUITES_API_BASE_URL}/menu_items/:menuItemId/activate`,
    method: 'POST'
  },
  deactivate: {
    url: `${SUITES_API_BASE_URL}/menu_items/:menuItemId/deactivate`,
    method: 'POST'
  },
  suiteHolderUserMenuItems: suiteHolderUserId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/menu_items/list`,
    method: 'POST'
  }),
  search: {
    url: `${SUITES_API_BASE_URL}/menu_items/search`,
    method: 'POST'
  }
}

module.exports.eventEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/events/list`,
    method: 'POST'
  },
  listCompatibleEvents: (eventId) => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/list_compatibles`,
      method: 'POST'
    }
  },
  orderWindow: {
    url: `${SUITES_API_BASE_URL}/events/:eventId/order_window`,
    method: 'GET'
  },
  edit: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/edit`,
      method: 'GET'
    }
  },
  byId: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}`,
      method: 'GET'
    }
  },
  update: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}`,
      method: 'PUT'
    }
  },
  hotSheet: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/hotsheet`,
      method: 'GET'
    }
  },
  eventEgineEvents: {
    url: `${EE_API_BASE_URL}/events`,
    method: 'GET'
  },
  activate: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/activate`,
      method: 'POST'
    }
  },
  deactivate: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/deactivate`,
      method: 'POST'
    }
  },
  moveOrders: eventId => {
    return {
      url: `${SUITES_API_BASE_URL}/events/${eventId}/move_orders`,
      method: 'POST'
    }
  }
}

module.exports.eventsSuitesEndpoints = {
  add: {
    url: `${SUITES_API_BASE_URL}/events_suites`,
    method: 'POST'
  },
  suites: eventId => ({
    url: `${SUITES_API_BASE_URL}/events_suites/list`,
    method: 'POST'
  }),
  update: eventSuiteId => ({
    url: `${SUITES_API_BASE_URL}/events_suites/${eventSuiteId}`,
    method: 'PUT'
  }),
  byId: eventSuiteId => ({
    url: `${SUITES_API_BASE_URL}/events_suites/${eventSuiteId}`,
    method: 'GET'
  }),
  voidReservation: eventSuiteId => ({
    url: `${SUITES_API_BASE_URL}/events_suites/${eventSuiteId}/void_reservation`,
    method: 'GET'
  })
}

module.exports.companyEndpoints = {
  byId: companyId => ({
    url: `${SUITES_API_BASE_URL}/companies/${companyId}`,
    method: 'GET'
  }),
  list: {
    url: `${SUITES_API_BASE_URL}/companies/list`,
    method: 'POST'
  },
  getSuites: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/suites`,
    method: 'GET'
  },
  getUsers: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/suites/:suiteId/users`,
    method: 'GET'
  },
  getEvents: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/suites/:suiteId/assigned_events`,
    method: 'POST'
  },
  getUsersByCompany: companyId => ({
    url: `${SUITES_API_BASE_URL}/companies/${companyId}/users`,
    method: 'POST'
  }),
  getEventsForSuite: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/suites/:suiteId/events`,
    method: 'POST'
  },
  updateCompanySuiteEvents: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/suites/:suiteId/events`,
    method: 'PUT'
  },
  fetchCompanyToEdit: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId/edit`,
    method: 'GET'
  },
  updateCompany: {
    url: `${SUITES_API_BASE_URL}/companies/:companyId`,
    method: 'PUT'
  },
  deleteCompanySuite: {
    url: `${SUITES_API_BASE_URL}/company_suites/:companySuiteId/share_types/:shareTypeId`,
    method: 'DELETE'
  }
}

module.exports.staffUsersEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/users/list`,
    method: 'POST'
  },
  addUser: {
    url: `${SUITES_API_BASE_URL}/users`,
    method: `POST`
  },
  updateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/users/${userId}`,
    method: `PUT`
  }),
  activateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/users/${userId}/activate`,
    method: `PUT`
  }),
  deactivateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/users/${userId}/deactivate`,
    method: `PUT`
  })
}

module.exports.suiteHolderUsersEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/suite_holder_users/list`,
    method: 'POST'
  },
  paymentDetails: {
    url: `${SUITES_API_BASE_URL}/suite_holder_users/:suiteHolderUserId/payment_details`,
    method: 'GET'
  },
  updateCard: {
    url: `${SUITES_API_BASE_URL}/suite_holder_users/:suiteHolderUserId`,
    method: 'PUT'
  },
  fetchById: suiteHolderUserId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder_users/${suiteHolderUserId}/edit`,
    method: `GET`
  }),
  activateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder_users/${userId}/activate`,
    method: `PUT`
  }),
  deactivateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder_users/${userId}/deactivate`,
    method: `PUT`
  }),
  addUser: {
    url: `${SUITES_API_BASE_URL}/suite_holder_users`,
    method: `POST`
  },
  updateUser: userId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder_users/${userId}`,
    method: `PUT`
  }),
  deleteClientCreditCard: currentCardIdToDelete => ({
    url: `${SUITES_API_BASE_URL}/credit_cards/${currentCardIdToDelete}`,
    method: 'DELETE'
  }),
  recommendations: userId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder_users/${userId}/recommended_items`,
    method: 'POST'
  })
}

module.exports.suitesEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/suites/list`,
    method: 'POST'
  },
  editById: {
    url: `${SUITES_API_BASE_URL}/suites/:suiteId/edit`,
    method: 'GET'
  }
}

module.exports.shareTypesEndpoints = {
  availableEvents: {
    url: `${SUITES_API_BASE_URL}/suite_shares/available_events`,
    method: 'POST'
  },
  list: {
    url: `${SUITES_API_BASE_URL}/suite_shares/list`,
    method: 'POST'
  },
  edit: id => ({
    url: `${SUITES_API_BASE_URL}/suite_shares/${id}/edit`,
    method: 'GET'
  }),
  create: {
    url: `${SUITES_API_BASE_URL}/suite_shares`,
    method: 'POST'
  },
  update: id => ({
    url: `${SUITES_API_BASE_URL}/suite_shares/${id}`,
    method: 'PUT'
  })
}

module.exports.clientEventsEndpoint = (suiteHolderUserId) => {
  return {
    list: () => ({
      url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/events/list`,
      method: 'POST'
    }),
    byId: eventId => ({
      url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/events/${eventId}`,
      method: 'GET'
    }),
    settings: () => ({
      url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/settings`,
      method: 'GET'
    }),
    addClientCreditCard: () => ({
      url: `${SUITES_API_BASE_URL}/credit_cards/secure_hash`,
      method: 'GET'
    }),
    updateNotificationPreferences: () => ({
      url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/notification_preferences`,
      method: 'PUT'
    }),
    deleteClientCreditCard: currentCardIdToDelete => ({
      url: `${SUITES_API_BASE_URL}/credit_cards/${currentCardIdToDelete}`,
      method: 'DELETE'
    })
  }
}

module.exports.clientOrdersEndpoint = {
  list: suiteHolderUserId => ({
    url: `${SUITES_API_BASE_URL}/suite_holder/${suiteHolderUserId}/orders/list`,
    method: 'POST'
  })
}

module.exports.reportEndpoints = {
  reconciliation: {
    method: 'POST',
    url: `${SUITES_API_BASE_URL}/reports/reconciliation`
  },
  invoice: {
    method: 'POST',
    url: `${SUITES_API_BASE_URL}/reports/invoice`
  },
  exportReconciliation: {
    method: 'POST',
    url: `${SUITES_API_BASE_URL}/reports/export_reconciliation`
  },
  exportInvoice: {
    method: 'POST',
    url: `${SUITES_API_BASE_URL}/reports/export_invoice`
  }
}

module.exports.menuCategoriesEndpoints = {
  list: {
    url: `${SUITES_API_BASE_URL}/menu_categories/list`,
    method: 'POST'
  },
  create: {
    url: `${SUITES_API_BASE_URL}/menu_categories`,
    method: 'POST'
  },
  update: id => ({
    url: `${SUITES_API_BASE_URL}/menu_categories/${id}`,
    method: 'PUT'
  }),
  delete: menuCategoryId => ({
    url: `${SUITES_API_BASE_URL}/menu_categories/${menuCategoryId}`,
    method: 'DELETE'
  })
}

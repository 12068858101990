import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

const LoginLayout = ({ component: Component, route }) => (
  <Grid container>
    <Grid item xs={12} />
    <Grid item xs={12}>
      <Component route={route} />
    </Grid>
    <Grid item xs={12} />
  </Grid>
)

LoginLayout.propTypes = {
  component: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
}

export default LoginLayout

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './VoidEventSuiteReservationContent.css'

const VoidEventSuiteReservationContent = ({
  classes
}) => {
  return (
    <div>
      Please note that voiding reservation in suites portal <span className={classes.warningText}> will not</span> do the following:
      <ul>
        <li>
          Delete the opportunity record from Salesforce.
        </li>
        <li>
          Refund the transaction associated with the booking.
        </li>
      </ul>
    </div>
  )
}

VoidEventSuiteReservationContent.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(VoidEventSuiteReservationContent)

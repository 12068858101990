import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { fetchAssignedSuitesToCurrentEvent } from '../../store/eventSuites/actions'
import Dialog from '../../elements/Dialog/Dialog'
import EditEventSuiteTitle from '../EditEventSuite/EditEventSuiteTitle'
import styles from './CreateEventSuite.css'
import CreateEventSuiteDialogContent from './CreateEventSuiteDialogContent'

const getDialogContent = (suites, eventId, eventSuitesList, handleSubmit, handleCancel) => {
  return (
    <CreateEventSuiteDialogContent
      suites={suites}
      eventId={eventId}
      eventSuitesList={eventSuitesList}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
    />
  )
}

const getTitle = (classes, actionMode) =>
  <EditEventSuiteTitle className={classes.editEventSuiteTitle} actionMode={actionMode} />

const CreateEventSuite = ({
  openDialog,
  classes,
  eventId,
  eventSuitesList = [],
  fetchAssignedSuitesToCurrentEvent,
  handleDialogClose,
  handleSubmit,
  suitesAssignedToCurrentEvent,
  suites
}) => {
  useEffect(() => {
    if (openDialog) {
      fetchAssignedSuitesToCurrentEvent(eventId)
    }
  }, [eventId, fetchAssignedSuitesToCurrentEvent, openDialog])

  const [availableSuites, setAvailableSuites] = useState([])

  useEffect(() => {
    let availableSuitesForSER = []
    if (suites && suitesAssignedToCurrentEvent) {
      availableSuitesForSER = suites.filter(suite => suite.is_available_for_ser === true)
      suitesAssignedToCurrentEvent.forEach(assignedSuite => {
        const existingSuiteIndex = availableSuitesForSER.findIndex(suite => suite.id === assignedSuite.suite_id)
        if (existingSuiteIndex > -1) {
          availableSuitesForSER.splice(existingSuiteIndex, 1)
        }
      })
    }
    setAvailableSuites(availableSuitesForSER)
  }, [suites, suitesAssignedToCurrentEvent])

  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(classes, 'Add')}
      dialogContent={getDialogContent(availableSuites, eventId, eventSuitesList, handleSubmit, handleDialogClose)}
      dialogActions={null}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

const mapStateToProps = state => {
  return {
    suites: state.staticData.suites,
    eventSuitesList: state.eventSuitesData.eventSuitesList,
    suitesAssignedToCurrentEvent: state.eventSuitesData.suitesAssignedToCurrentEvent
  }
}

const mapDispatchToProps = {
  fetchAssignedSuitesToCurrentEvent
}

CreateEventSuite.propTypes = {
  classes: PropTypes.object,
  fetchAssignedSuitesToCurrentEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  eventSuitesList: PropTypes.array,
  handleDialogClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  openDialog: PropTypes.bool,
  suites: PropTypes.array,
  suitesAssignedToCurrentEvent: PropTypes.array
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventSuite))

export const validateEmail = (emailId) => {
  const emailRegEx = /^([\w-]+(?:(\.|\')[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i // eslint-disable-line
  return emailRegEx.test(emailId)
}

export const isBlank = (fieldValue) => (!fieldValue || /^\s*$/.test(fieldValue))

const passwordminLengthRequirements = 12

export const checkProperties = (obj) => {
  for (var key in obj) {
    if (isBlank(obj[key])) { return false }
  }
  return true
}

export const validatePasswordRequirementsMatch = (password) => {
  var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/
  return re.test(password)
}

export const isPasswordsMatch = (newPassword, confirmationPassword) => {
  return newPassword === confirmationPassword
}

export const validatePassword = (newPassword, confirmationPassword, resetToken) => {
  const passwordsMatch = isPasswordsMatch(newPassword, confirmationPassword)
  const passwordsMatchRequirement = validatePasswordRequirementsMatch(newPassword) && validatePasswordRequirementsMatch(confirmationPassword)
  return passwordsMatch && passwordsMatchRequirement && resetToken
}

export const getPasswordRequirements = () => {
  return [
    '' + passwordminLengthRequirements + ' characters',
    'one uppercase letter',
    'one lowercase letter',
    'one number',
    'must not include your name'
  ]
}

export const getParameterByKey = (key) => {
  const vars = []; var hash
  const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=')
    vars.push(hash[0])
    vars[hash[0]] = hash[1]
  }
  return vars[key]
}

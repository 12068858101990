const styles = {
  billSummaryLineItem: {
    color: 'black',
    display: 'flex',
    padding: '10px 24px 10px 30px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '15px'
  },
  paymentSummaryLineItem: {
    color: 'black',
    display: 'flex',
    padding: '10px 24px 10px 30px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px'
  },
  label: {
    width: '353px'
  },
  value1: {
    width: '90px',
    textAlign: 'right'
  },
  value2: {
    width: '90px',
    textAlign: 'right'
  }
}

export default styles

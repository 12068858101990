import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import styles from './ManageSuiteHolderUser.css'
import Content from './Content'
import Actions from './Actions'

const getDialogContent = (
  classes,
  suiteHolderUser,
  companies,
  suites,
  rolesList,
  setFirstName,
  setLastName,
  setEmail,
  setContactNumber,
  setRole,
  handleSuitesUpdate,
  handleSuitesInvoiceUpdate,
  paymentDetails,
  handleCardDelete,
  cardDeletionErr,
  companyId,
  dialogActions
) => {
  return <Content
    classes={classes}
    suiteHolderUser={suiteHolderUser}
    suites={suites}
    companies={companies}
    rolesList={rolesList}
    setFirstName={setFirstName}
    setLastName={setLastName}
    setEmail={setEmail}
    setContactNumber={setContactNumber}
    setRole={setRole}
    handleSuitesUpdate={handleSuitesUpdate}
    handleSuitesInvoiceUpdate={handleSuitesInvoiceUpdate}
    paymentDetails={paymentDetails}
    handleCardDelete={handleCardDelete}
    cardDeletionErr={cardDeletionErr}
    companyId={companyId}
    dialogActions={dialogActions}
  />
}

const getDialogActions = (
  suiteHolderUser,
  classes,
  saveSuiteHolderUser,
  handleDialogClose,
  handleUserStatus,
  validationError,
  showCreditCardAddedMessage,
  creditCardAddedMessage
) => {
  return <Actions
    classes={classes}
    suiteHolderUser={suiteHolderUser}
    saveSuiteHolderUser={saveSuiteHolderUser}
    handleCancel={handleDialogClose}
    handleUserStatus={handleUserStatus}
    validationError={validationError}
    showCreditCardAddedMessage={showCreditCardAddedMessage}
    creditCardAddedMessage={creditCardAddedMessage}
  />
}

const ManageSuiteHolderUser = ({
  classes,
  companies,
  creditCardAddedMessage,
  cardDeletionErr,
  companyId,
  handleUserStatus,
  handleDialogClose,
  handleSuitesUpdate,
  handleSuitesInvoiceUpdate,
  handleCardDelete,
  mode,
  openDialog,
  paymentDetails,
  rolesList,
  showCreditCardAddedMessage,
  saveSuiteHolderUser,
  suiteHolderUser = {},
  suites,
  setFirstName,
  setLastName,
  setEmail,
  setContactNumber,
  setRole,
  validationError
}) => {
  const dialogActions = getDialogActions(suiteHolderUser, classes, saveSuiteHolderUser, handleDialogClose, handleUserStatus, validationError, showCreditCardAddedMessage, creditCardAddedMessage)

  const dialogContent = getDialogContent(classes, suiteHolderUser, companies, suites, rolesList, setFirstName, setLastName, setEmail, setContactNumber, setRole, handleSuitesUpdate, handleSuitesInvoiceUpdate, paymentDetails, handleCardDelete, cardDeletionErr, companyId, dialogActions)
  return (
    <Dialog
      openDialog={openDialog}
      dialogContent={dialogContent}
      displayTabs
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

ManageSuiteHolderUser.propTypes = {
  companyId: PropTypes.number,
  classes: PropTypes.object,
  creditCardAddedMessage: PropTypes.string,
  cardDeletionErr: PropTypes.object,
  companies: PropTypes.array,
  handleDialogClose: PropTypes.func,
  handleUserStatus: PropTypes.func,
  handleSuitesUpdate: PropTypes.func,
  handleCardDelete: PropTypes.func,
  handleSuitesInvoiceUpdate: PropTypes.func,
  mode: PropTypes.string,
  openDialog: PropTypes.bool,
  paymentDetails: PropTypes.array,
  rolesList: PropTypes.array,
  suiteHolderUser: PropTypes.object,
  saveSuiteHolderUser: PropTypes.func,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  setContactNumber: PropTypes.func,
  setRole: PropTypes.func,
  suites: PropTypes.array,
  showCreditCardAddedMessage: PropTypes.bool,
  validationError: PropTypes.string
}

export default withStyles(styles)(ManageSuiteHolderUser)

const styles = {
  tableCell: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
  },
  tableRow: {
    height: '65px',
    position: 'relative',
    transform: 'none'
  },
  linkToDetailsPage: {
    color: 'white',
    textDecoration: 'none'
  }
}

export default styles

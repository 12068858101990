import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import { withStyles } from '@material-ui/core'
import {
  FirstLineCategories,
  SecondLineCategories
} from '../../constants/menuCatalog'
import {
  ANALYTICS_ACTION_FILTER_CLICK,
  ANALYTICS_PAGE_TYPE_MENU_CATALOG,
  ANALYTICS_ACTION_LOCATION_HEADER,
  ANALYTICS_ACTION_FILTER_SELECTION,
  ANALYTICS_ACTION_TYPE_CATEGORY_FILTER
} from '../../constants/analytics'
import { ALL_CATEGORIES } from '../../constants/menuItems'
import { reportAnalytics } from '../../utils/analytics'
import styles from './MenuCategories.css'

const categoryItems = (categories, menuCategories) => {
  return categories.filter((category) => (menuCategories.indexOf(category) > -1) || (category === 'All'))
}

const getCategoriesList = (classes, categories, handleCategorySelection, selectedCategories, handleAllSelections, menuCategories) => {
  const uniqueCategories = categoryItems(categories, menuCategories)
  return <div className={classes.menuCategoriesLine}>
    {
      uniqueCategories.map(category => {
        const classNames = `${selectedCategories.includes(category) ? classes.activeMenuCategory : classes.inactiveMenuCategory}`
        return <Fab variant="extended" key={category} className={classNames} onClick={() => category === ALL_CATEGORIES ? handleAllSelections(category) : handleCategorySelection(category)}> {category} </Fab>
      })
    }
  </div>
}

const categoryAlreadySelected = (category, selectedCategories) => selectedCategories.indexOf(category) > -1
const isSearchActive = (menuSearchQuery) => menuSearchQuery.length > 0

const getFilteredItems = (selectedCategories, menuItems) => {
  const menuItemsBySelectedCategories = {}
  selectedCategories.forEach(category => {
    menuItemsBySelectedCategories[category] = menuItems[category]
  })
  return menuItemsBySelectedCategories
}

const getIndexOfSelectedCategory = (category, selectedCategories) => selectedCategories.findIndex(selectedCategory => selectedCategory === category)
const MenuCategories = ({
  classes,
  menuSearchQuery,
  menuItemsByCategory,
  menuItemsCatalogBySuite,
  uniqueCategories,
  selectedCategories,
  setSelectedCategories,
  setMenuItemsByCategory,
  clearResults
}) => {
  function handleCategorySelection (category) {
    let filteredItems = []
    const itemsToFilter = isSearchActive(menuSearchQuery) ? menuItemsByCategory : menuItemsCatalogBySuite
    if (categoryAlreadySelected(category, selectedCategories)) {
      const selectedCategoryIndex = getIndexOfSelectedCategory(category, selectedCategories)
      selectedCategories.splice(selectedCategoryIndex, 1)
      filteredItems = getFilteredItems(selectedCategories, itemsToFilter)
    } else {
      selectedCategories.push(category)
      reportAnalytics({
        eventType: ANALYTICS_ACTION_FILTER_CLICK,
        detail: {
          actionName: ANALYTICS_ACTION_FILTER_SELECTION,
          actionLocation: ANALYTICS_ACTION_LOCATION_HEADER,
          actionType: ANALYTICS_ACTION_TYPE_CATEGORY_FILTER,
          actionFilterselected: category,
          actionFilterallselected: selectedCategories.join(',')
        },
        pageType: ANALYTICS_PAGE_TYPE_MENU_CATALOG
      })
      filteredItems = getFilteredItems(selectedCategories, itemsToFilter)
    }
    selectedCategories.length > 0 ? setMenuItemsByCategory(filteredItems) : setMenuItemsByCategory(menuItemsCatalogBySuite)
    setSelectedCategories(selectedCategories.filter(item => item !== ALL_CATEGORIES).slice())
  }

  function handleAllSelections (category) {
    if (categoryAlreadySelected(category, selectedCategories)) {
      const selectedCategoryIndex = getIndexOfSelectedCategory(category, selectedCategories)
      selectedCategories.splice(selectedCategoryIndex, 1)
    } else {
      selectedCategories.push(category)
    }
    setSelectedCategories(selectedCategories.filter(item => item === ALL_CATEGORIES).slice())
    clearResults()
  }

  return (
    <div className={classes.menuCategoriesContainer}>
      <div className={classes.title}>Filters</div>
      { menuItemsByCategory && uniqueCategories && getCategoriesList(classes, FirstLineCategories, handleCategorySelection, selectedCategories, handleAllSelections, uniqueCategories) }
      { menuItemsByCategory && uniqueCategories && getCategoriesList(classes, SecondLineCategories, handleCategorySelection, selectedCategories, handleAllSelections, uniqueCategories) }
    </div>
  )
}

MenuCategories.propTypes = {
  classes: PropTypes.object,
  menuSearchQuery: PropTypes.string,
  menuItemsByCategory: PropTypes.object,
  menuItemsCatalogBySuite: PropTypes.object,
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  setMenuItemsByCategory: PropTypes.func,
  clearResults: PropTypes.func,
  uniqueCategories: PropTypes.array
}

export default withStyles(styles)(MenuCategories)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import InputField from '../../elements/InputField/InputField'
import SwitchButton from '../../elements/SwitchButton/SwitchButton'
import styles from './ViewEventSuiteContent.css'

const ViewEventSuiteContent = ({
  classes,
  eventSuite = {}
}) => {
  const [allowAdditionalPasses, setAllowAdditionalPasses] = useState(false)
  const [additionalPasses, setAdditionalPasses] = useState('')
  const [allowFoodOrder, setAllowFoodOrder] = useState(false)
  const [availableSeats, setAvailableSeats] = useState()
  const [costPerPass, setCostPerPass] = useState('')
  const [isFeatured, setIsFeatured] = useState(false)
  const [isReserved, setIsReserved] = useState(false)
  const [name, setName] = useState()
  const [opportunityId, setOpportunityId] = useState()
  const [price, setPrice] = useState()

  useEffect(() => {
    if (eventSuite) {
      const {
        availableSeats,
        additionalPassCost,
        additionalAvailablePasses,
        allowAdditionalPasses,
        allowFoodOrder,
        isFeatured,
        isReserved,
        opportunityId,
        price,
        suiteName
      } = eventSuite
      setAdditionalPasses(additionalAvailablePasses)
      setAllowAdditionalPasses(allowAdditionalPasses)
      setAllowFoodOrder(allowFoodOrder)
      setAvailableSeats(availableSeats)
      setCostPerPass(additionalPassCost)
      setIsFeatured(isFeatured)
      setIsReserved(isReserved)
      setName(suiteName)
      setOpportunityId(opportunityId)
      setPrice(`${price}`)
    }
  }, [eventSuite])

  return (
    <div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          disabled
          id={'ViewEventSuiteName'}
          label={'SUITE NAME'}
          placeholder={'Name'}
          showLabel
          value={name}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          disabled
          id={'opportunityId'}
          label={'Oppotunity ID'}
          showLabel
          value={opportunityId}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          disabled={isReserved}
          id={'ViewEventSuitePrice'}
          label={'PRICE'}
          placeholder={''}
          showLabel
          value={price}
        />
      </div>
      <div className={classes.inputContainer}>
        <SwitchButton
          checked={isFeatured}
          disabled={isReserved}
          id="is-featured-suite"
          label="FEATURED SUITE"
          onChange={(event) => setIsFeatured(event.target.checked)}
        />
      </div>
      <div className={classes.inputContainer}>
        <SwitchButton
          checked={allowFoodOrder}
          disabled={isReserved}
          id="allow-food-order"
          label="ALLOW F&B ORDERING"
          onChange={(event) => setAllowFoodOrder(event.target.checked)}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          id={'createEventSuiteAvailableSeats'}
          label={'AVAILABLE SEATS'}
          placeholder={''}
          showLabel
          value={availableSeats}
          disabled={isReserved}
        />
      </div>
      <div className={classes.inputContainer}>
        <SwitchButton
          checked={allowAdditionalPasses}
          disabled={isReserved}
          id="allow-food-order"
          label="ALLOW ADDITIONAL PASSES"
        />
      </div>
      {
        allowAdditionalPasses &&
        <>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteAvailableSeats'}
              label={'ADDITIONAL PASSES'}
              placeholder={''}
              showLabel
              value={additionalPasses}
            />
          </div>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteCostPerPass'}
              label="COST PER ADDITIONAL PASS"
              placeholder={''}
              showLabel
              value={costPerPass}
            />
          </div>
        </>
      }
    </div>
  )
}

ViewEventSuiteContent.propTypes = {
  classes: PropTypes.object,
  eventSuite: PropTypes.object
}

export default withStyles(styles)(ViewEventSuiteContent)


import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles({
  searchIcon: {
    fontSize: '28px',
    marginTop: '21px'
  },
  searchIconContainer: {
    height: '50px',
    paddingRight: '10px',
    cursor: 'pointer'
  },
  searchTextFieldContainer: props => ({
    display: 'inline-flex',
    '& div > div': {
      width: props.styles.searchFieldWidth
    }
  })
}, { index: 1 })

export default useStyles

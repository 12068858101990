import {
  FETCH_MENU_ITEMS,
  CLEAR_MENU_ITEMS,
  GET_MENU_ITEM_BY_ID_TO_EDIT,
  UPDATE_MENU_ITEMS_LIST,
  FETCH_MENU_CATEGORIES,
  CREATE_MENU_CATEGORIES,
  DELETE_MENU_CATEGORIES,
  UPDATE_MENU_CATEGORIES,
  REST_MENU_CATEGORIES_UPDATE_MESSAGE
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'
import { getSecureImageUrl, updateMenuImageUrls } from '../../utils/imageUtils'
import { DEFAULT_IMAGE_URL } from '../../constants/events'

const initialState = {}

const MenuItemStatus = {
  Active: 'Active',
  Inactive: 'Inactive'
}

const getSupportedEventTypes = (eventCategories) => {
  let supportedCategotiesName
  const assignedCategories = eventCategories.filter(eventCategory => eventCategory.is_assigned)
  const eventCategoriesCount = eventCategories.length
  const assignedCategoriesCount = assignedCategories.length
  if (assignedCategoriesCount < eventCategoriesCount) {
    supportedCategotiesName = assignedCategories.map(category => category.category_name).join(',')
  } else {
    supportedCategotiesName = 'All'
  }
  return supportedCategotiesName
}

const getPrices = (variants) => {
  return variants.map(variant => {
    const { suite_location_id: locationId, price, location_name: locationName } = variant
    return {
      locationId,
      price,
      locationName
    }
  }).sort((a, b) => a.locationId - b.locationId)
}

const getStatus = (webStatus) => {
  return webStatus === true ? MenuItemStatus.Active : MenuItemStatus.Inactive
}

const getImageUrl = (item) => {
  let imageUrl
  try {
    imageUrl = item.image.thumbnail.url || item.image.thumbnail.image.url || DEFAULT_IMAGE_URL
  } catch (error) {
    imageUrl = DEFAULT_IMAGE_URL
  }
  return getSecureImageUrl(imageUrl)
}

const processItemData = (item) => {
  const {
    id,
    web_status: webStatus,
    web_name: webName,
    event_categories: eventCategories,
    advance_price: advancePrice,
    game_day_price: gameDayPrice
  } = item

  return {
    id,
    status: getStatus(webStatus),
    imageUrl: getImageUrl(item),
    webname: webName,
    eventType: getSupportedEventTypes(eventCategories),
    categories: item.categories.map(categoryObject => categoryObject.parent_category_name).join(','),
    advanceOrderPrice: getPrices(advancePrice),
    gamedayOrderPrice: getPrices(gameDayPrice)
  }
}

const processMenuItemForEdit = (item) => {
  const { image } = item
  const clonedImageObj = { ...image }
  updateMenuImageUrls(clonedImageObj)
  return {
    ...item,
    ...{
      image: clonedImageObj
    }
  }
}

const updateMenuItemsList = (payload) => {
  const { menuItemsList, menuItem } = payload
  const { rows } = menuItemsList
  const indexOfUpdateItem = rows.findIndex(item => item.id === menuItem.id)
  rows[indexOfUpdateItem] = processItemData(menuItem)
  menuItemsList.rows = rows.slice()
  return menuItemsList
}

export default function MenuItemsReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_MENU_ITEMS:
      return {
        ...state,
        menuItemsList: processItemsList(action.payload, processItemData)
      }
    case CLEAR_MENU_ITEMS:
      return {
        ...state,
        menuItemsList: {}
      }
    case GET_MENU_ITEM_BY_ID_TO_EDIT:
      return {
        ...state,
        menuItemToEdit: processMenuItemForEdit(action.payload)
      }
    case UPDATE_MENU_ITEMS_LIST:
      return {
        ...state,
        menuItemsList: updateMenuItemsList(action.payload)
      }
    case FETCH_MENU_CATEGORIES:
      return {
        ...state,
        menuCategories: action.payload
      }
    case CREATE_MENU_CATEGORIES:
      return {
        ...state,
        menuCategoriesMessage: action.payload
      }
    case DELETE_MENU_CATEGORIES:
      return {
        ...state,
        menuCategoriesMessage: action.payload
      }
    case UPDATE_MENU_CATEGORIES:
      return {
        ...state,
        menuCategoriesMessage: action.payload
      }
    case REST_MENU_CATEGORIES_UPDATE_MESSAGE:
      return {
        ...state,
        menuCategoriesMessage: null
      }
    default:
      return state
  }
}

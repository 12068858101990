import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import styles from '../Login/Login.css'
import { validateEmail } from '../../utils/validations'
import { resetPassword, logoutUser } from '../../store/authentication/actions'

const ForgotPassword = ({ classes, resetPassword, logoutUser, resetPwd }) => {
  const [email, setEmail] = useState('')
  const [requestedForPassword, setRequestedForPassword] = useState(false)

  useEffect(() => {
    if (resetPwd) {
      setRequestedForPassword(true)
    }
  }, [resetPwd])

  const handleInputChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const user = {
      email
    }
    resetPassword({ user })
  }

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.headerGrid}>
          <div className={classes.logoContainer}>
            <img src="/msgLogo.png" alt="" className={classes.suitesImage} />
          </div>
          <div className={classes.suitesBackground} />
        </div>
        <div className={classes.contentContainer}>
          <div>
            <h2 className={classes.signInHeaderText}>Forgot Password</h2>
            <div className={classes.formContainer}>
              {
                !requestedForPassword && <form onSubmit={handleSubmit}>
                  <TextField
                    id="outlined-email-input"
                    label=""
                    className={classes.textField}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    placeholder="EMAIL ADDRESS"
                    onChange={handleInputChange}
                    value={email}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <div className={classes.signInButtonContainer}>
                    <Button variant="contained" type="submit" color="primary" disabled={!validateEmail(email)}>
                      Send
                    </Button>
                  </div>
                </form>
              }
              {
                requestedForPassword && <div className={classes.forgotPasswordAckContainer}>
                  <Typography component="h6" variant="h6">
                    Email has been sent. Please check your email to reset password.
                  </Typography>
                  <div className={classes.signInButtonContainer}>
                    <Button variant="contained" type="submit" color="primary" onClick={logoutUser}>
                      Ok
                    </Button>
                  </div>
                </div>
              }
              <Button variant="contained" type="submit" color="primary" onClick={logoutUser} className={classes.forgotPassWordButton}>
                Back to Login
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  resetPwd: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  resetPwd: state.authReducer.resetPwd
})

export default withStyles(styles)(connect(mapStateToProps, { resetPassword, logoutUser })(ForgotPassword))

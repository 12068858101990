import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomFabButton from '../CustomFabButton/CustomFabButton'

const SECTION_LABEL = 'Items may be added to this check by'
const VALIDATION_ERROR_MESSAGE = 'You must complete this field.'

const getItemAddtionsByOptions = (classes, itemAdditionsByOptions, itemAdditionsBy, setItemAdditionsBy) => {
  return itemAdditionsByOptions.map(option => {
    return <CustomFabButton
      key={option.id}
      variant="extended"
      color="default"
      styleClasses={`${classes.genericFabPill} ${itemAdditionsBy === option.id ? classes.activeFabPill : ''}`}
      disableRipple
      disableFocusRipple
      label={option.value}
      value={option.id}
      clickHandler={setItemAdditionsBy}
    />
  })
}
const ItemAdditionOptions = ({
  classes,
  itemAdditionsByOptions,
  itemAdditionsBy,
  setItemAdditionsBy,
  showValidationErrorMessage
}) => {
  return (
    <div className={classes.paymentMethodOptionsContainer}>
      <div className={classes.sectionTitle}>
        {SECTION_LABEL.toUpperCase()}
      </div>
      <div className={classes.paymentMethodButtonsContainer}>
        {getItemAddtionsByOptions(classes, itemAdditionsByOptions, itemAdditionsBy, setItemAdditionsBy)}
      </div>
      {
        showValidationErrorMessage &&
        !itemAdditionsBy &&
        <FormHelperText className={classes.inputTextHelper}>
          {VALIDATION_ERROR_MESSAGE}
        </FormHelperText>
      }
    </div>
  )
}

ItemAdditionOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  itemAdditionsByOptions: PropTypes.array,
  itemAdditionsBy: PropTypes.number,
  setItemAdditionsBy: PropTypes.func.isRequired,
  showValidationErrorMessage: PropTypes.bool
}

ItemAdditionOptions.defaultProps = {
  showValidationErrorMessage: false,
  itemAdditionsBy: null,
  itemAdditionsByOptions: []
}

export default ItemAdditionOptions

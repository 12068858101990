import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import idx from 'idx'
import styles from './SearchMenuItems.css'
const SearchMenuItems = ({
  classes,
  fetchMenuItems,
  handleMenuSelection
}) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (searchText && searchText.length > 2) {
      setTimeout(() => {
        fetchMenuItems(searchText).then(response => {
          const { data } = response
          const { menu_items: menuItems } = data
          setAutoCompleteOptions(menuItems)
          setLoading(false)
        })
      }, 500)
    } else {
      setLoading(false)
      setAutoCompleteOptions([])
    }
  }, [searchText, fetchMenuItems])

  const handleInputChange = (event) => {
    const searchText = idx(event, _ => _.target.value) || ''
    setSearchText(searchText)
    setLoading(true)
  }

  const handleChange = (event, value) => {
    if (!value) return
    handleMenuSelection(value)
    setSearchText('')
    setAutoCompleteOptions([])
  }

  return (
    <div className={classes.autoCompleteContainer}>
      <Autocomplete
        id="auto-complete"
        classes={{
          paper: classes.paper,
          input: classes.input,
          endAdornment: classes.endAdornment,
          popupIndicator: classes.popupIndicator,
          noOptions: classes.noOptions
        }}
        options={autoCompleteOptions}
        getOptionLabel={option => option.web_name}
        onInputChange={handleInputChange}
        onChange={handleChange}
        includeInputInList
        inputValue={searchText}
        loading={loading}
        noOptionsText="No items found"
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Quick Add"
            className={classes.autoCompleteTextField}
            margin="normal"
            fullWidth
          />
        )}
      />
    </div>
  )
}

SearchMenuItems.propTypes = {
  classes: PropTypes.object,
  fetchMenuItems: PropTypes.func,
  handleMenuSelection: PropTypes.func
}

export default withStyles(styles)(SearchMenuItems)

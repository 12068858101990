import styled from 'styled-components'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

export const ItemList = styled.ul`
  background-color: #eee;
  flex-grow: 1;
  margin: 0;
  overflow-y: auto;
  padding: 20px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border: none;
    background-color: rgba(0, 0, 0, .5);
  }


  li {
    list-style-type: none;
  }
`
export const Label = styled(FormControlLabel)`
  font-family: 'Ringside Regular A', 'Ringside Regular B';
  font-size: 16px;
  font-weight: 300;
`

export const Checkbox = styled(MuiCheckbox)`
  margin-right: 16px;
`

export const Actions = styled.div`
  display: flex;
  margin-top: 20px;

  div {
    width: 50%;

    &:last-child: {
      text-align: right;
    }
  }
`

export const SelectButton = styled.button`
  background-color: #000;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Ringside Regular A', 'Ringside Regular B';
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  width: 100%;
`

export const SelectAllLabel = styled(FormControlLabel)`
  color: #000;
  font-family: 'Ringside Regular A', 'Ringside Regular B';
  font-size: 160px;
  font-weight: bold !important;
  width: 275px;
`

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  Paper
} from '@material-ui/core'
import TableDataRow from '../TableDataRow/TableDataRow'
import TableHeader from '../TableHeader/TableHeader'
import TablePaginationActions from '../TablePaginationActions/TablePaginationActions'
import SettingsMenu from '../SettingsMenu/SettingsMenu'
import theme from '../../theme'
import styles from './Table.css'

const CustomPaginationActionsTable = ({
  classes,
  properties,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSorting,
  rows,
  rowsPerPage,
  page,
  rowsPerPageOptions,
  totalPages,
  rowsFor,
  resourceName,
  sortParams,
  settingsMenuBuilder
}) => (
  <MuiThemeProvider theme={theme}>
    <Paper>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHeader properties={properties} rowsFor={rowsFor} handleSorting={handleSorting} sortParams={sortParams} />
          <TableBody>
            {
              rows && rows.map(row => {
                const settingsMenu = settingsMenuBuilder
                  ? <SettingsMenu settingsMenuBuilder={settingsMenuBuilder} item={row} />
                  : null
                return <TableDataRow key={row.id} row={row} properties={properties} settingsMenu={settingsMenu} rowsFor={rowsFor} resourceName={resourceName} />
              })
            }
          </TableBody>
          {
            rowsPerPageOptions &&
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    className={classes.pagination}
                    colSpan={10}
                    count={+totalPages * rowsPerPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    classes={{
                      caption: classes.tablePaginationCaption,
                      selectIcon: classes.tablePaginationSelectIcon,
                      select: classes.tablePaginationSelect,
                      actions: classes.tablePaginationActions
                    }}
                  />
                </TableRow>
              </TableFooter>
          }
        </Table>
      </div>

    </Paper>
  </MuiThemeProvider>
)

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  properties: PropTypes.array.isRequired,
  resourceName: PropTypes.string.isRequired,
  rows: PropTypes.array,
  rowsFor: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  /**
   * Builds an array of menu item option settings
   * @type {Function}
   * @param {Object} item The item data for a current table row.
   * @returns {Array} An array of menu item option settings.
   * @example
   *  item => [
   *   {
   *     id: item.id,
   *      label: 'Edit',
   *      action: editMenuItem
   *    },
   *    {
   *      id: item.id,
   *      label: item.status === 'Active' ? 'Deactivate' : 'Activate',
   *      action: toggleStatus
   *    }
   *  ]
   */
  settingsMenuBuilder: PropTypes.func,
  sortParams: PropTypes.object,
  totalPages: PropTypes.number.isRequired
}

export default withStyles(styles)(CustomPaginationActionsTable)

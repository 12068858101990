import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  slider_container: {
    width: '450px',
    overflow: 'hidden'
  },
  items_container: ({ currentIndex }) => ({
    width: '99999999px',
    position: 'relative',
    marginLeft: `${currentIndex * -460}px`,
    transition: 'margin 700ms'
  })
}, { index: 1 })

export default useStyles

const HTTP = 'http://'
const HTTPS = 'https://'

export const getSecureImageUrl = (imageUrl) => {
  let imageSecureUrl = ''
  if (imageUrl && imageUrl.indexOf(HTTP) > -1) {
    imageSecureUrl = imageUrl.replace(HTTP, HTTPS)
  } else {
    imageSecureUrl = imageUrl
  }
  return imageSecureUrl
}

export const updateMenuImageUrls = (menuItemImage = {}) => {
  Object.keys(menuItemImage).forEach(key => {
    if (typeof menuItemImage[key] === 'object') {
      updateMenuImageUrls(menuItemImage[key])
    } else {
      menuItemImage[key] = getSecureImageUrl(menuItemImage[key])
    }
  })
}

export const styles = {
  genericPaperStyles: {

  },
  genericDialogActionRoot: {

  },
  genericDialogContentRoot: {

  },
  genericDialogTitleRoot: {

  }
}

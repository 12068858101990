const styles = {
  activeMenuCategory: {
    boxSizing: 'border-box',
    height: '29px',
    border: '1px solid #AAAAAA',
    borderRadius: '16px',
    backgroundColor: 'white',
    marginRight: '4px',
    '& span': {
      color: '#000000',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    textTransform: 'none'
  },
  inactiveMenuCategory: {
    boxSizing: 'border-box',
    height: '29px',
    border: '1px solid #AAAAAA',
    borderRadius: '16px',
    backgroundColor: 'transparent',
    marginRight: '4px',
    textTransform: 'none',
    '& span': {
      color: '#CCCCCC',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  menuCategoriesLine: {
    marginBottom: '10px'
  },
  menuCategoriesContainer: {
    marginRight: '12px'
  },
  title: {
    height: '14px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    marginBottom: '20px',
    textTransform: 'uppercase'
  }
}

export default styles

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { fetchEvents, resetCompaniesSuiteEvents } from '../../store/companies/actions'
import styles from './CopyOrder.css'
import CopyOrderDialogContent from './CopyOrderDialogContent'
import CopyOrderDialogTitle from './CopyOrderDialogTitle'

const CopyOrder = ({
  classes,
  order,
  handleDialogClose,
  handleCopyOrder,
  eventTypes,
  openDialog,
  eventName,
  events = [],
  fetchAvailableEventsToCopy,
  resetCompaniesSuiteEvents
}) => {
  const [eventId, setEventId] = useState()

  const onEventSelect = (event) => {
    setEventId(+event.target.value)
  }

  const filterEvents = (filters) => {
    fetchAvailableEventsToCopy(order.companyId, order.suiteId, order.menuTypeId, filters)
  }

  useEffect(() => {
    if (order && events.length === 0) {
      fetchAvailableEventsToCopy(order.companyId, order.suiteId, order.menuTypeId, {})
    }

    return () => {
      resetCompaniesSuiteEvents()
    }
  }, [])

  const proceedToCheckout = () => {
    handleCopyOrder(order, eventId)
  }

  return (
    <React.Fragment>
      { order && <Dialog
        openDialog={openDialog}
        dialogTitle={
          <CopyOrderDialogTitle
            classes={classes}
            order={order}
          />
        }
        dialogContent={
          <CopyOrderDialogContent
            classes={classes}
            events={events}
            eventTypes={eventTypes}
            filterEvents={filterEvents}
            eventId={eventId}
            onEventSelect={onEventSelect}
            handleDialogClose={handleDialogClose}
            proceedToCheckout={proceedToCheckout}
          />
        }
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

CopyOrder.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleCopyOrder: PropTypes.func,
  openDialog: PropTypes.bool,
  order: PropTypes.object,
  eventTypes: PropTypes.array,
  eventName: PropTypes.string,
  events: PropTypes.array,
  fetchAvailableEventsToCopy: PropTypes.func,
  resetCompaniesSuiteEvents: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventTypes: state.staticData.event_categories,
    events: state.companiesData.companiesSuitesEvents
  }
}
const mapDispatchToProps = {
  fetchAvailableEventsToCopy: fetchEvents,
  resetCompaniesSuiteEvents
}

export default React.memo(
  withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
  )(CopyOrder))
)

const styles = {
  genericPaperStyles: {
    width: '550px',
    height: 'auto',
    display: 'flex',
    padding: '30px 40px 40px 40px',
    maxWidth: '550px',
    minHeight: 'auto',
    borderRadius: '0px',
    backgroundColor: '#fff'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '30px 40px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '0px 30px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '30px',
    color: 'black'
  },
  orderDelayMessageTitle: {
    height: '24px',
    width: '207px',
    color: '#061860',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '24px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  orderDelayMessageBody: {
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '19px'
  }
}

export default styles

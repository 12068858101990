import {
  SET_DEFAULT_DATA,
  SET_CURRENT_USER_FROM_LOCALSTORAGE
} from '../actionTypes'

export const setDefaultDataFromLocalStorage = () => dispatch => {
  const defaultDataFromLocalStorage = localStorage.getItem('defaultData') || {}
  const parsedDefaultData = JSON.parse(defaultDataFromLocalStorage)
  dispatch({
    type: SET_DEFAULT_DATA,
    payload: parsedDefaultData
  })
  dispatch({
    type: SET_CURRENT_USER_FROM_LOCALSTORAGE,
    payload: { user_details: parsedDefaultData.user_details }
  })
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import EventsBySuiteList from '../../elements/EventsBySuiteList/EventsBySuiteList'
import EventsBySuiteListFilterBar from '../../elements/EventsBySuiteListFilterBar/EventsBySuiteListFilterBar'
const filterStyles = {
  selectorWidth: '380px'
}

const styles = {
  eventFilterContainer: {
    display: 'flex',
    paddingTop: '10px'
  },
  eventSelectionContainer: {
    paddingTop: '20px'
  },
  filterContainer: {
    paddingTop: '10px',
    height: '70px'
  },
  resetEventFiltersButton: {
    backgroundColor: '#FFF',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#FFF',
      textDecoration: 'underline'
    }
  },
  eventFilterFormControl: {
    width: '150px'
  },
  inputLabel: {
    marginBottom: '10px',
    height: '28px',
    width: '351.79px',
    fontSize: '20px',
    fontWeight: 'bold'
  }
}

const CreateOrderDialogContent = ({
  classes,
  companiesList,
  companyId,
  eventId,
  eventTypes,
  events,
  filterEvents,
  handleCompanySelection,
  handleEventSelection,
  handleMenuTypeSelection,
  handleSuiteSelection,
  handleUserSelection,
  menuTypes,
  menuTypeId,
  suites,
  suiteId,
  users,
  userId
}) => {
  function onCompanySelect (event) {
    handleCompanySelection(event.target.value)
  }

  function onSuiteSelect (event) {
    handleSuiteSelection(companyId, event.target.value)
  }

  function onUserSelect (event) {
    handleUserSelection(event.target.value)
  }

  function onMenuTypeSelect (event) {
    handleMenuTypeSelection(event.target.value)
  }

  function onEventSelect (event) {
    handleEventSelection(event.target.value)
  }

  function filterEventsList (filters = {}) {
    filterEvents(filters)
  }

  return (
    <Fragment>
      {getFilterSelector('Select Company', 'company', companyId, onCompanySelect, companiesList, 'name', filterStyles, false)}
      {getFilterSelector('Select Suite', 'suite', suiteId, onSuiteSelect, suites, 'name', filterStyles, false)}
      {getFilterSelector('Select User', 'status', userId, onUserSelect, users, 'name', filterStyles, false)}
      {getFilterSelector('Select Menu Type', 'menuType', menuTypeId, onMenuTypeSelect, menuTypes, 'name', filterStyles, false)}
      <EventsBySuiteListFilterBar classes={classes} eventTypes={eventTypes} filterEventsList={filterEventsList} />
      <EventsBySuiteList events={events} eventId={eventId} onEventSelect={onEventSelect} />
    </Fragment>
  )
}

CreateOrderDialogContent.propTypes = {
  handleCompanySelection: PropTypes.func,
  handleEventSelection: PropTypes.func,
  handleSuiteSelection: PropTypes.func,
  handleUserSelection: PropTypes.func,
  handleMenuTypeSelection: PropTypes.func,
  companiesList: PropTypes.array,
  companyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suites: PropTypes.array,
  suiteId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  users: PropTypes.array,
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  menuTypes: PropTypes.array,
  menuTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  events: PropTypes.array,
  eventId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  classes: PropTypes.object,
  eventTypes: PropTypes.array,
  filterEvents: PropTypes.func
}

export default React.memo(
  withStyles(styles)(CreateOrderDialogContent)
)

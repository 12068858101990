import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

AddCreditCardTitle.propTypes = {
  classes: PropTypes.object,
  closeModal: PropTypes.func
}

function AddCreditCardTitle ({ classes, closeModal }) {
  return (
    <div className={classes.addCreditCardHeader}>
      <div className={classes.addCreditCardHeaderLabel}>
        Add Credit Card
      </div>
      <div className={classes.addCreditCardHeaderCloseButton} onClick={() => closeModal()}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default AddCreditCardTitle

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import theme from '../../theme'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import {
  CART_ADD_TYPES
} from '../../constants/menuItems'
import { PRODUCT_DETAIL, reportGoogleAnalytics } from '../../utils/googleAnalytics'
const filterStyles = {
  selectorWidth: '200px'
}
const {
  MODAL_ADD
} = CART_ADD_TYPES
const MenuItemDetailsActions = ({
  classes,
  item,
  handleAddToCart,
  currentUser,
  orderWindow = 'advance'
}) => {
  const { advancePrice, gamedayPrice } = item
  const [quantity, setQuantity] = useState(1)
  const getMenuItemPrice = () => {
    const price = parseInt((orderWindow.toLowerCase() === 'advance' ? advancePrice : gamedayPrice), 10)
    return price === 0 ? '' : `$${price}`
  }

  const [menuPriceByOrderWindow, setMenuPriceByOrderWindow] = useState('Advance')
  const isUserAdmin = currentUserIsAdmin(currentUser)

  useEffect(() => {
    if (orderWindow) {
      const updatedOrderWindow = ((orderWindow.toLowerCase() === 'gameday') && gamedayPrice > 0 && isUserAdmin)
        ? orderWindow
        : isUserAdmin
          ? 'Advance'
          : orderWindow
      setMenuPriceByOrderWindow(updatedOrderWindow)
      getGoogleAnalyticsProduct(menuPriceByOrderWindow, item, quantity)
    }
  }, [gamedayPrice, isUserAdmin, orderWindow])

  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }

  const decreaseQuantity = () => {
    if ((quantity - 1) > 0) {
      setQuantity(quantity - 1)
    }
  }

  const addToCart = () => {
    handleAddToCart(item, quantity, menuPriceByOrderWindow, MODAL_ADD)
  }

  const getGoogleAnalyticsProduct = (menuPriceByOrderWindow, item, quantity) => {
    const itemPrice = menuPriceByOrderWindow === 'Advance' ? item.advancePrice : item.gamedayPrice
    const value = []
    PRODUCT_DETAIL.ecommerce.items = []
    const subtotal = (itemPrice * quantity)
    value.push(parseFloat(subtotal))
    const product = {
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: itemPrice,
      quantity: quantity
    }
    PRODUCT_DETAIL.ecommerce.items.push(product)
    PRODUCT_DETAIL.ecommerce.value = value.reduce((a, b) => a + b, 0)
    reportGoogleAnalytics('view_item', PRODUCT_DETAIL)
  }

  const pricesByOrderWindow = [
    { id: 'Advance', value: `Advance Price - $${advancePrice}` }
  ]

  if (orderWindow && orderWindow.toLowerCase() === 'gameday' && gamedayPrice > 0) {
    pricesByOrderWindow.push(
      { id: 'Gameday', value: `Gameday Price - $${gamedayPrice}` }
    )
  }

  const handlePriceSelection = (event) => {
    const value = event.target.value
    setMenuPriceByOrderWindow(value)
  }

  const isGameday = () => {
    const isOrderWindowGameDay = orderWindow.toLowerCase() === 'gameday'
    return isUserAdmin && isOrderWindowGameDay
  }

  return (
    <div className={classes.menuItemDetailsActionContainer}>
      <div className={classes.menuItemQuantityControlContainer}>
        <div className={classes.menuItemQuantityTitle}>QUANTITY</div>
        <div className={classes.controlContainer}>
          <Fab color="primary" className={classes.manageQuantity} onClick={decreaseQuantity}>
            <RemoveCircleOutlineIcon className={classes.quantityControls} />
          </Fab>
          <div className={classes.quantiyValueContainer}>
            <Input value={quantity} classes={{ input: classes.quantityValue }} />
          </div>
          <Fab color="primary" className={classes.manageQuantity} onClick={increaseQuantity}>
            <AddCircleOutlineIcon className={classes.quantityControls} />
          </Fab>
        </div>
      </div>
      {
        orderWindow && <div className={classes.menuItemName}>
          {getMenuItemPrice()}
        </div>
      }
      { isGameday() && <div className={classes.orderWindowSelector}>
        <MuiThemeProvider theme={theme}>
          {
            getFilterSelector('Price', 'price', menuPriceByOrderWindow, handlePriceSelection, pricesByOrderWindow, 'value', filterStyles, false, false)
          }
        </MuiThemeProvider>
      </div>
      }
      <div className={classes.menuItemDetailsAddToCartButtonContainer}>
        <Button variant="contained" color="primary" className={classes.addToCartButton} onClick={addToCart} >
          Add To Cart
        </Button>
      </div>
    </div>
  )
}

MenuItemDetailsActions.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  handleAddToCart: PropTypes.func,
  orderWindow: PropTypes.string,
  currentUser: PropTypes.object
}
export default MenuItemDetailsActions

import React from 'react'
import PropTypes from 'prop-types'

const CopyOrderDialogTitle = ({
  classes,
  order
}) => {
  return (
    <div className={classes.copyOrderTitle}>
      Copy order#{order.id} from {order.eventName}
    </div>
  )
}

CopyOrderDialogTitle.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object
}

export default CopyOrderDialogTitle

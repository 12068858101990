import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import { fetchMenuCategories, createCategories, deleteCategory, updateCategories } from '../../store/menuItems/actions'
import styles from './EditCategoriesDialogContent.css'
import EditCategoriesDialogActions from './EditCategoriesDialogActions'
import EditCategoriesTitle from './EditCategoriesTitle'
import EditCategoriesDialogContent from './EditCategoriesDialogContent'

const EditCategories = ({
  openDialog,
  classes,
  handleDialogClose,
  menuCategories,
  fetchMenuCategories,
  createCategories,
  deleteCategory,
  updateCategories
}) => {
  const [actionMode, setActionMode] = useState('List')
  const [selectedMenuCategories, setSelectedMenuCategories] = useState(null)

  useEffect(() => {
    fetchMenuCategories()
  }, [fetchMenuCategories])

  useEffect(() => {
    setActionMode('List')
  }, [openDialog])

  const getDialogActions = (handleAddCategory, actionMode, selectedMenuCategories, handleDeleteCategory, handleSaveCategory, handleCancelCategory) =>
    <EditCategoriesDialogActions
      classes={classes}
      selectedMenuCategories={selectedMenuCategories}
      handleAddCategory={handleAddCategory}
      actionMode={actionMode}
      handleDeleteCategory={handleDeleteCategory}
      handleSaveCategory={handleSaveCategory}
      handleCancelCategory={handleCancelCategory}
    />

  const getDialogContent = (classes, menuCategories, actionMode, handleEditCategory, selectedMenuCategories, setCategoryName, setSubCategory, handleDialogClose) =>
    <EditCategoriesDialogContent classes={classes} menuCategories={menuCategories} actionMode={actionMode} handleEditCategory={handleEditCategory}
      selectedMenuCategories={selectedMenuCategories} setCategoryName={setCategoryName} setSubCategory={setSubCategory} handleDialogClose={handleDialogClose} />

  const getTitle = (classes, actionMode) =>
    <EditCategoriesTitle className={classes.editCategoriesTitle} actionMode={actionMode} />

  const handleAddCategory = () => {
    setSelectedMenuCategories({
      name: '',
      subcategories: [],
      updatedSubCategory: ''
    })
    setActionMode('Add')
  }

  const handleEditCategory = (category) => {
    const { subcategories = [] } = category
    const updatedSubCategory = subcategories.map(subcategory => subcategory.name).join(', ')
    setSelectedMenuCategories({
      ...category,
      updatedSubCategory
    })
    setActionMode('Edit')
  }

  const handleDeleteCategory = () => {
    const { id } = selectedMenuCategories
    deleteCategory(id)
  }

  const handleSaveCategory = () => {
    const { name, updatedSubCategory = '', id, subcategories } = selectedMenuCategories
    const subcategoryNames = updatedSubCategory.split(', ')
    const subCategoriesList = subcategories.map(subCategory => {
      const deletedSubCategories = subcategoryNames.find(item => item === subCategory.name)
      return {
        ...subCategory,
        ...((!deletedSubCategories) ? { _destroy: 1 } : null)
      }
    })
    const updatedSubCategories = subcategoryNames.map(subCategory => {
      const subCategoryObj = subcategories.find(category => category.name.toLowerCase() === subCategory.toLowerCase())
      return {
        name: subCategory,
        ...((subCategoryObj && subCategoryObj.id) ? { id: subCategoryObj.id } : null)
      }
    })

    const deletedSubCategories = subCategoriesList.filter(category => category._destroy)

    if (id) {
      updateCategories(id, {
        category: {
          id,
          name,
          sub_categories_attributes: [
            ...updatedSubCategories,
            ...deletedSubCategories
          ]
        }
      })
    } else {
      createCategories({
        category: {
          name,
          sub_categories_attributes: updatedSubCategories
        }
      })
    }
  }

  const handleCancelCategory = () => {
    setSelectedMenuCategories(null)
    setActionMode('List')
  }

  const setCategoryName = (category) => {
    const menuCategory = { ...selectedMenuCategories }
    setSelectedMenuCategories({
      ...menuCategory,
      name: category
    })
  }

  const setSubCategory = (subCategory) => {
    const menuCategory = { ...selectedMenuCategories }
    setSelectedMenuCategories({
      ...menuCategory,
      updatedSubCategory: subCategory
    })
  }

  return (
    <React.Fragment>
      <Dialog
        openDialog={openDialog}
        dialogTitle={getTitle(classes, actionMode)}
        dialogContent={getDialogContent(classes, menuCategories, actionMode, handleEditCategory, selectedMenuCategories, setCategoryName, setSubCategory, handleDialogClose)}
        dialogActions={getDialogActions(handleAddCategory, actionMode, selectedMenuCategories, handleDeleteCategory, handleSaveCategory, handleCancelCategory)}
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      />
    </React.Fragment>
  )
}

EditCategories.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  fetchMenuCategories: PropTypes.func,
  menuCategories: PropTypes.array,
  deleteCategory: PropTypes.func,
  createCategories: PropTypes.func,
  updateCategories: PropTypes.func
}

const mapStateToProps = (state) => {
  const { menuCategories } = state.menuItemsData
  return {
    menuCategories
  }
}

const mapDispatchToProps = {
  fetchMenuCategories,
  createCategories,
  deleteCategory,
  updateCategories
}

const EditCategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCategories)

export default withStyles(styles)(EditCategoriesContainer)

const styles = {
  addQuickNotice: {
    height: '38px',
    width: '100%',
    backgroundColor: '#65FFF5',
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    position: 'fixed',
    left: '0px',
    top: '120px',
    zIndex: 9999999,
    paddingTop: '8px'
  }
}

export default styles

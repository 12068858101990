import { cleanUpFilterObject } from '../helpers/cleanupFilters'

export const prepareEventsListQuery = (filters, page, limit) => {
  const { selectedMonth, eventCategory, startDate, endDate, menuType } = cleanUpFilterObject(filters)
  return {
    filters: {
      date: {
        start_date: startDate,
        end_date: endDate
      },
      category: eventCategory,
      selectedMonth: selectedMonth,
      menuType
    },
    pagination: {
      page: page + 1,
      limit
    }
  }
}

export const validFilters = (filters, page, limit, sort) => {
  const { selectedMonth, eventCategory, startDate, endDate, menuTypeId } = cleanUpFilterObject(filters)
  return {
    filters: {
      ...((startDate && endDate) ? { date: {
        start_date: startDate,
        end_date: endDate
      } } : null),
      ...((eventCategory) ? { category: eventCategory } : null),
      ...((selectedMonth) ? { selectedMonth } : null),
      ...((menuTypeId) ? { menuTypeId } : null)
    },
    pagination: {
      page: page + 1,
      limit
    }
  }
}

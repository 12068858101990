import { makeStyles } from '@material-ui/styles'

const rowStyles = {
  shareTypes: {
    '& th:nth-child(1)': {
      width: '24px'
    },
    '& th:nth-child(2)': {
      maxWidth: '275px',
      width: '275px',
      textOverflow: 'ellipsis'
    }
  },
  orders: {
    '& th:nth-child(1)': {
      width: '3%'
    },
    '& th:nth-child(2)': {
      width: '9%'
    },
    '& th:nth-child(3)': {
      width: '18%'
    },
    '& th:nth-child(4)': {
      width: '8%'
    },
    '& th:nth-child(5)': {
      width: '8%'
    },
    '& th:nth-child(6)': {
      width: '12%'
    },
    '& th:nth-child(7)': {
      width: '14%'
    },
    '& th:nth-child(8)': {
      width: '10%'
    },
    '& th:nth-child(9)': {
      width: '10%'
    },
    '& th:nth-child(10)': {
      width: '8%'
    }
  },
  menuItems: {
    '& th:nth-child(1)': {
      width: '4%'
    },
    '& th:nth-child(2)': {
      width: '8%'
    },
    '& th:nth-child(3)': {
      width: '12%'
    },
    '& th:nth-child(4)': {
      width: '20%'
    },
    '& th:nth-child(5)': {
      width: '10%'
    },
    '& th:nth-child(6)': {
      width: '14%'
    },
    '& th:nth-child(7)': {
      width: '12%'
    },
    '& th:nth-child(8)': {
      width: '12%'
    }
  },
  companies: {
    height: '48px',
    '& th:nth-child(1)': {
      width: '10%'
    },
    '& th:nth-child(2)': {
      width: '30%'
    },
    '& th:nth-child(3)': {
      width: '30%'
    },
    '& th:nth-child(4)': {
      width: '20%'
    },
    '& th:nth-child(5)': {
      width: '10%'
    }
  },
  events: {
    '& th:nth-child(1)': {
      width: '4%'
    },
    '& th:nth-child(2)': {
      width: '10%'
    },
    '& th:nth-child(3)': {
      width: '12%'
    },
    '& th:nth-child(4)': {
      width: '20%'
    },
    '& th:nth-child(5)': {
      width: '10%'
    },
    '& th:nth-child(6)': {
      width: '10%'
    },
    '& th:nth-child(7)': {
      width: '12%'
    },
    '& th:nth-child(8)': {
      width: '12%'
    },
    '& th:nth-child(9)': {
      width: '10%'
    }
  },
  suiteHolderUsers: {
    '& th:nth-child(1)': {
      width: '4%'
    },
    '& th:nth-child(2)': {
      width: '12%'
    },
    '& th:nth-child(3)': {
      width: '15%'
    },
    '& th:nth-child(4)': {
      width: '15%'
    },
    '& th:nth-child(5)': {
      width: '8%'
    },
    '& th:nth-child(6)': {
      width: '20%'
    },
    '& th:nth-child(7)': {
      width: '12%'
    },
    '& th:nth-child(8)': {
      width: '10%'
    }
  },
  staffUsers: {
    '& th:nth-child(1)': {
      width: '5%'
    },
    '& th:nth-child(2)': {
      width: '25%'
    },
    '& th:nth-child(3)': {
      width: '15%'
    },
    '& th:nth-child(4)': {
      width: '25%'
    },
    '& th:nth-child(5)': {
      width: '15%'
    }
  },
  suites: {
    '& th:nth-child(1)': {
      width: '5%'
    },
    '& th:nth-child(2)': {
      width: '25%'
    },
    '& th:nth-child(3)': {
      width: '15%'
    }
  },
  reconciliationReport: {
    '& th:nth-child(1)': {
      width: '3%'
    },
    '& th:nth-child(2)': {
      width: '6%'
    },
    '& th:nth-child(3)': {
      width: '10%'
    },
    '& th:nth-child(4)': {
      width: '20%'
    },
    '& th:nth-child(5)': {
      width: '6%'
    },
    '& th:nth-child(6)': {
      width: '12%'
    },
    '& th:nth-child(7)': {
      width: '10%'
    },
    '& th:nth-child(8)': {
      width: '20%'
    }
  },
  invoiceReport: {
    '& th:nth-child(1)': {
      width: '1%'
    },
    '& th:nth-child(2)': {
      width: '15%'
    },
    '& th:nth-child(3)': {
      width: '8%'
    },
    '& th:nth-child(4)': {
      width: '8%'
    },
    '& th:nth-child(5)': {
      width: '8%'
    },
    '& th:nth-child(6)': {
      width: '8%'
    },
    '& th:nth-child(7)': {
      width: '14%'
    },
    '& th:nth-child(8)': {
      width: '6%'
    },
    '& th:nth-child(9)': {
      width: '8%'
    },
    '& th:nth-child(10)': {
      width: '8%'
    },
    '& th:nth-child(11)': {
      width: '8%'
    },
    '& th:nth-child(12)': {
      width: '8%'
    }
  }
}

const useStyles = makeStyles({
  tableHeader: props => {
    return {
      ...rowStyles[props.rowsFor || 'orders'],
      '& th': {
        border: 'none'
      }
    }
  },
  sortableColumn: {
    cursor: 'pointer'
  }
}, { index: 1 })

export default useStyles

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TabGroup from '../TabGroup/TabGroup'
import Tab from '../Tab/Tab'
import styles from './MainNav.css'

const MainNav = ({ classes, routes }) => {
  const RouteTab = routes.reduce(
    (tabs, { name, path, showTab }, index) =>
      showTab
        ? [
          ...tabs,
          <Tab
            key={index}
            className={classes.Tab}
            activeClassName={classes.isSelected}
            name={name}
            path={path}
          />
        ]
        : tabs,
    []
  )

  return <TabGroup className={classes.MainNav}>{RouteTab}</TabGroup>
}

MainNav.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.array
}
export default withStyles(styles)(MainNav)

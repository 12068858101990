import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './EventDate.css'

const EventDate = ({
  classes,
  eventMonth,
  eventDate,
  eventDay
}) => {
  return (
    <div className={classes.eventDateContainer}>
      <div className={classes.eventMonth}>{eventMonth.toUpperCase()}</div>
      <div className={classes.eventDate}>{eventDate}</div>
      <div className={classes.eventDayName}>{eventDay.toUpperCase()}</div>
    </div>
  )
}

EventDate.propTypes = {
  classes: PropTypes.object,
  eventMonth: PropTypes.string,
  eventDate: PropTypes.string,
  eventDay: PropTypes.string
}

export default withStyles(styles)(EventDate)

// Event Types
export const ANALYTICS_EVENT_BUTTON_CLICK = 'button_click'
export const ANALYTICS_EVENT_PAGE_LOAD = 'page_load'
export const ANALYTICS_EVENT_PAGE_VIEW = 'page_view'
export const ANALYTICS_ACTION_MODAL_OPEN = 'modal-open'
export const ANALYTICS_ACTION_MODAL_CLOSE = 'modal_close'
export const ANALYTICS_ACTION_CART_ADD = 'cart_add'
export const ANALYTICS_ACTION_FILTER_CLICK = 'filter-click'
export const ANALYTICS_ACTION_SUBMIT_CLICK = 'submit-click'
export const ANALYTICS_ACTION_TYPE_CART_MODAL_RECOMMENDED_ADD = 'recommended-cart-quick-add'
export const ANALYTICS_EVENT_CAROUSEL_ARROW_CLICK = 'carousel-click'
export const ANALYTICS_EVENT_CHECKOUT_VIEW = 'checkout-view'
export const ANALYTICS_EVENT_CART_QUICK_VIEW = 'cart_view'
export const ANALYTICS_EVENT_CLICK_CONTENT = 'click_content'
export const ANALYTICS_EVENT_SELECT_CONTENT = 'select_content'
export const ANALYTICS_CLICK_CONTENT_CREATION = 'order_creation_add_an_order'
export const ANALYTICS_CLICK_CREATION_CONTINUE = 'order_creation_continue'
export const ANALYTICS_EVENT_CART_MODAL_ADD = 'add_to_cart'

// Actions
export const ANALYTICS_ACTION_SIGN_IN = 'sign-in'
export const ANALYTICS_ACTION_CLIENT_ADD_ORDER = 'add-order'
export const ANALYTICS_ACTION_CART_SELECTION = 'cart-selection'
export const ANALYTICS_ACTION_FILTER_SELECTION = 'filter-selection'
export const ANALYTICS_ACTION_CART_QUICK_ADD = 'cart-add'
export const ANALYTICS_ACTION_TYPE_CART_QUICK_ADD = 'menu_quick_add_to_cart'
export const ANALYTICS_ACTION_TYPE_CART_MODAL_ADD = 'modal_add_to_cart'
export const ANALYTICS_ACTION_VIEW_ITEM = 'view_item'
export const ANALYTICS_ACTION_VIEW_CART = 'view_cart'
export const ANALYTICS_ACTION_QUANTITY_SELECTION = 'qty-selection'
export const ANALYTICS_ACTION_ITEM_DELETE = 'item-delete'
export const ANALYTICS_ACTION_CONTACT_US = 'contact-us'
export const ANALYTICS_ACTION_CHANGE_PASSWORD = 'change-password'
export const ANALYTICS_ACTION_ADD_CARD = 'add-a-card'
export const ANALYTICS_ACTION_BACK_TO_CATALOG = 'back-selection'
export const ANALYTICS_ACTION_BROWSE_CATALOG = 'browse-catalog'
export const ANALYTICS_ACTION_VIEW_ORDER = 'view-order'
export const ANALYTICS_ACTION_EDIT_ORDER = 'edit-order'
export const ANALYTICS_ACTION_COPY_ORDER = 'copy-order'
export const ANALYTICS_ACTION_CANCEL_ORDER = 'cancel-order'
export const ANALYTICS_ACTION_PROCEED_TO_CHECKOUT = 'proceed-to-checkout'
export const ANALYTICS_ACTION_CANCEL_EDIT = 'cancel-edit'
export const ANALYTICS_ACTION_PROCEED_TO_CHEKOUT = 'checkout-selection'
export const ANALYTICS_ACTION_ADD_ADDITIONAL_CARDS = 'add-additional-cards'
export const ANALYTICS_ACTION_FILTER_EVENTS = 'filter-events'
export const ANALYTICS_ACTION_CLEAR_SELECTION = 'clear-selection'
export const ANALYTICS_ACTION_FILTER_ORDERS = 'filter-orders'
export const ANALYTICS_ACTION_FILTER_USERS = 'filter-users'
export const ANALYTICS_ACTION_ADD_USER = 'add-user'
export const ANALYTICS_ACTION_CANCEL_ORDER_CLOSE = 'cancel-order-dialog-close'
export const ANALYTICS_ACTION_CANCEL_ORDER_CONFIRM = 'cancel-order-dialog-confirm'
export const ANALYTICS_ACTION_PLACE_ORDER = 'place-order'
export const ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_QUICK_ADD = 'you_may_also_like_add_to_cart'
export const ANALYTICS_ACTION_TYPE_RECOMMENDATION_CART_MODAL_ADD = 'menu_quick_add_to_cart'
export const ANALYTICS_ACTION_CAROUSEL_CLICK = 'carousel_click'
export const ANALYTICS_ACTION_RECOMMENDED_CHECKOUT_VIEW = 'recommended-checkout-view'
export const ANALYTICS_ACTION_RECOMMENDED_CART_VIEW = 'recommended-cart-view'
export const ANALYTICS_ACTION_CREATE_ORDER = 'order_creation'

// Action Types
export const ANALYTICS_ACTION_CAROUSEL_CLICK_LEFT = 'left'
export const ANALYTICS_ACTION_CAROUSEL_CLICK_RIGHT = 'right'
export const ANALYTICS_ACTION_TYPE_CTA = 'cta'
export const ANALYTICS_ACTION_TYPE_ITEM_ADDITIONS_BY = 'checkout:payment-options:item-additions-by'
export const ANALYTICS_ACTION_TYPE_PAYMENT_METHOD = 'checkout:payment-options:payment-method'
export const ANALYTICS_ACTION_TYPE_PAR_INSTRUCTIONS = 'checkout:checkout-options:par-instructions'
export const ANALYTICS_ACTION_TYPE_SIGNATURE_OPTIONS = 'checkout:checkout-options:signature-required'
export const ANALYTICS_ACTION_TYPE_BACK_TO_CATALOG = 'checkout:back-to-ordering'
export const ANALYTICS_ACTION_TYPE_PRIMARY_NAVIGATION = 'primary-navigation'
export const ANALYTICS_ACTION_TYPE_EDIT_TO_CHECKOUT = 'order-edit:proceed-to-checkout'
export const ANALYTICS_ACTION_TYPE_CANCEL_EDIT = 'order-edit:cancel-edit'
export const ANALYTICS_ACTION_TYPE_BROWSE_CATALOG = 'order-edit:browse-menu-catalog'
export const ANALYTICS_ACTION_TYPE_CART_VIEW_QUICK = 'menu-catalog:cart:cta'
export const ANALYTICS_ACTION_TYPE_CATALOG_TO_CHECKOUT = 'menu-catalog:cart-checkout'
export const ANALYTICS_ACTION_TYPE_ADD_ADDITIONAL_CARDS = 'checkout:payment-options:add-cards'
export const ANALYTICS_ACTION_TYPE_CATEGORY_FILTER = 'menu-catalog:filter'
export const ANALYTICS_ACTION_TYPE_DELETE_LINE_ITEM = 'checkout:delete'
export const ANALYTICS_ACTION_TYPE_UPDATE_QUANTITY = 'checkout:qty'
export const ANALYTICS_ACTION_TYPE_EVENT_DETAILS = 'events-landing:cta'
export const ANALYTICS_ACTION_TYPE_EVENTS_FILTER = 'events:filters'
export const ANALYTICS_ACTION_TYPE_ORDERS_FILTER = 'orders:filters'
export const ANALYTICS_ACTION_TYPE_USERS_FILTER = 'users:filters'
export const ANALYTICS_ACTION_TYPE_EVENTS_CLEAR_FILTERS = 'events:clear-filters'
export const ANALYTICS_ACTION_TYPE_ORDERS_CLEAR_FILTERS = 'orders:clear-filters'
export const ANALYTICS_ACTION_TYPE_USERS_CLEAR_FILTERS = 'users:clear-filters'
export const ANALYTICS_ACTION_TYPE_ADD_USER = 'users:add-user'
export const ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CONFIRM = 'orders:cancel:confirm'
export const ANALYTICS_ACTION_TYPE_ORDER_CANCEL_CLOSE = 'orders:cancel:close'
export const ANALYTICS_ACTION_TYPE_ORDER_COPY = 'orders:copy'
export const ANALYTICS_ACTION_TYPE_ORDER_EDIT = 'orders:edit'
export const ANALYTICS_ACTION_TYPE_ORDER_COPY_CANCEL = 'orders:copy:cancel'
export const ANALYTICS_ACTION_TYPE_ORDER_COPY_CONFIRM = 'orders:copy:checkout'
export const ANALYTICS_ACTION_TYPE_ORDER_CANCEL = 'orders:cancel'
export const ANALYTICS_ACTION_TYPE_ORDER_SUBMIT = 'checkout:order-submit'
export const ANALYTICS_ACTION_TYPE_EVENT_MODAL_ADD_ORDER = 'events:details-modal:add-order'
export const ANALYTICS_ACTION_CART_RECOMMENDATION_CART_ADD = 'checkout:recommendation:cart-add'
export const ANALYTICS_ACTION_TYPE_RECOMMENDATION_DETAILS_VIEW = 'checkout:recommendation:view-details'
export const ANALYTICS_ACTION_TYPE_CHECKOUT_VIEW = 'checkout_view'
export const ANALYTICS_ACTION_TYPE_CART_VIEW = 'cart_view'

// Action Location
export const ANALYTICS_ACTION_LOCATION_BODY = 'body'
export const ANALYTICS_ACTION_LOCATION_HEADER = 'header'
export const ANALYTICS_ACTION_LOCATION_MODAL = 'modal'
export const ANALYTICS_ACTION_LOCATION_DEAL_MODULE = 'deal-module'
export const ANALYTICS_ACTION_LOCATION_INTERAL = 'internal'

// Page Types
export const ANALYTICS_PAGE_TYPE_LOGIN = 'login'
export const ANALYTICS_PAGE_TYPE_CLIENT_EVENTS = 'events'
export const ANALYTICS_PAGE_TYPE_CLIENT_EVENT_MODAL = 'events-modal'
export const ANALYTICS_PAGE_TYPE_CLIENT_ORDERS = 'orders-home'
export const ANALYTICS_PAGE_TYPE_CLIENT_ORDERS_EDIT = 'orders-edit'
export const ANALYTICS_PAGE_TYPE_VIEW_ORDER = 'view-order'
export const ANALYTICS_PAGE_TYPE_EDIT_ORDER = 'edit-order'
export const ANALYTICS_PAGE_TYPE_MENU_CATALOG = 'menu-catalog'
export const ANALYTICS_PAGE_TYPE_MENU_ITEM_MODAL = 'item-modal'
export const ANALYTICS_PAGE_TYPE_CHECKOUT = 'checkout'
export const ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT = 'my-account'
export const ANALYTICS_PAGE_TYPE_CLIENT_USERS = 'users'
export const ANALYTICS_PAGE_TYPE_ORDER_LIST = 'orders_list'
export const ANALYTICS_PAGE_TYPE_ORDER_CART = 'orders_cart'
export const ANALYTICS_PAGE_TYPE_MENU = 'menu'

export const DOMAIN_NAME = 'www.mymsgsuites.com'
export const SITE_NAME = 'suites_web'
export const BRAND_NAME = 'madison_square_garden'

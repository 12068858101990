import React from 'react'
import PropTypes from 'prop-types'
const EditCompanyTitle = ({
  className,
  title
}) => {
  return (
    <div className={className}>
      {title}
    </div>
  )
}
EditCompanyTitle.propTypes = {
  className: PropTypes.object,
  title: PropTypes.string
}

export default EditCompanyTitle

import {
  FETCH_SUITES_HOLDERS_ACCOUNT,
  UPDATE_ACCOUNT_INFORMATION,
  UPDATE_ACCOUNT_INFORMATION_ERR,
  SHOW_ADD_CREDIT_CARD_MESSAGE,
  CLEAR_ADD_CREDIT_CARD_MESSAGE,
  CLEAR_UPDATE_ACCOUNT_ERR
} from '../actionTypes'

export default function SettingsReducers (state = {}, action) {
  switch (action.type) {
    case FETCH_SUITES_HOLDERS_ACCOUNT:
      return {
        ...state,
        accountInfo: action.payload
      }
    case UPDATE_ACCOUNT_INFORMATION:
      const { accountInfo: userAccountInfo, accountUpdateErr: updateErr } = action.payload
      return {
        ...state,
        accountInfo: userAccountInfo,
        accountUpdateErr: updateErr
      }
    case UPDATE_ACCOUNT_INFORMATION_ERR:
      const { accountInfo, accountUpdateErr } = action.payload
      return {
        ...state,
        accountInfo,
        accountUpdateErr
      }
    case SHOW_ADD_CREDIT_CARD_MESSAGE:
      return {
        ...state,
        ...action.payload
      }
    case CLEAR_ADD_CREDIT_CARD_MESSAGE:
      return {
        ...state,
        ...action.payload
      }
    case CLEAR_UPDATE_ACCOUNT_ERR:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

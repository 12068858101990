import React from 'react'
import PropTypes from 'prop-types'

const AssignEventsTitle = ({ className }) =>
  <div className={className}>Manage Events</div>

AssignEventsTitle.propTypes = {
  className: PropTypes.string
}

export default AssignEventsTitle

import { GET_ERRORS } from '../actionTypes'

const initialState = {}

export default function ErrorsReduers (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state
  }
}

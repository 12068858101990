import styled from 'styled-components'

const table = {
  width: 'calc(50% - 20px)',
  '& td:nth-child(2)': {
    fontSize: '20px',
    fontWeight: '900'
  }
}

export const DefaultShareTypesTable = styled.div({
  ...table,
  '&:before': {
    content: 'default hello',
    display: 'block',
    fontSize: '200px',
    fontWeight: '900'
  }
})

export const CustomShareTypesTable = styled.div({
  ...table,
  '&:before': {
    content: 'custom hello',
    display: 'block',
    fontSize: '20px',
    fontWeight: '900'
  }
})

const styles = {
  addButton: {
    height: '50px',
    borderRadius: '0px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '0 30px',
    position: 'absolute',
    right: '0',
    textTransform: 'none',
    top: '50px'
  }
}

export default styles

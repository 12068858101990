const styles = {
  orderBillLineItem: {
    display: 'flex',
    height: '45px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    '& div:nth-child(1)': {
      width: '60%'
    },
    '& div:nth-child(2)': {
      width: '18%',
      textAlign: 'right'
    },
    '& div:nth-child(3)': {
      width: '20%',
      textAlign: 'right'
    }
  },
  orderBillDetailsContainer: {
    padding: '40px'
  }
}

export default styles

import axios from 'axios'
import {
  CLEAR_UPDATE_PASSWORD_MESSAGE,
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_DEFAULT_DATA,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  NEW_PASSWORD_UPDATED,
  USER_LOGOUT
} from '../actionTypes'
import { history } from '../store'
import { authEndpoints } from '../../constants/apiConfig'
import { currentUserIsClient, currentUserIsFinanceAdmin, currentUserIsAdmin } from '../helpers/userRoleManager'
import { getItemsList } from '../helpers/apiClient'
import setAuthToken from './setAuthToken'

export const setCurrentUser = (userDetails) => {
  return {
    type: SET_CURRENT_USER,
    payload: { user_details: userDetails }
  }
}

export const setDefaultData = data => {
  return {
    type: SET_DEFAULT_DATA,
    payload: data
  }
}

export const loginUser = (user) => dispatch => {
  const { url } = authEndpoints.signIn
  axios.post(url, user)
    .then(res => {
      const { data: responseData = {} } = res
      const { data = {} } = responseData
      const defaultData = { ...data, menuItemStatusList: [{ id: 'true', status: 'Active' }, { id: 'false', status: 'Inactive' }] }
      const { user_details: userDetails } = data
      const { client_id: clientId, uid, token } = res.headers
      localStorage.setItem('Client', clientId)
      localStorage.setItem('Uid', uid)
      localStorage.setItem('Token', token)
      localStorage.setItem('ValidToken', true)
      localStorage.setItem('defaultData', JSON.stringify(defaultData))
      setAuthToken(token, uid, clientId)
      dispatch(setCurrentUser(userDetails))
      dispatch(setDefaultData(defaultData))
      if (currentUserIsClient(userDetails)) {
        history.push('/my-events')
      } else if (currentUserIsAdmin(userDetails)) {
        history.push('/orders/list')
      } else if (currentUserIsFinanceAdmin(userDetails)) {
        history.push('/reports')
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    })
}

export const resetPassword = (user) => dispatch => {
  const { url } = authEndpoints.resetPassword
  axios.post(url, user)
    .then(res => {
      dispatch({
        type: RESET_PASSWORD,
        payload: 'Email has been sent. Please check your email to reset password.'
      })
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    })
}

export const savePassword = (user) => dispatch => {
  const { method, url } = authEndpoints.savePassword
  getItemsList(method, url, user)
    .then(res => {
      const { data: { message } } = res
      dispatch({
        type: UPDATE_PASSWORD,
        payload: message
      })
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error
      })
    })
}

export const resetUpdatePasswordMessage = () => dispatch => {
  dispatch({
    type: CLEAR_UPDATE_PASSWORD_MESSAGE,
    payload: ''
  })
}

export const refreshTokenFromLocalStorage = () => dispatch => {
  const { Token, Uid, Client } = localStorage
  setAuthToken(Token, Uid, Client)
}

export const logoutUser = () => dispatch => {
  localStorage.removeItem('Client')
  localStorage.removeItem('Token')
  localStorage.removeItem('Uid')
  localStorage.removeItem('ValidToken')
  localStorage.removeItem('defaultData')
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  dispatch({
    type: USER_LOGOUT
  })
  history.push('/login')
}

export const updateNewPasswordByToken = (user) => dispatch => {
  const { method, url } = authEndpoints.updateNewPasswordWithToken
  getItemsList(method, url, user)
    .then(res => {
      const { data: { message } } = res
      dispatch({
        type: NEW_PASSWORD_UPDATED,
        payload: message
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser())
    })
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormControl, TextField } from '@material-ui/core'

const AssignEventsHeaderText = ({ isDisabled, companyName, suiteName, classes }) => (
  <Fragment>
    <FormControl className={classes.companyName}>
      <TextField
        disabled={isDisabled}
        InputLabelProps={{ shrink: true }}
        label="Company"
        value={companyName}
        variant="outlined"
      />
    </FormControl>
    <FormControl className={classes.suites}>
      <TextField
        disabled={isDisabled}
        InputLabelProps={{ shrink: true }}
        label="Suite"
        value={suiteName}
        variant="outlined"
      />
    </FormControl>
  </Fragment>
)

AssignEventsHeaderText.propTypes = {
  isDisabled: PropTypes.bool,
  companyName: PropTypes.string,
  suiteName: PropTypes.string,
  classes: PropTypes.object
}

AssignEventsHeaderText.defaultProps = {
  isDisabled: false,
  name: ''
}

export default AssignEventsHeaderText

export const FirstLineCategories = [
  'All',
  'Featured',
  'Previously Ordered',
  'Starters',
  'Beer',
  'Champagne',
  'Sweets',
  'Main Attractions'
]

export const SecondLineCategories = [
  'Liquor',
  'Non-Alcoholic Beverages',
  'From the Garden',
  'Handhelds',
  'Seafood',
  'Wine',
  'Others'
]

export const SearchStyles = {
  searchFieldWidth: '230px'
}

export const DietMenuOptions = [{
  name: 'Gluten Free',
  selected: false,
  id: 'gluten_free'
}, {
  name: 'Vegan',
  selected: false,
  id: 'vegan'
}]

const styles = {
  genericPaperStyles: {
    minHeight: '650px',
    minWidth: '662px',
    maxWidth: '662px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    paddingLeft: '38px',
    paddingRight: '38px',
    paddingTop: '30px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '40px 40px 10px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px',
    paddingTop: '8px'
  },
  editCompanyActionsContainer: {
    '& button:last-child': {
      marginRight: 0
    }
  },
  actionButton: {
    width: '48%',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    border: '1px solid #000000',
    height: '50px'
  },
  saveButton: {
    width: '48%',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px',
    color: '#FFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    border: '1px solid #000000',
    height: '50px'
  },
  assignSuite: {
    width: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    border: '1px solid #000000',
    height: '50px'
  },
  editCompanyTitle: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  companyDetailsInputFields: {
    width: '100%',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    margin: 0
  },
  companyNameInputFields: {
    float: 'left',
    width: '46%',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    margin: 0
  },
  companyIdInputFields: {
    display: 'inline-block',
    float: 'right',
    width: '46%',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    margin: 0
  },
  companyZipInputFields: {
    display: 'inline-block',
    float: 'right',
    width: '46%',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    margin: 0,
    marginTop: '4px'
  },
  companyZipInput: {
    color: 'black',
    width: '100%',
    border: 0,
    height: '1.1875em',
    margin: 0,
    display: 'block',
    padding: '15px 0px',
    minWidth: 0,
    background: 'none',
    transition: false,
    borderBottom: '2px dotted black',
    outline: 'none'
  },
  assignedSuitesTitle: {
    height: '14px',
    width: '274px',
    color: '#000000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    padding: '12px'
  },
  suitesContainer: {
    marginLeft: '8px',
    height: '170px',
    overflow: 'auto',
    marginTop: '16px'
  },
  activeSinceText: {
    height: '18px',
    width: '128px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    marginLeft: '7px'
  },
  deleteSuiteButton: {
    marginLeft: '12px'
  },
  addQuickNotice: {
    height: 'auto',
    '& ul': {
      listStyle: 'none'
    }
  },
  errorText: {
    color: ' #FF0000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    marginBottom: '12px'
  }
}

export default styles

import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { getDateSelector } from '../DateSelector/getDateSelector'
import SearchField from '../SearchField/SearchField'
import ResetFilters from '../ResetFilters/ResetFilters'
import styles from './ReportsFilterBar.css'

const ReportsFilter = ({
  classes = [],
  companies = [],
  disableExportButton = false,
  exportReport,
  filters = {},
  filterItems,
  suites = [],
  styles
}) => {
  const [companyId, setCompanyId] = useState(filters.companyId || '')
  const [suiteId, setSuiteId] = useState(filters.suiteId || '')
  const [startDate, setStartDate] = useState(filters.startDate || '')
  const [endDate, setEndDate] = useState(filters.endDate || '')
  const [query, setSearchQuery] = useState(filters.query || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleCompanySelection = (event) => {
    const companyId = event.target.value
    setCompanyId(companyId)
  }

  const handleSuiteSelection = (event) => {
    const suiteId = event.target.value
    setSuiteId(suiteId)
  }

  const handleStartDateSelection = (event) => {
    const startDate = event.target.value
    setStartDate(startDate)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const clearFilters = () => {
    setStartDate('')
    setEndDate('')
    setSearchQuery('')
    setSuiteId('')
    setCompanyId('')
    setResetFiltersFlag(true)
  }

  const handleSubmit = () => {
    filterItems({ companyId, startDate, endDate, suiteId, query })
  }

  const handleEndDateSelection = (event) => {
    const endDate = event.target.value
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() > startDateObj.getTime()) {
      setEndDate(endDate)
    } else {
      setEndDate(startDate)
    }
  }

  return (
    <React.Fragment>
      {getDateSelector('DATE RANGE', startDate, handleStartDateSelection)}
      {getDateSelector('  ', endDate, handleEndDateSelection)}
      {getFilterSelector('Company', 'companyId', companyId, handleCompanySelection, companies, 'name', styles)}
      {getFilterSelector('Suite', 'suiteId', suiteId, handleSuiteSelection, suites, 'display_name', styles)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
      <FormControl className={classes.exportReonciliationReportButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.exportReonciliationReport}
          onClick={exportReport}
          disabled={disableExportButton}
        >
          Export Report
        </Button>
      </FormControl>
    </React.Fragment>
  )
}

ReportsFilter.propTypes = {
  suites: PropTypes.array,
  companies: PropTypes.array,
  disableExportButton: PropTypes.bool,
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  classes: PropTypes.object,
  styles: PropTypes.object,
  exportReport: PropTypes.func
}

export default withStyles(styles)(ReportsFilter)

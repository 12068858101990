import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import { fetchCompanyToEdit, updateCompanyById, deleteCompanySuite } from '../../store/companies/actions'
import styles from './EditCompanyDialogContent.css'
import EditCompanyDialogActions from './EditCompanyDialogActions'
import EditCompanyTitle from './EditCompanyTitle'
import EditCompanyDialogContent from './EditCompanyDialogContent'

const EditCompany = ({
  openDialog,
  classes,
  handleDialogClose,
  company,
  fetchCompanyToEdit,
  companyDetailsToEdit: companyDetails,
  updateCompanyById,
  suiteShares,
  suites,
  deleteCompanySuite,
  companyUpdatedMessage
}) => {
  const [companyDetailsToEdit, setCompanyDetailsToEdit] = useState(null)

  useEffect(() => {
    if (company) {
      const { id } = company
      fetchCompanyToEdit(id)
    }
  }, [company, fetchCompanyToEdit])

  useEffect(() => {
    if (companyDetails) {
      setCompanyDetailsToEdit(companyDetails)
    }
  }, [companyDetails])

  const getDialogActions = (handleDialogClose, handleUpdate, handleAddSuite) => {
    return <EditCompanyDialogActions
      classes={classes}
      handleCancel={handleDialogClose}
      handleUpdate={handleUpdate}
      handleAddSuite={handleAddSuite}
    />
  }

  const getDialogContent = (companyDetailsToEdit, classes, setName, setAddress, setCity, setState, setZip, suites, suiteShares, setSuites, setSuiteShare, deleteSuite, companyUpdatedMessage) => {
    return <EditCompanyDialogContent classes={classes} company={companyDetailsToEdit} setName={setName} setAddress={setAddress} setCity={setCity} setState={setState} setZip={setZip} suites={suites} suiteShares={suiteShares} companyUpdatedMessage={companyUpdatedMessage}
      setSuites={setSuites} setSuiteShare={setSuiteShare} deleteSuite={deleteSuite} />
  }

  const getTitle = (classes) => {
    return <EditCompanyTitle className={classes.editCompanyTitle} title={'Edit Company'} />
  }

  const setName = (name) => {
    const currentCompany = { ...companyDetailsToEdit }
    setCompanyDetailsToEdit({
      ...currentCompany,
      name
    })
  }

  const setAddress = (streetAddress) => {
    const currentCompany = { ...companyDetailsToEdit }
    const { address } = currentCompany
    address.streetAddress = streetAddress
    setCompanyDetailsToEdit({
      ...currentCompany,
      address
    })
  }

  const setCity = (city) => {
    const currentCompany = { ...companyDetailsToEdit }
    const { address } = currentCompany
    address.city = city
    setCompanyDetailsToEdit({
      ...currentCompany,
      address
    })
  }

  const setZip = (event) => {
    const zip = parseInt(event.target.value, 10)
    const currentCompany = { ...companyDetailsToEdit }
    const { address } = currentCompany
    address.zip = !isNaN(zip) ? event.target.value : ''
    setCompanyDetailsToEdit({
      ...currentCompany,
      address
    })
  }

  const setState = (event) => {
    const currentCompany = { ...companyDetailsToEdit }
    const { address } = currentCompany
    address.state = event.target.value
    setCompanyDetailsToEdit({
      ...currentCompany,
      address
    })
  }

  const handleUpdate = () => {
    const companyDetails = {
      ...companyDetailsToEdit
    }
    updateCompanyById(companyDetails)
  }

  const setSuites = (index, suite) => {
    const currentCompany = { ...companyDetailsToEdit }
    const { suites: companySuites } = currentCompany
    companySuites[index].suite_id = suite
    setCompanyDetailsToEdit({
      ...currentCompany,
      suites: companySuites
    })
  }

  const setSuiteShare = (index, suiteShare) => {
    const currentCompany = { ...companyDetailsToEdit }
    const { suites: companySuites } = currentCompany
    companySuites[index].suite_share_id = suiteShare
    setCompanyDetailsToEdit({
      ...currentCompany,
      suites: companySuites
    })
  }

  const deleteSuite = (companySuite, index) => {
    const { suite_share_id: suiteShareId, active_since: activeSince, id } = companySuite
    const currentCompany = { ...companyDetailsToEdit }
    const { suites: companySuites } = currentCompany
    if (activeSince) {
      deleteCompanySuite(id, suiteShareId)
    } else {
      companySuites.splice(index, 1)
      setCompanyDetailsToEdit({
        ...currentCompany,
        suites: companySuites
      })
    }
  }

  const handleAddSuite = () => {
    const currentCompany = { ...companyDetailsToEdit }
    const { suites: companySuites } = currentCompany
    companySuites.push({
      suite_id: suites[0].id,
      suite_share_id: suiteShares[0].id
    })
    setCompanyDetailsToEdit({
      ...currentCompany,
      suites: companySuites
    })
  }

  return (
    <React.Fragment>
      { company && companyDetailsToEdit && <Dialog
        openDialog={openDialog}
        dialogTitle={getTitle(classes)}
        dialogContent={getDialogContent(companyDetailsToEdit, classes, setName, setAddress, setCity, setState, setZip, suites, suiteShares, setSuites, setSuiteShare, deleteSuite, companyUpdatedMessage)}
        dialogActions={getDialogActions(handleDialogClose, handleUpdate, handleAddSuite)}
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

EditCompany.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  company: PropTypes.object,
  fetchCompanyToEdit: PropTypes.func,
  updateCompanyById: PropTypes.func,
  companyDetailsToEdit: PropTypes.object,
  suiteShares: PropTypes.array,
  suites: PropTypes.array,
  deleteCompanySuite: PropTypes.func,
  companyUpdatedMessage: PropTypes.object
}

const mapStateToProps = (state) => {
  const {
    companyDetailsToEdit
  } = state.companiesData

  const {
    suiteShares,
    suites
  } = state.authReducer

  return {
    companyDetailsToEdit,
    suiteShares,
    suites
  }
}

const mapDispatchToProps = {
  fetchCompanyToEdit,
  updateCompanyById,
  deleteCompanySuite
}

const EditCompanyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCompany)

export default withStyles(styles)(EditCompanyContainer)

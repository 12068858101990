import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CreditCard from '../CreditCard/CreditCard'
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_ACTION_FILTER_CLICK,
  ANALYTICS_ACTION_ADD_ADDITIONAL_CARDS,
  ANALYTICS_ACTION_TYPE_ADD_ADDITIONAL_CARDS,
  ANALYTICS_PAGE_TYPE_CHECKOUT,
  ANALYTICS_ACTION_LOCATION_BODY
} from '../../constants/analytics'

import styles from './CreditCardsList.css'

const getUsedCardsListToRender = (allCards, usedCreditCards, handleInput, handleDelete, storeCreditCardPaymentDetails) => {
  if (usedCreditCards.length <= 0) return
  return usedCreditCards.map(usedCard => {
    const currentCard = allCards.find(card => card.id === usedCard.id)
    return <CreditCard
      key={usedCard.id}
      userCreditCards={[currentCard]}
      handleInput={handleInput}
      handleDelete={handleDelete}
      currentAmount={usedCard.amount}
      currentIsPrimary={usedCard.isPrimary}
      currentSelectedCardId={usedCard.id}
      enableDelete
    />
  })
}

const getDefaultCreditCardToRender = (allCards, usedCreditCards, handleInput, handleDelete, usedCard) => {
  let availableCards = []
  if (usedCreditCards.length > 0) {
    const usedCreditCardIds = usedCreditCards.map(card => card.id)
    availableCards = allCards.filter(availableCard => usedCreditCardIds.indexOf(availableCard.id) === -1)
  } else {
    availableCards = allCards
  }

  return <CreditCard
    userCreditCards={availableCards}
    handleInput={handleInput}
    handleDelete={handleDelete}
    currentIsPrimary={usedCard.isPrimary}
    enableDelete={usedCreditCards.length > 0}
  />
}

const isAddMoreButtonVisible = (allCards, usedCreditCards) => {
  return usedCreditCards.length < allCards.length && usedCreditCards.length < 6
}

const CreditCardsList = ({
  availableCreditCards,
  storeCreditCardPaymentDetails,
  usedCreditCards,
  orderId,
  classes
}) => {
  const [showDefaultCard, setShowDefaultCard] = useState(false)

  const handleInput = (cardData) => {
    const currentCardIndex = usedCreditCards.findIndex(card => card.id === cardData.id)
    if (cardData.isPrimary) {
      usedCreditCards.map(card => {
        card.isPrimary = false
        return card
      })
    }

    if (usedCreditCards.length === 0) {
      cardData.isPrimary = true
    }

    if (currentCardIndex > -1) {
      usedCreditCards[currentCardIndex] = cardData
    } else {
      usedCreditCards.push(cardData)
    }
    setShowDefaultCard(false)
    storeCreditCardPaymentDetails(usedCreditCards.slice())
  }

  const handleDelete = (selectedCardId) => {
    if (selectedCardId) {
      const cardIndexToDelete = usedCreditCards.findIndex(card => card.id === selectedCardId)
      usedCreditCards.splice(cardIndexToDelete, 1)
      if (usedCreditCards.length > 0) {
        usedCreditCards[0].isPrimary = true
      }
      storeCreditCardPaymentDetails(usedCreditCards.slice())
    } else {
      setShowDefaultCard(false)
    }
  }

  const addAdditionalCard = () => {
    reportAnalytics({
      eventType: ANALYTICS_ACTION_FILTER_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_ADD_ADDITIONAL_CARDS,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionType: ANALYTICS_ACTION_TYPE_ADD_ADDITIONAL_CARDS,
        actionOrderId: orderId
      },
      pageType: ANALYTICS_PAGE_TYPE_CHECKOUT
    })
    setShowDefaultCard(true)
  }

  return (
    <React.Fragment>
      { getUsedCardsListToRender(availableCreditCards, usedCreditCards, handleInput, handleDelete, storeCreditCardPaymentDetails) }
      { usedCreditCards.length === 0 || showDefaultCard ? getDefaultCreditCardToRender(availableCreditCards, usedCreditCards, handleInput, handleDelete, {}) : ''}
      { isAddMoreButtonVisible(availableCreditCards, usedCreditCards) ? <Button onClick={addAdditionalCard} className={classes.addMoreCardButton}>Add Additional Cards</Button> : ''}
    </React.Fragment>
  )
}

CreditCardsList.propTypes = {
  availableCreditCards: PropTypes.array,
  storeCreditCardPaymentDetails: PropTypes.func,
  usedCreditCards: PropTypes.array,
  classes: PropTypes.object,
  orderId: PropTypes.number
}

export default withStyles(styles)(CreditCardsList)

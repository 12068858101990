const styles = {
  textInputHolder: {
    height: '24px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px',
    padding: '12px',
    float: 'left'
  }
}

export default styles

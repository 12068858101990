import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { getDateSelector } from '../DateSelector/getDateSelector'
import { MENU_TYPES, MONTH_NAMES } from '../../constants/clientEvents'
import { reportAnalytics } from '../../utils/analytics'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_FILTER_EVENTS,
  ANALYTICS_ACTION_TYPE_EVENTS_FILTER,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_TYPE_EVENTS_CLEAR_FILTERS,
  ANALYTICS_ACTION_CLEAR_SELECTION,
  ANALYTICS_PAGE_TYPE_CLIENT_EVENTS
} from '../../constants/analytics'
import clientEventsFilterBarStyles from './ClientEventsFilterBar.css'

const ClientEventsFilterBar = ({ filters = {}, filterItems, resetPaginationToDefault, eventCategories = [], classes }) => {
  const [selectedMonth, setSelectedMonth] = useState(filters.month || '')
  const [eventCategory, setEventCategory] = useState(filters.eventCategory || '')
  const [startDate, setStartDate] = useState(filters.startDate || '')
  const [endDate, setEndDate] = useState(filters.endDate || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)
  const [menuTypeId, setMenuTypeId] = useState('')

  const handleMenuTypeSelection = (event) => {
    setMenuTypeId(event.target.value)
  }

  function handleEventMonthSelection (event) {
    const selectedMonth = event.target.value
    setSelectedMonth(selectedMonth)
  }

  function handleEventCategorySelection (event) {
    const eventCategory = event.target.value
    setEventCategory(eventCategory)
  }

  function handleStartDateSelection (event) {
    const startDate = event.target.value
    setStartDate(startDate)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [filterItems, resetFilters])

  function handleSubmit () {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_FILTER_EVENTS,
        actionType: ANALYTICS_ACTION_TYPE_EVENTS_FILTER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionSelectedMonth: selectedMonth,
        actionStartDate: startDate,
        actionEndDate: endDate,
        actionEventCategory: eventCategory,
        actionMenuTypeId: menuTypeId
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_EVENTS
    })
    resetPaginationToDefault({ selectedMonth, startDate, endDate, eventCategory, menuTypeId })
    filterItems({ selectedMonth, startDate, endDate, eventCategory, menuTypeId })
  }

  function handleEndDateSelection (event) {
    const endDate = event.target.value
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() > startDateObj.getTime()) {
      setEndDate(endDate)
    } else {
      setEndDate(startDate)
    }
  }

  function clearSearchResults () {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CLEAR_SELECTION,
        actionType: ANALYTICS_ACTION_TYPE_EVENTS_CLEAR_FILTERS,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_EVENTS
    })
    resetPaginationToDefault({})
    setSelectedMonth('')
    setStartDate('')
    setEndDate('')
    setMenuTypeId('')
    setEventCategory('')
    filterItems({})
  }

  return (
    <React.Fragment>
      {getDateSelector('DATE RANGE', startDate, handleStartDateSelection, 'Start Date')}
      <span className={classes.dateSeperatorLabel}>To</span>
      {getDateSelector('  ', endDate, handleEndDateSelection, 'End Date')}
      {getFilterSelector('Month', 'month', selectedMonth, handleEventMonthSelection, MONTH_NAMES, 'name', { selectorWidth: '180px' })}
      {getFilterSelector('Event Type', 'eventType', eventCategory, handleEventCategorySelection, eventCategories, 'name', { selectorWidth: '180px' })}
      {getFilterSelector('Menu Type', 'menuType', menuTypeId, handleMenuTypeSelection, MENU_TYPES, 'name', { selectorWidth: '180px' })}
      <FormControl>
        <div className={classes.searchIconContainer}>
          <SearchIcon className={classes.searchIcon} onClick={handleSubmit} />
          <div className={classes.clearFilters} onClick={clearSearchResults}>Clear Filters</div>
        </div>
      </FormControl>
    </React.Fragment>
  )
}

ClientEventsFilterBar.propTypes = {
  eventCategories: PropTypes.array,
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  resetPaginationToDefault: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(clientEventsFilterBarStyles)(ClientEventsFilterBar)

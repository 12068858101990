import {
  FETCH_MENU_ITEMS,
  GET_MENU_ITEM_BY_ID_TO_EDIT,
  UPDATE_MENU_ITEMS_LIST,
  FETCH_MENU_CATEGORIES,
  CREATE_MENU_CATEGORIES,
  DELETE_MENU_CATEGORIES,
  UPDATE_MENU_CATEGORIES,
  REST_MENU_CATEGORIES_UPDATE_MESSAGE
} from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { menuItemEndpoints, menuCategoriesEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/menuItems'
import { getItemsList } from '../helpers/apiClient'

const prepareMenuListParams = (filters, sortParams, page, limit) => {
  const { status, eventCategory, menuType, menuCategory, query } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sortParams
  return {
    filters: {
      status,
      event_category_id: eventCategory,
      menu_type_id: menuType,
      category_id: menuCategory,
      query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchMenuItems = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = menuItemEndpoints.list
  const data = prepareMenuListParams(filters, sortParams, page, limit)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { menu_items: menuItems } = data
      const { items, meta } = menuItems
      dispatch({
        type: FETCH_MENU_ITEMS,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

const getMenuItemById = (menuItemId) => {
  const { method, url } = menuItemEndpoints.byId
  const menuEditUrl = url.replace(':menuItemId', menuItemId)
  return getItemsList(method, menuEditUrl)
}

export const setItemToEdit = (itemId) => dispatch => {
  getMenuItemById(itemId)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: GET_MENU_ITEM_BY_ID_TO_EDIT,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateMenuItem = (menuItemData) => dispatch => {
  const {
    image,
    name,
    status,
    description,
    menuType,
    assignedEventTypes,
    isGlutenFree,
    isVegan,
    assignedCategories
  } = menuItemData
  const filteredEventTypes = assignedEventTypes.filter(eventType => eventType.is_assigned === true || eventType._destroy === 1)
  const data = new FormData()
  data.append('menu_item[image]', image)
  data.append('menu_item[web_name]', name)
  data.append('menu_item[web_status]', status)
  data.append('menu_item[web_description]', description)
  data.append('menu_item[menu_type_id]', menuType)
  data.append('menu_item[is_gluten_free]', isGlutenFree)
  data.append('menu_item[is_vegan]', isVegan)
  data.append('menu_item[event_categories_menu_items_attributes]', JSON.stringify(filteredEventTypes))
  data.append('menu_item[categories_attributes]', JSON.stringify(assignedCategories))
  const { method, url } = menuItemEndpoints.update
  const menuUpdateUrl = url.replace(':menuItemId', menuItemData.id)
  getItemsList(method, menuUpdateUrl, data)
    .then(res => {
      getMenuItemById(menuItemData.id).then(res => {
        const { data = {} } = res
        const {
          menuItemsList
        } = store.getState().menuItemsData
        dispatch({
          type: UPDATE_MENU_ITEMS_LIST,
          payload: { menuItemsList, menuItem: data }
        })
      })
    }).catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const activateItem = (menuItemId) => dispatch => {
  const { method, url } = menuItemEndpoints.activate
  const menuActivateUrl = url.replace(':menuItemId', menuItemId)
  getItemsList(method, menuActivateUrl)
    .then(res => {
      getMenuItemById(menuItemId).then(res => {
        const { data = {} } = res
        const {
          menuItemsList
        } = store.getState().menuItemsData
        dispatch({
          type: UPDATE_MENU_ITEMS_LIST,
          payload: { menuItemsList, menuItem: data }
        })
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const deactivateItem = (menuItemId) => dispatch => {
  const { method, url } = menuItemEndpoints.deactivate
  const menuActivateUrl = url.replace(':menuItemId', menuItemId)
  getItemsList(method, menuActivateUrl)
    .then(res => {
      getMenuItemById(menuItemId).then(res => {
        const { data = {} } = res
        const {
          menuItemsList
        } = store.getState().menuItemsData
        dispatch({
          type: UPDATE_MENU_ITEMS_LIST,
          payload: { menuItemsList, menuItem: data }
        })
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchMenuCategories = () => dispatch => {
  const { method, url } = menuCategoriesEndpoints.list
  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      const { menu_categories: menuCategories } = data
      dispatch({
        type: FETCH_MENU_CATEGORIES,
        payload: menuCategories
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const createCategories = data => dispatch => {
  const { method, url } = menuCategoriesEndpoints.create
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: CREATE_MENU_CATEGORIES,
        payload: data
      })
    })
    .then(() => {
      dispatch(fetchMenuCategories())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const deleteCategory = id => dispatch => {
  const { method, url } = menuCategoriesEndpoints.delete(id)
  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: DELETE_MENU_CATEGORIES,
        payload: data
      })
    })
    .then(() => {
      dispatch(fetchMenuCategories())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateCategories = (id, data) => dispatch => {
  const { method, url } = menuCategoriesEndpoints.update(id)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: UPDATE_MENU_CATEGORIES,
        payload: data
      })
    })
    .then(() => {
      dispatch(fetchMenuCategories())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetUpdateMessage = () => dispatch => {
  dispatch({
    type: REST_MENU_CATEGORIES_UPDATE_MESSAGE
  })
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { getEventSuiteById } from '../../store/eventSuites/actions'
import styles from './ViewEventSuite.css'
import ViewEventSuiteContent from './ViewEventSuiteContent'
import ViewEventSuiteTitle from './ViewEventSuiteTitle'

const ACTION_MODE = 'View'

const getDialogContent = (eventSuite, handleUpdate, handleCancel, validationError) => {
  return <ViewEventSuiteContent
    eventSuite={eventSuite}
    handleUpdate={handleUpdate}
    handleCancel={handleCancel}
    validationError={validationError}
  />
}

const getTitle = (classes, actionMode) =>
  <ViewEventSuiteTitle
    actionMode={actionMode}
    className={classes.eventSuiteTitle}
  />

const ViewEventSuite = ({
  classes,
  eventSuiteId,
  eventSuite,
  getEventSuiteById,
  handleDialogClose,
  openDialog
}) => {
  useEffect(() => {
    if (eventSuiteId) {
      getEventSuiteById(eventSuiteId)
    }
  }, [eventSuiteId, getEventSuiteById])

  return (
    <React.Fragment>
      { eventSuite && <Dialog
        openDialog={openDialog}
        dialogTitle={getTitle(classes, ACTION_MODE)}
        dialogContent={getDialogContent(eventSuite, handleDialogClose)}
        dialogActions={null}
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    eventCategories: state.staticData.event_categories,
    event: state.eventsData.eventToEdit,
    eventSuite: state.eventSuitesData.eventSuiteToEdit
  }
}

const mapDispatchToProps = {
  getEventSuiteById
}

ViewEventSuite.propTypes = {
  classes: PropTypes.object,
  eventSuiteId: PropTypes.number.isRequired,
  getEventSuiteById: PropTypes.func,
  handleDialogClose: PropTypes.func,
  eventSuite: PropTypes.object,
  openDialog: PropTypes.bool
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEventSuite))

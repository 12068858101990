import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '../MenuItem/MenuItem'

const getItemsGridByCategory = (items, openMenuItemDetailsDialog, handleAddToCart, orderWindow, currentUser) => {
  return items.map(item => {
    return <MenuItem
      item={item}
      key={item.id}
      openMenuItemDetailsDialog={openMenuItemDetailsDialog}
      handleAddToCart={handleAddToCart}
      orderWindow={orderWindow}
      currentUser={currentUser}
    />
  })
}

const MenuItemsByCategory = ({
  category,
  items,
  classes,
  openMenuItemDetailsDialog,
  handleAddToCart,
  orderWindow,
  currentUser
}) => {
  const hasItems = Array.isArray(items) && items.length > 0

  return (
    <React.Fragment>
      {
        hasItems && <div key={category} id={category} className={classes.menuItemsByCateoryContainer}>
          <div className={classes.categoryTitle}>
            {category}
          </div>
          <div className={classes.menuItemsList}>
            {hasItems && getItemsGridByCategory(items, openMenuItemDetailsDialog, handleAddToCart, orderWindow, currentUser)}
          </div>
        </div>
      }
    </React.Fragment>
  )
}

MenuItemsByCategory.propTypes = {
  category: PropTypes.string,
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  handleAddToCart: PropTypes.func,
  items: PropTypes.array,
  openMenuItemDetailsDialog: PropTypes.func,
  orderWindow: PropTypes.string
}

export default MenuItemsByCategory

import React from 'react'
import PropTypes from 'prop-types'
import ExistingAssignedCategory from './ExistingAssignedCategory'
import NewAssignedCategory from './NewAssignedCategory'
const MenuCategories = ({
  classes,
  assignedCategories,
  allCategories,
  showNewCategorySelector = false,
  addCategory,
  updateSubcategory,
  removeNewlyAssignedCategory,
  markExistingCategoryToDelete
}) => {
  return (
    <div className={classes.fieldContainer}>
      <span className={classes.fieldTitle}> MENU CATEGORIES </span>
      <div className={classes.categoryHeaders}>
        <div> Category </div>
        <div> Subcategory </div>
        <div> Remove </div>
      </div>
      {
        assignedCategories.map((assignedCategory, index) => {
          if (assignedCategory.id) {
            return <ExistingAssignedCategory
              index={index}
              key={index}
              classes={classes}
              allCategories={allCategories}
              assignedCategory={assignedCategory}
              updateSubcategory={updateSubcategory}
              addCategory={addCategory}
              markExistingCategoryToDelete={markExistingCategoryToDelete}
            />
          } else {
            return <NewAssignedCategory
              index={index}
              key={index}
              availableCategories={allCategories}
              classes={classes}
              assignedCategory={assignedCategory}
              addCategory={addCategory}
              updateSubcategory={updateSubcategory}
              removeNewlyAssignedCategory={removeNewlyAssignedCategory}
            />
          }
        })
      }
      {
        showNewCategorySelector &&
          <NewAssignedCategory
            availableCategories={allCategories}
            classes={classes}
            assignedCategories={assignedCategories}
            addCategory={addCategory}
          />
      }
    </div>
  )
}

MenuCategories.propTypes = {
  classes: PropTypes.object,
  assignedCategories: PropTypes.array,
  showNewCategorySelector: PropTypes.bool,
  allCategories: PropTypes.array,
  addCategory: PropTypes.func,
  updateSubcategory: PropTypes.func,
  removeNewlyAssignedCategory: PropTypes.func,
  markExistingCategoryToDelete: PropTypes.func
}

export default MenuCategories

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import styles from './OrderDelayMessage.css'
const getTitle = (classes) => {
  return <div className={classes.orderDelayMessageTitle}>
    { 'Hang Tight...' }
  </div>
}
const getDialogContent = (classes) => {
  return <div className={classes.orderDelayMessageBody}>
    {'This process could take a minute or longer to complete. Please do not leave or refresh this page until you receive a confirmation message.'}
  </div>
}
const getApiStatus = (apiStatus) => {
  if (!apiStatus) {
    return true
  }
}

const OrderDelayMessage = ({
  classes,
  openDialog,
  apiStatus
}) => {
  useEffect(() => {
    getApiStatus()
  }, [apiStatus])

  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(classes)}
      dialogContent={getDialogContent(classes)}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
    />
  )
}

OrderDelayMessage.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool,
  apiStatus: PropTypes.bool
}

export default withStyles(styles)(OrderDelayMessage)

import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/events'

export const prepareEventsListQuery = (filters, page, limit, sort = {}) => {
  const { status = null, query, eventCategory, startDate = '', endDate = '' } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      date: {
        start_date: startDate.replace('/-/g', '/'),
        end_date: endDate.replace('/-/g', '/')
      },
      category: eventCategory,
      status: status,
      query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

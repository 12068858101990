import { combineReducers } from 'redux'
import authReducer from './authentication/reducers'
import errorReducer from './errors/reducer'
import orderReducer from './orders/reducers'
import staticData from './staticData/reducer'
import menuItemsData from './menuItems/reducer'
import eventsData from './events/reducer'
import companiesData from './companies/reducer'
import staffUsersData from './staffUsers/reducer'
import suitesData from './suites/reducer'
import shareTypesData from './shareTypes/reducer'
import suiteHolderUsersData from './suiteHolderUsers/reducer'
import menuItemsCatalog from './menuCatalog/reducer'
import checkoutData from './CheckoutFlow/reducer'
import clientEventsData from './ClientEvents/reducer'
import accountInfo from './settings/reducer'
import reportsData from './Reports/reducer'
import eventSuitesData from './eventSuites/reducer'
import creditCardsReducers from './creditCard/reducer'

import { USER_LOGOUT } from './actionTypes'

const combinedReducers = combineReducers({
  authReducer,
  accountInfo,
  errorReducer,
  eventSuitesData,
  orderReducer,
  staticData,
  menuItemsData,
  eventsData,
  companiesData,
  staffUsersData,
  suitesData,
  shareTypesData,
  suiteHolderUsersData,
  menuItemsCatalog,
  checkoutData,
  clientEventsData,
  reportsData,
  creditCardsReducers
})

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined
  }
  return combinedReducers(state, action)
}

export default rootReducer

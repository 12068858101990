import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CustomButton from '../../elements/CustomButton/CustomButton'
import CustomFabButton from '../../elements/CustomFabButton/CustomFabButton'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import ClientEventCutoff from './ClientEventCutoff'

import styles from './ClientEventDetails.css'

const ClientEventDetails = ({
  classes,
  event,
  handleShowEventDetails
}) => {
  const handleSelection = () => {}

  return (
    <div className={classes.eventDetailsContainer}>
      <div className={classes.eventImageContainer}>
        <img className={classes.eventThumbnail} src={event.thumbnail || DEFAULT_IMAGE_URL} alt={event.webName} />
      </div>
      <div className={classes.eventBasicDetailsContainer}>
        <div className={classes.eventWindow}>{event.orderWindow}</div>
        <div className={classes.eventName}>{event.webName}</div>
        <div className={classes.eventEatecId}>{event.webDescription}</div>
        {
          (event.ordersCount === 0)
            ? <div className={classes.noOrdersText}>No Current Orders</div>
            : <CustomFabButton
              variant="extended"
              color="default"
              styleClasses={`${classes.genericFabPill}`}
              disableRipple
              disableFocusRipple
              label={`${event.ordersCount} Current Order`}
              value={`${event.ordersCount} Current Order`}
              clickHandler={handleSelection}
            />
        }
      </div>
      <ClientEventCutoff
        nonKosherMenuCutoff={event.nonKosherMenuCutoff}
        kosherMenuCutoff={event.kosherMenuCutoff}
      />
      <CustomButton
        variant={'contained'}
        disableRipple
        styleClass={classes.placeOrderButton}
        label={'Add an Order'}
        color={'primary'}
        clickHandler={(clientEvent = event) => handleShowEventDetails(clientEvent)}
      />
    </div>
  )
}

ClientEventDetails.propTypes = {
  classes: PropTypes.object,
  handleShowEventDetails: PropTypes.func,
  event: PropTypes.object
}

export default withStyles(styles)(ClientEventDetails)

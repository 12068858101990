const styles = {
  quntitySelector: {
    color: 'black',
    width: '38px',
    marginLeft: '22px',
    '& svg': {
      paddingLeft: '10px',
      fontSize: '34px',
      top: 'calc(50% - 18px)',
      position: 'absolute',
      left: 'calc(50% - 11px)'
    }
  },
  selectQuantity: {
    color: 'black',
    borderBottom: '1px dotted black',
    padding: '0px 14px 0px 6px',
    '&:focus': {
      backgroundColor: 'white'
    },
    '&:disabled': {
      opacity: 0.5
    },
    minWidth: '16px'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const EditCompanyDialogActions = ({
  classes,
  handleCancel,
  handleUpdate,
  handleAddSuite
}) => {
  return (
    <div className={classes.editCompanyActionsContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="outlined" className={classes.assignSuite} onClick={handleAddSuite}>
            Add Suite
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" className={classes.actionButton} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" className={classes.saveButton} onClick={handleUpdate}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

EditCompanyDialogActions.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleAddSuite: PropTypes.func
}

export default EditCompanyDialogActions

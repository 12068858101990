import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core'
import styles from './DateSelector.css'

const DateSelector = ({ label, placeholder, value, changeHandler, classes }) => {
  const handleBlur = e => {
    const { value } = e.target
    e.target.type = 'text'
    if (value) e.target.value = moment(value).format('MM/DD/YYYY')
  }

  const handleFocus = e => {
    e.target.type = 'date'
  }

  return (
    <TextField
      id={label}
      label={label}
      type="text"
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={changeHandler}
      placeholder={placeholder}
      InputLabelProps={{
        shrink: true
      }}
      className={classes.dateSelectorTextField}
    />
  )
}

DateSelector.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

DateSelector.defaultProps = {
  label: null,
  placeholder: 'Select Date',
  value: null
}

export default withStyles(styles)(DateSelector)

import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Grid } from '@material-ui/core'
import FilterSelector from '../../elements/FilterSelector/FilterSelector'

const ExistingAssignedCategory = ({
  assignedCategory,
  classes,
  index,
  markExistingCategoryToDelete
}) => {
  const {
    parent_category_id: categoryId,
    parent_category_name: categoryName,
    sub_category_id: subCategoryId,
    sub_category_name: subcategoryName,
    _destroy
  } = assignedCategory

  const category = [{
    id: categoryId,
    name: categoryName
  }]

  const subCategory = [{
    id: subCategoryId,
    name: subcategoryName
  }]

  const emptyHandler = () => null

  return (
    <div className={classes.categoryContainer}>
      <div className={classes.selectContainer}>
        <Grid item xs={5} className={classes.parentCategorySelect}>
          <FilterSelector
            name={'parentCategorySelector'}
            label={'Category'}
            value={categoryId}
            changeHandler={emptyHandler}
            selectionOptions={category}
            optionValueKey={'name'}
            styles={{ selectorWidth: '100%' }}
            shrink={false}
            displayLabel={false}
            defaultOption={'Select Category'}
            disabled
          />
        </Grid>
        <Grid item xs={5} className={classes.subCategorySelect}>
          <FilterSelector
            name={'parentSubcategorySelector'}
            label={'Subcategory'}
            value={subCategoryId}
            changeHandler={emptyHandler}
            selectionOptions={subCategory}
            optionValueKey={'name'}
            styles={{ selectorWidth: '100%' }}
            shrink={false}
            displayLabel={false}
            defaultOption={'Select Subcategory'}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            checked={!!_destroy}
            classes={{
              root: classes.deleteCategoryCheckbox
            }}
            onChange={() => markExistingCategoryToDelete(index)}
          />
        </Grid>
      </div>
    </div>
  )
}

ExistingAssignedCategory.propTypes = {
  assignedCategory: PropTypes.object,
  classes: PropTypes.object,
  index: PropTypes.number,
  markExistingCategoryToDelete: PropTypes.func
}

export default ExistingAssignedCategory

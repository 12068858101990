import React from 'react'
import PropTypes from 'prop-types'
const PRICE_LABELS = {
  ADVANCE: 'ADVANCE PRICE',
  GAMEDAY: 'GAMEDAY PRICE'
}
const GetPriceInfo = ({
  classes,
  menuItem = {}
}) => {
  return (
    <div className={`${classes.priceListContainer} ${classes.fieldContainer}`}>
      <div>
        <span className={classes.fieldTitle}> {PRICE_LABELS.ADVANCE} </span>
        <ul className={classes.priceList}>
          {menuItem.advance_price && menuItem.advance_price.map(item => <li key={item.id}>{item.location_name} - ${item.price}</li>)}
        </ul>
      </div>
      <div>
        <span className={classes.fieldTitle}> {PRICE_LABELS.GAMEDAY} </span>
        <ul className={classes.priceList}>
          {menuItem.advance_price && menuItem.game_day_price.map(item => <li key={item.id}>{item.location_name} - ${item.price}</li>)}
        </ul>
      </div>
    </div>
  )
}

GetPriceInfo.propTypes = {
  classes: PropTypes.object,
  menuItem: PropTypes.object
}

export default GetPriceInfo

import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import SearchField from '../SearchField/SearchField'
import ResetFilters from '../ResetFilters/ResetFilters'
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_CLEAR_SELECTION,
  ANALYTICS_ACTION_FILTER_USERS,
  ANALYTICS_PAGE_TYPE_CLIENT_USERS,
  ANALYTICS_ACTION_TYPE_USERS_FILTER,
  ANALYTICS_ACTION_TYPE_USERS_CLEAR_FILTERS
} from '../../constants/analytics'
import styles from './SuiteHolderUsersFilterBar.css'

const SuiteHolderUserFilterBar = ({
  addSuiteHolderUserHandler,
  classes,
  ctaLabel,
  filters = {},
  filterItems,
  styles
}) => {
  const [query, setSearchQuery] = useState(filters.query || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const handleSubmit = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_FILTER_USERS,
        actionType: ANALYTICS_ACTION_TYPE_USERS_FILTER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionSearchQuery: query
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_USERS
    })
    filterItems({ query })
  }

  const clearFilters = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CLEAR_SELECTION,
        actionType: ANALYTICS_ACTION_TYPE_USERS_CLEAR_FILTERS,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_USERS
    })
    setSearchQuery('')
    setResetFiltersFlag(false)
    filterItems({ query: '' })
  }

  return (
    <React.Fragment>
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />

      <FormControl className={classes.addSuiteHolderUserButtonContainer}>
        <Button variant="contained" color="primary" className={classes.addSuiteHolderUserButtton} onClick={addSuiteHolderUserHandler}>
          {ctaLabel}
        </Button>
      </FormControl>
    </React.Fragment>
  )
}

SuiteHolderUserFilterBar.propTypes = {
  filterItems: PropTypes.func,
  filters: PropTypes.object,
  ctaLabel: PropTypes.string,
  classes: PropTypes.object,
  styles: PropTypes.object,
  addSuiteHolderUserHandler: PropTypes.func
}

export default withStyles(styles)(SuiteHolderUserFilterBar)

import { history } from '../store'
import { logoutUser } from '../authentication/actions'
import {
  ADD_EVENT_SUITES,
  FETCH_EVENT_SUITES,
  UPDATE_EVENT_SUITES,
  STORE_EVENT_SUITE_TO_EDIT,
  RESET_EVENT_SUITE_TO_EDIT,
  STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT
} from '../actionTypes'
import { eventsSuitesEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { getItemsList } from '../helpers/apiClient'
import { sortParamsMapping } from '../../constants/eventSuites'
import { refreshEventsList } from '../events/actions'

const prepareEventSuitesListQuery = (filters, page, limit, sort = {}) => {
  const { status, query, eventId } = cleanUpFilterObject(filters)
  const { sortBy = 'suiteName', order = 'desc' } = sort

  return {
    filters: {
      is_available: status,
      query,
      event_id: eventId
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchEventSuites = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { eventId } = filters
  const { method, url } = eventsSuitesEndpoints.suites(eventId)
  const data = prepareEventSuitesListQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { items, meta } = data
      dispatch({
        type: FETCH_EVENT_SUITES,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateEventSuiteMapping = eventSuite => dispatch => {
  const {
    additionalPasses,
    allowAdditionalPasses,
    allowFoodOrder,
    availableSeats,
    costPerPass,
    id,
    isFeatured = false,
    price,
    status
  } = eventSuite
  const { method, url } = eventsSuitesEndpoints.update(id)
  const data = {
    additional_seat_cost: allowAdditionalPasses ? costPerPass : 0,
    allow_food_order: allowFoodOrder,
    allow_additional_passes: allowAdditionalPasses,
    available_additional_passes: allowAdditionalPasses ? additionalPasses : 0,
    available_seats: availableSeats,
    price,
    is_available: status,
    is_featured: isFeatured
  }
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: UPDATE_EVENT_SUITES,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const addEventSuiteMapping = eventSuite => dispatch => {
  const {
    additionalAvailablePasses,
    allowFoodOrder,
    allowAdditionalPasses,
    availableSeats,
    costPerPass,
    eventId,
    isFeatured,
    price,
    suiteId,
    status
  } = eventSuite
  const { method, url } = eventsSuitesEndpoints.add
  const data = {
    additional_seat_cost: allowAdditionalPasses ? costPerPass : 0,
    allow_additional_passes: allowAdditionalPasses,
    allow_food_order: allowFoodOrder,
    available_additional_passes: allowAdditionalPasses ? additionalAvailablePasses : 0,
    available_seats: availableSeats,
    event_id: eventId,
    is_available: status,
    is_featured: isFeatured,
    price,
    suite_id: suiteId
  }

  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: ADD_EVENT_SUITES,
        payload: data
      })
      dispatch(refreshEventsList())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const getEventSuiteById = eventSuiteId => dispatch => {
  const { method, url } = eventsSuitesEndpoints.byId(eventSuiteId)
  getItemsList(method, url)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: STORE_EVENT_SUITE_TO_EDIT,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetEventSuiteToEdit = () => dispatch => {
  dispatch({
    type: RESET_EVENT_SUITE_TO_EDIT
  })
}

export const fetchAssignedSuitesToCurrentEvent = eventId => dispatch => {
  const { method, url } = eventsSuitesEndpoints.suites(eventId)
  const data = prepareEventSuitesListQuery({ eventId }, 0, 100)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { items } = data
      dispatch({
        type: STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT,
        payload: { suites: items }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const voidSuiteReservtionById = (eventId, eventSuiteId) => dispatch => {
  const { method, url } = eventsSuitesEndpoints.voidReservation(eventSuiteId)
  getItemsList(method, url)
    .then(res => {
      dispatch(fetchEventSuites({ eventId }))
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

module.exports.OrderItemProperties = [
  {
    key: 'eventDate',
    label: 'EVENT DATE',
    sortable: true
  },
  {
    key: 'eventName',
    label: 'EVENT',
    sortable: false
  },
  {
    key: 'id',
    label: 'ORDER',
    sortable: false
  },
  {
    key: 'createdDate',
    label: 'SUBMITTED',
    sortable: true
  },
  {
    key: 'submittedBy',
    label: 'LAST MODIFIED BY',
    sortable: true
  },
  {
    key: 'suite',
    label: 'SUITE',
    sortable: false
  },
  {
    key: 'menuTypeName',
    label: 'MENU TYPE',
    sortable: false
  },
  {
    key: 'status',
    label: 'STATUS',
    sortable: false
  },
  {
    key: 'total',
    label: 'TOTAL',
    sortable: false
  }
]

module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.sortParamsMapping = {
  eventDate: 'event_date',
  submittedBy: 'submitted_by_id',
  createdDate: 'submitted_date',
  orderId: 'order_id'
}

module.exports.styles = {
  selectorWidth: '100px',
  searchFieldWidth: '200px'
}

module.exports.rowsFor = 'orders'
module.exports.resourceName = 'orders'
module.exports.DEFAULT_CURRENT_PAGE = 'orders'

import React from 'react'
import PropTypes from 'prop-types'

const VoidEventSuiteReservationTitle = ({
  classes,
  suiteName
}) => {
  return (
    <div className={classes.title}>
      Are you sure you want to void the reservation for {suiteName}?
    </div>
  )
}

VoidEventSuiteReservationTitle.propTypes = {
  classes: PropTypes.string,
  suiteName: PropTypes.string
}

export default VoidEventSuiteReservationTitle

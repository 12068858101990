import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './BillSummaryLineItem.css'

const BillSummaryLineItem = ({
  classes,
  label = '',
  value1 = '',
  value2 = '',
  paymentLineItem = false
}) => {
  return (
    <div className={`${paymentLineItem ? classes.paymentSummaryLineItem : classes.billSummaryLineItem}`}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value1}>{value1}</div>
      <div className={classes.value2}>{value2}</div>
    </div>
  )
}

BillSummaryLineItem.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  value1: PropTypes.string,
  value2: PropTypes.string,
  paymentLineItem: PropTypes.bool
}

export default withStyles(styles)(BillSummaryLineItem)

export const styles = {
  genericPaperStyles: {
    minHeight: '575px',
    minWidth: '586px',
    maxWidth: '586px',
    borderRadius: '0px',
    overflowX: 'hidden'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'black',
    display: 'initial',
    padding: '12px 40px'
  },
  menuItemDetailsActionContainer: {
    backgroundColor: 'black',
    color: '#FFFFFF'
  },
  menuItemQuantityControlContainer: {
    float: 'left'
  },
  menuItemQuantityTitle: {
    height: '14px',
    width: '234px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    marginBottom: '16px'
  },
  manageQuantity: {
    backgroundColor: 'black',
    width: '25px',
    maxHeight: '25px',
    minHeight: '25px',
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  quantityControls: {
    fontSize: '25px'
  },
  quantityValue: {
    color: 'white',
    width: '40px',
    padding: '0px',
    textAlign: 'center',
    borderBottom: '2px dotted white',
    margin: '0px 2px'
  },
  controlContainer: {
    display: 'flex'
  },
  quantiyValueContainer: {
    padding: '0px 6px'
  },
  addToCartButton: {
    width: '200px',
    height: '50px',
    borderRadius: '0px',
    marginTop: '30px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular A',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none'
  },
  genericDialogContentRoot: {
    backgroundColor: 'black',
    color: 'black',
    padding: '0px',
    overflowY: 'unset'
  },
  menuItemDetailsContainer: {
    padding: '20px 40px',
    backgroundColor: 'black'
  },
  menuItemDetailsName: {
    width: '505px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px',
    paddingBottom: '1px'
  },
  menuItemDetailsDescription: {
    width: '506px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '0px',
    '& button': {
      right: 0,
      top: 325,
      color: 'white'
    }
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: 'black'
  },
  menuItemImage: {
    height: '329px',
    width: '586px'
  },
  menuItemImageHolder: {
    height: '329px'
  },
  priceByOrderWindowSelector: {
    width: '100%',
    padding: '0px 10px',
    marginTop: '12px',
    float: 'right'
  },
  orderWindowSelector: {
    width: '100%',
    marginTop: '12px',
    float: 'left'
  },
  menuItemDetailsAddToCartButtonContainer: {
    width: '100%',
    '& button': {
      width: '100%',
      backgroundColor: '#0078D4',
      '&:hover': {
        backgroundColor: '#0078D4'
      }
    }
  },
  menuItemName: {
    float: 'right',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    margin: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dietButton: {
    color: '#061860',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textAlign: 'center',
    height: '18px',
    width: '30px',
    borderRadius: '12px',
    backgroundColor: '#65FFF5',
    minHeight: '18px',
    marginTop: '10px',
    marginRight: '5px',
    '&:first-child': {
      marginRight: '6px',
      marginLeft: '10px'
    },
    '&:hover': {
      color: 'black',
      backgroundColor: '#65FFF5'
    }
  }
}

const styles = {
  resetFilterIcon: {
    paddingTop: '25px',
    height: '50px',
    border: '0',
    margin: '5px',
    display: 'inline-flex',
    position: 'relative',
    minWidth: '0',
    flexDirection: 'column',
    verticalAlign: 'bottom',
    cursor: 'pointer',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    textDecoration: 'underline solid #0078D4',
    fontWeight: 'bold'
  }
}

export default styles

import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import useStyles from './SearchField.css'

const SearchField = ({
  handleSubmit,
  handleSearchQueryInput,
  query,
  searchOnKeyPress,
  styles
}) => {
  const classes = useStyles({ styles })

  const keypress = (e) => {
    if (searchOnKeyPress) return
    if (e.keyCode === 13) {
      handleSubmit(e)
    }
  }

  return (
    <Fragment>
      <div className={classes.searchTextFieldContainer}>
        <TextField
          id="outlined-search"
          label="Search"
          type="search"
          margin="normal"
          value={query}
          onChange={handleSearchQueryInput}
          onKeyDown={keypress}
          autoComplete={'off'}
          className={classes.searchInputField}
        />
      </div>
      <FormControl>
        <div className={classes.searchIconContainer}>
          <SearchIcon className={classes.searchIcon} onClick={handleSubmit} />
        </div>
      </FormControl>
    </Fragment>
  )
}

SearchField.propTypes = {
  query: PropTypes.string,
  handleSearchQueryInput: PropTypes.func,
  handleSubmit: PropTypes.func,
  searchOnKeyPress: PropTypes.bool,
  styles: PropTypes.object
}

SearchField.defaultPropTypes = {
  searchOnKeyPress: false
}

export default SearchField

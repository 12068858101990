const styles = {
  eventContainer: {
    height: 'auto',
    width: '1209px',
    display: 'flex',
    marginBottom: '20px',
    borderBottom: '1px solid #4D4E58',
    paddingBottom: '12px'
  },
  eventsListContainer: {
    paddingBottom: '60px'
  },
  dateSeperatorLabel: {
    marginTop: '27px',
    display: 'inline-flex',
    marginLeft: '8px',
    marginRight: '8px'
  }
}

export default styles

/** Depracated */
import {
  DOMAIN_NAME,
  SITE_NAME,
  ANALYTICS_PAGE_TYPE_CLIENT_EVENTS,
  ANALYTICS_PAGE_TYPE_CLIENT_USERS,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS,
  ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT,
  ANALYTICS_PAGE_TYPE_LOGIN,
  ANALYTICS_EVENT_PAGE_LOAD,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_HEADER,
  ANALYTICS_ACTION_TYPE_PRIMARY_NAVIGATION
} from '../constants/analytics'

import store, { history } from '../store/store'
import { currentUserIsAdmin } from '../store/helpers/userRoleManager'
import { reportGoogleAnalytics } from './googleAnalytics'

const site = {
  site_name: SITE_NAME,
  site_domain: DOMAIN_NAME
}

const getGlobalData = (pageType, urlPath) => {
  const currentUrlPath = urlPath.replace('/', '')
  return {
    page_url: `${DOMAIN_NAME}/${currentUrlPath}`,
    canonical_url: `${DOMAIN_NAME}/${currentUrlPath}`,
    page_path: currentUrlPath,
    page_id: currentUrlPath,
    page_template: currentUrlPath,
    page_type: pageType
  }
}

export const reportAnalytics = ({
  eventType,
  detail,
  pageType,
  urlPath = history.location.pathname
}) => {
  console.log(
    eventType,
    detail,
    pageType,
    urlPath = history.location.pathname
  )
  const {
    user: currentUser = {}
  } = store.getState().authReducer
  if (currentUser && currentUserIsAdmin(currentUser)) return
  const global = getGlobalData(pageType, urlPath)
  document.body.dispatchEvent(new CustomEvent(eventType, { bubbles: true, detail: { ...detail, site, global } }))
}

export const reportClientEventsPageLoad = (pageType, urlPath, detail = {}) => {
  reportAnalytics({
    eventType: ANALYTICS_EVENT_PAGE_LOAD,
    detail,
    pageType,
    urlPath
  })
}

export const reportPageLoad = (urlPath) => {
  switch (urlPath) {
    case '/login':
      reportClientEventsPageLoad(ANALYTICS_PAGE_TYPE_LOGIN, urlPath)
      break
    case '/my-events':
      reportGoogleAnalytics(ANALYTICS_PAGE_TYPE_CLIENT_EVENTS)
      reportClientEventsPageLoad(ANALYTICS_PAGE_TYPE_CLIENT_EVENTS, urlPath)
      break
    case '/my-orders':
      reportClientEventsPageLoad(ANALYTICS_PAGE_TYPE_CLIENT_ORDERS, urlPath)
      break
    case '/my-account':
      reportClientEventsPageLoad(ANALYTICS_PAGE_TYPE_CLIENT_ACCOUNT, urlPath)
      break
    case '/suite-holder-users':
      reportClientEventsPageLoad(ANALYTICS_PAGE_TYPE_CLIENT_USERS, urlPath)
      break
    default:
      window.__reportingUrlPath = urlPath || 'unknown'
  }
}

export const reportNavClicks = (urlPath) => {
  let actionName, pageType
  switch (urlPath) {
    case '/my-events':
      actionName = 'events'
      pageType = ANALYTICS_PAGE_TYPE_CLIENT_EVENTS
      break
    case '/my-orders':
      actionName = 'orders'
      pageType = ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
      break
    case '/suite-holder-users':
      actionName = 'users'
      pageType = ANALYTICS_PAGE_TYPE_CLIENT_USERS
      break
    default:
      window.__reportingUrlPath = urlPath || 'unknown'
  }
  if (!actionName || !pageType) return
  reportAnalytics({
    eventType: ANALYTICS_EVENT_BUTTON_CLICK,
    detail: {
      actionName,
      actionLocation: ANALYTICS_ACTION_LOCATION_HEADER,
      actionType: ANALYTICS_ACTION_TYPE_PRIMARY_NAVIGATION
    },
    pageType
  })
}

const styles = {
  genericPaperStyles: {
    minHeight: '404px',
    minWidth: '350px',
    maxWidth: '350px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    paddingLeft: '38px',
    paddingRight: '38px',
    paddingTop: '30px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '40px 40px 10px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px'
  },
  saveStaffUserActionsContainer: {
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    marginTop: '30px'
  },
  focused: {
    borderBottom: '0px'
  },
  cancelButton: {
    width: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px'
  },
  saveStaffUserButton: {
    width: '100%',
    marginBottom: '10px'
  },
  staffUserDetailsInputFields: {
    width: '274px',
    margin: '10px 0px'
  },
  saveStaffUserActionsTitle: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '11px',
    fontWeight: '600',
    height: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  validationErrText: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    color: '#FF0000'
  }
}

export default styles

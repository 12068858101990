import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const ShareTypeActions = ({ classes, onCancel, onSave }) => {
  return (
    <div className={classes.actionContainer}>
      <Button variant="outlined" color="default" className={classes.cancelButton} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="outlined" color="primary" className={classes.submitButton} onClick={onSave}>
        Save
      </Button>

    </div>
  )
}

ShareTypeActions.propTypes = {
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

ShareTypeActions.defaultProps = {
  classes: {}
}

export default ShareTypeActions

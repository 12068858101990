import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

const styles = {
  continueButton: {
    width: '370px',
    height: '50px',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0px',
    textTransform: 'none',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontWeight: 'bold'
  },
  cancelButton: {
    width: '370px',
    height: '50px',
    color: 'black',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    marginRight: '30px',
    paddingRight: '0px',
    boxShadow: 'none',
    borderRadius: '0px',
    textTransform: 'none',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontWeight: 'bold'
  },
  buttonContainer: {
    width: '773px',
    marginLeft: '44px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}
function CreateOrderDialogActions ({ classes, handleCancel, handleContinue }) {
  return (
    <div className={classes.buttonContainer}>
      <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" className={classes.continueButton} onClick={handleContinue}>
        Continue
      </Button>
    </div>
  )
}

CreateOrderDialogActions.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
  handleContinue: PropTypes.func
}

export default withStyles(styles)(CreateOrderDialogActions)

import {
  CREATE_SHARE_TYPE,
  FETCH_EVENTS_FOR_SHARE_TYPE,
  FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE,
  FETCH_SHARE_TYPES,
  RESET_EVENTS_FOR_SHARE_TYPE,
  UPDATE_SHARE_TYPE
} from '../actionTypes'
import { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { shareTypesEndpoints } from '../../constants/apiConfig'
import { getItemsList } from '../helpers/apiClient'

export const fetchShareTypes = () => dispatch => {
  const { method, url } = shareTypesEndpoints.list
  getItemsList(method, url, {})
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: FETCH_SHARE_TYPES,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchEventsForShareType = id => dispatch => {
  const { method, url } = shareTypesEndpoints.edit(id)
  getItemsList(method, url, {})
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: FETCH_EVENTS_FOR_SHARE_TYPE,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const fetchAvailableEventsForShareTypes = formData => dispatch => {
  const { method, url } = shareTypesEndpoints.availableEvents
  getItemsList(method, url, formData)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE,
        payload: data
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const createShareType = formData => dispatch => {
  const { method, url } = shareTypesEndpoints.create
  getItemsList(method, url, formData)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: CREATE_SHARE_TYPE,
        payload: data
      })
    })
    .then(() => {
      dispatch(fetchShareTypes())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const updateShareType = formData => dispatch => {
  const id = formData.get('id')
  const { method, url } = shareTypesEndpoints.update(id)

  getItemsList(method, url, formData)
    .then(res => {
      const { data = {} } = res
      dispatch({
        type: UPDATE_SHARE_TYPE,
        payload: data
      })
    })
    .then(() => {
      dispatch(fetchEventsForShareType(id))
      dispatch(fetchShareTypes())
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

export const resetEventsForShareType = () => dispatch => {
  dispatch({
    type: RESET_EVENTS_FOR_SHARE_TYPE,
    payload: {}
  })
}

import {
  ADD_EVENT_SUITES,
  FETCH_EVENT_SUITES,
  UPDATE_EVENT_SUITES,
  STORE_EVENT_SUITE_TO_EDIT,
  RESET_EVENT_SUITE_TO_EDIT,
  STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'

const hasOpportunityId = opportunityId => opportunityId && opportunityId.length > 0

const getEventsSuiteStatusText = (isAvailable, opportunityId) => {
  return hasOpportunityId(opportunityId)
    ? 'Booked'
    : isAvailable
      ? 'Available'
      : 'Not Available'
}
const processEventSuiteData = (eventSuite) => {
  const {
    allow_food_order: allowFoodOrder,
    additional_available_passes: additionalAvailablePasses,
    additional_pass_cost: additionalPassCost,
    available_seats: availableSeats,
    id,
    is_available: isAvailable,
    opportunity_id: opportunityId,
    price,
    suite_name: suiteName
  } = eventSuite

  return {
    additionalAvailablePasses,
    additionalPassCost,
    allowFoodOrder,
    availableSeats,
    isReserved: hasOpportunityId(opportunityId),
    id,
    isAvailable,
    status: getEventsSuiteStatusText(isAvailable, opportunityId),
    suiteName,
    price: +price,
    formattedPrice: `$${+price}.00`
  }
}

const storeEventSuiteToEdit = (eventSuite) => {
  if (eventSuite) {
    const {
      additional_available_passes: additionalAvailablePasses,
      additional_pass_cost: additionalPassCost,
      allow_additional_passes: allowAdditionalPasses,
      allow_food_order: allowFoodOrder,
      available_seats: availableSeats,
      id,
      is_available: isAvailable,
      is_featured: isFeatured,
      max_available_seats: maxAvailableSeats,
      max_available_passes: maxAvailablePasses,
      opportunity_id: opportunityId,
      price,
      suite_name: suiteName
    } = eventSuite
    return {
      additionalAvailablePasses,
      additionalPassCost: additionalPassCost ? parseInt(additionalPassCost, 10) : null,
      allowAdditionalPasses,
      allowFoodOrder,
      availableSeats,
      id,
      isAvailable,
      isFeatured,
      isReserved: hasOpportunityId(opportunityId),
      maxAvailableSeats,
      maxAvailablePasses,
      opportunityId,
      price,
      suiteName
    }
  } else {
    return {}
  }
}

export default function EventSuitesReducers (state = {}, action = {}) {
  switch (action.type) {
    case FETCH_EVENT_SUITES:
      return {
        ...state,
        eventSuitesList: processItemsList(action.payload, processEventSuiteData)
      }
    case UPDATE_EVENT_SUITES:
      return {
        ...state
      }
    case ADD_EVENT_SUITES:
      return {
        ...state
      }
    case STORE_EVENT_SUITE_TO_EDIT: {
      return {
        ...state,
        eventSuiteToEdit: storeEventSuiteToEdit(action.payload.event_suite)
      }
    }
    case STORE_ASSIGNED_SUITES_FOR_CURRENT_EVENT: {
      return {
        ...state,
        suitesAssignedToCurrentEvent: action.payload.suites
      }
    }
    case RESET_EVENT_SUITE_TO_EDIT: {
      return {
        ...state,
        eventSuiteToEdit: null
      }
    }
    default:
      return state
  }
}

export const AdminRoles = [
  {
    id: 1,
    name: 'Admin'
  },
  {
    id: 2,
    name: 'Finance'
  },
  {
    id: 5,
    name: 'SuperAdmin'
  }
]

export const AdminRoleIds = [1, 5]

export const ClientRoles = [
  {
    id: 3,
    name: 'SuiteHolderAdmin'
  },
  {
    id: 4,
    name: 'SuiteHolderUser'
  }
]

export const ClientRoleIds = [3, 4]

export const SuiteHolderAdminRoleId = 3

export const FinanceRoleIds = [2]

export const SuperAdminRoleID = 5

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../elements/CustomButton/CustomButton'

const EditCategoriesDialogActions = ({
  classes,
  selectedMenuCategories,
  handleAddCategory,
  actionMode = 'List',
  handleDeleteCategory,
  handleSaveCategory,
  handleCancelCategory
}) => {
  return (
    <div>
      {
        actionMode === 'List' && <CustomButton
          disableRipple
          styleClass={classes.categoriesButton}
          label="Add Category"
          variant="outlined"
          clickHandler={handleAddCategory}
        />
      }
      {
        actionMode === 'Edit' && selectedMenuCategories && <Fragment>
          <CustomButton
            disableRipple
            styleClass={classes.primaryCategoriesButton}
            label="Delete"
            variant="contained"
            color={'primary'}
            clickHandler={handleDeleteCategory}
          />
          <CustomButton
            disableRipple
            styleClass={classes.primaryCategoriesButton}
            label="Save"
            variant="contained"
            color={'primary'}
            clickHandler={handleSaveCategory}
            disabled={!selectedMenuCategories.name || !selectedMenuCategories.updatedSubCategory}
          />
          <CustomButton
            disableRipple
            styleClass={classes.categoriesButton}
            label="Cancel"
            variant="outlined"
            clickHandler={handleCancelCategory}
          />
        </Fragment>
      }{
        actionMode === 'Add' &&
          selectedMenuCategories &&
          <Fragment>
            <CustomButton
              disableRipple
              styleClass={classes.primaryCategoriesButton}
              label="Save"
              variant="contained"
              color={'primary'}
              disabled={!selectedMenuCategories.name || !selectedMenuCategories.updatedSubCategory}
              clickHandler={handleSaveCategory}
            />
            <CustomButton
              disableRipple
              styleClass={classes.categoriesButton}
              label="Cancel"
              variant="outlined"
              clickHandler={handleCancelCategory}
            />
          </Fragment>
      }
    </div>
  )
}

EditCategoriesDialogActions.propTypes = {
  classes: PropTypes.object,
  handleAddCategory: PropTypes.func,
  selectedMenuCategories: PropTypes.object,
  actionMode: PropTypes.string,
  handleDeleteCategory: PropTypes.func,
  handleSaveCategory: PropTypes.func,
  handleCancelCategory: PropTypes.func
}

export default EditCategoriesDialogActions

const styles = {
  paymentMethodOptionsContainer: {
    padding: '10px 40px'
  },
  sectionTitle: {
    margin: '12px 0px',
    height: '14px',
    width: '73px',
    color: 'black',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    display: 'inline-block'
  },
  paymentMethodButtonsContainer: {

  },
  genericFabPill: {
    background: 'transparent',
    boxShadow: 'none',
    height: '28px',
    fontSize: '13px',
    fontWeight: '300',
    lineHeight: '28px',
    textAlign: 'center',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    padding: '0px 20px',
    marginRight: '20px'
  },
  inactiveFabPill: {
    background: 'transparent',
    color: 'black',
    '&:hover': {
      color: 'white',
      background: 'black',
      boxShadow: 'none'
    }
  },
  activeFabPill: {
    background: 'black',
    color: 'white',
    '&:hover': {
      color: 'white',
      background: 'black',
      boxShadow: 'none'
    }
  },
  negativeBalance: {
    color: 'red',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    float: 'right',
    fontWeight: 900,
    marginRight: '12px'
  },
  creditCardBalaceAmount: {
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontWeight: 900,
    float: 'right',
    color: '#FF0000',
    marginRight: '12px'
  },
  zeroBalance: {
    color: 'green',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    float: 'right',
    fontWeight: 900,
    marginRight: '12px'
  },
  balanceTextTitle: {
    margin: '12px 0px',
    height: '14px',
    width: '73px',
    color: 'black',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    display: 'inline-block',
    float: 'right'
  }
}

export default styles

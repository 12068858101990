const styles = {
  quntitySelector: {
    color: 'black'
  },
  selectQuantity: {
    color: 'black',
    borderBottom: '1px dotted black',
    padding: '0px 20px 0px 1px',
    '&:focus': {
      backgroundColor: 'white'
    },
    minWidth: '16px'
  },
  orderItemName: {
    width: '260px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '15px',
    padding: '10px 5px 10px 15px'
  },
  unitPrice: {
    width: '85px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '15px',
    textAlign: 'right',
    padding: '10px'
  },
  totalPrice: {
    padding: '10px 5px 10px',
    width: '95px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '15px',
    textAlign: 'right'
  },
  orderItemRow: {
    display: 'flex',
    margin: '5px 0px'
  },
  deleteButtonWrapper: {
    padding: '0px',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  deleteIcon: {
    margin: '8px 5px',
    fontSize: '20px'
  }
}

export default styles

const styles = {
  scrollToTopButton: {
    backgroundColor: '#fff',
    width: '40px',
    height: '40px',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    borderRadius: '5px',
    border: 'none',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  arrowUpwardIcon: {
    color: '#0F1321',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-11px',
    marginLeft: '-11px'
  }
}

export default styles

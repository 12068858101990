import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputField from '../../elements/InputField/InputField'
import StatusField from '../../elements/StatusField/StatusField'
import SwitchButton from '../../elements/SwitchButton/SwitchButton'
import EditEventSuiteDialogActions from './EditEventSuiteDialogActions'
import styles from './EditEventSuiteDialogContent.css'

const getDialogActions = (classes, handleUpdate, handleCancel, disableUpdate) => {
  return <EditEventSuiteDialogActions
    classes={classes}
    disableUpdate={disableUpdate}
    handleUpdate={handleUpdate}
    handleCancel={handleCancel}
  />
}

const EditEventSuiteDialogContent = ({
  classes,
  eventSuite = {},
  handleCancel,
  handleUpdate,
  validationError
}) => {
  const [allowAdditionalPasses, setAllowAdditionalPasses] = useState(false)
  const [additionalPasses, setAdditionalPasses] = useState('')
  const [allowFoodOrder, setAllowFoodOrder] = useState(false)
  const [availableSeats, setAvailableSeats] = useState()
  const [costPerPass, setCostPerPass] = useState('')
  const [disableUpdate, setDisableUpdate] = useState()
  const [isFeatured, setIsFeatured] = useState(false)
  const [maxAdditionalPasses, setMaxAdditionalPasses] = useState(0)
  const [name, setName] = useState()
  const [price, setPrice] = useState()
  const [priceRangeError, setPriceRangeError] = useState(false)
  const [status, setStatus] = useState()

  const handleUpdateButtonClick = () => {
    handleUpdate({
      additionalPasses,
      allowAdditionalPasses,
      allowFoodOrder,
      availableSeats,
      costPerPass,
      id: eventSuite.id,
      isFeatured,
      price,
      status
    })
  }

  const handlePriceChange = price => {
    price = +price > 0 ? price : ''
    setPrice(price)
  }

  useEffect(() => {
    if (price < 1000 || price > 200000) {
      setPriceRangeError(true)
    } else {
      setPriceRangeError(false)
    }
  }, [price])

  const handleAdditionalAvailablePassChange = availablePasses => {
    const passes = +availablePasses > 0 ? availablePasses : ''
    setAdditionalPasses(passes)
  }

  const handleCostPerPassChange = passCost => {
    const price = +passCost > 0 ? passCost : ''
    setCostPerPass(price)
  }

  const handleAvailableSeataChange = availableSeats => {
    const seats = +availableSeats > 0 ? availableSeats : ''
    setAvailableSeats(seats)
  }

  useEffect(() => {
    let enableUpdate = true
    if (allowAdditionalPasses) {
      enableUpdate = additionalPasses > 0 && costPerPass > 0
    }
    enableUpdate = enableUpdate && price >= 1000 && price <= 200000
    enableUpdate = enableUpdate && availableSeats && +availableSeats > 0
    enableUpdate ? setDisableUpdate(false) : setDisableUpdate(true)
  }, [additionalPasses, allowAdditionalPasses, availableSeats, costPerPass, price])

  useEffect(() => {
    if (eventSuite) {
      const {
        availableSeats,
        additionalPassCost,
        additionalAvailablePasses,
        allowAdditionalPasses,
        allowFoodOrder,
        isAvailable,
        isFeatured,
        maxAvailablePasses,
        price,
        suiteName
      } = eventSuite
      setAdditionalPasses(additionalAvailablePasses)
      setAllowAdditionalPasses(allowAdditionalPasses)
      setAllowFoodOrder(allowFoodOrder)
      setAvailableSeats(availableSeats)
      setCostPerPass(additionalPassCost)
      setIsFeatured(isFeatured)
      setMaxAdditionalPasses(maxAvailablePasses)
      setName(suiteName)
      setPrice(`${price}`)
      setStatus(isAvailable)
    }
  }, [eventSuite])

  return (
    <div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          disabled
          id={'editEventSuiteName'}
          label={'SUITE NAME'}
          placeholder={'Name'}
          showLabel
          value={name}
        />
      </div>

      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          id={'editEventSuitePrice'}
          inputProps={{ pattern: '^[0-9]*$' }}
          label={'PRICE'}
          changeHandler={handlePriceChange}
          placeholder={''}
          showLabel
          value={price}
        />
        {priceRangeError && <FormHelperText>Please enter a value greater than or equal to $1,000 and less than or equal to $200,000</FormHelperText>}
      </div>

      <div className={classes.inputContainer}>
        <StatusField
          classes={{
            title: classes.fieldTitle,
            container: classes.statusFieldContainer,
            active: classes.active,
            inactive: classes.inactive
          }}
          currentStatus={status}
          setStatus={setStatus} />
      </div>
      <div className={classes.inputContainer}>
        <SwitchButton id="is-featured-suite" label="FEATURED SUITE" checked={isFeatured} onChange={(event) => setIsFeatured(event.target.checked)} />
      </div>
      <div className={classes.inputContainer}>
        <SwitchButton id="allow-food-order" label="ALLOW F&B ORDERING" checked={allowFoodOrder} onChange={(event) => setAllowFoodOrder(event.target.checked)} />
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          id={'createEventSuiteAvailableSeats'}
          label={'AVAILABLE SEATS'}
          changeHandler={handleAvailableSeataChange}
          placeholder={''}
          showLabel
          value={availableSeats}
        />
      </div>
      {
        maxAdditionalPasses > 0 &&
        <div className={classes.inputContainer}>
          <SwitchButton id="allow-food-order" label="ALLOW ADDITIONAL PASSES" checked={allowAdditionalPasses} onChange={(event) => setAllowAdditionalPasses(event.target.checked)} />
        </div>
      }
      {
        maxAdditionalPasses > 0 &&
        allowAdditionalPasses &&
        <>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteAvailableSeats'}
              label={'ADDITIONAL PASSES'}
              changeHandler={handleAdditionalAvailablePassChange}
              placeholder={''}
              showLabel
              value={additionalPasses}
            />
          </div>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteCostPerPass'}
              label="COST PER ADDITIONAL PASS"
              changeHandler={handleCostPerPassChange}
              placeholder={''}
              showLabel
              value={costPerPass}
            />
          </div>
        </>
      }
      {
        validationError && <span className={classes.validationErrText}>{validationError}</span>
      }
      <div className={classes.actionsContainer}>
        {getDialogActions(classes, handleUpdateButtonClick, handleCancel, disableUpdate)}
      </div>
    </div>
  )
}

EditEventSuiteDialogContent.propTypes = {
  classes: PropTypes.object,
  eventSuite: PropTypes.object,
  handleCancel: PropTypes.func,
  handleUpdate: PropTypes.func,
  validationError: PropTypes.string
}

export default withStyles(styles)(EditEventSuiteDialogContent)

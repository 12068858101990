import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import { withStyles } from '@material-ui/core/styles'
import styles from './StatusField.css'
const StatusField = ({
  classes,
  currentStatus,
  setStatus
}) => {
  return (
    <div className={classes.container}>
      <span className={classes.title}> STATUS </span>
      <div className={classes.statusButtonsContainer}>
        <Fab
          key={'active'}
          variant="extended"
          onClick={() => setStatus(true)}
          value
          className={`${currentStatus ? classes.active : classes.inactive}`}>
          Active
        </Fab>
        <Fab
          key={'inactive'}
          variant="extended"
          onClick={() => setStatus(false)}
          value={false}
          className={`${!currentStatus ? classes.active : classes.inactive}`}>
          Inactive
        </Fab>
      </div>
    </div>
  )
}

StatusField.propTypes = {
  classes: PropTypes.object,
  currentStatus: PropTypes.bool,
  setStatus: PropTypes.func
}

export default withStyles(styles)(StatusField)

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import styles from './EditableText.css'

const EditableText = ({ text = '', remove, classes, update, id }) => {
  const [value, setValue] = useState(text)
  const [isInEditableMode, setEditableMode] = useState(false)
  const textInput = useRef(null)

  const changeEditMode = (event) => {
    event.preventDefault()
    setEditableMode(!isInEditableMode)
  }

  const updateComponentValue = (event) => {
    event.preventDefault()
    const updatedValue = textInput.current.value
    setEditableMode(!isInEditableMode)
    setValue(updatedValue)
    update({ id, updatedValue })
  }

  const renderEditView = () => {
    return <div>
      <IconButton aria-label="check" onClick={updateComponentValue}>
        <CheckIcon />
      </IconButton>
      <IconButton aria-label="close" onClick={changeEditMode}>
        <CloseIcon />
      </IconButton>
    </div>
  }
  const renderDefaultView = () => {
    return <div>
      <IconButton aria-label="edit" onClick={changeEditMode}>
        <EditIcon />
      </IconButton>
      <IconButton aria-label="delete" onClick={remove}>
        <DeleteIcon />
      </IconButton>
    </div>
  }

  return (
    <div>
      <TextField
        type="text"
        inputRef={textInput}
        defaultValue={value}
        disabled={!isInEditableMode}
        className={classes.textInputHolder}
      />
      {
        isInEditableMode
          ? renderEditView()
          : renderDefaultView()
      }
    </div>
  )
}

EditableText.propTypes = {
  text: PropTypes.string,
  remove: PropTypes.func,
  classes: PropTypes.object,
  update: PropTypes.func,
  id: PropTypes.string
}

export default withStyles(styles)(EditableText)

import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'

const EventTypeField = ({
  classes,
  assignedEventTypes,
  setEventType,
  assignedToAllEventTypes,
  assignAllEventCategories
}) => {
  return (
    <div classes={classes.fieldContainer}>
      <span className={classes.fieldTitle}> EVENT TYPE </span>
      <div>
        <Fab
          variant="extended"
          aria-label="All"
          className={`${assignedToAllEventTypes ? classes.active : classes.inactive}`}
          onClick={assignAllEventCategories}>
          All
        </Fab>
        {
          assignedEventTypes.map(eventType => {
            return <Fab
              key={eventType.id}
              variant="extended"
              aria-label={eventType.category_name}
              className={`${!assignedToAllEventTypes && eventType.is_assigned ? classes.active : classes.inactive}`}
              onClick={() => setEventType(eventType.event_category_id)} >
              {eventType.category_name}
            </Fab>
          })
        }
      </div>
    </div>
  )
}

EventTypeField.propTypes = {
  classes: PropTypes.object,
  assignedEventTypes: PropTypes.array,
  assignedToAllEventTypes: PropTypes.bool,
  assignAllEventCategories: PropTypes.func,
  setEventType: PropTypes.func
}

export default EventTypeField

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputField from '../../elements/InputField/InputField'
import StatusField from '../../elements/StatusField/StatusField'
import SwitchButton from '../../elements/SwitchButton/SwitchButton'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import CreateEventSuiteDialogActions from './CreateEventSuiteDialogActions'
import styles from './CreateEventSuiteDialogContent.css'

const getDialogActions = (classes, addEventSuite, handleCancel, disableUpdate) => {
  return <CreateEventSuiteDialogActions
    classes={classes}
    disableUpdate={disableUpdate}
    handleSubmit={addEventSuite}
    handleCancel={handleCancel}
  />
}

const CreateEventSuiteDialogContent = ({
  classes,
  eventId,
  eventSuitesList = [],
  handleCancel,
  handleSubmit,
  suites = []
}) => {
  const [allowFoodOrder, setAllowFoodOrder] = useState()
  const [allowAdditionalPasses, setAllowAdditionalPasses] = useState(false)
  const [availableSeats, setAvailableSeats] = useState()
  const [additionalAvailablePasses, setAdditionalAvailablePasses] = useState()
  const [availableSuites, setAvailableSuites] = useState([])
  const [costPerPass, setCostPerPass] = useState('')
  const [disableUpdate, setDisableUpdate] = useState(true)
  const [isFeatured, setIsFeatured] = useState(false)
  const [price, setPrice] = useState()
  const [priceRangeError, setPriceRangeError] = useState(false)
  const [selectedSuiteId, setSelectedSuiteId] = useState(null)
  const [selectedSuite, setSelectedSuite] = useState(null)
  const [status, setStatus] = useState(true)
  const [maxAdditionalPasses, setMaxAdditionalPasses] = useState(0)

  const addEventSuite = () => {
    handleSubmit({
      additionalAvailablePasses,
      allowAdditionalPasses,
      allowFoodOrder,
      availableSeats,
      costPerPass,
      eventId,
      isFeatured,
      price,
      suiteId: selectedSuiteId,
      status
    })
  }

  const handlePriceChange = price => {
    price = +price > 0 ? price : ''
    if (price <= 200000) {
      setPrice(price)
    }
  }

  const handleCostPerPassChange = price => {
    price = +price > 0 ? price : ''
    setCostPerPass(price)
  }

  useEffect(() => {
    if (price < 1000 || price > 200000) {
      setPriceRangeError(true)
    } else {
      setPriceRangeError(false)
    }
  }, [price])

  useEffect(() => {
    let enableUpdate = true
    if (allowAdditionalPasses) {
      enableUpdate = additionalAvailablePasses > 0 && costPerPass > 0
    }
    enableUpdate = enableUpdate && price >= 1000 && price <= 200000
    enableUpdate = enableUpdate && availableSeats && +availableSeats > 0
    enableUpdate ? setDisableUpdate(false) : setDisableUpdate(true)
  }, [additionalAvailablePasses, allowAdditionalPasses, availableSeats, costPerPass, price])

  const compareByName = (obj1, obj2) => {
    return (obj1.suiteName === obj2.display_name)
  }

  useEffect(() => {
    if (suites && eventSuitesList && eventSuitesList.rows) {
      const { rows } = eventSuitesList
      const availableItems = suites
        .filter(b => {
          const indexFound = rows.findIndex(a => compareByName(a, b))
          return indexFound === -1
        })
        .map(item => {
          const suiteId = +item.display_name.split('-')[1]
          return { ...item, suiteId }
        })
        .sort((a, b) => a.suiteId - b.suiteId)
      setAvailableSuites(availableItems)
    }
  }, [suites, eventSuitesList])

  const handleEventSuiteChange = event => {
    const { value: id } = event.target
    setSelectedSuiteId(id)
    setSelectedSuite(suites.filter(suite => suite.id === id)[0])
  }

  useEffect(() => {
    if (selectedSuite) {
      const {
        extra_capacity: passes
      } = selectedSuite
      setMaxAdditionalPasses(passes)
      setAllowFoodOrder(false)
      setAllowAdditionalPasses(false)
    }
  }, [selectedSuite])

  const handleAvailableSeataChange = availableSeats => {
    const seats = +availableSeats > 0 ? availableSeats : ''
    setAvailableSeats(seats)
  }

  const handleAdditionalAvailablePassChange = additionalPasses => {
    const passes = +additionalPasses > 0 ? additionalPasses : ''
    setAdditionalAvailablePasses(passes)
  }

  return (
    <div>
      <div className={classes.eventSuiteSelectorContainer}>
        {getFilterSelector('SUITE NAME', 'suiteName', selectedSuiteId, handleEventSuiteChange, availableSuites, 'display_name', { selectorWidth: '100%' }, false, true, '')}
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          id={'createEventSuitePrice'}
          label={'PRICE'}
          changeHandler={handlePriceChange}
          placeholder={''}
          showLabel
          value={price}
        />
        {priceRangeError && <FormHelperText>Please enter a value greater than or equal to $1,000 and less than or equal to $200,000</FormHelperText>}
      </div>

      <div className={classes.inputContainer}>
        <SwitchButton id="is-featured-suite" label="FEATURED" checked={isFeatured} onChange={(event) => setIsFeatured(event.target.checked)} />
      </div>

      <div className={classes.inputContainer}>
        <SwitchButton id="allow-food-order" label="ALLOW F&B ORDERING" checked={allowFoodOrder} onChange={(event) => setAllowFoodOrder(event.target.checked)} />
      </div>

      <div className={classes.inputContainer}>
        <StatusField
          classes={{
            title: classes.fieldTitle,
            container: classes.statusFieldContainer,
            active: classes.active,
            inactive: classes.inactive
          }}
          currentStatus={status}
          setStatus={setStatus} />
      </div>
      <div className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.inputFields
          }}
          id={'createEventSuiteAvailableSeats'}
          label={'Available Seats'}
          changeHandler={handleAvailableSeataChange}
          placeholder={''}
          showLabel
          value={availableSeats}
        />
      </div>
      {
        maxAdditionalPasses > 0 &&
        <div className={classes.inputContainer}>
          <SwitchButton id="allow-food-order" label="ALLOW ADDITIONAL PASSES" checked={allowAdditionalPasses} onChange={(event) => setAllowAdditionalPasses(event.target.checked)} />
        </div>
      }
      {
        selectedSuite &&
        maxAdditionalPasses > 0 &&
        allowAdditionalPasses &&
        <>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteAdditionalPasses'}
              label={'Additional Passes'}
              changeHandler={handleAdditionalAvailablePassChange}
              placeholder={''}
              showLabel
              value={additionalAvailablePasses}
            />
          </div>
          <div className={classes.inputContainer}>
            <InputField
              classes={{
                inputLabelRoot: classes.fieldTitle,
                inputRoot: classes.inputFields
              }}
              id={'createEventSuiteCostPerPass'}
              label="COST PER ADDITIONAL PASS"
              changeHandler={handleCostPerPassChange}
              placeholder={''}
              showLabel
              value={costPerPass}
            />
          </div>
        </>
      }
      <div className={classes.actionsContainer}>
        {getDialogActions(classes, addEventSuite, handleCancel, disableUpdate)}
      </div>
    </div>
  )
}

CreateEventSuiteDialogContent.propTypes = {
  classes: PropTypes.object,
  eventId: PropTypes.number,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  suites: PropTypes.array,
  eventSuitesList: PropTypes.array
}

export default withStyles(styles)(CreateEventSuiteDialogContent)

const styles = {
  genericPaperStyles: {
    minHeight: '575px',
    minWidth: '586px',
    maxWidth: '586px',
    borderRadius: '0px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'black',
    display: 'initial',
    padding: '0'
  },
  genericDialogContentRoot: {
    backgroundColor: 'black',
    color: 'black',
    padding: '0px',
    overflowY: 'unset'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '0px',
    '& button': {
      top: '330px',
      color: '#fff'
    }
  },
  eventBannerImage: {
    height: '329px',
    width: '586px'
  },
  eventDetailsContainer: {
    padding: '40px'
  },
  eventName: {
    height: 'auto',
    width: '505px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '32px',
    fontWeight: '900',
    lineHeight: '44px'
  },
  row: {
    display: 'flex'
  },
  subDetail: {
    height: '24px',
    width: '234px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px'
  },
  dateTime: {
    width: '234px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '28px',
    marginTop: '10px'
  },
  placeOrderButton: {
    width: '220px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '0px',
    height: '50px',
    margin: '20px 30px 0px',
    backgroundColor: '#0078D4',
    '&:disabled': {
      backgroundColor: '#0078D4',
      color: '#fff'
    }
  },
  disabled: {},
  menuTypeSelector: {
    marginTop: '10px'
  },
  orderOptionsSelectorContainer: {
    display: 'flex'
  },
  placeOrderActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  menuItemImageHolder: {
    height: '329px'
  },
  dialogActions: {
    padding: '0px'
  }
}

export default styles

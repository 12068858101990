import moment from 'moment-timezone'
import {
  FETCH_CLIENT_EVENTS,
  RESET_CLIENT_EVENTS,
  FILTER_CLIENT_EVENTS,
  FETCH_CLIENT_EVENTS_IN_PROGRESS
} from '../actionTypes'
import { processItemsList } from '../helpers/processItemsList'
import { applyFilter, paginateClientEvents } from '../helpers/clientEventsFilter'
const initialState = {}
const DEFAULT_TZ = 'America/New_York'

const getFormattedCutOffDate = (momentObj) => {
  const displayCutoff = momentObj.clone().tz(DEFAULT_TZ)
  return displayCutoff.format('MMM DD, YYYY / hh:mm A')
}

const getKosherMenuCutoff = (kosherCutoff) => {
  const { is_available: isAvailable, client_cut_off_in_int: clientCutoffInInt } = kosherCutoff
  if (isAvailable) {
    const utcMomentObj = moment.utc(+`${clientCutoffInInt}000`)
    return getFormattedCutOffDate(utcMomentObj)
  } else {
    return 'N/A'
  }
}

const getNonKosherMenuCutoff = (nonkosherCutoff) => {
  const { client_cut_off_in_int: clientCutoffInInt } = nonkosherCutoff
  const utcMomentObj = moment.utc(+`${clientCutoffInInt}000`)
  return getFormattedCutOffDate(utcMomentObj)
}

const isKosherMenuAvailable = (kosherCutoff) => {
  const { is_available: isAvailable } = kosherCutoff
  return isAvailable
}

const processEventData = (event) => {
  const {
    id,
    web_name: webName,
    start_date: startDate,
    start_time: startTime,
    doors,
    orders,
    cut_off_by_menu_types: cutoffByMenuTypes,
    thumbnail,
    banner,
    order_window: orderWindow,
    order_count: ordersCount,
    web_description: webDescription
  } = event
  const eventDate = moment(startDate, 'YYYY-MM-DD')
  return {
    id,
    webName,
    eventCategoryId: event.event_category_id,
    eventDate,
    startDateMonth: eventDate.format('MMM'),
    startDate: eventDate.format('DD'),
    startDateDay: eventDate.format('ddd'),
    startTime,
    doors,
    orders,
    kosherMenuCutoff: getKosherMenuCutoff(cutoffByMenuTypes.kosher),
    nonKosherMenuCutoff: getNonKosherMenuCutoff(cutoffByMenuTypes.non_kosher),
    kosherMenuAvailable: isKosherMenuAvailable(cutoffByMenuTypes.kosher),
    thumbnail,
    banner,
    orderWindow,
    ordersCount,
    webDescription
  }
}

export default function ClientEventsReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_CLIENT_EVENTS:
      const { rows = [], filters, page, limit, sortParams, totalPages } = processItemsList(action.payload, processEventData)
      const filteredEvents = applyFilter({ allEvents: rows }, { filters, pagination: { page: 0, limit } })
      const paginatedEvents = paginateClientEvents(filteredEvents, { page: 0, limit })
      return {
        ...state,
        clientEventsList: {
          allEvents: rows,
          rows: paginatedEvents,
          filters,
          page,
          limit,
          sortParams,
          totalPages,
          totalEventsCount: rows.length
        },
        fetchClientEventsInProgress: false
      }
    case FILTER_CLIENT_EVENTS:
      return {
        ...state,
        clientEventsList: action.payload
      }
    case RESET_CLIENT_EVENTS:
      return initialState
    case FETCH_CLIENT_EVENTS_IN_PROGRESS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

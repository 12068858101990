import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'

const TextArea = ({
  classes,
  placeholder,
  id,
  label,
  value,
  changeHandler,
  showLabel,
  disabled = false,
  multiline = false
}) => {
  return (
    <React.Fragment>
      {showLabel && <InputLabel htmlFor={placeholder} classes={{ root: classes.inputLabelRoot }}>{label}</InputLabel>}
      <TextField
        id={id}
        placeholder={placeholder}
        name={placeholder}
        onChange={(event) => changeHandler(event.target.value)}
        value={value}
        classes={{ root: classes.inputRoot }}
        disabled={!!disabled}
        multiline={multiline}
      />
    </React.Fragment>
  )
}

TextArea.propTypes = {
  changeHandler: PropTypes.func,
  classes: PropTypes.object,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool
}

export default TextArea

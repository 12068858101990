import { FETCH_SUITES } from '../actionTypes'
import { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { suitesEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { sortParamsMapping } from '../../constants/staffUsers'
import { getItemsList } from '../helpers/apiClient'

const prepareQuery = (filters, page, limit, sort) => {
  const { query, status } = cleanUpFilterObject(filters)
  const { sortBy = '', order = '' } = sort
  return {
    filters: {
      status,
      query
    },
    pagination: {
      page: page + 1,
      limit
    },
    sort: {
      order_by: sortParamsMapping[sortBy],
      order
    }
  }
}

export const fetchSuites = (filters = {}, page = 0, limit = 10, sortParams = {}) => dispatch => {
  const { method, url } = suitesEndpoints.list
  const data = prepareQuery(filters, page, limit, sortParams)
  getItemsList(method, url, data)
    .then(res => {
      const { data = {} } = res
      const { suites: items, meta } = data
      dispatch({
        type: FETCH_SUITES,
        payload: { items, meta, page, limit, filters, sortParams }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch(logoutUser(history))
    })
}

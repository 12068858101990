import styled from 'styled-components'
import { TextField as MuiTextField, Select as MuiSelect } from '@material-ui/core'

export const Filters = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  & > * {

    &:nth-child(1) {
      width: 29% !important;
    }

    &:nth-child(2), &:nth-child(3) {
      margin-left: 16px;
      width: calc(36% - 30px)
    }
  }

  & label {
    color: #aaa;
  }

  & input[type=text], & label, & div {
    font-family: 'Ringside Regular A', 'Ringside Regular B';
    font-size: 20px !important;
    font-weight: bold;
  }
`

export const TextField = styled(MuiTextField)`
  flex-grow: 1;
  width: calc(100% - 116px) !important;
`

export const Select = styled(MuiSelect)`
  margin-top: 1px;
`

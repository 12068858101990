const styles = {
  dateSelectorTextField: {
    width: '150px',

    '& input[type=text]': {
      letterSpacing: '.5px'
    }
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Delete from '@material-ui/icons/DeleteSharp'
import { Typography, IconButton } from '@material-ui/core'
import { currentUserIsClient } from '../../store/helpers/userRoleManager'
import QuantitySelector from '../QuantitySelector/QuantitySelector'
import styles from './OrderItemsList.css'

const OrderItem = ({
  currentOrderWindow,
  currentUser,
  orderItem,
  classes,
  index,
  mode,
  handleQuantityChange,
  handleRemoveItemFromCart
}) => {
  const isEditMode = mode === 'Edit'
  const {
    menuItemId,
    quantity,
    menu_item_name: name,
    unitPrice,
    totalPrice,
    orderWindow,
    advanced_only: advancedOnly
  } = orderItem
  return (
    <div className={classes.orderLineItemContainer}>
      {
        isEditMode
          ? <QuantitySelector
            classes={{
              quntitySelector: classes.quntitySelector,
              selectQuantity: classes.selectQuantity
            }}
            disableQuantityChange={currentOrderWindow.toLowerCase() !== orderWindow}
            itemId={menuItemId}
            handleQuantityChange={handleQuantityChange}
            quantity={quantity}
          />
          : <div className={`${classes.quantity} ${classes.topMargin}`}>{orderItem.quantity}</div>
      }
      <div className={`${classes.name} ${classes.topMargin}`}>{name}</div>
      <div className={`${classes.unitPrice} ${classes.topMargin}`}>{`$${parseFloat(unitPrice).toFixed(2)}`}</div>
      <div className={`${classes.totalPrice} ${classes.topMargin}`}>{`$${parseFloat(totalPrice).toFixed(2)}`}</div>
      {isEditMode &&
        <div>
          <IconButton className={classes.disabledIcon} disabled={orderWindow.toLowerCase() !== currentOrderWindow.toLowerCase() && currentUserIsClient(currentUser) && advancedOnly}>
            <Delete className={`${classes.deleteIcon}`} onClick={() => handleRemoveItemFromCart(menuItemId, orderWindow)} />
          </IconButton>
        </div>}
    </div>
  )
}

OrderItem.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  currentOrderWindow: PropTypes.string,
  index: PropTypes.number,
  orderItem: PropTypes.object,
  mode: PropTypes.string,
  handleQuantityChange: PropTypes.func,
  handleRemoveItemFromCart: PropTypes.func
}

const OrderItemsList = ({
  classes,
  currentUser,
  currentOrderWindow,
  orderItems = {},
  mode = 'View',
  handleQuantityChange,
  handleRemoveItemFromCart
}) => {
  const orderItemGroups = Object.keys(orderItems)
  return (
    <div className={classes.orderItemsListContainer}>
      {
        (mode === 'Edit') ? orderItems.map((item, index) => {
          return <OrderItem
            key={index}
            currentUser={currentUser}
            currentOrderWindow={currentOrderWindow}
            index={index}
            orderItem={item}
            classes={classes}
            mode={mode}
            handleQuantityChange={handleQuantityChange}
            handleRemoveItemFromCart={handleRemoveItemFromCart}
          />
        })
          : orderItemGroups.map(orderGroup => {
            const orders = orderItems[orderGroup]
            return <div key={'orderGroup'}>
              <Typography variant="h6" className={classes.orderGroupName}>{orderGroup}</Typography>
              {
                orders.map((item, index) => {
                  return <OrderItem
                    key={index}
                    orderItem={item}
                    classes={classes}
                    mode={mode}
                    handleQuantityChange={handleQuantityChange}
                    handleRemoveItemFromCart={handleRemoveItemFromCart}
                  />
                })
              }
            </div>
          })
      }
    </div>
  )
}

OrderItemsList.propTypes = {
  classes: PropTypes.object,
  currentOrderWindow: PropTypes.string,
  currentUser: PropTypes.object,
  orderItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mode: PropTypes.string,
  handleQuantityChange: PropTypes.func,
  handleRemoveItemFromCart: PropTypes.func
}

export default withStyles(styles)(OrderItemsList)

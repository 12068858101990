import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import EventDate from '../EventDate/EventDate'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import styles from './EventDetailsBar.css'
const EXPECTED_DATE_FORMAT = 'MM/DD/YYYY'
const DATE_FORMAT = 'MMMM D, YYYY'

const EventDetailsBar = ({
  classes,
  eventMonth = '',
  eventDate,
  eventDay = '',
  eventImageUrl,
  eventName,
  eventEatecId,
  eventSuiteName,
  currentUser,
  orderWindow,
  menuTypeId,
  clientCutoffDate,
  clientCutoffTime,
  adminCutoffDate,
  adminCutoffTime
}) => {
  const [orderDueTimeMessage, setOrderDueTimeMessage] = useState('')
  const isOrderWindowAdvance = () => orderWindow.toLowerCase() === 'advance'
  const formatClientCutoffDate = moment(clientCutoffDate).format(DATE_FORMAT)

  const getAdvancedDeadline = (eventDay) => {
    if (['Sat', 'Sun'].includes(eventDay)) {
      return formatClientCutoffDate
    } else if (eventDay === 'Mon') {
      return moment(formatClientCutoffDate).subtract(3, 'day').format(DATE_FORMAT)
    } else {
      return moment(formatClientCutoffDate).subtract(1, 'day').format(DATE_FORMAT)
    }
  }

  useEffect(() => {
    if (currentUser && orderWindow && menuTypeId) {
      const menuCatalogDisplayMessage = `
        Game Day Deadline is
        ${currentUserIsAdmin(currentUser) ? moment(adminCutoffDate, EXPECTED_DATE_FORMAT).format(DATE_FORMAT) + ' @ ' + adminCutoffTime : moment(clientCutoffDate, 'MM/DD/YYYY').format('MMMM D, YYYY') + ' @ ' + clientCutoffTime}
        Advance Deadline is 
        ${currentUserIsAdmin(currentUser) ? moment(adminCutoffDate, EXPECTED_DATE_FORMAT).format(DATE_FORMAT) + ' @ ' + adminCutoffTime : getAdvancedDeadline(eventDay) + ' @ 12:00 PM'}`
      setOrderDueTimeMessage(menuCatalogDisplayMessage)
    }
  }, [currentUser, orderWindow, menuTypeId, adminCutoffDate, adminCutoffTime, clientCutoffDate, clientCutoffTime])

  return (
    <div className={classes.eventDetailsBarContainer}>
      <EventDate
        eventMonth={eventMonth}
        eventDay={eventDay}
        eventDate={eventDate}
      />
      <div className={classes.eventImage}>
        <img alt="eventImage" src={eventImageUrl || DEFAULT_IMAGE_URL} />
      </div>
      <div className={classes.eventDetails}>
        <div className={classes.eventName}>{eventName}</div>
        <div className={classes.eventEatecId}>{eventEatecId}</div>
        <div className={classes.eventSuite}>{eventSuiteName}</div>
      </div>
      <div>
        <div className={classes.orderDueTimeMessage}>{orderDueTimeMessage}</div>
        { orderWindow && !isOrderWindowAdvance() && <div className={classes.gameDayMessage}>Game Day pricing in effect.</div> }
      </div>
    </div>
  )
}

EventDetailsBar.propTypes = {
  classes: PropTypes.object,
  eventMonth: PropTypes.string,
  eventDate: PropTypes.string,
  eventDay: PropTypes.string,
  eventImageUrl: PropTypes.string,
  eventName: PropTypes.string,
  eventEatecId: PropTypes.string,
  eventSuiteName: PropTypes.string,
  currentUser: PropTypes.object,
  orderWindow: PropTypes.string,
  menuTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clientCutoffDate: PropTypes.string,
  clientCutoffTime: PropTypes.string,
  adminCutoffDate: PropTypes.string,
  adminCutoffTime: PropTypes.string
}

export default withStyles(styles)(EventDetailsBar)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Provider, connect } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import store, { history } from './store/store'
import { setDefaultDataFromLocalStorage } from './store/staticData/actions'
import theme from './theme'
import MainLayout from './pages/MainLayout'
import { routes } from './pages'
import { getRoutes } from './utils/getRoutes'
import { reportPageLoad } from './utils/analytics'

const withLayout = (Component, Layout) => route => <Layout component={Component} route={route} /> // eslint-disable-line react/display-name

const buildRoutes = routes => routes
  .reduce((RouteList, { childRoutes = [], component, layout = MainLayout, path, redirectTo }) => {
    const routeComponent = redirectTo
      ? () => <Redirect to={redirectTo} /> // eslint-disable-line react/display-name
      : withLayout(component, layout)

    return [
      ...RouteList,
      <Route
        component={routeComponent}
        key={path}
        layout={layout}
        path={path}
        exact
      />,
      ...buildRoutes(childRoutes)
    ]
  }, [])

export class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dataLoadedFromLocalStorage: false
    }
  }

  renderApp = () => {
    const { currentUser } = this.props
    const Routes = buildRoutes(getRoutes(currentUser, routes))
    return (
      <div>
        <Provider store={store}>
          <Switch>{Routes}</Switch>
        </Provider>
      </div>
    )
  }

  isValidTokenPresent = () => {
    const { staticData, setDefaultDataFromLocalStorage } = this.props
    const validTokenPresent = localStorage.getItem('ValidToken')
    if (validTokenPresent && Object.keys(staticData).length === 0) {
      setDefaultDataFromLocalStorage()
      this.setState({
        dataLoadedFromLocalStorage: true
      })
    }
    return validTokenPresent
  }

  componentDidMount () {
    this.unlisten = history.listen((location, action) => {
      reportPageLoad(location.pathname)
    })
  }

  componentWillUnmount () {
    this.unlisten()
  }

  render () {
    const { dataLoadedFromLocalStorage } = this.state
    return (
      <MuiThemeProvider theme={theme}>
        { this.isValidTokenPresent() ? (dataLoadedFromLocalStorage ? this.renderApp() : <div />) : this.renderApp() }
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
  staticData: PropTypes.object,
  setDefaultDataFromLocalStorage: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}

const mapStateToProps = ({ staticData, authReducer }) => ({
  staticData,
  currentUser: authReducer.user
})

const mapDispatchToProps = { setDefaultDataFromLocalStorage }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Dialog from '../../elements/Dialog/Dialog'
import { fetchCompatibleEventsToMoveOrders, resetEventsToMoveOrders } from '../../store/events/actions'
import styles from './MoveOrders.css'
import MoveOrdersDialogContent from './MoveOrdersDialogContent'
import MoveOrdersDialogTitle from './MoveOrdersDialogTitle'

const MoveOrders = ({
  classes,
  handleDialogClose,
  handleMoveOrders,
  eventTypes,
  openDialog,
  eventId,
  eventName,
  eventDate,
  events = [],
  fetchEventsToMoveOrders,
  resetEventsToMoveOrders
}) => {
  const [targetEventId, setTargetEventId] = useState()

  const onEventSelect = (event) => setTargetEventId(parseInt(event.target.value, 10))

  const filterEvents = (filters) => {
    fetchEventsToMoveOrders(eventId, filters)
  }

  useEffect(() => {
    if (eventId) {
      fetchEventsToMoveOrders(eventId, {})
    }

    return () => {
      resetEventsToMoveOrders()
    }
  }, [])

  useEffect(() => {
    if (targetEventId) {
      const event = events.find(event => event.id === targetEventId)
      if (!event) {
        setTargetEventId(null)
      }
    }
  }, [events, targetEventId])

  const handleSubmit = () => {
    const event = events.find(event => event.id === targetEventId)
    handleMoveOrders(event)
  }

  return (
    <React.Fragment>
      { eventId && <Dialog
        openDialog={openDialog}
        dialogTitle={
          <MoveOrdersDialogTitle
            classes={classes}
            eventName={eventName}
            eventDate={eventDate}
          />
        }
        dialogContent={
          <MoveOrdersDialogContent
            classes={classes}
            events={events}
            eventTypes={eventTypes}
            filterEvents={filterEvents}
            targetEventId={targetEventId}
            onEventSelect={onEventSelect}
            handleDialogClose={handleDialogClose}
            handleSubmit={handleSubmit}
          />
        }
        classes={{
          genericPaperStyles: classes.genericPaperStyles,
          genericDialogActionRoot: classes.genericDialogActionRoot,
          genericDialogContentRoot: classes.genericDialogContentRoot,
          genericDialogTitleRoot: classes.genericDialogTitleRoot
        }}
        handleDialogClose={handleDialogClose}
      /> }
    </React.Fragment>
  )
}

MoveOrders.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleMoveOrders: PropTypes.func,
  openDialog: PropTypes.bool,
  eventId: PropTypes.number,
  eventTypes: PropTypes.array,
  eventName: PropTypes.string,
  eventDate: PropTypes.string,
  events: PropTypes.array,
  fetchEventsToMoveOrders: PropTypes.func,
  resetEventsToMoveOrders: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    eventTypes: state.staticData.event_categories,
    events: state.eventsData.compatibleEventsToMoveOrders
  }
}
const mapDispatchToProps = {
  fetchEventsToMoveOrders: fetchCompatibleEventsToMoveOrders,
  resetEventsToMoveOrders
}

export default React.memo(
  withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
  )(MoveOrders))
)

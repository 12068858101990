import moment from 'moment'
import {
  STORE_MENU_CATALOG_PREREQUISITES,
  STORE_ORDER_ITEMS,
  STORE_CHECKOUT_FLOW_EVENT_DATA,
  STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA,
  STORE_CHECKOUT_FLOW_SUITE_DATA,
  STORE_CHECKOUT_PAYMENT_DATA,
  STORE_CHECKOUT_PAYMENT_METHOD_DATA,
  RESET_CHECKOUT_DATA,
  STORE_CHECKOUT_VALIDATION_DATA,
  STORE_CHECKOUT_PAYMENT_OPTIONS_DATA,
  SHOW_CHECKOUT_SUCCESS_MESSAGE,
  SHOW_CHECKOUT_ERROR_MESSAGE,
  SET_CHECKOUT_DATA_FROM_ORDER,
  SET_CHECKOUT_DATA_TO_COPY_ORDER,
  STORE_COMPANIES_SUITE_ID_OF_CLIENT,
  RESET_PAYMENT_OPTION_DATA,
  STORE_RECOMMENDATIONS,
  STORE_FAVORITE_RECOMMENDATIONS
} from '../actionTypes'
import { getSecureImageUrl } from '../../utils/imageUtils'
import { trueRounder, addZeroToOrderPrice } from '../../utils/formatters'

const PAYMENT_METHODS = {
  CreditCard: 1,
  Invoice: 5
}

const initialState = {
  usedCreditCards: []
}

const getFormattedTime = dateTime => moment(new Date(dateTime)).tz('America/New_York').format('hh:mm A')

const processRecommendations = (recommendedItems) => {
  const menuItems = recommendedItems ? recommendedItems.reduce((allItems, item) => {
    const tempItem = {
      advancePrice: item.advance_price,
      gamedayPrice: item.game_day_price,
      id: item.id,
      image: item.image,
      description: item.web_description,
      name: item.web_name,
      isGlutenFree: item.is_gluten_free,
      isVegan: item.is_vegan,
      recommended: item.recommended,
      isActive: true
    }
    allItems.push(tempItem)
    return allItems
  }, []) : []
  return menuItems
}

const getCreditCardBalanceAmount = (usedCreditCards, orderTotal) => {
  const creditCardAmountSum = usedCreditCards.reduce((result, card) => {
    const { amount = 0.0 } = card
    const parsedAmount = parseFloat(amount)
    result += isNaN(parsedAmount) ? 0.0 : parsedAmount
    return result
  }, 0.0)
  return parseFloat(orderTotal) - parseFloat(creditCardAmountSum)
}

const getFormatedEventData = (eventDetails, orderWindow) => {
  const {
    web_name: name,
    number,
    start_date: startDate,
    thumb_image: thumbnailUrl,
    client_cutoff_date: clientCutoffDate,
    client_cutoff_in_int: clientCutoffTime,
    admin_cutoff_date: adminCutoffDate,
    admin_cutoff_in_int: adminCutoffTime
  } = eventDetails
  const eventDate = moment(startDate, 'MM/DD/YYYY')
  return {
    name,
    startDateMonth: eventDate.format('MMM'),
    startDate: eventDate.format('DD'),
    startDateDay: eventDate.format('ddd'),
    thumbnailUrl: getSecureImageUrl(thumbnailUrl),
    eatecId: number,
    orderWindow,
    clientCutoffDate,
    clientCutoffTime: getFormattedTime(+clientCutoffTime * 1000),
    adminCutoffDate,
    adminCutoffTime: getFormattedTime(+adminCutoffTime * 1000),
    eventDays: eventDate.diff(moment(), 'days')
  }
}

const getOrderBillSummary = (orderItems = []) => {
  const activeOrderItems = orderItems.filter(item => item._destroy !== 1)
  if (activeOrderItems.length > 0) {
    const subTotal = trueRounder(activeOrderItems.reduce((total, item) => {
      const price = isNaN(parseFloat(item.price)) ? item.unitPrice : item.price
      total = total + (price * item.quantity)
      return total
    }, 0.0), 2)
    const gratuity = trueRounder((subTotal * 18) / 100, 2)
    const tax = trueRounder((subTotal * 8.875) / 100, 2)
    const total = trueRounder(+tax + +subTotal + +gratuity, 2)
    const billSummary = {
      subTotal,
      tax,
      total,
      gratuity
    }
    const formattedBillSummary = [
      {
        field1: 'Subtotal',
        field2: '',
        field3: addZeroToOrderPrice(subTotal)
      },
      {
        field1: 'Tax',
        field2: '8.875%',
        field3: addZeroToOrderPrice(tax)
      },
      {
        field1: 'Gratuity',
        field2: '18%',
        field3: addZeroToOrderPrice(gratuity)
      },
      {
        field1: 'Total',
        field2: '',
        field3: addZeroToOrderPrice(total)
      }
    ]
    return { formattedBillSummary, billSummary }
  } else {
    return { formattedBillSummary: {}, billSummary: {} }
  }
}

const prePopulateOrderBalanceInPrimaryCard = (usedCreditCards, orderTotal, creditCardBalanceAmount, prePopulatedStatusFromStore) => {
  let prePopulated = false
  const [creditCard] = usedCreditCards
  if (creditCard && !creditCard.amount && !prePopulatedStatusFromStore) {
    prePopulated = true
    usedCreditCards[0].amount = trueRounder(orderTotal, 2)
    creditCardBalanceAmount = trueRounder(0, 2)
  } else {
    creditCardBalanceAmount = trueRounder(getCreditCardBalanceAmount(usedCreditCards, orderTotal), 2)
  }
  return {
    usedCreditCards,
    creditCardBalanceAmount,
    prePopulated
  }
}

const hasUsedCard = (usedCards) => Array.isArray(usedCards) && usedCards.length > 0

const getCreditCardPaymentData = ({ usedCreditCards, orderTotal }, prePopulatedStatusFromStore) => {
  let disablePlaceOrderButton = false
  let creditCardBalanceAmount = 0.0
  let prePopulated = false
  if (hasUsedCard(usedCreditCards)) {
    if (usedCreditCards.length === 1) {
      const {
        usedCreditCards: updatedCards,
        creditCardBalanceAmount: balance,
        prePopulated: prePopulatedStatus
      } = prePopulateOrderBalanceInPrimaryCard(usedCreditCards, orderTotal, creditCardBalanceAmount, prePopulatedStatusFromStore)
      usedCreditCards = [...updatedCards]
      creditCardBalanceAmount = balance
      prePopulated = prePopulatedStatus
    } else {
      creditCardBalanceAmount = trueRounder(getCreditCardBalanceAmount(usedCreditCards, orderTotal), 2)
    }
  } else {
    creditCardBalanceAmount = trueRounder(orderTotal, 2)
  }
  if (parseFloat(creditCardBalanceAmount) !== 0) {
    disablePlaceOrderButton = true
  }
  return {
    usedCreditCards,
    creditCardBalanceAmount: trueRounder(creditCardBalanceAmount, 2),
    disablePlaceOrderButton,
    prePopulated
  }
}

const getPaymentMehodData = (paymentMethod, usedCreditCards, orderTotal) => {
  let disablePlaceOrderButton = false
  let creditCardBalanceAmount = 0.0
  if (paymentMethod === PAYMENT_METHODS.CreditCard) {
    if (hasUsedCard(usedCreditCards)) {
      creditCardBalanceAmount = getCreditCardBalanceAmount(usedCreditCards, orderTotal)
    } else {
      creditCardBalanceAmount = trueRounder(orderTotal, 2)
    }
    disablePlaceOrderButton = parseFloat(creditCardBalanceAmount) !== 0
  }
  return { paymentMethod, disablePlaceOrderButton, creditCardBalanceAmount, usedCreditCards }
}

const getOrderItemsFromExistingOrder = (orderItems) => {
  const orderWindows = Object.keys(orderItems)
  let allOrderItems = []
  orderWindows.forEach(orderWindow => {
    allOrderItems = [
      ...allOrderItems,
      ...orderItems[orderWindow].map(item => {
        item.menuItemId = item.menu_item_id
        item.orderWindow = orderWindow
        item.unitPrice = trueRounder(item.unit_price, 2)
        item.totalPrice = trueRounder(item.total_price, 2)
        delete item.unit_price
        delete item.total_price
        delete item.menu_item_id
        return item
      })
    ]
  })
  return allOrderItems
}

const getOrderItemsToCopyOrder = (orderItems, orderWindow) => {
  const allOrderItems = []
  orderItems.forEach(orderItem => {
    const item = {
      menuItemId: orderItem.menu_item_id,
      menu_item_name: orderItem.menu_item_name,
      orderWindow,
      quantity: orderItem.quantity,
      unitPrice: trueRounder(orderItem.unit_price, 2),
      totalPrice: orderItem.quantity * trueRounder(orderItem.unit_price, 2)
    }
    allOrderItems.push(item)
  })
  return allOrderItems
}

const getBasicOrderDetails = (order) => {
  const {
    companies_suite_id: companiesSuiteId,
    company_id: companyId,
    event_id: eventId,
    suite_id: suiteId,
    suite: suiteDisplayName,
    submitted_for: userId,
    menu_type_id: menuTypeId,
    id: orderId
  } = order
  return {
    companiesSuiteId,
    companyId,
    eventId,
    suiteId,
    suiteDisplayName,
    userId,
    menuTypeId,
    orderId,
    usedCreditCards: []
  }
}

const getOrderCheckoutOptions = (order) => {
  const {
    item_additions_by: itemAdditionsBy,
    liquor_cabinet_access: liquorCabinetAccess,
    signature_required: signatureRequired,
    host_name: hostName,
    comment: orderComment,
    name: orderName
  } = order
  return {
    itemAdditionsBy,
    liquorCabinetAccess,
    signatureRequired,
    hostName,
    orderComment,
    orderName
  }
}

const getExistingOrderDataToEdit = (order) => {
  return {
    ...getBasicOrderDetails(order),
    ...getOrderCheckoutOptions(order)
  }
}

const getAvailableItems = (existingItems, unavailableItems) => {
  unavailableItems.forEach(unavailableItem => {
    const unavailableItemIndex = existingItems.findIndex(item => item.menuItemId === unavailableItem.id)
    if (unavailableItemIndex > -1) {
      existingItems.splice(unavailableItemIndex, 1)
    }
  })
  return existingItems
}
const getCheckoutDataToCopyOrder = (payload) => {
  const { orderData = {}, eventId, unavailableItems } = payload
  const {
    order,
    order_items: orderItems,
    is_game_day: isGameDay
  } = orderData
  const {
    company_id: companyId,
    company_name: companyName
  } = order
  const orderWindow = isGameDay ? 'Gameday' : 'Advance'
  const orderDataToEdit = getBasicOrderDetails(order)
  const allOrderItems = getOrderItemsToCopyOrder(orderItems, orderWindow)
  const avaialbleItems = getAvailableItems(allOrderItems, unavailableItems).map(item => {
    delete item.id
    return item
  })
  const billDetails = getOrderBillSummary(allOrderItems)
  return {
    ...orderDataToEdit,
    company: { id: companyId, name: companyName },
    orderItems: avaialbleItems,
    orderWindow,
    showOrderSuccessMessage: false,
    orderSuccessResponse: {},
    eventId,
    orderId: null,
    billDetails
  }
}

const getCheckoutDataFromOrder = (payload) => {
  const { orderData = {} } = payload
  const {
    event_details: eventDetails,
    order,
    order_bill: orderBill,
    order_items: orderItems,
    is_game_day: isGameDay
  } = orderData
  const {
    company_id: companyId,
    company_name: companyName
  } = order
  const formattedEventDetails = getFormatedEventData(eventDetails)
  const orderDataToEdit = getExistingOrderDataToEdit(order)

  const {
    payment_method: paymentMethod,
    payment_details: {
      credit_cards: creditCards = []
    } = {},
    total: orderTotal,
    in_suite_addition_charge: inSuiteAdditionsCharge
  } = orderBill
  const allOrderItems = getOrderItemsFromExistingOrder(orderItems)
  const billDetails = getOrderBillSummary(allOrderItems)
  const usedCreditCards = creditCards.map(card => ({ id: card.id, amount: card.amount, isPrimary: card.is_primary, cardType: card.card_type }))
  const currentPaymentDetails = getPaymentMehodData(paymentMethod, usedCreditCards, orderTotal)
  return {
    ...orderDataToEdit,
    company: { id: companyId, name: companyName },
    orderItems: allOrderItems,
    eventDetails: formattedEventDetails,
    billDetails,
    orderWindow: isGameDay ? 'Gameday' : 'Advance',
    paymentMethod,
    orderSuccessResponse: {},
    ...currentPaymentDetails,
    inSuiteAdditionsCharge
  }
}

const getUpdatedRecommendations = (orderItems, recommendations = []) => {
  const orderdedItemIds = orderItems.map(orderItem => orderItem.menuItemId)
  return recommendations.map(recommendedItem => {
    if (orderdedItemIds.includes(recommendedItem.id)) {
      recommendedItem.isActive = false
    } else {
      recommendedItem.isActive = true
    }
    return recommendedItem
  })
}

export default function CheckoutFlowReducer (state = initialState, action) {
  const { payload = {} } = action
  const { paymentMethod } = payload
  switch (action.type) {
    case STORE_MENU_CATALOG_PREREQUISITES:
      return {
        ...state,
        ...action.payload
      }
    case STORE_ORDER_ITEMS:
      const { orderItems, usedCreditCards: creditCards = [] } = action.payload
      const billDetails = getOrderBillSummary(orderItems)
      const {
        billSummary: {
          total
        }
      } = billDetails
      let cardBalance
      let disablePlaceOrderButton = false
      if (paymentMethod === PAYMENT_METHODS.CreditCard) {
        cardBalance = getCreditCardBalanceAmount(creditCards, total)
        disablePlaceOrderButton = cardBalance !== 0
      }
      return {
        ...state,
        orderItems: orderItems.slice(),
        recommendations: getUpdatedRecommendations(orderItems, state.recommendations),
        billDetails,
        creditCardBalanceAmount: cardBalance,
        disablePlaceOrderButton: disablePlaceOrderButton
      }
    case STORE_CHECKOUT_FLOW_EVENT_DATA:
      return {
        ...state,
        eventDetails: getFormatedEventData(action.payload.eventDetails),
        orderWindow: action.payload.orderWindow,
        showOrderSuccessMessage: false
      }
    case STORE_CHECKOUT_FLOW_USER_PAYMENT_DATA:
      return {
        ...state,
        ...action.payload,
        showOrderSuccessMessage: false
      }
    case STORE_CHECKOUT_FLOW_SUITE_DATA:
      return {
        ...state,
        ...action.payload,
        showOrderSuccessMessage: false
      }
    case STORE_CHECKOUT_PAYMENT_DATA:
      const {
        prePopulated
      } = state
      const paymentData = getCreditCardPaymentData(action.payload, prePopulated)
      return {
        ...state,
        ...paymentData
      }
    case STORE_CHECKOUT_PAYMENT_METHOD_DATA:
      const { usedCreditCards, orderTotal, inSuiteAdditionsCharge } = action.payload
      const paymentMethodData = getPaymentMehodData(paymentMethod, usedCreditCards, orderTotal)
      return {
        ...state,
        ...paymentMethodData,
        inSuiteAdditionsCharge
      }
    case STORE_CHECKOUT_PAYMENT_OPTIONS_DATA:
      return {
        ...state,
        ...action.payload
      }
    case STORE_CHECKOUT_VALIDATION_DATA:
      return {
        ...state,
        ...action.payload
      }
    case SHOW_CHECKOUT_SUCCESS_MESSAGE:
      return {
        ...action.payload
      }
    case SHOW_CHECKOUT_ERROR_MESSAGE:
      return {
        ...state,
        ...action.payload
      }
    case SET_CHECKOUT_DATA_FROM_ORDER:
      return {
        ...state,
        ...getCheckoutDataFromOrder(action.payload)
      }
    case SET_CHECKOUT_DATA_TO_COPY_ORDER:
      return {
        ...state,
        ...getCheckoutDataToCopyOrder(action.payload)
      }
    case STORE_COMPANIES_SUITE_ID_OF_CLIENT:
      return {
        ...state,
        ...action.payload
      }
    case RESET_PAYMENT_OPTION_DATA:
      return {
        ...state,
        itemAdditionsBy: null,
        liquorCabinetAccess: null,
        signatureRequired: null,
        hostName: null,
        orderComment: null,
        orderName: null,
        inSuiteAdditionsCharge: null,
        paymentMethod: null,
        usedCreditCards: []
      }
    case RESET_CHECKOUT_DATA:
      return {}
    case STORE_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: processRecommendations(action.payload.recommendations)
      }
    case STORE_FAVORITE_RECOMMENDATIONS:
      return {
        ...state,
        favoriteRecommendations: processRecommendations(action.payload.recommendations)
      }
    default:
      return state
  }
}

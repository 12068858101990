const styles = {
  MainNav: {
    height: '90px',
    margin: 'auto',
    width: '1210px'
  },
  Tab: {
    height: '100%',
    position: 'relative',
    width: '16%',
    '&:before': {
      borderRight: '2px solid #4D4E58',
      content: '""',
      display: 'block',
      height: '50px',
      position: 'absolute',
      top: 'calc(50% - 25px)',
      width: '1px'
    },
    '&:first-child': {
      '&:before': {
        display: 'none'
      }
    },
    '& a, & a:visited': {
      color: 'white',
      fontFamily: 'Ringside Condensed A, Ringside Condensed B',
      fontSize: '24px',
      fontWeight: 'bold',
      justifyContent: 'left',
      lineHeight: '30px',
      textDecoration: 'none',
      '& span': {
        alignItems: 'center',
        borderBottom: '5px solid transparent',
        display: 'flex',
        height: '90px',
        marginLeft: '36px'
      }
    }
  },
  isSelected: {
    '& a span': {
      borderBottom: '5px solid #65FFF5 !important'
    }
  }
}

export default styles

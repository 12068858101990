import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'

const MenuItemDetailsContent = ({ classes, item }) => {
  return (
    <div className={classes.menuItemDetailsContainer}>
      <div className={classes.menuItemDetailsName}>{item.name}</div>
      <div className={classes.menuItemDetailsDescription}>{item.description}</div>
      { item && item.isGlutenFree && <Fab className={classes.dietButton}>{'GF'}</Fab> }
      { item && item.isVegan && <Fab className={classes.dietButton}>{'V'}</Fab> }
    </div>
  )
}

MenuItemDetailsContent.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object
}

export default MenuItemDetailsContent

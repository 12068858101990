
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  item: {
    width: '220px',
    height: '240px',
    marginRight: '10px',
    background: '#FFFFFF',
    float: 'left'
  },
  image: {
    width: '220px',
    '& img': {
      width: '220px',
      height: '120px'
    },
    cursor: 'pointer'
  },
  item_details: {
    width: '240px',
    height: '80px'
  },
  item_name: {
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    width: '206px',
    height: '24px',
    left: '20px',
    margin: '5px 10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  item_price: {
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    width: '206px',
    height: '24px',
    left: '20px',
    margin: '5px 10px'
  },
  addToCartButton: {
    fontSize: '14px',
    padding: '2px 10px',
    margin: '2px 10px',
    width: '170px',
    color: '#081B5E',
    height: '36px',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B'
  },
  addIcon: {
    fontSize: '18px',
    color: '#081B5E',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B'
  }
}, { index: 1 })

export default useStyles

import React, { Fragment, useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { getDateSelector } from '../DateSelector/getDateSelector'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import SearchField from '../SearchField/SearchField'
import CreateOrder from '../../Dialogs/CreateOrder/CreateOrder'
import ResetFilters from '../ResetFilters/ResetFilters'
import {
  reportAnalytics
} from '../../utils/analytics'
import {
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_ACTION_LOCATION_BODY,
  ANALYTICS_ACTION_CLEAR_SELECTION,
  ANALYTICS_ACTION_FILTER_ORDERS,
  ANALYTICS_PAGE_TYPE_CLIENT_ORDERS,
  ANALYTICS_ACTION_TYPE_ORDERS_FILTER,
  ANALYTICS_ACTION_TYPE_ORDERS_CLEAR_FILTERS
} from '../../constants/analytics'
import { reportGoogleAnalytics } from '../../utils/googleAnalytics'
import styles from './OrderFilterBar.css'
const OrderFilterBar = ({
  currentUser,
  classes,
  eventCategories = [],
  eventId,
  filters = {},
  filterItems,
  menuTypes = [],
  orderStatusList = [],
  resetCreateOrderDialogData,
  resetCheckoutData,
  styles,
  handleMoveOrders
}) => {
  const [status, setStatus] = useState(filters.status || '')
  const [eventCategory, setEventCategory] = useState(filters.eventCategory || '')
  const [menuType, setMenuType] = useState(filters.menuType || '')
  const [startDate, setStartDate] = useState(filters.startDate || '')
  const [endDate, setEndDate] = useState(filters.endDate || '')
  const [query, setSearchQuery] = useState(filters.query || '')
  const [resetFilters, setResetFiltersFlag] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleOrderStatusSelection = (event) => {
    const status = event.target.value
    setStatus(status)
  }

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleEventCategorySelection = (event) => {
    const eventCategory = event.target.value
    setEventCategory(eventCategory)
  }

  const handleMenuTypeSelection = (event) => {
    const menuType = event.target.value
    setMenuType(menuType)
  }

  const handleStartDateSelection = (event) => {
    const startDate = event.target.value
    setStartDate(startDate)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({ eventId })
    }
  }, [eventId, resetFilters, filterItems])

  const clearFilters = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_CLEAR_SELECTION,
        actionType: ANALYTICS_ACTION_TYPE_ORDERS_CLEAR_FILTERS,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    setStatus('')
    setStartDate('')
    setEndDate('')
    setMenuType('')
    setEventCategory('')
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  const handleSubmit = () => {
    reportAnalytics({
      eventType: ANALYTICS_EVENT_BUTTON_CLICK,
      detail: {
        actionName: ANALYTICS_ACTION_FILTER_ORDERS,
        actionType: ANALYTICS_ACTION_TYPE_ORDERS_FILTER,
        actionLocation: ANALYTICS_ACTION_LOCATION_BODY,
        actionStatus: status,
        actionStartDate: startDate,
        actionEndDate: endDate,
        actionEventCategory: eventCategory,
        actionMenuTypeId: menuType,
        actionSearchQuery: query
      },
      pageType: ANALYTICS_PAGE_TYPE_CLIENT_ORDERS
    })
    filterItems({
      endDate,
      eventCategory,
      eventId,
      menuType,
      status,
      startDate,
      query
    })
  }

  const handleEndDateSelection = (event) => {
    const endDate = event.target.value
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() > startDateObj.getTime()) {
      setEndDate(endDate)
    } else {
      setEndDate(startDate)
    }
  }

  const handleClickOpen = () => {
    resetCreateOrderDialogData()
    resetCheckoutData()
    reportGoogleAnalytics('create_order')
    setTimeout(() => {
      setOpenDialog(true)
    }, 100)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  return (
    <Fragment>
      {getDateSelector('DATE RANGE', startDate, handleStartDateSelection, 'Select Date')}
      {getDateSelector(' ', endDate, handleEndDateSelection, 'Select Date')}
      {getFilterSelector('Status', 'status', status, handleOrderStatusSelection, orderStatusList, 'status', styles)}
      {getFilterSelector('Event Type', 'eventType', eventCategory, handleEventCategorySelection, eventCategories, 'name', styles)}
      {getFilterSelector('Menu Type', 'MenuType', menuType, handleMenuTypeSelection, menuTypes, 'name', styles)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
      {
        currentUser && currentUserIsAdmin(currentUser) && !eventId && <FormControl className={classes.createOrderButtonContainer}>
          <Button variant="contained" color="primary" className={classes.createOrderButton} onClick={handleClickOpen}>
            Create an Order
          </Button>
          <CreateOrder openDialog={openDialog} handleDialogClose={handleDialogClose} />
        </FormControl>
      }
      {
        currentUser && currentUserIsAdmin(currentUser) && eventId && <FormControl className={classes.createOrderButtonContainer}>
          <Button variant="contained" color="primary" className={classes.createOrderButton} onClick={handleMoveOrders}>
            Move All Orders
          </Button>
        </FormControl>
      }
    </Fragment>
  )
}

OrderFilterBar.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  eventId: PropTypes.string,
  eventCategories: PropTypes.array,
  filters: PropTypes.object,
  filterItems: PropTypes.func,
  handleMoveOrders: PropTypes.func.isRequired,
  menuTypes: PropTypes.array,
  orderStatusList: PropTypes.array,
  resetCreateOrderDialogData: PropTypes.func,
  resetCheckoutData: PropTypes.func,
  styles: PropTypes.object
}

export default withStyles(styles)(OrderFilterBar)

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import StatusField from '../../elements/StatusField/StatusField'
import InputField from '../../elements/InputField/InputField'
import ImageField from '../../elements/ImageField/ImageField'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import GetPriceInfo from './GetPriceInfo'
import MenuTypeField from './MenuTypeField'
import EventTypeField from './EventTypeField'
import FoodTypeField from './FoodTypeField'
import EditMenuItemDialogActions from './EditMenuItemDialogActions'
import styles from './EditMenuItemDialogContent.css'
import MenuCategories from './MenuCategories'
const DefaultMenuType = 1
function EditMenuItemDialogContent ({
  classes,
  menuItem = {},
  menuTypes,
  foodTypes: foodTypesFromProps,
  handleUpdate,
  handleCancel,
  allCategories
}) {
  const [id, setItemId] = useState(null)
  const [status, setStatus] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [menuType, setMenuType] = useState(DefaultMenuType)
  const [foodTypes, setFoodTypes] = useState(foodTypesFromProps)
  const [assignedEventTypes, setAssignedEventTypes] = useState([])
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [assignedToAllEventTypes, setAssignedToAllEventTypes] = useState(false)
  const [assignedCategories, setAssignedCategories] = useState([])
  const [showNewCategorySelector, setShowNewCategorySelector] = useState(false)

  const handleImageSelection = (imageFile) => {
    setImage(imageFile)
  }

  const handleEventTypeSelection = (eventTypeId) => {
    if (assignedToAllEventTypes) {
      setAssignedEventTypes(assignedEventTypes.map(eventType => {
        if (eventType.event_category_id === eventTypeId) {
          eventType.is_assigned = true
          delete eventType._destroy
        } else {
          eventType.is_assigned = false
          eventType._destroy = 1
        }
        return eventType
      }))
      setAssignedToAllEventTypes(false)
    } else {
      setAssignedEventTypes(assignedEventTypes.map(eventType => {
        if (eventType.event_category_id === eventTypeId) {
          eventType.is_assigned = !eventType.is_assigned
          eventType._destroy = !eventType.is_assigned ? 1 : 0
        }
        return eventType
      }))
      if (assignedEventTypes.filter(eventType => eventType.is_assigned).length === 5) {
        setAssignedToAllEventTypes(true)
      }
    }
  }

  const handleFoodTypeSelection = (foodType) => {
    const options = [...foodTypes]
    const foodTypeIndex = foodTypes.findIndex(type => type.id === foodType)
    const selectedFoodType = options[foodTypeIndex]
    options[foodTypeIndex].selected = !selectedFoodType.selected
    setFoodTypes(options)
  }

  const assignAllEventCategories = () => {
    setAssignedEventTypes(assignedEventTypes.map(eventType => {
      eventType.is_assigned = true
      delete eventType._destroy
      return eventType
    }))
    setAssignedToAllEventTypes(true)
  }

  const getFoodTypesStatus = (options) => {
    let isGlutenFree = false
    let isVegan = false
    options.map(option => {
      if (option.id === 'gluten_free') {
        isGlutenFree = option.selected
      } else {
        isVegan = option.selected
      }
      return option
    })
    return { isGlutenFree, isVegan }
  }

  const updateItem = () => {
    const { isGlutenFree, isVegan } = getFoodTypesStatus(foodTypes)
    const menuItemData = { id, status, name, description, menuType, assignedEventTypes, image, isGlutenFree, isVegan, assignedCategories }
    handleUpdate(menuItemData)
  }

  const handleAssignCategory = () => {
    setShowNewCategorySelector(true)
  }

  const addCategory = (category, index = null) => {
    if (index) {
      assignedCategories[index].parent_category_id = category.id
      assignedCategories[index].parent_category_name = category.name
    } else {
      assignedCategories.push({
        parent_category_id: category.id,
        parent_category_name: category.name
      })
    }
    setAssignedCategories(assignedCategories.slice())
    setShowNewCategorySelector(false)
  }

  const markExistingCategoryToDelete = (index) => {
    const category = assignedCategories[index]
    if (category) {
      const deleteFlagValue = category._destroy === 1 ? 0 : 1
      assignedCategories[index]._destroy = deleteFlagValue
      setAssignedCategories(assignedCategories.slice())
    }
  }

  const updateSubcategory = (subcategory, index) => {
    assignedCategories[index].sub_category_id = subcategory.id
    assignedCategories[index].sub_category_name = subcategory.name
    setAssignedCategories(assignedCategories.slice())
  }

  const removeNewlyAssignedCategory = (index) => {
    assignedCategories.splice(index, 1)
    setAssignedCategories(assignedCategories.slice())
  }

  const getFoodTypes = useCallback((menuItem) => {
    const {
      is_gluten_free: isGlutenFree = false,
      is_vegan: isVegan = false
    } = menuItem
    return [...foodTypes].map(type => {
      if (type.id === 'gluten_free') {
        type.selected = isGlutenFree
      } else {
        type.selected = isVegan
      }
      return type
    })
  }, [menuItem])

  const setMenuItemDataToState = useCallback(() => {
    setItemId(menuItem.id)
    setName(menuItem.web_name)
    setDescription(menuItem.web_description)
    setStatus(menuItem.web_status)
    setMenuType(menuItem.menu_type_id || DefaultMenuType)
    setAssignedEventTypes(menuItem.event_categories)
    setAssignedToAllEventTypes(menuItem.assigned_to_all_event_categories)
    setImageUrl(menuItem.image.thumbnail.url || DEFAULT_IMAGE_URL)
    setFoodTypes(getFoodTypes(menuItem))
    setAssignedCategories(menuItem.categories)
  }, [menuItem, getFoodTypes])

  useEffect(() => {
    if (menuItem) {
      setMenuItemDataToState()
    }
  }, [menuItem, setMenuItemDataToState])

  return (
    <div className={classes.editMenuItemDialogContentContainer}>
      <StatusField
        classes={{
          title: classes.fieldTitle,
          container: classes.statusFieldContainer,
          active: classes.active,
          inactive: classes.inactive
        }}
        currentStatus={status} setStatus={setStatus} />
      <div className={classes.menuItemEditInputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.menuItemEditInputFields
          }}
          placeholder={'Name'}
          id={'editMenuItemName'}
          label={'NAME'}
          value={name}
          changeHandler={setName}
          showLabel
        />
      </div>
      <div className={classes.menuItemEditInputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.menuItemEditInputFields
          }}
          placeholder={'Description'}
          id={'editMenuItemDescription'}
          label={'DESCRIPTION'}
          value={description}
          changeHandler={setDescription}
          showLabel
        />
      </div>
      <GetPriceInfo classes={classes} menuItem={menuItem} />
      <ImageField
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        menuItem={menuItem}
        setImage={handleImageSelection}
        selectedImage={image}
      />
      <EventTypeField
        classes={classes}
        assignedEventTypes={assignedEventTypes}
        setEventType={handleEventTypeSelection}
        assignedToAllEventTypes={assignedToAllEventTypes}
        assignAllEventCategories={assignAllEventCategories}
      />
      <MenuTypeField
        classes={classes}
        menuTypeId={menuType}
        menuTypes={menuTypes}
        setMenuType={setMenuType}
      />
      <FoodTypeField
        classes={classes}
        foodTypes={foodTypes}
        setFoodType={handleFoodTypeSelection}
      />
      <MenuCategories
        classes={classes}
        assignedCategories={assignedCategories}
        addCategory={addCategory}
        updateSubcategory={updateSubcategory}
        allCategories={allCategories}
        showNewCategorySelector={showNewCategorySelector}
        removeNewlyAssignedCategory={removeNewlyAssignedCategory}
        markExistingCategoryToDelete={markExistingCategoryToDelete}
      />
      <EditMenuItemDialogActions
        classes={classes}
        updateItem={updateItem}
        handleCancel={handleCancel}
        handleAssignCategory={handleAssignCategory}
      />
    </div>
  )
}

EditMenuItemDialogContent.propTypes = {
  classes: PropTypes.object,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
  menuItem: PropTypes.object,
  menuTypes: PropTypes.array,
  foodTypes: PropTypes.array,
  allCategories: PropTypes.array
}
export default withStyles(styles)(EditMenuItemDialogContent)

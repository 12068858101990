import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import InputField from '../../elements/InputField/InputField'

const SuiteHolderForm = ({
  classes,
  suiteHolderUser,
  setFirstName,
  setLastName,
  setEmail,
  setContactNumber
}) => {
  const { email = '', firstName = '', lastName, contactNumber } = suiteHolderUser

  return (
    <Fragment>
      <Grid item xs={6} className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.staffUserDetailsInputFields
          }}
          placeholder={'First Name'}
          label={''}
          value={firstName}
          changeHandler={setFirstName}
          showLabel
        />
      </Grid>
      <Grid item xs={6} className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.staffUserDetailsInputFields
          }}
          placeholder={'Last Name'}
          label={''}
          value={lastName}
          changeHandler={setLastName}
          showLabel
        />
      </Grid>
      <Grid item xs={6} className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.staffUserDetailsInputFields
          }}
          placeholder={'Phone Number'}
          label={''}
          value={contactNumber}
          changeHandler={setContactNumber}
          showLabel
        />
      </Grid>
      <Grid item xs={6} className={classes.inputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.staffUserDetailsInputFields
          }}
          placeholder={'Email'}
          label={''}
          value={email}
          changeHandler={setEmail}
          showLabel
        />
      </Grid>
    </Fragment>
  )
}
SuiteHolderForm.propTypes = {
  classes: PropTypes.object,
  suiteHolderUser: PropTypes.object,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  setContactNumber: PropTypes.func
}

export default SuiteHolderForm

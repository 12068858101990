export const ITEM_ADDTION_BY_OPTIONS = {
  1: 'host-only',
  2: 'guest',
  3: 'no-additions'
}

export const PAYMENT_METHODS = {
  1: 'credit-card',
  5: 'invoice'
}

export const PAR_INSTRUCTION_OPTIONS = {
  1: 'default',
  2: 'open',
  3: 'closed'
}

export const SIGNATURE_OPTIONS = {
  1: 'yes',
  2: 'no'
}

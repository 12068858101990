import React from 'react'
import PropTypes from 'prop-types'

const MoveOrdersDialogTitle = ({
  classes,
  eventName,
  eventDate
}) => {
  return (
    <div className={classes.moveOrderTitle}>
      Move orders from {eventName} - {eventDate}
    </div>
  )
}

MoveOrdersDialogTitle.propTypes = {
  classes: PropTypes.object,
  eventName: PropTypes.string,
  eventDate: PropTypes.string
}

export default MoveOrdersDialogTitle

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { matchPath, Link, withRouter } from 'react-router-dom'
import { reportNavClicks } from '../../utils/analytics'
import styles from './Tab.css'

const Tab = ({
  activeClassName,
  classes,
  className,
  history,
  name,
  onClick,
  path
}) => {
  const pathname = history.location.pathname

  const isSelected = matchPath(pathname, { path })

  const classNames = cx({
    [activeClassName]: isSelected,
    [classes.Tab]: true,
    [className]: !!className
  })
  const handleClick = e => {
    reportNavClicks(path)
    onClick && onClick(e)
  }

  return (
    <li className={classNames}>
      <Link onClick={handleClick} to={path}>
        <span>{name}</span>
      </Link>
    </li>
  )
}

Tab.propTypes = {
  activeClassName: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired
}

Tab.defaultProps = {
  className: null,
  onClick: null
}

export default withRouter(withStyles(styles)(Tab))

const styles = {
  createOrderButton: {
    width: '160px',
    height: '50px',
    borderRadius: '0px',
    marginLeft: '30px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center'
  },
  createOrderButtonContainer: {
    borderLeft: '2px solid #4D4E58',
    color: 'white'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import { currentUserIsClient } from '../../store/helpers/userRoleManager'
import QuantitySelector from '../QuantitySelector/QuantitySelector'
import styles from './OrderItem.css'

const OrderItem = ({
  orderItem = {},
  classes,
  handleQuantityChange,
  handleRemoveItemFromCart,
  currentOrderWindow,
  currentUser
}) => {
  const { menuItemId, menu_item_name: name, unitPrice, quantity, totalPrice, orderWindow, advanced_only: advancedOnly } = orderItem
  return (
    <div className={classes.orderItemRow}>
      <QuantitySelector
        disableQuantityChange={orderWindow.toLowerCase() !== currentOrderWindow.toLowerCase()}
        itemId={menuItemId}
        quantity={quantity}
        handleQuantityChange={handleQuantityChange}
        classes={{
          quntitySelector: classes.quntitySelector,
          selectQuantity: classes.selectQuantity
        }} />
      <div className={classes.orderItemName}>{name}</div>
      <div className={classes.unitPrice}>{`$${unitPrice}`}</div>
      <div className={classes.totalPrice}>{`$${totalPrice}`}</div>
      <IconButton
        aria-label="delete"
        className={classes.deleteButtonWrapper}
        onClick={() => handleRemoveItemFromCart(menuItemId, orderWindow)}
        disabled={orderWindow.toLowerCase() !== currentOrderWindow.toLowerCase() && currentUserIsClient(currentUser) && advancedOnly}>
        <Delete className={classes.deleteIcon} />
      </IconButton>
    </div>
  )
}

OrderItem.propTypes = {
  currentUser: PropTypes.object,
  currentOrderWindow: PropTypes.string,
  orderItem: PropTypes.object,
  classes: PropTypes.object,
  handleQuantityChange: PropTypes.func,
  handleRemoveItemFromCart: PropTypes.func
}

export default withStyles(styles)(OrderItem)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import CustomButton from '../../elements/CustomButton/CustomButton'
import theme from '../../theme'
const MENU_TYPES = [
  { id: 1, name: 'Non Kosher' },
  { id: 2, name: 'Kosher' }
]
const Content = ({ classes, item, handleAddOrder, suites = [] }) => {
  const [menuType, setMenuType] = useState('')
  const [selectedSuite, setSelectedSuite] = useState('')

  useEffect(() => {
    if (suites && suites.length === 1) {
      setSelectedSuite(suites[0].id)
    }
  }, [suites, setSelectedSuite])

  const handleMenuTypeSelection = (event) => {
    setMenuType(event.target.value)
  }

  const handleSuiteSelection = (event) => {
    setSelectedSuite(event.target.value)
  }

  const handleAddNewOrder = () => {
    handleAddOrder(menuType, selectedSuite)
  }

  const menuTypeOptions = item.kosherMenuAvailable ? MENU_TYPES : [MENU_TYPES[0]]
  return (
    <div className={classes.eventDetailsContainer}>
      <div className={classes.eventName}>{item.webName}</div>
      <div className={classes.row}>
        <div className={classes.subDetail}>{item.webDescription}</div>
      </div>
      <div>
        <div className={classes.dateTime}>{item.startDateDay}, {item.startDateMonth} {item.startDate} /{item.startTime}</div>
      </div>
      <div className={classes.orderOptionsSelectorContainer}>
        <div className={classes.menuTypeSelector}>
          <MuiThemeProvider theme={theme}>
            {getFilterSelector('Select Suite', 'Select Suite', selectedSuite, handleSuiteSelection, suites, 'display_name', { selectorWidth: '230px' }, true, false, 'Select Suite')}
          </MuiThemeProvider>
        </div>
        <div className={classes.menuTypeSelector}>
          <MuiThemeProvider theme={theme}>
            {getFilterSelector('Select Menu Type', 'Select Menu Type', menuType, handleMenuTypeSelection, menuTypeOptions, 'name', { selectorWidth: '230px' }, true, false, 'Select Menu Type')}
          </MuiThemeProvider>
        </div>
      </div>
      <div className={classes.placeOrderActionContainer}>
        <CustomButton
          variant={'contained'}
          disableRipple
          styleClass={classes.placeOrderButton}
          label={'Add an Order'}
          color={'primary'}
          disabled={!menuType || !selectedSuite}
          clickHandler={handleAddNewOrder}
        />
      </div>
    </div>
  )
}

Content.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  handleAddOrder: PropTypes.func,
  suites: PropTypes.array
}

export default Content

const styles = {
  SubNav: {
    backgroundColor: '#1C202E',
    height: '65px',
    margin: 'auto',
    width: '1210px'
  },
  Tab: {
    height: '100%',
    position: 'relative',
    width: '16%',
    '& a, & a:visited': {
      color: 'white',
      fontFamily: 'Ringside Condensed A, Ringside Condensed B',
      fontSize: '20px',
      fontWeight: 'bold',
      justifyContent: 'left',
      lineHeight: '30px',
      textDecoration: 'none',
      '& span': {
        alignItems: 'center',
        display: 'flex',
        height: '65px',
        marginLeft: '36px'
      }
    }
  },
  isSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
const EditEventSuite = ({
  className,
  actionMode = 'Add'
}) => {
  const title = actionMode === 'Add' ? 'Add Event Suite' : 'Edit Event Suite'
  return (
    <div className={className}>
      {title}
    </div>
  )
}
EditEventSuite.propTypes = {
  className: PropTypes.string,
  actionMode: PropTypes.string
}

export default EditEventSuite

import React from 'react'
import PropTypes from 'prop-types'
import EventsBySuiteList from '../../elements/EventsBySuiteList/EventsBySuiteList'
import EventsBySuiteListFilterBar from '../../elements/EventsBySuiteListFilterBar/EventsBySuiteListFilterBar'
import CopyOrderDialogActions from './CopyOrderDialogActions'
const CopyOrderDialogContent = ({
  classes,
  eventTypes,
  events,
  filterEvents,
  eventId,
  onEventSelect,
  handleDialogClose,
  proceedToCheckout
}) => {
  return (
    <div className={classes.copyOrderDialogBody}>
      <EventsBySuiteListFilterBar
        classes={classes}
        eventTypes={eventTypes}
        filterEventsList={filterEvents}
      />
      <EventsBySuiteList
        events={events}
        eventId={eventId}
        onEventSelect={onEventSelect}
      />
      <CopyOrderDialogActions
        classes={classes}
        handleDialogClose={handleDialogClose}
        proceedToCheckout={proceedToCheckout}
        eventId={eventId}
      />
    </div>
  )
}

CopyOrderDialogContent.propTypes = {
  classes: PropTypes.object,
  eventTypes: PropTypes.array,
  events: PropTypes.array,
  filterEvents: PropTypes.func,
  eventId: PropTypes.number,
  onEventSelect: PropTypes.func,
  handleDialogClose: PropTypes.func,
  proceedToCheckout: PropTypes.func
}

export default CopyOrderDialogContent

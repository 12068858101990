const styles = {
  loadingStateContainer: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 999999
  },
  spinner: {
    position: 'fixed',
    left: '47%',
    top: '50%',
    zIndex: 9999
  }
}
export default styles

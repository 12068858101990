import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
const PAY_BY_INVOICE = 'Pay by invoice'
const SuitesList = ({
  classes,
  suites,
  handleSuitesUpdate,
  handleSuitesInvoiceUpdate
}) => {
  return (
    <Grid item xs={12} className={classes.inputContainer}>
      <Typography variant="h6" className={classes.suitesLabel}>Suites</Typography>
      <ul>
        { suites && suites.map(suite => <li key={suite.id}>
          <FormControlLabel
            control={<Checkbox
              id={suite.id || suite.suite_id}
              checked={suite.is_assigned || false}
              value={suite.name || suite.display_name}
              color="default"
              name={suite.companiesSuiteId}
              onChange={handleSuitesUpdate}
            />}
            label={suite.name || suite.display_name}
          />
          {
            suite.is_assigned && <FormControlLabel
              control={<Checkbox
                id={suite.id || suite.suite_id}
                checked={suite.can_use_invoice || false}
                value={suite.display_name}
                color="default"
                onChange={handleSuitesInvoiceUpdate}
              />}
              label={PAY_BY_INVOICE}
            />
          }
        </li>)
        }
      </ul>
    </Grid>
  )
}
SuitesList.propTypes = {
  classes: PropTypes.object,
  suites: PropTypes.array,
  handleSuitesUpdate: PropTypes.func,
  handleSuitesInvoiceUpdate: PropTypes.func
}

export default SuitesList

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const EditStaffUserActions = ({
  item,
  classes,
  handleCancel,
  saveStaffUser,
  handleUserStatus
}) => {
  return (
    <div className={classes.saveStaffUserActionsContainer}>
      {
        item.webStatus && <Button variant="contained" color="primary" className={classes.saveStaffUserButton} onClick={handleUserStatus}>
          {item.webStatus ? 'Deactivate User' : 'Activate User'}
        </Button>
      }
      <Button variant="contained" color="primary" className={classes.saveStaffUserButton} onClick={saveStaffUser}>
        Save
      </Button>
      <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  )
}
EditStaffUserActions.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.array,
  handleCancel: PropTypes.func,
  saveStaffUser: PropTypes.func,
  handleUserStatus: PropTypes.func
}

export default EditStaffUserActions

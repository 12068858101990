import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from './TabGroup.css'

const TabGroup = ({ children, classes, className }) => {
  const classNames = cx([classes.TabGroup, className])

  return <ul id="main-nav" className={classNames}>{children}</ul>
}

TabGroup.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
}

TabGroup.defaultProps = {
  className: null
}

export default withStyles(styles)(TabGroup)

import idx from 'idx'
import {
  FETCH_EVENTS_FOR_SHARE_TYPE,
  FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE,
  FETCH_SHARE_TYPES,
  RESET_EVENTS_FOR_SHARE_TYPE
} from '../actionTypes'

const initialState = {}

export default function ShareTypesReducers (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SHARE_TYPES:
      return {
        ...state,
        ...payload.suite_shares
      }

    case FETCH_EVENTS_FOR_SHARE_TYPE: {
      const {
        assigned_events: assignedEvents,
        available_events: availableEvents,
        suite_share: { id, name }
      } = payload

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            assignedEvents,
            availableEvents,
            name
          }
        }
      }
    }

    case FETCH_AVAILABLE_EVENTS_FOR_SHARE_TYPE: {
      const id = idx(payload, _ => _.events.suite_share.id)

      return id
        ? {
          ...state,
          events: {
            ...state.events,
            [id]: {
              ...state.events[id],
              availableEvents: idx(payload, _ => _.events.available_events)
            }
          }
        }
        : {
          ...state,
          availableEvents: payload.events
        }
    }

    case RESET_EVENTS_FOR_SHARE_TYPE: {
      return {
        ...state,
        events: null,
        availableEvents: null,
        assignedEvents: null
      }
    }

    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'

const ExportReportTitle = ({
  classes,
  title
}) => {
  return (
    <div className={classes.title}>
      {title}
    </div>
  )
}
ExportReportTitle.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string
}

export default ExportReportTitle

const styles = {
  menuItemWrapper: {
    height: '257px',
    backgroundColor: '#000',
    minWidth: '274px',
    maxWidth: '274px',
    margin: '0px 37px 50px 0px',
    cursor: 'pointer'
  },
  menuItem: {
    position: 'absolute',
    maxWidth: '274px'
  },
  menuItemImage: {
    minWidth: '274px',
    maxHeight: '155px'
  },
  menuItemDetails: {
    minHeight: '78px',
    width: '274px',
    backgroundColor: '#000000',
    paddingLeft: '10px',
    paddingBottom: '10px',
    paddingRight: '20px'
  },
  menuItemName: {
    height: '24px',
    width: '234px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    margin: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  addToCartButtonContainer: {
    position: 'absolute',
    right: '0px',
    padding: '10px'
  },
  addToCartButton: {
    position: 'relative',
    color: 'black',
    backgroundColor: '#65FFF5',
    height: '36px',
    width: '36px',
    fontWeight: 'bolder',
    '&:hover': {
      color: 'black',
      backgroundColor: '#65FFF5'
    }
  },
  dietButton: {
    color: '#061860',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: '14px',
    textAlign: 'center',
    height: '18px',
    width: '30px',
    borderRadius: '12px',
    backgroundColor: '#65FFF5',
    minHeight: '18px',
    marginTop: '10px',
    '&:first-child': {
      marginRight: '6px',
      marginLeft: '10px'
    },
    '&:hover': {
      color: 'black',
      backgroundColor: '#65FFF5'
    }
  }
}

export default styles

import React from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'

const HOSTNAME_PLACEHOLDER = 'In-Suite Host'
const ORDER_NAME_PLACEHOLDER = 'Order Name'
const ORDER_COMMENT_PLACEHOLDER = 'Additional Instructions, Special Occasions, Allergies, etc'
const HOSTNAME_VALIDATION_ERROR_MESSAGE = `You must complete this field. If no host, please write "none"`
const getInputField = (classes, placeholder, value, changeHandler) => {
  return <Input
    placeholder={placeholder}
    className={classes.input}
    value={value}
    onChange={(event) => {
      changeHandler(event.target.value)
    }}
  />
}

const CheckoutInputs = ({
  classes,
  hostName,
  hostNameChangeHandler,
  orderName,
  orderNameChangeHandler,
  orderComment,
  orderCommentChangeHandler,
  showValidationErrorMessage
}) => {
  return (
    <div className={classes.paymentMethodOptionsContainer}>
      {getInputField(classes, HOSTNAME_PLACEHOLDER, hostName, hostNameChangeHandler)}
      {
        showValidationErrorMessage &&
        !hostName &&
        <FormHelperText className={classes.inputTextHelper}>
          {HOSTNAME_VALIDATION_ERROR_MESSAGE}
        </FormHelperText>
      }
      {getInputField(classes, ORDER_NAME_PLACEHOLDER, orderName, orderNameChangeHandler)}
      {getInputField(classes, ORDER_COMMENT_PLACEHOLDER, orderComment, orderCommentChangeHandler)}
    </div>
  )
}

CheckoutInputs.propTypes = {
  classes: PropTypes.object,
  hostName: PropTypes.string,
  hostNameChangeHandler: PropTypes.func,
  orderName: PropTypes.string,
  orderNameChangeHandler: PropTypes.func,
  orderComment: PropTypes.string,
  orderCommentChangeHandler: PropTypes.func,
  showValidationErrorMessage: PropTypes.bool
}

CheckoutInputs.defaultProps = {
  hostName: '',
  orderName: '',
  orderComment: '',
  showValidationErrorMessage: false
}

export default CheckoutInputs

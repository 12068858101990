import React from 'react'
import PropTypes from 'prop-types'
import { history } from '../../store/store'
const CreditCard = ({
  route
}) => {
  const searchQuery = route.location.search
  const searchQueryObj = searchQuery.split('&').reduce((result, qureyParam) => {
    result[qureyParam.split('=')[0]] = qureyParam.split('=')[1] || ''
    return result
  }, {})

  const {
    CardHoldersName: cardHoldersName,
    Card_Number: cardNumber,
    Expiry_Date: expiryDate,
    Bank_Message: bankMessage,
    TransactionCardType: cardType
  } = searchQueryObj

  localStorage.setItem('addedCreditCard', JSON.stringify({
    cardHoldersName,
    cardNumber,
    expiryDate,
    bankMessage,
    cardType
  }))

  const lastVisitedUrl = localStorage.getItem('lastVisitedUrl')
  history.push(lastVisitedUrl)
  return (
    <div>
      test
    </div>
  )
}

CreditCard.propTypes = {
  route: PropTypes.object
}

export default CreditCard

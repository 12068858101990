import {
  FETCH_RECONCILIATION_REPORT,
  FETCH_INVOICE_REPORT,
  SHOW_SPINNER,
  HIDE_SPINNER
} from '../actionTypes'

const processReconciliationData = (payload) => {
  const { items, meta, page, limit, filters, sortParams } = payload
  const reconciliationReportItems = items.map(item => {
    return {
      orderId: item.order_id,
      eatecId: item.eatec_order_number,
      companyName: item.company_name,
      suiteName: item.suite_name,
      igCheckNumber: item.ig_check_number,
      eventDate: item.event_date,
      eventName: item.event_name,
      total: item.total
    }
  })
  const { total_pages: totalPages } = meta

  return { rows: reconciliationReportItems, totalPages, page, limit, filters, sortParams }
}

const processInvoiceReportData = (payload) => {
  const { companies, items, meta, page, limit, filters, sortParams } = payload
  const invoiceItems = items.map(item => {
    const company = companies.find(company => company.eatec_id === item.company_id)
    item.company_name = company.name
    return item
  })
  const { total_pages: totalPages } = meta

  return { rows: invoiceItems, totalPages, page, limit, filters, sortParams }
}
export default function ReportsReducers (state = {}, action) {
  switch (action.type) {
    case FETCH_RECONCILIATION_REPORT:
      return {
        ...state,
        reconciliationReportData: processReconciliationData(action.payload)
      }
    case FETCH_INVOICE_REPORT:
      return {
        ...state,
        invoiceReportData: processInvoiceReportData(action.payload)
      }
    case SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true
      }
    case HIDE_SPINNER:
      return {
        ...state,
        showSpinner: false
      }
    default:
      return state
  }
}

import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Button, FormControl } from '@material-ui/core'
import SearchField from '../SearchField/SearchField'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import ResetFilters from '../ResetFilters/ResetFilters'
import CreateEventSuite from '../../Dialogs/CreateEventSuite/CreateEventSuite'
import styles from './SuitesFilterBar.css'

const OPTIONS = [
  { id: 'true', label: 'Available' },
  { id: 'false', label: 'Not Available' }
]
const SuitesFilterBar = ({
  addEventSuiteMapping,
  classes = {},
  eventId,
  filterItems,
  styles,
  showAddButton = false
}) => {
  const [query, setSearchQuery] = useState('')
  const [status, setStatus] = useState('true')
  const [resetFilters, setResetFiltersFlag] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleSearchQueryInput = (event) => {
    const query = event.target.value
    setSearchQuery(query)
  }

  const handleStatusSelection = (event) => {
    const status = event.target.value
    setStatus(status)
  }

  const handleSubmit = () => {
    filterItems({ query, status })
  }

  const clearFilters = () => {
    setStatus('true')
    setSearchQuery('')
    setResetFiltersFlag(true)
  }

  useEffect(() => {
    if (resetFilters) {
      setResetFiltersFlag(false)
      filterItems({})
    }
  }, [resetFilters, filterItems])

  const handleAddNewSuite = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleAddEventSuite = (eventSuite) => {
    addEventSuiteMapping(eventSuite)
    setTimeout(() => {
      setOpenDialog(false)
      filterItems({ query, status })
    }, 1000)
  }

  return (
    <Fragment>
      {getFilterSelector('status', 'Status', status, handleStatusSelection, OPTIONS, 'label', styles)}
      <SearchField styles={styles} query={query} handleSearchQueryInput={handleSearchQueryInput} handleSubmit={handleSubmit} />
      <ResetFilters handleClick={clearFilters} />
      {
        showAddButton && <>
          <FormControl className={classes.addNewSuiteButtonContainer}>
            <Button variant="contained" color="primary" className={classes.addNewSuiteButton} onClick={handleAddNewSuite}>
              Add New Suite
            </Button>
          </FormControl>
          <CreateEventSuite
            eventId={eventId}
            openDialog={openDialog}
            handleDialogClose={handleDialogClose}
            handleSubmit={handleAddEventSuite}
          />
        </>
      }
    </Fragment>
  )
}

SuitesFilterBar.propTypes = {
  addEventSuiteMapping: PropTypes.func,
  classes: PropTypes.object,
  eventId: PropTypes.number,
  filterItems: PropTypes.func,
  styles: PropTypes.object,
  showAddButton: PropTypes.bool
}

export default withStyles(styles)(SuitesFilterBar)

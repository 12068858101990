import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import {
  CART_ADD_TYPES
} from '../../constants/menuItems'
import { currentUserIsAdmin } from '../../store/helpers/userRoleManager'
import styles from './MenuItem.css'
const DEFAULT_QUANTITY = 1
const {
  QUICK_ADD
} = CART_ADD_TYPES
const MenuItem = ({ classes, item, orderWindow, openMenuItemDetailsDialog, handleAddToCart, currentUser }) => {
  const getItemNameInSentenceCase = (itemName) => {
    return itemName.toLowerCase().split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')
  }

  const handleClickOnMenuItem = () => {
    openMenuItemDetailsDialog(item)
  }

  const quickAddToCart = () => {
    handleAddToCart(item, DEFAULT_QUANTITY, orderWindow, QUICK_ADD)
  }

  const isGameday = () => {
    const isOrderWindowGameDay = orderWindow.toLowerCase() === 'gameday'
    return (currentUserIsAdmin(currentUser) && isOrderWindowGameDay)
  }

  const getMenuItemPrice = () => {
    const price = parseInt((orderWindow.toLowerCase() === 'advance' ? item.advancePrice : item.gamedayPrice), 10)
    return price === 0 ? 'Advance Only' : `$${price}`
  }

  const menuItemImageUrl = item.image.thumbnail.url || item.image.thumbnail.image.url

  return (
    <div className={classes.menuItemWrapper}>
      <div className={classes.menuItem}>
        <div className={classes.addToCartButtonContainer}>
          <Fab disableRipple color="primary" aria-label="add" className={classes.addToCartButton} onClick={() => isGameday() ? handleClickOnMenuItem() : quickAddToCart()}>
            <AddIcon />
          </Fab>
        </div>
        <LazyLoadImage
          alt={item.name}
          effect="blur"
          className={classes.menuItemImage}
          onClick={handleClickOnMenuItem}
          src={menuItemImageUrl || DEFAULT_IMAGE_URL}
        />
        <div className={classes.menuItemDetails} onClick={handleClickOnMenuItem}>
          { item && item.isGlutenFree && <Fab className={classes.dietButton}>{'GF'}</Fab> }
          { item && item.isVegan && <Fab className={classes.dietButton}>{'V'}</Fab> }
          <div className={classes.menuItemName}>
            {getItemNameInSentenceCase(item.name)}
          </div>
          <div className={classes.menuItemName}>
            {getMenuItemPrice()}
          </div>
        </div>
      </div>
    </div>
  )
}

MenuItem.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object,
  handleAddToCart: PropTypes.func,
  openMenuItemDetailsDialog: PropTypes.func,
  orderWindow: PropTypes.string,
  currentUser: PropTypes.object
}

export default withStyles(styles)(MenuItem)

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

import { getDateSelector } from '../../elements/DateSelector/getDateSelector'

const START_DATE = 'START DATE'
const END_DATE = 'END DATE'
const DATE_FORMAT = 'YYYY-MM-DD'
const APPROX_DAYS_IN_3_MONTHS = 92

const ExportReportContent = ({
  classes = {},
  handleDialogClose,
  handleExportReport
}) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [allowExportReport, setAllowExportReport] = useState(true)

  const handleStartDateSelection = (event) => {
    const date = moment(event.target.value)
    const endDateObj = moment(endDate, DATE_FORMAT)
    if (date.isValid()) {
      if (!endDate || !endDateObj.isValid()) {
        setEndDate(date.format(DATE_FORMAT))
      }
      setStartDate(date.format(DATE_FORMAT))
    } else {
      setStartDate(null)
      setEndDate(null)
      setErrorMessage('Please select valid start & end date')
    }
  }

  const handleEndDateSelection = (event) => {
    const endDateObj = moment(event.target.value)
    if (endDateObj.isValid()) {
      setEndDate(endDateObj.format(DATE_FORMAT))
    } else {
      setEndDate(null)
      setErrorMessage('Please select valid start & end date')
    }
  }

  useEffect(() => {
    const startDateObj = moment(startDate, DATE_FORMAT)
    const endDateObj = moment(endDate, DATE_FORMAT)
    var duration = moment.duration(endDateObj.diff(startDateObj)).asDays()

    if (startDateObj.isValid() && endDateObj.isValid()) {
      if (startDateObj && startDateObj > endDateObj) {
        setErrorMessage('End date should be greater than start date.')
      } else if (duration > APPROX_DAYS_IN_3_MONTHS) {
        setErrorMessage('Report can be exported for upto 3 months at a time.')
      } else {
        setErrorMessage('')
      }
    }
  }, [startDate, endDate])

  const handleSubmit = () => {
    handleExportReport({ startDate, endDate })
    handleDialogClose()
  }

  useEffect(() => {
    if (errorMessage.length > 0) {
      setAllowExportReport(false)
    } else {
      setAllowExportReport(true)
    }
  }, [setAllowExportReport, errorMessage])

  return (
    <div className={classes.content}>
      <div className={classes.startDateFieldContainer}>
        {getDateSelector(START_DATE, startDate, handleStartDateSelection, 'Select Date')}
        {getDateSelector(END_DATE, endDate, handleEndDateSelection, 'Select Date')}
      </div>
      <span className={classes.errorMessage}>{errorMessage}</span>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" className={classes.cancelButton} onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button variant="contained" className={classes.submitButton} disabled={!allowExportReport} color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}

ExportReportContent.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleExportReport: PropTypes.func
}

export default ExportReportContent

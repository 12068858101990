import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Info from '@material-ui/icons/InfoOutlined'
import CustomFabButton from '../CustomFabButton/CustomFabButton'

const SECTION_LABEL = 'PAR instructions'
const PAR_TOOLTIP_TITLE = `PAR is your suite’s standing beverage order.
  Selecting OPEN may initiate a charge based on your default PAR instructions.
  This will be a separate charge from any beverage items you may have included in this order.`
const PAR_DEFAULT_OPTION_TOOLTIP_TITLE = `If your suite does not have a PAR or if you’re unsure, please select this option.`

const getToolTip = (classes, title) => {
  return <Tooltip
    classes={{ tooltip: classes.tooltip }}
    title={
      <React.Fragment>
        {title}
      </React.Fragment>
    }
  >
    <Info classes={{ root: classes.infoIconRoot }} />
  </Tooltip>
}

const getLiquorCabinetAccessOptions = (classes, liquorCabinetAccessOptions, liquorCabinetAccess, setLiquorCabinetAccess) => {
  return liquorCabinetAccessOptions.map(option => {
    return <CustomFabButton
      key={option.id}
      variant="extended"
      color="default"
      styleClasses={`${classes.genericFabPill} ${liquorCabinetAccess === option.id ? classes.activeFabPill : ''}`}
      disableRipple
      disableFocusRipple
      label={option.value}
      value={option.id}
      clickHandler={setLiquorCabinetAccess}
    />
  })
}

const ParOptions = ({
  classes,
  liquorCabinetAccessOptions,
  liquorCabinetAccess,
  setLiquorCabinetAccess
}) => {
  return (
    <div className={classes.paymentMethodOptionsContainer}>
      <div className={classes.sectionTitle}>
        {SECTION_LABEL.toUpperCase()}
        {getToolTip(classes, PAR_TOOLTIP_TITLE)}
      </div>
      <div className={classes.paymentMethodButtonsContainer}>
        {getLiquorCabinetAccessOptions(classes, liquorCabinetAccessOptions, liquorCabinetAccess, setLiquorCabinetAccess)}
        {getToolTip(classes, PAR_DEFAULT_OPTION_TOOLTIP_TITLE)}
      </div>
    </div>
  )
}

ParOptions.propTypes = {
  classes: PropTypes.object,
  liquorCabinetAccessOptions: PropTypes.array,
  liquorCabinetAccess: PropTypes.number,
  setLiquorCabinetAccess: PropTypes.func
}

export default ParOptions

import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchCompanies, clearUpdateMessage } from '../../store/companies/actions'
import CompaniesFilterBar from '../../elements/CompaniesFilterBar/CompaniesFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import EditCompany from '../../Dialogs/EditCompany/EditCompany'
import QuickNotice from '../../elements/QuickNotice/QuickNotice'

import { history } from '../../store/store'
import {
  CompanyProperties,
  rowsFor,
  resourceName,
  styles
} from '../../constants/companies'

import {
  defaultPage,
  defaultLimit,
  defaultTotalPages,
  rowsPerPageOptions
} from '../../constants/pagination'

const LazyTable = React.lazy(() => import('../../elements/Table/Table'))

const CompaniesList = ({
  clearUpdateMessage,
  companyUpdatedMessage,
  fetchItems,
  filters,
  limit: rowsPerPage = defaultLimit,
  page = defaultPage,
  rows,
  sortParams,
  totalPages = defaultTotalPages
}) => {
  const [company, setCompany] = useState(null)
  const [isEditCompanyDialogOpen, setEditCompanyDialogStatus] = useState(false)

  const [updateMessage, setUpdateMessage] = useState('')

  const closeDialog = () => {
    setEditCompanyDialogStatus(false)
  }

  useEffect(() => {
    if (companyUpdatedMessage && companyUpdatedMessage.success) {
      setUpdateMessage(companyUpdatedMessage.message)
      closeDialog()
      const timer1 = setTimeout(() => {
        setUpdateMessage('')
        clearUpdateMessage()
      }, 1000)
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [companyUpdatedMessage, clearUpdateMessage])

  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, rowsPerPage, sort)
  }

  const viewCompany = (item) => {
    history.push(`/suite-holder-users/${item.id}`)
  }

  const editCompany = (item) => {
    setCompany(item)
    setEditCompanyDialogStatus(true)
  }

  const settingsMenuBuilder = item => [
    {
      id: item.id,
      label: 'View',
      action: viewCompany
    },
    {
      id: item.id,
      label: 'Edit',
      action: editCompany
    }
  ]

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    fetchItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  return (
    <div>
      { updateMessage && <QuickNotice message={updateMessage} /> }
      {
        <DataContainer>
          <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
            <CompaniesFilterBar
              styles={styles}
            />
          </FilterBar>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyTable
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSorting={handleSorting}
              page={page}
              properties={CompanyProperties}
              rows={rows}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              rowsFor={rowsFor}
              resourceName={resourceName}
              settingsMenuBuilder={settingsMenuBuilder}
              sortParams={sortParams}
              totalPages={totalPages}
            />
          </Suspense>
        </DataContainer>
      }
      {
        isEditCompanyDialogOpen && <EditCompany
          company={company}
          companyUpdatedMessage={companyUpdatedMessage}
          handleDialogClose={closeDialog}
          openDialog={isEditCompanyDialogOpen}
        />
      }
    </div>

  )
}

CompaniesList.propTypes = {
  clearUpdateMessage: PropTypes.func,
  companyUpdatedMessage: PropTypes.object,
  fetchItems: PropTypes.func,
  filters: PropTypes.object,
  limit: PropTypes.number,
  page: PropTypes.number,
  rows: PropTypes.array,
  sortParams: PropTypes.object,
  totalPages: PropTypes.number
}

const mapStateToProps = (state) => {
  return {
    ...state.companiesData.companiesList,
    companyUpdatedMessage: state.companiesData.companyUpdatedMessage
  }
}

const mapDispatchToProps = {
  clearUpdateMessage,
  fetchItems: fetchCompanies
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompaniesList)
)

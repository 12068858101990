import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import ExportReportTitle from './ExportReportTitle'
import ExportReportContent from './ExportReportContent'
import styles from './ExportReport.css'

const ExportReport = ({
  classes,
  handleDialogClose,
  handleExportReport,
  openDialog,
  title
}) => {
  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={<ExportReportTitle classes={classes} title={title} />}
      dialogContent={<ExportReportContent classes={classes} handleExportReport={handleExportReport} handleDialogClose={handleDialogClose} />}
      dialogActions={null}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

ExportReport.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  handleExportReport: PropTypes.func,
  openDialog: PropTypes.bool,
  title: PropTypes.string
}

export default withStyles(styles)(ExportReport)

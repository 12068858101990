import React from 'react'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import styles from './ResetFilters.css'

const ResetFilters = ({ classes, handleClick }) => {
  return (
    <div className={classes.resetFilterIcon} onClick={handleClick}>
      Clear
    </div>
  )
}

ResetFilters.propTypes = {
  classes: PropTypes.object,
  handleClick: PropTypes.func
}

export default withStyles(styles)(ResetFilters)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import { checkProperties, validateEmail } from '../../utils/validations'
import styles from './ManageStaffUser.css'
import Title from './Title'
import Content from './Content'
import Actions from './Actions'
const ManageStaffUser = ({
  classes,
  staffUser: user,
  openDialog,
  handleDialogClose,
  mode,
  rolesList,
  addStaffUser,
  updateStaffUser,
  activateStaffUserStatus,
  deActivateStaffUserStatus
}) => {
  const [staffUser, setStaffUser] = useState()
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    setValidationError('')
    setStaffUser(user)
  }, [user])

  const getTitle = (classes, mode) => {
    return mode && <Title className={classes.saveStaffUserActionsTitle} title={`${mode} Staff User`} />
  }

  const getDialogContent = (item, classes, setFirstName, setLastName, setEmail, setRole, rolesList, validationError) => {
    return <Content classes={classes} item={item} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setRole={setRole} rolesList={rolesList} validationError={validationError} />
  }

  const getDialogActions = (item, classes, saveStaffUser, handleDialogClose, handleUserStatus) => {
    return <Actions
      classes={classes}
      item={item}
      saveStaffUser={saveStaffUser}
      handleCancel={handleDialogClose}
      handleUserStatus={handleUserStatus}
    />
  }

  const setFirstName = (firstName) => {
    const currentUser = { ...staffUser }
    const [, lastName = ''] = currentUser.name.split(' ')
    const name = `${firstName} ${lastName}`
    setStaffUser({
      ...currentUser,
      name,
      firstName,
      lastName
    })
  }

  const setLastName = (lastName) => {
    const currentUser = { ...staffUser }
    const [firstName = ''] = currentUser.name.split(' ')
    const name = `${firstName} ${lastName}`
    setStaffUser({
      ...currentUser,
      name,
      firstName,
      lastName
    })
  }

  const setEmail = (email) => {
    const currentUser = { ...staffUser }
    setStaffUser({
      ...currentUser,
      email
    })
  }

  const getRoleIdByName = (roleName) => {
    return rolesList.filter(role => role.name.toLowerCase() === roleName.toLowerCase())[0].id
  }

  const setRole = (event) => {
    const currentUser = { ...staffUser }
    const userRoleId = event.target.value
    const roleName = rolesList.filter(role => role.id === userRoleId)[0].name.toLowerCase()
    const selectedRoleId = rolesList.filter(role => role.id === userRoleId)[0].id
    setStaffUser({
      ...currentUser,
      roleName,
      selectedRoleId
    })
  }

  const saveStaffUser = () => {
    const { firstName, lastName, roleName, email } = staffUser
    const fieldsToValidate = {
      firstName,
      lastName,
      roleName,
      email
    }
    const isStaffFormValid = checkProperties(fieldsToValidate) && validateEmail(email)
    if (isStaffFormValid) {
      setValidationError('')
      if (staffUser.id) {
        updateStaffUser({ ...staffUser, selectedRoleId: getRoleIdByName(roleName) })
      } else {
        addStaffUser(staffUser)
      }
    } else {
      setValidationError('Please valid inputs to required fields.')
    }
  }

  const handleUserStatus = () => {
    if (staffUser.webStatus) {
      deActivateStaffUserStatus(staffUser)
    } else {
      activateStaffUserStatus(staffUser)
    }
  }
  return (
    <Dialog
      openDialog={openDialog}
      dialogTitle={getTitle(classes, mode)}
      dialogContent={getDialogContent(staffUser, classes, setFirstName, setLastName, setEmail, setRole, rolesList, validationError)}
      dialogActions={getDialogActions(staffUser, classes, saveStaffUser, handleDialogClose, handleUserStatus)}
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      handleDialogClose={handleDialogClose}
    />
  )
}

ManageStaffUser.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func,
  openDialog: PropTypes.bool,
  staffUser: PropTypes.object,
  mode: PropTypes.string,
  rolesList: PropTypes.array,
  addStaffUser: PropTypes.func,
  updateStaffUser: PropTypes.func,
  activateStaffUserStatus: PropTypes.func,
  deActivateStaffUserStatus: PropTypes.func
}

export default withStyles(styles)(ManageStaffUser)

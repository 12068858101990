import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const VoidEventSuiteReservationTitleActions = ({
  classes,
  handleCancel,
  handleVoidReservation
}) => {
  return (
    <div className={classes.actionContainer}>
      <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={handleVoidReservation}
      >
        Void Reservation
      </Button>
    </div>
  )
}

VoidEventSuiteReservationTitleActions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleVoidReservation: PropTypes.func.isRequired
}

export default VoidEventSuiteReservationTitleActions

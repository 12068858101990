import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  overrides: {
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true
    },
    MuiTypography: {
      caption: {
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        color: 'white'
      },
      colorInherit: {
        color: 'white'
      }
    },
    MuiInput: {
      underline: {
        '&&&&:before, &&&&:after': {
          borderBottom: '2px dotted white',
          transition: 'none'
        },
        '&&&&:hover:before': {
          borderBottom: '2px dotted white'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: 'white',
        fontFamily: 'Ringside Condensed A , Ringside Condensed B'
      },
      root: {
        fontFamily: 'Ringside Condensed A , Ringside Condensed B',
        '&&&&:hover': {
          color: 'white'
        }
      }
    },
    MuiSelect: {
      icon: {
        color: 'white'
      }
    },
    MuiFormControl: {
      root: {
        margin: '5px'
      },
      marginNormal: {
        marginTop: '5px'
      }
    },
    MuiFormLabel: {
      root: {
        color: 'white',
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        '&$focused': {
          color: 'white',
          textTransform: 'uppercase'
        }
      }
    },
    MuiList: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#2A2D3B'
      },
      elevation2: {
        boxShadow: 'none'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 6px 0px 6px',
        borderBottom: '1px solid #4D4E58'
      },
      head: {
        color: 'white',
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        fontSize: '10px',
        fontWeight: '600',
        letterSpacing: '1px',
        lineHeight: '14px'
      },
      body: {
        color: 'white',
        fontFamily: 'Ringside Condensed A, Ringside Condensed B',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px'
      }
    },
    MuiIconButton: {
      root: {
        color: 'white',
        '&$disabled': {
          color: 'white'
        }
      }
    },
    MuiTablePagination: {
      spacer: {
        flex: 'none'
      }
    }
  },
  palette: {
    primary: {
      main: '#0078D4'
    }
  },
  typography: {
    button: {
      textTransform: 'capitalize',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  }
})

export default theme

module.exports.MenuItemProperties = [
  {
    key: 'status',
    label: 'STATUS',
    sortable: true
  },
  {
    key: 'imageUrl',
    label: 'IMAGE',
    sortable: false
  },
  {
    key: 'webname',
    label: 'MENU ITEM',
    sortable: true
  },
  {
    key: 'eventType',
    label: 'EVENT TYPE',
    sortable: false
  },
  {
    key: 'categories',
    label: 'CATEGORIES',
    sortable: false
  },
  {
    key: 'advanceOrderPrice',
    label: 'ADVANCE',
    sortable: false
  },
  {
    key: 'gamedayOrderPrice',
    label: 'GAME DAY',
    sortable: false
  }
]

module.exports.sortParamsMapping = {
  status: 'web_status',
  webname: 'web_name'
}

module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.styles = {
  selectorWidth: '150px',
  searchFieldWidth: '220px'
}

module.exports.rowsFor = 'menuItems'
module.exports.resourceName = 'menu_items'
module.exports.FEATURED_CATEGORY_NAME = 'Featured'
module.exports.ALL_CATEGORIES = 'All'

module.exports.SCROLL_TOP_OPTIONS = {
  SCROLL_STEP_IN_PX: 50,
  DELAY_IN_MS: 16.66
}

module.exports.CART_ICON_STICKY_POSITION_OFFSET = 150
module.exports.ADVANCE_PRICE_WINDOW = 'advance'
module.exports.GAMEDAY_PRICE_WINDOW = 'gameday'
module.exports.ADMIN_ROLE = 'admin'
module.exports.SUPER_ADMIN_ROLE = 'super_admin'
module.exports.ADMIN_ROLES = ['admin', 'super_admin']
module.exports.CART_ADD_TYPES = {
  QUICK_ADD: 'QUICK_ADD',
  MODAL_ADD: 'MODAL_ADD'
}
module.exports.ITEM_CANCELLATION_WARNING = 'All orders will become due and payable in full as of the applicable cancellation deadline, inclusive of all applicable taxes and gratuities. Orders for Advance menu items may only be modified or cancelled before 12:00pm ET on the business day prior to the applicable event. Orders for Game Day items may only be modified or cancelled before (i) 12:00pm ET on the day of event for weekday events or (ii) or 12:00pm ET on the business day prior to the event for Saturday, Sunday and holiday events.'
module.exports.BUSINESS_DAY_NOTE = 'Business days exclude weekends, federal holidays and corporate holidays at Madison Square Garden.'
module.exports.TAX_AND_GRATUITIES_INFO = 'NY State sales tax (8.875%) and gratuity (18%) will be applied to your total'
module.exports.ADVANCE_ITEM_DEADLINE_WARNING = 'Orders may only be modified or cancelled before the applicable cancellation deadline. As of the cancellation deadline, all orders will become due and payable in full, inclusive of applicable taxes and gratuities'
module.exports.REQUIRED_RECOMMENDATIONS_COUNT = 8

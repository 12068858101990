import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import { Actions, Checkbox, ItemList, Label, SelectAllLabel, SelectButton, Root } from './MultiSelectList.css'

const MultiSelectList = ({ filterComponent, filterText, items, nameKey, dateKey, onSelect, selectButtonLabel }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const hasItems = items.length > 0

  const handleItemSelect = e => {
    const { target: { checked, id } } = e
    const filteredItems = selectedItems.filter(({ id: id2 }) => id.toString() !== id2.toString())
    setSelectedItems(filteredItems)

    if (checked) {
      const itemToAdd = items.find(({ id: id2 }) => id.toString() === id2.toString())
      setSelectedItems([...selectedItems, itemToAdd])
    }
  }

  const itemFilter = item => !filterText || new RegExp(filterText, 'i').test(item[nameKey])

  const Items = items
    .filter(itemFilter)
    .map(item => {
      const itemId = item.id.toString()
      const name = `${item[nameKey]} - ${item[dateKey]}`
      const isChecked = !!selectedItems.find(({ id }) => id.toString() === itemId)
      return (
        <li key={itemId}>
          <Label
            control={
              <Checkbox
                id={itemId}
                name={itemId}
                color="primary"
                onChange={handleItemSelect}
                checked={isChecked}
                value={name}
              />
            }
            label={name}
          />
        </li>
      )
    })

  const handleButtonClick = () => {
    onSelect(selectedItems)
    setSelectedItems([])
  }

  const handleSelectAll = e => e.target.checked
    ? setSelectedItems(items.filter(itemFilter))
    : setSelectedItems([])

  return (
    <Root>
      { filterComponent }
      <ItemList>
        {Items}
      </ItemList>
      <Actions>
        <div>
          <SelectAllLabel
            control={<MuiCheckbox color="primary" disabled={!hasItems} onChange={handleSelectAll} checked={items.length === selectedItems.length} />}
            label="Select All"
          />

        </div>
        <div>
          <SelectButton
            variant="contained"
            onClick={handleButtonClick}
            disabled={!hasItems}
          >
            {selectButtonLabel}
          </SelectButton>
        </div>
      </Actions>
    </Root>
  )
}

MultiSelectList.propTypes = {
  filterComponent: PropTypes.node,
  filterText: PropTypes.string,
  items: PropTypes.array.isRequired,
  nameKey: PropTypes.string,
  dateKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectButtonLabel: PropTypes.string
}

MultiSelectList.defaultProps = {
  filterComponent: null,
  filterText: null,
  nameKey: 'name',
  selectButtonLabel: 'Select'
}

export default MultiSelectList

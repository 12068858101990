const styles = {
  inputContainer: {
    padding: '10px 0px'
  },
  warningText: {
    fontWeight: 'bold',
    color: 'red'
  }
}

export default styles


import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { getFilterSelector } from '../FilterSelector/getFilterSelector'
import { getDateSelector } from '../DateSelector/getDateSelector'
import SearchField from '../SearchField/SearchField'

const styles = {
  searchFieldWidth: '130px',
  selectorWidth: '130px'
}

const EventsFilterTitle = styled.div`
  height: 24px;
  width: 131px;
  color: #000000;
  font-family: Ringside Condensed A, Ringside Condensed B;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  padding-left: 4px;
`

EventsBySuiteListFilterBar.propTypes = {
  classes: PropTypes.object,
  filterEventsList: PropTypes.func,
  eventTypes: PropTypes.array
}

function EventsBySuiteListFilterBar ({
  classes,
  eventTypes,
  filterEventsList
}) {
  const [eventType, setEventType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [query, setQuery] = useState('')

  function onEventTypeSelect (event) {
    setEventType(event.target.value)
  }

  function handleStartDateSelection (event) {
    const startDate = event.target.value
    setStartDate(startDate)
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() < startDateObj.getTime()) {
      setEndDate(startDate)
    }
  }
  function handleEndDateSelection (event) {
    const endDate = event.target.value
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)
    if (endDateObj.getTime() > startDateObj.getTime()) {
      setEndDate(endDate)
    } else {
      setEndDate(startDate)
    }
  }

  function handleSearchQueryInput (event) {
    setQuery(event.target.value)
  }

  function handleSubmit () {
    const filters = {
      startDate,
      endDate,
      eventType,
      query
    }
    filterEventsList(filters)
  }

  function handleResetFilters () {
    setEventType('')
    setStartDate('')
    setEndDate('')
    setQuery('')
    filterEventsList({})
  }

  return (
    <div className={classes.eventSelectionContainer}>
      <EventsFilterTitle> Select Event </EventsFilterTitle>
      <div className={classes.eventFilterContainer}>
        {getFilterSelector('Event Type', 'eventType', eventType, onEventTypeSelect, eventTypes, 'name', styles, true)}
        {getDateSelector('DATE RANGE', startDate, handleStartDateSelection)}
        {getDateSelector('  ', endDate, handleEndDateSelection)}
        <SearchField
          styles={styles}
          query={query}
          handleSearchQueryInput={handleSearchQueryInput}
          handleSubmit={handleSubmit}
        />
        <Button disableRipple className={classes.resetEventFiltersButton} onClick={handleResetFilters}>
          Clear Filters
        </Button>
      </div>
    </div>
  )
}

export default EventsBySuiteListFilterBar

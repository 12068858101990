const styles = {
  inSuiteActionOptionsDetails: {
    background: 'white',
    height: '129px',
    width: '1210px',
    display: 'flex',
    color: 'black'
  },
  optionContainer: {
    width: '290px',
    padding: '40px 30px'
  },
  inSuiteActionOptionsLabel: {
    height: '34px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    margin: '8px 0px'
  },
  inSuiteActionOptionsValue: {
    height: '24px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    margin: '8px 0px'
  }
}

export default styles

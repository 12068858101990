import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import { withStyles } from '@material-ui/core/styles'
import styles from './QuantitySelector.css'

const defaultMaxQuantity = 10

const getQuantityOptions = () => {
  const options = []
  for (let i = 1; i <= defaultMaxQuantity; i++) {
    options.push(<option key={i} value={i}>{i}</option>)
  }
  return options
}

const QuantitySelector = ({
  disableQuantityChange = false,
  itemId,
  quantity,
  handleQuantityChange,
  classes
}) => {
  return (
    <FormControl className={classes.quntitySelector}>
      <NativeSelect
        value={quantity}
        onChange={(e) => { handleQuantityChange(itemId, parseInt(e.target.value, 10)) }}
        name="quantity"
        classes={{ root: classes.selectQuantity, select: classes.selectQuantity }}
        inputProps={{ 'aria-label': 'quantity' }}
        disabled={disableQuantityChange}
      >
        {getQuantityOptions()}
      </NativeSelect>
    </FormControl>
  )
}

QuantitySelector.propTypes = {
  disableQuantityChange: PropTypes.bool,
  itemId: PropTypes.number,
  quantity: PropTypes.number,
  handleQuantityChange: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(QuantitySelector)

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  recommendations_container: {
    alignSelf: 'flex-start',
    background: '#6DFEF4',
    flexDirection: 'column',
    height: '330px',
    margin: '0px 35px',
    padding: '15px 20px',
    width: '490px'
  },
  title: {
    alignSelf: 'flex-start',
    color: '#081B5E',
    fontSize: '15px',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontWeight: '600',
    height: '24px',
    lineHeight: '14px',
    letterSpacing: '1px',
    margin: '0px',
    width: '390px'
  },
  sliderNav: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px'
  },
  arrow: {
    color: '#081B5E'
  }
}, { index: 1 })

export default useStyles

const styles = {
  addStaffUserButton: {
    width: '160px',
    height: '50px',
    borderRadius: '0px',
    marginLeft: '30px',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B'
  },
  addStaffUserButtonContainer: {
    borderLeft: '2px solid #4D4E58'
  }
}

export default styles

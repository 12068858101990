import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchMenuItems, setItemToEdit, updateMenuItem, activateItem, deactivateItem } from '../../store/menuItems/actions'
import MenuItemsFilterBar from '../../elements/MenuItemsFilterBar/MenuItemsFilterBar'
import DataContainer from '../../elements/DataContainer/DataContainer'
import FilterBar from '../../elements/FilterBar/FilterBar'
import Confirmation from '../../Dialogs/Confirmation/Confirmation'
import {
  MenuItemProperties,
  styles,
  resourceName,
  rowsFor
} from '../../constants/menuItems'
import {
  DietMenuOptions
} from '../../constants/menuCatalog'
import {
  rowsPerPageOptions,
  defaultPage,
  defaultLimit,
  defaultTotalPages
} from '../../constants/pagination'
import EditMenuItem from '../../Dialogs/EditMenuItem/EditMenuItem'

const LazyTable = React.lazy(() => import('../../elements/Table/Table'))

const ActivateItemConfirmationMessage = 'Your changes will be permanent. Please confirm you want to activate the menu item.'
const DeactivateItemConfirmationMessage = 'Your changes will be permanent. Please confirm you want to deactivate the menu item.'
const ITEM_STATUS = { ACTIVE: 'Active', INACTIVE: 'Inactive' }
const MenuItemsList = ({
  fetchItems,
  setItemToEdit,
  updateMenuItem,
  activateItem,
  deactivateItem,
  rows,
  totalPages = defaultTotalPages,
  page = defaultPage,
  limit: rowsPerPage = defaultLimit,
  menuItemStatusList,
  menuCategories,
  menuTypes,
  eventCategories,
  filters,
  sortParams,
  currentUser
}) => {
  const [openEditMenuItemDialog, setOpenEditMenuItemDialog] = useState(false)
  const [menuItemIdToEdit, setMenuItemIdToEdit] = useState('')
  const [openItemStatuChangeConfirmation, setOpenItemStatuChangeConfirmation] = useState(false)
  const [selectedMenuItemStatus, setSelectedMenuItemStatus] = useState('')
  useEffect(() => {
    if (!rows) {
      fetchItems({})
    }
  }, [rows, fetchItems])

  const handleChangePage = (event, page) => {
    fetchItems(filters, page, rowsPerPage, sortParams)
  }

  const handleChangeRowsPerPage = event => {
    fetchItems(filters, defaultPage, +event.target.value, sortParams)
  }

  const handleSorting = (orderBy, sortOrder = 'desc') => {
    const sort = { sortBy: orderBy, order: sortOrder }
    fetchItems(filters, page, rowsPerPage, sort)
  }

  const editMenuItem = (item) => {
    setMenuItemIdToEdit(item.id)
    setItemToEdit(item.id)
    setOpenEditMenuItemDialog(true)
  }

  const handleConfirmationDialogClose = () => {
    setMenuItemIdToEdit('')
    setOpenItemStatuChangeConfirmation(false)
  }

  const handleUpdateMenuItemStatus = () => {
    if (selectedMenuItemStatus === ITEM_STATUS.ACTIVE) {
      deactivateItem(menuItemIdToEdit)
    } else {
      activateItem(menuItemIdToEdit)
    }
    setTimeout(() => {
      handleConfirmationDialogClose()
    }, 2000)
  }

  const toggleStatus = (item) => {
    if (item.status === ITEM_STATUS.ACTIVE) {
      setSelectedMenuItemStatus(ITEM_STATUS.ACTIVE)
      setMenuItemIdToEdit(item.id)
    } else {
      setSelectedMenuItemStatus(ITEM_STATUS.INACTIVE)
      setMenuItemIdToEdit(item.id)
    }
    setOpenItemStatuChangeConfirmation(true)
  }

  const handleDialogClose = () => {
    setOpenEditMenuItemDialog(false)
  }

  function handleUpdate (menuItemData) {
    updateMenuItem(menuItemData)
    setTimeout(() => {
      setOpenEditMenuItemDialog(false)
    }, 1000)
  }

  const settingsMenuBuilder = item => [
    {
      id: item.id,
      label: 'Edit',
      action: editMenuItem
    },
    {
      id: item.id,
      label: item.status === 'Active' ? 'Deactivate' : 'Activate',
      action: toggleStatus
    }
  ]

  const applyFilter = (filters, page, rowsPerPage, sortParams) => {
    fetchItems(filters, defaultPage, rowsPerPage, sortParams)
  }

  const confirmationMessage = selectedMenuItemStatus === ITEM_STATUS.ACTIVE ? DeactivateItemConfirmationMessage : ActivateItemConfirmationMessage
  return (
    <div>
      <DataContainer>
        <FilterBar fetchItems={applyFilter} page={page} limit={rowsPerPage} sortParams={sortParams} filters={filters} styles={styles}>
          <MenuItemsFilterBar
            menuItemStatusList={menuItemStatusList}
            menuCategories={menuCategories}
            menuTypes={menuTypes}
            eventCategories={eventCategories}
            currentUser={currentUser}
          />
        </FilterBar>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyTable
            properties={MenuItemProperties}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSorting={handleSorting}
            sortParams={sortParams}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            totalPages={totalPages}
            rowsFor={rowsFor}
            resourceName={resourceName}
            settingsMenuBuilder={settingsMenuBuilder}
          />
        </Suspense>
        <EditMenuItem
          openDialog={openEditMenuItemDialog}
          menuItemIdToEdit={menuItemIdToEdit}
          handleDialogClose={handleDialogClose}
          menuTypes={menuTypes}
          eventTypes={eventCategories}
          handleUpdate={handleUpdate}
          foodTypes={DietMenuOptions}
        />
      </DataContainer>
      <Confirmation
        isOpen={openItemStatuChangeConfirmation}
        onClose={handleConfirmationDialogClose}
        onSubmit={handleUpdateMenuItemStatus}
        message={confirmationMessage}
      />
    </div>

  )
}

MenuItemsList.propTypes = {
  rows: PropTypes.array,
  menuItemStatusList: PropTypes.array.isRequired,
  menuTypes: PropTypes.array.isRequired,
  eventCategories: PropTypes.array.isRequired,
  menuCategories: PropTypes.array.isRequired,
  filters: PropTypes.object,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  fetchItems: PropTypes.func.isRequired,
  sortParams: PropTypes.object,
  setItemToEdit: PropTypes.func,
  updateMenuItem: PropTypes.func,
  activateItem: PropTypes.func,
  deactivateItem: PropTypes.func,
  currentUser: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.menuItemsData.menuItemsList,
    menuItemStatusList: state.staticData.menuItemStatusList,
    eventCategories: state.staticData.event_categories,
    menuCategories: state.staticData.menu_categories,
    menuTypes: state.staticData.menu_types,
    currentUser: state.staticData.user_details
  }
}

const mapDispatchToProps = {
  fetchItems: fetchMenuItems,
  setItemToEdit,
  updateMenuItem,
  activateItem,
  deactivateItem
}

export default React.memo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuItemsList)
)

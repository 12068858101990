import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  bubble: ({ currentIndex, index }) => ({
    fontSize: currentIndex === index ? '14px' : '10px',
    margin: '0px 3px',
    color: '#081B5E'
  })
}, { index: 1 })

export default useStyles

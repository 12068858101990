import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataContainer from '../../elements/DataContainer/DataContainer'
import Table from '../../elements/Table/Table'
import SubNav from '../../elements/SubNav/SubNav'
import ShareTypeDialog from '../../Dialogs/ShareType/ShareType'
import { routes } from '../'
import { fetchShareTypes } from '../../store/shareTypes/actions'
import styles, {
  CustomShareTypesTable,
  DefaultShareTypesTable
} from './ShareTypes.css'

const {
  columnNames,
  shareTypesProperties,
  rowsFor,
  resourceName
} = require('../../constants/shareTypes')

const DataTables = styled(DataContainer)`
  display: flex;
  justify-content: space-between;
`

const ShareTypes = ({
  classes,
  default_types: defaultTypes,
  custom_types: customTypes = [],
  fetchShareTypes
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [eventIdToEdit, setEventIdToEdit] = useState(null)
  const [allowNameEdit, setAllowNameEdit] = useState(null)

  const closeDialog = () => {
    setIsDialogOpen(false)
    setEventIdToEdit(null)
  }

  const openDialog = (id, isNameEditable = true) => {
    if (id) {
      setEventIdToEdit(id)
      setAllowNameEdit(isNameEditable)
    }

    setIsDialogOpen(true)
  }

  const settingsMenuBuilder = isNameEditable => item => [
    {
      label: 'Edit',
      action: () => openDialog(item.id, isNameEditable)
    }
  ]

  useEffect(() => fetchShareTypes(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <SubNav
        routes={routes.find(({ path }) => path === '/admin').childRoutes}
      />
      <DataTables>
        <Button
          variant="contained"
          color="default"
          className={classes.addButton}
          onClick={() => openDialog()}
        >
          Add Share Type
        </Button>
        {defaultTypes && (
          <DefaultShareTypesTable>
            <h3>Default Share Types</h3>
            <Table
              classes={classes}
              columnNames={columnNames}
              properties={shareTypesProperties}
              rows={defaultTypes}
              rowsFor={rowsFor}
              resourceName={resourceName}
              settingsMenuBuilder={settingsMenuBuilder(false)}
            />
          </DefaultShareTypesTable>
        )}
        {customTypes && (
          <CustomShareTypesTable>
            <h3>Custom Share Types</h3>
            <Table
              classes={classes}
              columnNames={columnNames}
              properties={shareTypesProperties}
              rows={customTypes}
              rowsFor={rowsFor}
              resourceName={resourceName}
              sortParams={{ name: 'web_name' }}
              settingsMenuBuilder={settingsMenuBuilder(true)}
            />
          </CustomShareTypesTable>
        )}
      </DataTables>
      {isDialogOpen && <ShareTypeDialog
        id={eventIdToEdit}
        allowNameEdit={allowNameEdit}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
      }
    </div>
  )
}

ShareTypes.propTypes = {
  classes: PropTypes.object.isRequired,
  custom_types: PropTypes.array,
  default_types: PropTypes.array,
  fetchShareTypes: PropTypes.func.isRequired
}

ShareTypes.defaultProps = {
  custom_types: null,
  default_types: null
}

const mapStateToProps = state => ({
  ...state.shareTypesData
})

const mapDispatchToProps = { fetchShareTypes }

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(ShareTypes)
  )
)

import React from 'react'
import PropTypes from 'prop-types'
const EditCategoriesTitle = ({
  className,
  actionMode = 'List'
}) => {
  const title = actionMode === 'List' ? 'Categories' : actionMode === 'Add' ? 'Add Categories' : 'Edit Categories'
  return (
    <div className={className}>
      {title}
    </div>
  )
}
EditCategoriesTitle.propTypes = {
  className: PropTypes.string,
  actionMode: PropTypes.string
}

export default EditCategoriesTitle

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'

const EditCompanySuites = ({
  classes,
  suites,
  suiteShares,
  companySuite,
  setSuites,
  setSuiteShare,
  remove,
  index
}) => {
  const [selectedSuiteIndex, setSelectedSuiteIndex] = useState(null)
  const [selectedSuiteShareIndex, setSelectedSuiteShareIndex] = useState(null)
  useEffect(() => {
    if (companySuite) {
      const selectedSuite = suites.filter(suite => suite.id === companySuite.suite_id)
      if (selectedSuite && selectedSuite.length > 0 && selectedSuite[0].id) {
        setSelectedSuiteIndex(selectedSuite[0].id)
      }
      const selectedSuiteShare = suiteShares.filter(suiteShare => suiteShare.id === companySuite.suite_share_id)
      if (selectedSuiteShare && selectedSuiteShare.length > 0 && selectedSuiteShare[0].id) {
        setSelectedSuiteShareIndex(selectedSuiteShare[0].id)
      }
    }
  }, [companySuite, companySuite.suite_id, companySuite.suite_share_id, suiteShares, suites])

  const setCompanySuite = (event) => {
    setSuites(index, event.target.value)
  }

  const setCompanySuiteShare = (event) => {
    setSuiteShare(index, event.target.value)
  }

  return (
    <div>
      {
        selectedSuiteIndex && getFilterSelector('', '', selectedSuiteIndex, setCompanySuite, suites, 'display_name', { selectorWidth: '250px' }, false, false)
      }
      {
        selectedSuiteShareIndex && getFilterSelector('', '', selectedSuiteShareIndex, setCompanySuiteShare, suiteShares, 'name', { selectorWidth: '250px' }, false, false)
      }
      <IconButton aria-label="delete" onClick={() => remove(companySuite, index)} className={classes.deleteSuiteButton}>
        <DeleteIcon />
      </IconButton>
      { companySuite.active_since && <span className={classes.activeSinceText}>Effective {moment(companySuite.active_since, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MMM DD, YYYY')}</span> }
    </div>
  )
}

EditCompanySuites.propTypes = {
  classes: PropTypes.object,
  suites: PropTypes.array,
  suiteShares: PropTypes.array,
  companySuite: PropTypes.array,
  setSuites: PropTypes.func,
  setSuiteShare: PropTypes.func,
  index: PropTypes.string,
  remove: PropTypes.func
}

export default EditCompanySuites

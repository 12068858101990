const styles = {
  cartQuickView: {
    minHeight: '225px',
    width: '500px',
    backgroundColor: '#FFFFFF',
    color: 'black',
    position: 'absolute',
    right: '0',
    padding: '10px 0px 5px',
    zIndex: '999'
  },
  cartQuickViewTitle: {
    height: '24px',
    color: '#000000',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px',
    padding: '10px 20px',
    marginBottom: '12px'
  },
  lineItemsContainer: {
    maxHeight: '150px',
    overflow: 'auto',
    width: '55%'
  },
  lineItemContainer: {
    padding: '10px 15px',
    display: 'flex'
  },
  lineItemQuantity: {
    height: '15px',
    width: '17px',
    color: '#080808',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px'
  },
  lineItemName: {
    height: 'auto',
    width: '136px',
    color: '#080808',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px',
    paddingLeft: '10px'
  },
  lineItemPrice: {
    height: '15px',
    width: '70px',
    color: '#000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px',
    textAlign: 'right'
  },
  cartQuickViewActions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    margin: '10px 15px'
  },
  proceedToCheckoutButton: {
    height: '50px',
    backgroundColor: '#0078D4',
    width: '214px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    borderRadius: '0px',
    boxShadow: 'none'
  },
  emptyCartButton: {
    height: '50px',
    width: '214px',
    color: '#061860',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '36px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '0px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:active': {
      boxShadow: 'none'
    }
  },
  arrowToCartButton: {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 11.5px 12px 11.5px',
    borderColor: 'transparent transparent #ffffff transparent',
    margin: '5px 15px 0px'
  },
  orderBillContainer: {
    paddingLeft: '10px',
    borderLeft: '1px solid #999',
    width: '45%'
  },
  quantitySelector: {
    marginRight: '0px',
    marginLeft: 0,
    marginTop: 0
  },
  selectQuantity: {
    color: 'black',
    borderBottom: '1px dotted black',
    padding: '0px 0px 0px 1px !important',
    '&:focus': {
      backgroundColor: 'white'
    },
    minWidth: '16px',
    '& select': {
      paddingRight: '10px'
    }
  },
  deleteButtonWrapper: {
    marginLeft: '12px',
    padding: '0px',
    color: 'black',
    height: '15px',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  deleteIcon: {
    fontSize: '20px',
    margin: 'auto'
  },
  lineItemTitle: {
    height: 'auto',
    width: '136px',
    color: '#080808',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px'
  },
  emptyCart: {
    height: '24px',
    margin: '20px',
    fontSize: '16px',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontWeight: 300,
    lineHeight: '24px',
    float: 'left'
  },
  cartSummaryContainer: {
    display: 'flex',
    padding: '10px'
  },
  recommendationsContainer: {
    zIndex: '999999',
    marginLeft: '-30px'
  }
}
export default styles

module.exports.ReconciliationReportProperties = [
  {
    key: 'orderId',
    label: 'Order #',
    sortable: false
  },
  {
    key: 'eatecId',
    label: 'Eatec #',
    sortable: false
  },
  {
    key: 'companyName',
    label: 'Company',
    sortable: false
  },
  {
    key: 'suiteName',
    label: 'Suite',
    sortable: false
  },
  {
    key: 'igCheckNumber',
    label: 'IG Check',
    sortable: false
  },
  {
    key: 'eventDate',
    label: 'Event Date',
    sortable: false
  },
  {
    key: 'eventName',
    label: 'Event Name',
    sortable: false
  },
  {
    key: 'total',
    label: 'Total',
    sortable: false
  }
]

module.exports.InvoiceReportProperties = [
  {
    key: 'company_name',
    label: 'Company Name',
    sortable: false
  },
  {
    key: 'company_id',
    label: 'Customer Number',
    sortable: false
  },
  {
    key: 'company_address_ref',
    label: 'Address Reference',
    sortable: false
  },
  {
    key: 'suite_number',
    label: 'Suite Number',
    sortable: false
  },
  {
    key: 'order_id',
    label: 'Invoice Number',
    sortable: false
  },
  {
    key: 'ig_check_number',
    label: 'Check Number',
    sortable: false
  },
  {
    key: 'order_total',
    label: 'Amount',
    sortable: false
  },
  {
    key: 'event_code',
    label: 'Event',
    sortable: false
  },
  {
    key: 'event_date',
    label: 'Event Date',
    sortable: false
  },
  {
    key: 'event_description',
    label: 'Event Name',
    sortable: false
  },
  {
    key: 'account_code',
    label: 'Account Code',
    sortable: false
  }
]

module.exports.rowsPerPageOptions = [10, 25, 50]

module.exports.sortParamsMapping = {
}

module.exports.styles = {
  selectorWidth: '155px',
  searchFieldWidth: '250px'
}

module.exports.rowsForReconciliation = 'reconciliationReport'
module.exports.invoiceResourceName = 'reconciliationReport'

module.exports.rowsForInvoice = 'invoiceReport'
module.exports.reconciliationResourceName = 'invoiceReport'

import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styles from './DataContainer.css'

const DataContainer = ({ classes, className, children }) => (
  <div className={cx({
    [classes.dataContainer]: true,
    [className]: !!className
  })}>
    {children}
  </div>
)

DataContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.array
}

DataContainer.defaultProps = {
  className: null
}

export default withStyles(styles)(DataContainer)

import React from 'react'
import PropTypes from 'prop-types'
import EventsBySuiteList from '../../elements/EventsBySuiteList/EventsBySuiteList'
import EventsBySuiteListFilterBar from '../../elements/EventsBySuiteListFilterBar/EventsBySuiteListFilterBar'
import MoveOrdersDialogActions from './MoveOrdersDialogActions'

const MoveOrdersDialogContent = ({
  classes,
  eventTypes,
  events,
  filterEvents,
  targetEventId,
  onEventSelect,
  handleDialogClose,
  handleSubmit
}) => {
  return (
    <div className={classes.moveOrderDialogBody}>
      <EventsBySuiteListFilterBar
        classes={classes}
        eventTypes={eventTypes}
        filterEventsList={filterEvents}
      />
      <EventsBySuiteList
        events={events}
        eventId={targetEventId}
        onEventSelect={onEventSelect}
      />
      <MoveOrdersDialogActions
        classes={classes}
        handleDialogClose={handleDialogClose}
        handleSubmit={handleSubmit}
        eventId={targetEventId}
      />
    </div>
  )
}

MoveOrdersDialogContent.propTypes = {
  classes: PropTypes.object,
  eventTypes: PropTypes.array,
  events: PropTypes.array,
  filterEvents: PropTypes.func,
  handleDialogClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  onEventSelect: PropTypes.func,
  targetEventId: PropTypes.number
}

export default MoveOrdersDialogContent

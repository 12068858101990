import React from 'react'
import PropTypes from 'prop-types'

const AccountUpdateContent = ({ classes, accountUpdateErr }) => {
  return (
    <div className={classes.errorContainer}>
      <p>{accountUpdateErr.message}</p>
      <ul>
        {
          accountUpdateErr.orders && accountUpdateErr.orders.map(order => <li key={order.id}>#{order.id} Event: {order.event_name}</li>)
        }
      </ul>
    </div>
  )
}

AccountUpdateContent.propTypes = {
  accountUpdateErr: PropTypes.object,
  classes: PropTypes.object
}

export default AccountUpdateContent

import { processItemsList } from '../helpers/processItemsList'
import {
  ADD_STAFF_USER_TO_LIST,
  FETCH_STAFF_USERS,
  SET_STAFF_USER_TO_EDIT,
  UPDATE_STAFF_USER_TO_LIST,
  UPDATE_STAFF_USER_STATUS
} from '../actionTypes'

const initialState = {}

const processStaffUserData = staffUser => {
  const roleName = staffUser.role_name || staffUser.roleName
  const webStatus = staffUser.web_status || staffUser.webStatus
  const [firstName = '', lastName = ''] = staffUser.name.split(' ')
  const {
    id,
    name,
    email
  } = staffUser

  const webStatusText = webStatus ? 'Active' : 'Inactive'

  return {
    id,
    firstName,
    lastName,
    name,
    email,
    roleName: roleName.replace('_', ' '),
    webStatus,
    webStatusText
  }
}

const updateStaffUsersList = (staffUsersList, staffUserData) => {
  const { rows } = staffUsersList
  const indexOfUpdateItem = rows.findIndex(item => item.id === staffUserData.id)
  const processedData = processStaffUserData(staffUserData)
  rows[indexOfUpdateItem] = processedData
  staffUsersList.rows = rows.slice()
  return staffUsersList
}

export default function StaffUsersReducers (state = initialState, action) {
  switch (action.type) {
    case FETCH_STAFF_USERS:
      return {
        ...state,
        staffUsersList: processItemsList(action.payload, processStaffUserData)
      }
    case SET_STAFF_USER_TO_EDIT:
      const { user = {} } = action.payload
      return {
        ...state,
        staffUserToEdit: user,
        message: ''
      }
    case UPDATE_STAFF_USER_TO_LIST:
      const { staffUsersList, staffUserData, message } = action.payload
      return {
        ...state,
        staffUsersList: updateStaffUsersList(staffUsersList, staffUserData),
        message
      }
    case UPDATE_STAFF_USER_STATUS:
      const { staffUsersList: usersList, staffUserData: userData } = action.payload
      return {
        ...state,
        staffUsersList: updateStaffUsersList(usersList, userData),
        staffUserToEdit: userData
      }
    case ADD_STAFF_USER_TO_LIST:
      const { message: addUserResponse, staffUsersList: usersListFromStore } = action.payload
      return {
        ...state,
        staffUsersList: usersListFromStore,
        message: addUserResponse
      }
    default:
      return state
  }
}

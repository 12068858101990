import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Tabs, Tab, Typography, Box } from '@material-ui/core'

import { getFilterSelector } from '../../elements/FilterSelector/getFilterSelector'
import AccountUpdateContent from '../AccountUpdate/AccountUpdateContent'
import AccountUpdateActions from '../AccountUpdate/AccountUpdateActions'
import SuiteHolderForm from './SuiteHolderForm'
import SuitesList from './SuitesList'
import PaymentCardList from './PaymentCardList'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function BasicTabs ({ tab1Content, tab2Content, suiteHolderUser }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getUserLabel = () => {
    return !suiteHolderUser.id ? 'Add Suite Holder User' : 'Edit Suite Holder User'
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={getUserLabel()} {...a11yProps(0)} />
        <Tab label="Credit Cards" {...a11yProps(1)} disabled={!suiteHolderUser.id} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {tab1Content}
      </TabPanel >
      <TabPanel value={value} index={1}>
        {tab2Content}
      </TabPanel>
    </>
  )
}

BasicTabs.propTypes = {
  suiteHolderUser: PropTypes.object,
  tab1Content: PropTypes.node,
  tab2Content: PropTypes.node
}
const EditSuiteHolderUserContent = ({
  classes,
  suiteHolderUser = {},
  companies,
  suites,
  rolesList,
  setFirstName,
  setLastName,
  setEmail,
  setContactNumber,
  setSHUserCompanies,
  setRole,
  handleSuitesUpdate,
  handleSuitesInvoiceUpdate,
  paymentDetails,
  handleCardDelete,
  cardDeletionErr,
  companyId,
  cardPresentToAdd,
  dialogActions
}) => {
  const { roleId, id: suiteHolderUserId } = suiteHolderUser

  const handleSubmit = () => {
    dialogActions?.props?.handleCancel()
  }

  const tab1Content = <Grid container spacing={3}>
    <SuiteHolderForm classes={classes} suiteHolderUser={suiteHolderUser} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setContactNumber={setContactNumber} />
    <Grid item xs={6}>
      {companies[0] && getFilterSelector('', '', companyId, setSHUserCompanies, companies, 'name', { selectorWidth: '100%' }, false, false, '', !!companyId)}
    </Grid>
    <Grid item xs={6} className={classes.inputContainer}>
      {rolesList && getFilterSelector('', '', roleId, setRole, rolesList, 'name', { selectorWidth: '100%' }, false, false, '')}
    </Grid>
    <SuitesList classes={classes} suites={suites} handleSuitesUpdate={handleSuitesUpdate} handleSuitesInvoiceUpdate={handleSuitesInvoiceUpdate} />
    {dialogActions}
  </Grid>

  const tab2Content = <>
    <Grid container spacing={3}>
      {
        cardDeletionErr && <AccountUpdateContent
          classes={classes}
          accountUpdateErr={cardDeletionErr}
        />
      }
      {
        cardDeletionErr && <AccountUpdateActions
          classes={classes}
          onSubmit={handleSubmit}
        />
      }
      {
        !cardPresentToAdd && !cardDeletionErr &&
        <PaymentCardList
          classes={classes}
          paymentDetails={paymentDetails}
          handleCardDelete={handleCardDelete}
          suiteHolderUserId={suiteHolderUserId}
        />
      }
    </Grid>
  </>

  return (
    <div className={classes.suiteHolderUserDetailsContainer}>
      <BasicTabs suiteHolderUser={suiteHolderUser} tab1Content={tab1Content} tab2Content={tab2Content} />
    </div>
  )
}

EditSuiteHolderUserContent.propTypes = {
  companyId: PropTypes.number,
  classes: PropTypes.object,
  suiteHolderUser: PropTypes.object,
  setFirstName: PropTypes.func,
  setLastName: PropTypes.func,
  setEmail: PropTypes.func,
  setRole: PropTypes.func,
  setSHUserCompanies: PropTypes.func,
  setContactNumber: PropTypes.func,
  rolesList: PropTypes.array,
  handleSuitesUpdate: PropTypes.func,
  suites: PropTypes.array,
  companies: PropTypes.array,
  handleSuitesInvoiceUpdate: PropTypes.func,
  paymentDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  handleCardDelete: PropTypes.func,
  cardDeletionErr: PropTypes.object,
  cardPresentToAdd: PropTypes.bool,
  dialogActions: PropTypes.node
}

export default EditSuiteHolderUserContent

const styles = {
  genericPaperStyles: {
    minHeight: '650px',
    minWidth: '662px',
    maxWidth: '662px',
    borderRadius: '0px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '0px'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    overflowY: 'unset',
    padding: '40px 40px 10px'
  },
  genericDialogActionRoot: {
    margin: 0,
    backgroundColor: 'white',
    display: 'initial',
    padding: '0px 40px 20px'
  }
}

export default styles

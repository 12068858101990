import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { inSuiteOptionsLabel } from '../../constants/OrderDetails'
import styles from './InSuiteActionOptions.css'
const {
  hostnameLabel,
  itemAdditionsByLabel,
  inSuitePaymentLabel,
  signatureRequiredLabel,
  parInstructionsLabel
} = inSuiteOptionsLabel
const InSuiteActionOptions = ({
  classes,
  checkoutOptionsSummary: {
    hostName = '',
    signatureRequired = '',
    itemAdditionsBy = '',
    chargeAdditionsTo = '',
    liquorCabinetAccess = ''
  } = {}
}) => {
  const inSuiteActionOptions = [
    {
      label: hostnameLabel,
      value: hostName
    },
    {
      label: itemAdditionsByLabel,
      value: itemAdditionsBy
    },
    {
      label: inSuitePaymentLabel,
      value: chargeAdditionsTo
    },
    {
      label: signatureRequiredLabel,
      value: signatureRequired
    },
    {
      label: parInstructionsLabel,
      value: liquorCabinetAccess
    }
  ]
  return (
    <div className={classes.inSuiteActionOptionsDetails}>
      {
        inSuiteActionOptions.map(option => {
          return <div key={option.label} className={`${classes.optionContainer}`}>
            <div className={classes.inSuiteActionOptionsLabel}>
              {option.label}
            </div>
            <div className={classes.inSuiteActionOptionsValue}>
              {option.value}
            </div>
          </div>
        })
      }
    </div>
  )
}

InSuiteActionOptions.propTypes = {
  classes: PropTypes.object,
  checkoutOptionsSummary: PropTypes.object
}

export default withStyles(styles)(InSuiteActionOptions)

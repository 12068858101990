import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const AccountUpdateActions = ({ classes, onSubmit }) => {
  return (
    <div className={classes.actionContainer}>
      <Button variant="outlined" color="primary" className={classes.submitButton} onClick={onSubmit}>
        Ok
      </Button>
    </div>
  )
}

AccountUpdateActions.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

export default AccountUpdateActions

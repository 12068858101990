import React from 'react'
import PropTypes from 'prop-types'
import Lens from '@material-ui/icons/Lens'
import useStyles from './Bubble.css'

const Bubble = ({ currentIndex, index, showSelected }) => {
  const classes = useStyles({ currentIndex, index })
  const handleClick = () => {
    showSelected(index)
  }
  return (
    <div>
      <Lens className={classes.bubble} onClick={handleClick} />
    </div>
  )
}

Bubble.propTypes = {
  currentIndex: PropTypes.number,
  index: PropTypes.number,
  showSelected: PropTypes.func
}

export default Bubble

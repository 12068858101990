export const styles = {
  genericPaperStyles: {
    minHeight: '714px',
    minWidth: '870px'
  },
  genericDialogActionRoot: {
    margin: 0,
    padding: '15px 0px 20px 0px',
    backgroundColor: 'white',
    display: 'initial'
  },
  genericDialogContentRoot: {
    backgroundColor: 'white',
    color: 'black',
    padding: '0px 40px 0px 40px'
  },
  genericDialogTitleRoot: {
    margin: 0,
    backgroundColor: 'white',
    padding: '15px 40px 0px 40px'
  }
}

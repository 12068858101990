import moment from 'moment-timezone'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DatePicker from 'react-datepicker'
import InputLabel from '@material-ui/core/InputLabel'
import InputField from '../../elements/InputField/InputField'
import ImageField from '../../elements/ImageField/ImageField'
import StatusField from '../../elements/StatusField/StatusField'
import FilterSelector from '../../elements/FilterSelector/FilterSelector'
import { DEFAULT_IMAGE_URL } from '../../constants/events'
import EditEventDialogActions from './EditEventDialogActions'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './EditEventDialogContent.css'

const FIELD_LABELS = {
  bannerImage: 'BANNER',
  eventType: 'EVENT TYPE',
  description: 'DESCRIPTION',
  doorOpenTime: 'DOOR OPEN TIME',
  kosherCutoff: 'KOSHER CUTOFF',
  name: 'NAME',
  nonKosherCutoff: 'NONKOSHER CUTOFF',
  startDate: 'START DATE',
  startTime: 'START TIME',
  thumbnailImage: 'THUMBNAIL',
  ticketMasterEvent: 'TICKETMASTER EVENT'
}

const DEFAULT_DATE_TIME_FORMAT = 'MMM dd | h:mm aa'

const getKosherCutoffTime = (event = {}) => {
  const { cut_off_by_menu_types: cutoffTimes = {} } = event
  const { kosher = {} } = cutoffTimes
  if (kosher.is_available) {
    const timeWithoutOffset = +`${kosher.client_cut_off_in_int}000`
    const offset = new Date(timeWithoutOffset).getTimezoneOffset()
    return +moment.utc(timeWithoutOffset).utcOffset(offset)
  } else {
    return null
  }
}

const getNonKosherCutoffTime = (event = {}) => {
  const { cut_off_by_menu_types: cutoffTimes = {} } = event
  const { non_kosher: nonKosher = {} } = cutoffTimes
  if (nonKosher.is_available) {
    const timeWithoutOffset = +`${nonKosher.client_cut_off_in_int}000`
    const offset = new Date(timeWithoutOffset).getTimezoneOffset()
    return +moment.utc(timeWithoutOffset).utcOffset(offset)
  } else {
    return null
  }
}

const EditEventDialogContent = ({
  classes,
  event = {},
  eventEngineEvents,
  eventCategories,
  handleUpdate,
  handleCancel
}) => {
  const [bannerImage, setBannerImage] = useState(null)
  const [bannerImageUrl, setBannerImageUrl] = useState('')
  const [doorOpenTime, setDoorOpenTime] = useState(null)
  const [description, setDescription] = useState('')
  const [eventCategory, setEventCategory] = useState('')
  const [id, setItemId] = useState(null)
  const [kosherCutoffTime, setKosherCutoffTime] = useState(null)
  const [name, setName] = useState('')
  const [nonKosherCutoffTime, setNonKosherCutoffTime] = useState(null)
  const [status, setStatus] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState('')
  const [thumbnailImage, setThumbnailImage] = useState(null)
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('')
  const [ticketMasterEventId, setTicketMasterEventId] = useState('')

  const handleEventCategoryChange = (event) => {
    setEventCategory(event.target.value)
  }

  const handleTicketMasterEventSelection = (event) => {
    setTicketMasterEventId(event.target.value)
  }

  const handleUpdateButtonClick = () => {
    const updatedEvent = {
      bannerImage,
      description,
      doorOpenTime,
      eventCategory,
      id,
      name,
      kosherCutoffTime: kosherCutoffTime ? moment(kosherCutoffTime) : null,
      nonKosherCutoffTime: nonKosherCutoffTime ? moment(nonKosherCutoffTime) : null,
      status,
      startTime,
      thumbnailImage,
      ticketMasterEventId
    }
    handleUpdate(updatedEvent)
  }

  useEffect(() => {
    if (event) {
      setItemId(event.id)
      setName(event.web_name)
      setStatus(event.status)
      setDescription(event.web_description)
      setStartDate(event.start_date)
      setBannerImageUrl(event.banner)
      setThumbnailImageUrl(event.thumbnail)
      setEventCategory(event.event_category_id)
      setStartTime(event.start_time)
      setDoorOpenTime(event.doors)
      setKosherCutoffTime(getKosherCutoffTime(event))
      setNonKosherCutoffTime(getNonKosherCutoffTime(event))
      setTicketMasterEventId(event.ticket_master_event_id)
    }
  }, [event])

  return (
    <div className={classes.editEventContent}>
      <div className={classes.editEditInputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.eventEditInputFields
          }}
          placeholder={'Name'}
          id={'editEventName'}
          label={FIELD_LABELS.name}
          value={name}
          changeHandler={setName}
          showLabel
        />
      </div>
      <div className={classes.eventEditInputContainer}>
        <InputField
          classes={{
            inputLabelRoot: classes.fieldTitle,
            inputRoot: classes.eventEditInputFields
          }}
          placeholder={'Description'}
          id={'editEventDescription'}
          label={FIELD_LABELS.description}
          value={description}
          changeHandler={setDescription}
          showLabel
        />
      </div>
      <div className={classes.eventTypeSelectorContainer}>
        <FilterSelector
          name={'ticketMasterEventSelector'}
          label={FIELD_LABELS.ticketMasterEvent}
          value={ticketMasterEventId}
          changeHandler={handleTicketMasterEventSelection}
          selectionOptions={eventEngineEvents}
          optionValueKey={'title'}
          styles={{ selectorWidth: '530px' }}
          defaultOption={'Select Event'}
          shrink
        />
      </div>
      <div className={classes.row}>
        <div className={classes.startDateFieldContainer}>
          <InputField
            classes={{
              inputLabelRoot: classes.fieldTitle,
              inputRoot: classes.startDateInput
            }}
            placeholder={'Start Date'}
            id={'editEventStartDate'}
            label={FIELD_LABELS.startDate}
            value={startDate}
            disabled
            showLabel
          />
        </div>
        <StatusField
          classes={{
            container: classes.statusFieldContainer,
            title: classes.fieldTitle
          }}
          currentStatus={status}
          setStatus={setStatus}
        />
        <div className={classes.eventTypeSelectorContainer}>
          <FilterSelector
            name={'eventType'}
            label={FIELD_LABELS.eventType}
            value={eventCategory}
            changeHandler={handleEventCategoryChange}
            selectionOptions={eventCategories}
            optionValueKey={'name'}
            styles={{ selectorWidth: '130px' }}
          />
        </div>
      </div>
      <div className={classes.dateTimePickersContainer}>
        <div className={classes.timeFieldContainer}>
          <InputLabel className={classes.fieldTitle}>
            {FIELD_LABELS.startTime}
          </InputLabel>
          { startTime && <DatePicker
            selected={moment(startTime, 'hh:mm A').toDate()}
            onChange={date => {
              setStartTime(moment(date).format('hh:mm A'))
            }}
            calendarClassName={classes.reactDatePicker}
            className={classes.timeInputField}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            withPortal
          />}
        </div>
        <div className={classes.timeFieldContainer}>
          <InputLabel className={classes.fieldTitle}>
            {FIELD_LABELS.doorOpenTime}
          </InputLabel>
          {doorOpenTime && <DatePicker
            selected={moment(doorOpenTime, 'hh:mm A').toDate()}
            onChange={date => {
              setDoorOpenTime(moment(date).format('hh:mm A'))
            }}
            className={classes.timeInputField}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            withPortal
            calendarClassName={classes.reactDatePicker}
          />}
        </div>
        <div className={classes.dateTimeFieldContainer}>
          <InputLabel className={classes.fieldTitle}>
            {FIELD_LABELS.nonKosherCutoff}
          </InputLabel>
          {
            nonKosherCutoffTime
              ? <DatePicker
                showPopperArrow={false}
                selected={nonKosherCutoffTime}
                onChange={date => {
                  setNonKosherCutoffTime(date)
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat={DEFAULT_DATE_TIME_FORMAT}
                className={classes.dateTimeInputField}
                withPortal
                minDate={new Date()}
                maxDate={moment(startDate).toDate()}
                calendarClassName={classes.reactDatePicker}
              />
              : <span>NA</span>
          }
        </div>
        <div className={classes.dateTimeFieldContainer}>
          <InputLabel className={classes.fieldTitle}>
            {FIELD_LABELS.kosherCutoff}
          </InputLabel>
          {
            kosherCutoffTime
              ? <DatePicker
                selected={kosherCutoffTime}
                onChange={date => {
                  setKosherCutoffTime(date)
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat={DEFAULT_DATE_TIME_FORMAT}
                className={classes.dateTimeInputField}
                withPortal
                minDate={new Date()}
                maxDate={moment(startDate).toDate()}
                calendarClassName={classes.reactDatePicker}
              />
              : <span>NA</span>
          }
        </div>
      </div>
      <div className={classes.imageSelectorsContainer}>
        <ImageField
          imageUrl={thumbnailImageUrl || DEFAULT_IMAGE_URL}
          setImageUrl={setThumbnailImageUrl}
          event={event}
          classes={{ container: classes.imageFieldContainer }}
          setImage={setThumbnailImage}
          label={FIELD_LABELS.thumbnailImage}
          fieldId={'eventThumbanilUrl'}
        />
        <ImageField
          imageUrl={bannerImageUrl || DEFAULT_IMAGE_URL}
          setImageUrl={setBannerImageUrl}
          event={event}
          setImage={setBannerImage}
          label={FIELD_LABELS.bannerImage}
          classes={{ container: classes.imageFieldContainer }}
          fieldId={'eventBannerUrl'}
        />
      </div>
      <div className={classes.actionsContainer}>
        <EditEventDialogActions
          classes={classes}
          handleUpdate={handleUpdateButtonClick}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  )
}

EditEventDialogContent.propTypes = {
  classes: PropTypes.object,
  event: PropTypes.object,
  eventEngineEvents: PropTypes.array,
  eventCategories: PropTypes.array,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func
}

export default withStyles(styles)(EditEventDialogContent)

import { FETCH_MENU_CATALOG_BY_SUITE } from '../actionTypes'
import store, { history } from '../store'
import { logoutUser } from '../authentication/actions'
import { menuItemEndpoints } from '../../constants/apiConfig'
import { cleanUpFilterObject } from '../helpers/cleanupFilters'
import { getItemsList } from '../helpers/apiClient'
import { updateMenuImageUrls } from '../../utils/imageUtils'

const prepareMenuListParams = (filters, suiteId, eventId, menuTypeId) => {
  const { categoryId, subcategoryId } = cleanUpFilterObject(filters)
  return {
    filters: {
      category_id: categoryId,
      subcategory_id: subcategoryId
    },
    suite_id: suiteId,
    event_id: eventId,
    menu_type_id: menuTypeId
  }
}

const prepareRecentlyOrderedMenuListParams = (menuListParams, userId) => {
  return {
    ...menuListParams,
    submitted_for: userId,
    recently_ordered_items: true
  }
}

const changeMenuCatelogImageUrlsToHttps = (menuItems) => {
  return menuItems.map(menuItem => {
    const { image } = menuItem
    const clonedImageObj = { ...image }
    updateMenuImageUrls(clonedImageObj)
    return {
      ...menuItem,
      ...{
        image: clonedImageObj
      }
    }
  })
}

const updateCategory = (menuItems) => {
  return menuItems.map(menuItem => {
    return {
      ...menuItem,
      categories: [{ name: 'Previously Ordered' }]
    }
  })
}

export const fetchMenuItemsCatalogBySuite = (filters = {}, suiteId, eventId, menuTypeId, userId) => async dispatch => {
  const {
    user: currentUser
  } = store.getState().authReducer
  let endpointData
  if (currentUser.role_id === 1 || currentUser.role_id === 2) {
    endpointData = menuItemEndpoints.bySuite
  } else {
    endpointData = menuItemEndpoints.suiteHolderUserMenuItems(currentUser.id)
  }
  const { method, url } = endpointData
  const data = prepareMenuListParams(filters, suiteId, eventId, menuTypeId)
  const recentlyOrderedParams = prepareRecentlyOrderedMenuListParams(data, userId)
  const menuItemsCatalogs = await getItemsList(method, url, data)
  const recentOrders = await getItemsList(method, url, recentlyOrderedParams)
  try {
    const { data: menuItemsData = {} } = menuItemsCatalogs
    const { data: recentOrdersData = {} } = recentOrders
    const { menu_items: menuItems = [] } = menuItemsData
    const updatedMenuItems = changeMenuCatelogImageUrlsToHttps(menuItems)
    const { menu_items: recentOrdersMenuItems = [] } = recentOrdersData
    const updatedCategoryForRecentOrders = updateCategory(recentOrdersMenuItems)
    const updatedRecentOrdersMenuItems = changeMenuCatelogImageUrlsToHttps(updatedCategoryForRecentOrders)
    dispatch({
      type: FETCH_MENU_CATALOG_BY_SUITE,
      payload: { menuItems: updatedMenuItems, recentOrdersMenuItems: updatedRecentOrdersMenuItems }
    })
  } catch (error) {
    console.log(error)
    dispatch(logoutUser(history))
  }
}

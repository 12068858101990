const styles = {
  eventDetailsContainer: {
    height: 'auto',
    width: '1100px',
    display: 'flex'
  },
  eventImageContainer: {
    height: 'auto',
    width: '200px'
  },
  eventBasicDetailsContainer: {
    marginLeft: '40px',
    marginRight: '40px',
    width: '500px'
  },
  eventWindow: {
    height: '14px',
    width: '274px',
    color: '#65FFF5',
    fontFamily: 'Ringside Wide A, Ringside Wide B',
    fontSize: '10px',
    fontWeight: '600',
    letterSpacing: '1px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  eventName: {
    height: '24px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Condensed A, Ringside Condensed B',
    fontSize: '20px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  eventEatecId: {
    height: '24px',
    width: '274px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px'
  },
  noOrdersText: {
    height: '18px',
    width: '108px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    marginTop: '16px',
    float: 'left'
  },
  placeOrderButton: {
    width: '225px',
    color: '#FFFFFF',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '0px',
    height: '50px',
    marginTop: '27px'
  },
  eventThumbnail: {
    height: 'auto',
    width: 'auto'
  },
  genericFabPill: {
    background: '#65FFF5',
    boxShadow: 'none',
    height: '26px',
    lineHeight: '88px',
    textAlign: 'center',
    border: '1px solid #000000',
    fontFamily: 'Ringside Regular A, Ringside Regular B',
    borderRadius: '13px',
    marginTop: '12px',
    cursor: 'auto',
    '& span': {
      height: '18px',
      color: '#061860',
      fontFamily: 'Ringside Regular A, Ringside Regular B',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '18px',
      textTransform: 'none'
    },
    '&:hover': {
      color: '#061860',
      background: '#65FFF5',
      boxShadow: 'none'
    }
  }
}

export default styles

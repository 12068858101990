import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import ConfirmationTitle from './ConfirmationTitle'
import ConfirmationActions from './ConfirmationActions'
import styles from './Confirmation.css'

const Confirmation = ({
  classes,
  isOpen,
  onClose,
  onSubmit,
  message
}) => {
  return (
    <Dialog
      openDialog={isOpen}
      dialogTitle={<ConfirmationTitle className={classes.title} />}
      dialogContent={
        <Fragment>
          <div className={classes.confirmationMessage}>{message}</div>
        </Fragment>
      }
      dialogActions={
        <ConfirmationActions
          classes={classes}
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      }
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={onClose}
    />
  )
}

Confirmation.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string
}

Confirmation.defaultProps = {
  classes: {},
  isOpen: false
}

export default withStyles(styles)(Confirmation)

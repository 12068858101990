import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'

const SettingsMenu = ({ settingsMenuBuilder, item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOptions = settingsMenuBuilder && settingsMenuBuilder(item)

  function handleClick (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  const menuItemsList = menuOptions.map(({ action, label, enabled = true }, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        setAnchorEl(null)
        action(item)
      }}
      disabled={!enabled}
    >
      {label}
    </MenuItem>
  ))

  return (
    <div>
      <SettingsIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItemsList}
      </Menu>
    </div>
  )
}

SettingsMenu.propTypes = {
  item: PropTypes.object.isRequired,
  settingsMenuBuilder: PropTypes.func.isRequired
}

export default SettingsMenu

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '../../elements/Dialog/Dialog'
import AccountUpdateActions from './AccountUpdateActions'
import AccountUpdateContent from './AccountUpdateContent'
import styles from './AccountUpdate.css'

const AccountUpdate = ({
  classes,
  isOpen,
  onClose,
  onSubmit,
  accountUpdateErr
}) => {
  return (
    <Dialog
      openDialog={isOpen}
      dialogContent={
        accountUpdateErr && <Fragment>
          <div className="open-fields">
            <AccountUpdateContent
              classes={classes}
              accountUpdateErr={accountUpdateErr}
            />
          </div>
        </Fragment>
      }
      dialogActions={
        <AccountUpdateActions
          classes={classes}
          onSubmit={onSubmit}
        />
      }
      classes={{
        genericPaperStyles: classes.genericPaperStyles,
        genericDialogActionRoot: classes.genericDialogActionRoot,
        genericDialogContentRoot: classes.genericDialogContentRoot,
        genericDialogTitleRoot: classes.genericDialogTitleRoot
      }}
      onDialogClose={onClose}
    />
  )
}

AccountUpdate.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  accountUpdateErr: PropTypes.object
}

AccountUpdate.defaultProps = {
  classes: {},
  isOpen: false
}

export default withStyles(styles)(AccountUpdate)

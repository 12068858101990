import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import InputField from '../../elements/InputField/InputField'
import CustomButton from '../../elements/CustomButton/CustomButton'
import { updateCardName } from '../../store/creditCard/actions'

const CardNameUpdateForm = ({
  classes,
  updateCardName,
  newCreditCard,
  closeCCModal,
  stopEventDispatchForClientUser = false
}) => {
  const [cardName, setCardName] = useState()

  const saveCard = () => {
    updateCardName(newCreditCard.id, { cardName }, stopEventDispatchForClientUser)
    if (closeCCModal) {
      closeCCModal()
    }
  }

  return (
    <>
      {
        newCreditCard && <Grid item xs={12} className={classes.cardNameContainer}>
          <p>Please provide a short name for future reference.</p>
          <InputField
            classes={{
              inputLabelRoot: classes.fieldTitle,
              inputRoot: classes.nameOnCardInput
            }}
            placeholder={'Card Nickname'}
            id={'addCreditCardName'}
            label={'Card Nickname'}
            value={cardName}
            changeHandler={setCardName}
            showLabel
          />
          <CustomButton
            variant={'contained'}
            disableRipple
            styleClass={classes.addCardButton}
            label={'Set Card Name'}
            color={'primary'}
            disabled={!cardName}
            clickHandler={saveCard}
          />
        </Grid>
      }
    </>
  )
}

CardNameUpdateForm.propTypes = {
  classes: PropTypes.object,
  newCreditCard: PropTypes.object,
  closeCCModal: PropTypes.func,
  stopEventDispatchForClientUser: PropTypes.bool,
  updateCardName: PropTypes.func.isRequired
}

const mapStateToProps = ({ creditCardsReducers }) => {
  return {
    newCreditCard: creditCardsReducers.newCreditCard
  }
}

const mapDispatchToProps = {
  updateCardName
}

export default connect(mapStateToProps, mapDispatchToProps)(CardNameUpdateForm)

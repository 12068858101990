const styles = {
  cartIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cartIconWrapper: {
    height: '48px',
    width: '48px',
    backgroundColor: '#65FFF5',
    '&:hover': {
      backgroundColor: '#65FFF5'
    }
  },
  cartIconSvg: {
    height: '24.62px',
    width: '28px',
    color: '#061860'
  },
  itemCountBadge: {
    marginTop: '30px'
  }
}

export default styles

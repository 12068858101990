module.exports.eventSuitesProperties = [
  {
    key: 'suiteName',
    label: 'SUITE NAME',
    sortable: true
  },
  {
    key: 'formattedPrice',
    label: 'PRICE',
    sortable: false
  },
  {
    key: 'status',
    label: 'STATUS',
    sortable: false
  }
]

module.exports.sortParamsMapping = {
  suiteName: 'suites.number'
}

module.exports.rowsPerPageOptions = [10, 25, 50]
module.exports.ctaLabel = 'Add Suite'

module.exports.styles = {
  selectorWidth: '210px',
  searchFieldWidth: '320px'
}

module.exports.suitesFilterStyles = {
  selectorWidth: '300px',
  searchFieldWidth: '580px'
}

module.exports.rowsFor = 'eventSuites'
module.exports.resourceName = 'eventSuites'
